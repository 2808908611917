import {
  TextField,
  Autocomplete,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { COLOR } from 'betterlesson-library-react';
import { Control, Controller } from 'react-hook-form';
import { BasicProfileType } from '@app-types/User';
import { SORTED_TIMEZONES } from '@constants/timezone';

type Props = {
  control: Control<BasicProfileType>;
  error?: string;
  onTimezoneChange: (timezone: string) => void;
};

export default function TimezoneSelect({
  control,
  error,
  onTimezoneChange,
}: Readonly<Props>): JSX.Element {
  const handleAutoDetectTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    onTimezoneChange(timezone);
  };

  return (
    <Stack spacing={0.5}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle1" component="div">
          Time Zone
        </Typography>

        <Button
          data-testid="timezone-autocomplete-auto-detect-button"
          onClick={handleAutoDetectTimezone}
          sx={{ color: COLOR.blue[700] }}
        >
          Auto-detect Timezone
        </Button>
      </Stack>

      <Controller
        control={control}
        name="zoneinfo"
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            data-testid="timezone-autocomplete"
            options={SORTED_TIMEZONES}
            value={value || ''}
            getOptionLabel={(o) => o.replace(/_/g, ' ')}
            onChange={(_e, data) => onChange(data)}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { paddingTop: 0, paddingBottom: 0 },
                }}
                error={!!error}
                helperText={error}
              />
            )}
          />
        )}
      />
    </Stack>
  );
}
