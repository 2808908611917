import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';
import TabLabel from '../TabLabel/TabLabel';

export type Props = {
  children: JSX.Element;
};

export const TEST_ID = {
  CONTAINER: 'coach-home-tabs',
  COACHING_TAB: 'coach-home-tabs-coaching-tab',
  WORKSHOPS_TAB: 'coach-home-tabs-workshops-tab',
};

export default function CoachHomeTabs({ children }: Props): JSX.Element {
  const { coachUuid = '' } = useParams();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname.endsWith('/home')
    ? `${location.pathname}/coaching`
    : location.pathname;

  const renderTabs = () => (
    <Tabs
      sx={{ overflow: 'visible' }}
      orientation={isTablet ? 'horizontal' : 'vertical'}
      variant="scrollable"
      value={pathname}
      onChange={(_, value: string) => navigate(value)}
    >
      <Tab
        data-testid={TEST_ID.COACHING_TAB}
        label={
          <TabLabel
            label="Coaching"
            isActive={pathname.endsWith('coaching')}
            activeColor={COLOR_PALETTE.secondary.dark}
            Icon={PeopleRoundedIcon}
          />
        }
        value={`/coach/${coachUuid}/home/coaching`}
      />
      <Tab
        data-testid={TEST_ID.WORKSHOPS_TAB}
        label={
          <TabLabel
            label="Workshops"
            isActive={pathname.endsWith('workshops')}
            activeColor={COLOR_PALETTE.info.dark}
            Icon={DesignServicesRoundedIcon}
          />
        }
        value={`/coach/${coachUuid}/home/workshops`}
      />
    </Tabs>
  );

  const renderLayout = () =>
    isTablet ? (
      <>
        <Box flexGrow={1} display="flex" pb={2.5}>
          {renderTabs()}
        </Box>
        {children}
      </>
    ) : (
      <Box flexGrow={1} display="flex">
        {renderTabs()}
        {children}
      </Box>
    );
  return (
    <NavigationContainer>
      <Stack px={2} py={2.5} data-testid={TEST_ID.CONTAINER}>
        <Typography variant="h1" fontWeight={450} fontSize={36} pb={2.5}>
          Coach Dashboard
        </Typography>

        {renderLayout()}
      </Stack>
    </NavigationContainer>
  );
}
