import { atom } from 'jotai';
import { getFacilitatorWorkshops } from '@apis/facilitators';
import { FacilitatorWorkshopType } from '@app-types/Facilitator';
import { UserWorkshopResponseType } from '@app-types/Workshop';

const _atom = atom<UserWorkshopResponseType[]>([]);

export const userWorkshopResponseAtom = atom(
  (get) => get(_atom),
  (_get, set, updateAtom: UserWorkshopResponseType[]) => {
    set(_atom, updateAtom);
  }
);

export const facilitatorWorkshopsAtom = atom<FacilitatorWorkshopType[]>([]);

export const setFacilitatorWorkshopsAtom = atom(null, async (_get, set) => {
  const facilitatorWorkshops = await getFacilitatorWorkshops();
  set(facilitatorWorkshopsAtom, facilitatorWorkshops);
});
