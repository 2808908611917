import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Popover, Typography, Stack, Link } from '@mui/material';
import { COLOR, COLOR_PALETTE } from 'betterlesson-library-react';
import React from 'react';
import { FocusAreaType } from '@app-types/Invite';

interface FocusAreaPopoverProps {
  focusArea: FocusAreaType | null;
}

const FocusAreaPopover: React.FC<FocusAreaPopoverProps> = ({ focusArea }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!focusArea) {
    return <></>;
  }

  const getBackgroundColor = () => {
    switch (focusArea.elementName) {
      case 'flexibleInstructionalModels':
        return COLOR.flexibleInstructionalModels[600];
      case 'instructionalLeadership':
        return COLOR.instructionalLeadership[600];
      case 'curriculumAndAcademicContent':
        return COLOR.curriculumAndAcademicContent[600];
      case 'inclusiveAndResponsivePractices':
        return COLOR.inclusiveAndResponsivePractices[600];
      default:
        return COLOR.flexibleInstructionalModels[600];
    }
  };

  return (
    <Stack p={1} data-testid="focus-area-popover">
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          backgroundColor: getBackgroundColor(),
        }}
        data-testid="focus-area-popover-button"
      >
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
          }}
          key={focusArea.title}
        >
          {focusArea.title}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: '25rem',
            maxWidth: '80%',
            padding: '1rem',
            mt: '0.5rem',
            borderRadius: '0.4rem',
            boxShadow: '0px 0px 0.3rem rgba(0, 0, 0, 0.5)',
          },
        }}
        data-testid="focus-area-popover-popover"
      >
        <Typography
          sx={{ p: 1 }}
          variant="h5"
          component="div"
          key={focusArea.title}
        >
          <Link
            href={`${process.env.REACT_APP_TEACHING_URL}/browse/learning-domain/${focusArea.quickbaseId}?isQbId=true`}
            target="_blank"
            rel="noreferrer"
            color={COLOR_PALETTE.primary.light}
            underline="none"
            sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            data-testid="focus-area-popover-link"
          >
            {focusArea.title}
            <OpenInNewIcon />
          </Link>
        </Typography>
        <Typography sx={{ p: 1 }}>{focusArea.tagline}</Typography>
      </Popover>
    </Stack>
  );
};

export default FocusAreaPopover;
