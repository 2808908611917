import { CSSProperties } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './FormInput.module.css';

type Props = {
  autoComplete?: string;
  register: UseFormRegisterReturn;
  placeholder: string;
  error: string | boolean | undefined;
  type: string;
  className?: string;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
  displayErrorMessage?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
};

export default function FormInput({
  autoComplete,
  register,
  placeholder,
  error,
  type,
  className,
  handleKeyDown,
  displayErrorMessage = true,
  children,
}: Props): JSX.Element {
  const renderErrorText = () =>
    error && typeof error === 'string' ? (
      <span className={styles.errorText} data-testid={`${register.name}-error`}>
        {error}
      </span>
    ) : (
      <div style={{ height: children ? '60px' : '20px' }} />
    );

  const dynamicStyles: CSSProperties = {
    flexDirection: children ? 'row' : 'column',
  };

  return (
    <div className={styles.inputContainer} style={dynamicStyles}>
      <input
        {...register}
        id={register.name}
        className={`${styles.input} ${
          error && styles.errorInput
        } ${className} ${children ? styles.hasChildren : ''}`}
        autoComplete={autoComplete}
        data-testid={`${register.name}-input`}
        placeholder={placeholder}
        type={type}
        onKeyDown={handleKeyDown}
      />
      {children}
      {displayErrorMessage && renderErrorText()}
    </div>
  );
}
