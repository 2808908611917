import { Typography, Stack, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { AdditionalInformationType } from '@app-types/CoachingRegistrationStages';
import PreviousCoachPreference from './PreviousCoachPreference';

interface AdditionalInformationProps {
  previousCoach?: string;
  onContinue: () => void;
  onGoBack: () => void;
  additionalInformation: AdditionalInformationType;
  onChangeAdditionalInformation: (
    additionalInformation: AdditionalInformationType
  ) => void;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  previousCoach,
  onGoBack,
  onContinue,
  additionalInformation,
  onChangeAdditionalInformation,
}) => {
  const [additionalText, setAdditionalText] = useState<string>(
    additionalInformation.additionalText
  );
  const [prefersPreviousCoach, setPreferPreviousCoach] = useState<
    boolean | null
  >(additionalInformation.prefersPreviousCoach);

  const handleSubmitAdditionalInformationAndSubmit = () => {
    onChangeAdditionalInformation({
      additionalText: additionalText,
      prefersPreviousCoach: prefersPreviousCoach,
    });
    onContinue();
  };

  const handleSubmitAdditionalInformationAndGoBack = () => {
    onChangeAdditionalInformation({
      additionalText: additionalText,
      prefersPreviousCoach: prefersPreviousCoach,
    });

    onGoBack();
  };

  const handleAdditionalTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAdditionalText(event.target.value);
  };

  const handlePreviousCoachChange = (previousCoach: boolean | null) => {
    setPreferPreviousCoach(previousCoach);
  };

  const renderNavigationButtons = () => (
    <Stack width="50%" spacing={1} justifySelf="flex-end">
      <Button
        onClick={handleSubmitAdditionalInformationAndSubmit}
        variant="contained"
        data-testid="additional-information-continue-button"
      >
        Continue
      </Button>
      <Button
        onClick={handleSubmitAdditionalInformationAndGoBack}
        variant="text"
        data-testid="additional-information-go-back-button"
      >
        Go Back
      </Button>
    </Stack>
  );

  return (
    <Stack
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      data-testid="coaching-registration-additional-information"
      flexBasis={0}
      flexGrow={1}
    >
      <Stack height="100%" justifyContent="center" alignItems="center">
        <Typography fontWeight={500} textAlign="center" pb={2}>
          Is there anything else you want to tell us before we begin?
        </Typography>
        <TextField
          fullWidth
          multiline
          data-testid="additional-information-additional-text"
          inputProps={{
            'data-testid': 'additional-information-additional-text-input',
          }}
          id="outlined-basic"
          variant="outlined"
          value={additionalText}
          onChange={(event) => handleAdditionalTextChange(event)}
          minRows={5}
          placeholder="How are you feeling about coaching?&#10;e.g. Excited, cautiously optimistic&#10;Do you have any coaching preferences?&#10;e.g., I am working with this curriculum for the first time."
          InputProps={{
            sx: { height: '100%' },
          }}
        />
        {previousCoach && (
          <PreviousCoachPreference
            previousCoach={previousCoach}
            prefersPreviousCoach={prefersPreviousCoach}
            onCoachChange={handlePreviousCoachChange}
          />
        )}
      </Stack>

      {renderNavigationButtons()}
    </Stack>
  );
};

export default AdditionalInformation;
