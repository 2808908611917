import {
  Stack,
  Typography,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import {
  TEACHER_SUBJECT,
  TEACHER_SUBJECT_LABEL_MAP,
} from '@constants/professional-roles';
import { EducatorProfileType } from '@pages/EducatorProfile/types/EducatorProfile';

type Props = {
  control: Control<EducatorProfileType>;
  error?: string;
};

export default function SubjectMultiSelect({
  control,
  error,
}: Readonly<Props>): JSX.Element {
  const { fields, replace } = useFieldArray({
    rules: { minLength: 1 },
    control,
    name: 'professionalRole.subjects',
  });

  const handleSubjectChange = (_event: SyntheticEvent, subjects: string[]) => {
    replace(subjects.map((subject) => ({ subject })));
  };

  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle1" component="div">
        Supporting Specializations
      </Typography>

      <Autocomplete
        multiple
        id="autocomplete"
        data-testid="subject-multi-select"
        options={Object.values(TEACHER_SUBJECT) as string[]}
        getOptionLabel={(option) => TEACHER_SUBJECT_LABEL_MAP.get(option) ?? ''}
        value={fields.map(({ subject }) => subject)}
        onChange={handleSubjectChange}
        disableCloseOnSelect
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              sx={{
                '& .MuiChip-deleteIcon': {
                  color: '#fff',
                },
              }}
              label={TEACHER_SUBJECT_LABEL_MAP.get(option) ?? ''}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              sx: { height: '100%' },
              style: {
                paddingTop: '2.5px',
                paddingBottom: '2.5px',
                cursor: 'pointer',
              },
            }}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              style: { cursor: 'pointer' },
            }}
          />
        )}
      />
    </Stack>
  );
}
