import { Stack, Typography } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { TEACHER_SUBJECT } from '@constants/professional-roles';
import AnswerCard from '../AnswerCard/AnswerCard';

type Props = {
  subjects?: string[];
  onSubjectsChange: (subjects: string[]) => void;
};

export default function SubjectQuestion({
  subjects = [],
  onSubjectsChange,
}: Readonly<Props>): JSX.Element {
  const handleSubjectCardClick = (newSubject: string) => () => {
    const updatedSubjects = subjects.includes(newSubject)
      ? subjects.filter((s) => s !== newSubject)
      : [...subjects, newSubject];

    onSubjectsChange(updatedSubjects);
  };

  return (
    <Stack pt={6} pb={4.5} width={1} spacing={0.5}>
      <Typography variant="h6" component="p" fontSize="13px">
        I SPEND MOST OF MY TIME...
      </Typography>

      <Typography
        variant="body2"
        component="p"
        fontSize="13px"
        fontStyle="italic"
        color={COLOR_PALETTE.text.primary}
        pb={1}
      >
        Select all that apply
      </Typography>

      <AnswerCard
        title="Supporting core subjects"
        description="Math, ELA, etc."
        imageSrc="/teacher-subject/core.svg"
        imageAlt="Core Subject Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={subjects.includes(TEACHER_SUBJECT.CORE)}
        onClick={handleSubjectCardClick(TEACHER_SUBJECT.CORE)}
      />

      <AnswerCard
        title="Supporting special subjects or electives"
        description="Art, Music, etc."
        imageSrc="/teacher-subject/elective.svg"
        imageAlt="Elective Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={subjects.includes(TEACHER_SUBJECT.ELECTIVE)}
        onClick={handleSubjectCardClick(TEACHER_SUBJECT.ELECTIVE)}
      />

      <AnswerCard
        title="Supporting students with special needs"
        description="Inclusive or outside of a general education setting"
        imageSrc="/teacher-subject/special.svg"
        imageAlt="Special Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={subjects.includes(TEACHER_SUBJECT.SPECIAL_NEED)}
        onClick={handleSubjectCardClick(TEACHER_SUBJECT.SPECIAL_NEED)}
      />

      <AnswerCard
        title="Supporting English language learners"
        description="Students with limited proficiency"
        imageSrc="/teacher-subject/el.svg"
        imageAlt="EL Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={subjects.includes(TEACHER_SUBJECT.EL)}
        onClick={handleSubjectCardClick(TEACHER_SUBJECT.EL)}
      />

      <AnswerCard
        title="Focusing on other topics"
        description="With specializations that don't match the above"
        imageSrc="/teacher-subject/other.svg"
        imageAlt="Other Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={subjects.includes(TEACHER_SUBJECT.OTHER)}
        onClick={handleSubjectCardClick(TEACHER_SUBJECT.OTHER)}
      />
    </Stack>
  );
}
