import { LoadingPage } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AccessToken } from '@app-types/Token';
import { redirectToHome } from '@utils/HomePageHandler';
import {
  ImpersonateFactory,
  useImpersonatedUserErrorHandler,
} from '@utils/ImpersonateFactory';

export default function NavigateHome(): JSX.Element {
  const { userUuid = '' } = useParams();
  const handleImpersonatedUserError = useImpersonatedUserErrorHandler();

  const navigateToUsersHomePage = async () => {
    const impersonatedUser = await ImpersonateFactory.impersonateUser(userUuid);

    if (impersonatedUser.isInvalid()) {
      handleImpersonatedUserError(impersonatedUser.invalidReason);
      return;
    }

    const accessToken = jwtDecode<AccessToken>(impersonatedUser.accessToken);
    redirectToHome(accessToken);
  };

  useEffect(() => {
    navigateToUsersHomePage();
  }, []);

  return <LoadingPage data-testid="navigate-home" />;
}
