import {
  ArrowForwardRounded,
  DesignServices,
  ExpandLessRounded,
  GroupRounded,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { COLOR, COLOR_PALETTE } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { EventType } from '@app-types/Event';
import { EVENT_TYPE } from '@constants/event';
import { convertDate } from '@utils/Date';
import styles from './styles.module.scss';

const dividerStyles = {
  background: COLOR_PALETTE.divider,
  height: '.125rem',
  margin: '1rem 0',
};

type Props = {
  events: EventType[];
  isStatusCompleted?: boolean;
};

const LESS_COUNT = 3;

export const UpcomingEvents = ({
  events,
  isStatusCompleted,
}: Props): React.ReactElement => {
  const [isLessShown, setIsLessShown] = useState(true);

  const toggleList = () => {
    setIsLessShown(!isLessShown);
  };

  const renderIcon = (type: string) => {
    switch (type) {
      case EVENT_TYPE.COACHING:
        return <GroupRounded sx={{ color: COLOR.oceanTeal[700] }} />;
      default:
        return <DesignServices sx={{ color: COLOR.blue[900] }} />;
    }
  };

  const eventTypeColor = (type: string) => {
    switch (type) {
      case EVENT_TYPE.COACHING:
        return COLOR.oceanTeal[700];
      default:
        return COLOR.blue[900];
    }
  };

  const getShownEvents = () => {
    if (!isLessShown) {
      return events;
    }

    return events.slice(0, LESS_COUNT);
  };

  const heading = isStatusCompleted
    ? 'Completed'
    : 'Upcoming Professional Learning';

  const noEventsMessage = isStatusCompleted
    ? 'There are no completed events.'
    : 'There are no upcoming events at this time.';

  return (
    <>
      <Stack data-testid="upcoming-events">
        <Typography variant="h4">{heading}</Typography>

        <Divider style={{ ...dividerStyles }} />

        {events.length ? (
          <Accordion component={Paper} elevation={0} expanded={true}>
            <AccordionSummary style={{ display: 'none' }} />

            <AccordionDetails
              className={styles.fontStyleNormal}
              style={{ padding: 0 }}
            >
              <Table>
                <TableBody>
                  {getShownEvents().map((event, i: number) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Typography
                            display="flex"
                            variant="overline1"
                            alignItems="center"
                            color={eventTypeColor(event.type)}
                            sx={{ columnGap: 1 }}
                          >
                            {renderIcon(event.type)}
                            {event.type}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Stack>
                            <Typography
                              variant="link1"
                              fontWeight={500}
                              color={COLOR_PALETTE.text.primary}
                            >
                              {convertDate(
                                event.startTime,
                                DateTime.DATETIME_FULL
                              )}
                            </Typography>
                            <Typography
                              variant="link1"
                              color={COLOR_PALETTE.primary.dark}
                            >
                              {event.location}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="body3"
                            color={COLOR_PALETTE.text.secondary}
                          >
                            {event.title}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          {event.detailUrl && (
                            <Button href={event.detailUrl} target="_blank">
                              <Stack direction="row" spacing={1}>
                                <Typography color="inherit">
                                  View Details
                                </Typography>
                                <ArrowForwardRounded />
                              </Stack>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Typography fontStyle="italic" py="1rem">
            {noEventsMessage}
          </Typography>
        )}
      </Stack>

      {events.length > LESS_COUNT && (
        <Stack direction="row" mt="0.5rem">
          <Button disableRipple variant="text" onClick={toggleList}>
            <ExpandLessRounded
              sx={{
                transform: isLessShown ? 'rotate(180deg)' : 'rotate(0)',
              }}
            />
            <Typography color="inherit" fontWeight={600}>
              Show {isLessShown ? 'More' : 'Less'}
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  );
};
