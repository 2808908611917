import { Stack, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { LaunchDarklyFactory } from '@utils/LaunchDarklyFactory';

export default function UserProfilesFeatureRequired({
  children,
}: Readonly<{
  children: JSX.Element;
}>): JSX.Element {
  const { user } = useAuth();
  const [hasFeatureFlag, setHasFeatureFlag] = useState(false);

  const initializeFeatureFlag = async () => {
    const hasDynamicOnboarding = await LaunchDarklyFactory.hasUserProfiles(
      user?.profile
    );

    if (hasDynamicOnboarding) {
      setHasFeatureFlag(hasDynamicOnboarding);
      return;
    }

    return window.location.replace(`${process.env.REACT_APP_LAB_URL}/404`);
  };

  useEffect(() => {
    initializeFeatureFlag();
  }, []);

  if (!hasFeatureFlag) {
    return (
      <Stack
        data-testid="user-profiles-feature-required-loading"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  return children;
}
