import { CSSProperties } from 'react';

export const boxStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '.5rem',
};

export const inputStyle: CSSProperties = {
  paddingLeft: '1rem',
  paddingRight: '1rem',
  backgroundColor: 'var(--color-light)',
  zIndex: 1,
};

export const selectStyle: CSSProperties = {
  height: '100%',
};
