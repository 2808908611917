import { CSSProperties } from 'react';

export const alertStyle: CSSProperties = {
  backgroundColor: 'var(--color-alert-background)',
};

export const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 600,
};

export const snackbarStyle: CSSProperties = {
  bottom: 0,
  flexDirection: 'row-reverse',
  left: 0,
  width: '100%',
};
