import { v4 as uuid } from 'uuid';
import ChevronIcon from '@images/icons/chevron.svg';
import { onEnterKey } from '@utils/key-handler';
import styles from './Pagination.module.css';

type Props = {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
};

export default function Pagination({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}: Props): JSX.Element {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    if (pageCount < 7) {
      return Array(pageCount)
        .fill(null)
        .map((_, index) => index + 1);
    }

    if (currentPage <= 5) {
      return [1, 2, 3, 4, 5, null, pageCount];
    }

    if (currentPage > pageCount - 5) {
      return [
        1,
        null,
        pageCount - 4,
        pageCount - 3,
        pageCount - 2,
        pageCount - 1,
        pageCount,
      ];
    }

    return [
      1,
      null,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      null,
      pageCount,
    ];
  };

  const handlePageClick = (pageNumber: number) => () => {
    if (pageNumber === currentPage) {
      return;
    }

    onPageChange(pageNumber);
  };

  const renderPageNumbers = () =>
    getPageNumbers().map((pageNumber: number | null) =>
      pageNumber ? (
        <div // eslint-disable-line jsx-a11y/no-static-element-interactions
          className={`${styles.pageButton} ${
            pageNumber === currentPage && styles.selected
          }`}
          key={uuid()}
          onClick={handlePageClick(pageNumber)}
          onKeyDown={onEnterKey(handlePageClick(pageNumber))}
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        >
          {pageNumber}
        </div>
      ) : (
        <div className={styles.ellipsis} key={uuid()}>
          ...
        </div>
      )
    );

  const handlePreviousClick = () => {
    if (currentPage === 1) {
      return;
    }

    onPageChange(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentPage === pageCount) {
      return;
    }

    onPageChange(currentPage + 1);
  };

  return itemsPerPage < totalItems ? (
    <div className={styles.container} data-testid="pagination">
      <div className={styles.pagination}>
        <img // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
          data-testid="left-arrow"
          className={styles.beforeIcon}
          src={ChevronIcon}
          onClick={handlePreviousClick}
          onKeyDown={onEnterKey(handlePreviousClick)}
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          alt="left-arrow"
        />
        {renderPageNumbers()}
        <img // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
          data-testid="right-arrow"
          className={styles.nextIcon}
          src={ChevronIcon}
          onClick={handleNextClick}
          onKeyDown={onEnterKey(handleNextClick)}
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          alt="right-arrow"
        />
      </div>
    </div>
  ) : (
    <div className={styles.spacer} />
  );
}
