export const GRADE_OPTIONS = Object.freeze([
  'Kindergarten',
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
]);

export const GRADE_LEVEL_OPTIONS = Object.freeze(['K-2', '3-5', '6-8', '9-12']);
