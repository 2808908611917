import { DesignServices, GroupRounded } from '@mui/icons-material';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { COLOR } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import { EventType } from '@app-types/Event';
import { EVENT_TYPE } from '@constants/event';
import { convertDate } from '@utils/Date';

type Props = {
  event: EventType;
};

export const TEST_ID = {
  CONTAINER: 'event',
};

export const Event = ({ event }: Props): JSX.Element => {
  const getToolTipText = () => {
    return event.type === EVENT_TYPE.COACHING
      ? 'Use this link to join your coaching session.'
      : 'Zoom links will be available 10 minutes before a virtual session.';
  };

  const renderIcon = () => {
    switch (event.type) {
      case EVENT_TYPE.COACHING:
        return <GroupRounded sx={{ color: COLOR.oceanTeal[700] }} />;
      default:
        return <DesignServices sx={{ color: COLOR.blue[900] }} />;
    }
  };

  return (
    event && (
      <Grid
        data-testid={TEST_ID.CONTAINER}
        alignItems="center"
        border={`solid thin ${COLOR.instructionalLeadership[700]}`}
        container
        item
        mb="1rem"
        sx={{
          backgroundColor: 'rgba(255, 139, 0, .04)',
          borderRadius: '.5rem',
        }}
      >
        <Grid item xs={6} md={3} p="1rem">
          <Tooltip arrow title={getToolTipText()}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              href={event.accessUrl}
            >
              {event.type === EVENT_TYPE.COACHING && 'Join Now'}

              {event.type !== EVENT_TYPE.COACHING && 'Check In'}
            </Button>
          </Tooltip>
        </Grid>

        <Grid alignItems="center" item xs={6} md={9} p="1rem">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing="2rem">
            <Stack spacing=".25rem">
              <Typography
                color={COLOR.instructionalLeadership[800]}
                variant="body3"
              >
                Starting Now
              </Typography>

              <Typography variant="subtitle1">
                {convertDate(event.startTime, DateTime.DATE_FULL)}
              </Typography>

              <Typography variant="subtitle1">
                {convertDate(event.startTime, DateTime.TIME_SIMPLE)}
              </Typography>
            </Stack>

            <Stack alignItems={{ xs: 'start', sm: 'center' }} direction="row">
              <Stack direction="row" alignItems="center">
                {renderIcon()}

                <Typography
                  display={{ xs: 'none', sm: 'block' }}
                  pl={1}
                  color={COLOR.grey['A400']}
                  variant="overline1"
                >
                  {event.type}
                </Typography>
              </Stack>

              <Typography ml={{ xs: 'auto', sm: '1rem' }} variant="body3">
                {event.title}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    )
  );
};
