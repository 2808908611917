import { Stack, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

const formatQuantity = (quantity?: number) => {
  if (!quantity) {
    return '00';
  }

  return quantity > 0 && quantity < 10 ? `0${quantity}` : quantity;
};

export type Availability = {
  productInstance: string;
  quantityTotal: number;
  quantityAssigned: number;
  quantityActivated: number;
};

type Props = {
  availabilities: Availability[];
  error?: boolean;
};

export default function ProductInstanceAvailability({
  availabilities,
  error,
}: Props): JSX.Element {
  const renderQuantity = (quantity?: number) => (
    <Typography
      tabIndex={0}
      variant="body1"
      component="span"
      color={error ? 'var(--color-error)' : 'var(--color-grey-2)'}
      data-testid="quantity-text"
    >
      <strong>{formatQuantity(quantity)}</strong>
    </Typography>
  );

  const renderAvailability = (
    availability?: Omit<Availability, 'productInstance'>
  ) => (
    <>
      <Typography tabIndex={0}>
        Total: {renderQuantity(availability?.quantityTotal)}
      </Typography>

      <Typography tabIndex={0}>
        Invited: {renderQuantity(availability?.quantityAssigned)}
      </Typography>

      <Typography tabIndex={0}>
        Activated: {renderQuantity(availability?.quantityActivated)}
      </Typography>
    </>
  );

  if (availabilities.length <= 1) {
    return (
      <Typography
        variant="body2"
        component="div"
        color={error ? 'var(--color-error)' : 'var(--color-grey-2)'}
        data-testid="availability-text"
      >
        {renderAvailability(availabilities[0])}
      </Typography>
    );
  }

  return (
    <Stack spacing={2}>
      {availabilities.map(({ productInstance, ...availability }) => (
        <Typography
          key={uuid()}
          component="div"
          color={error ? 'var(--color-error)' : 'var(--color-grey-2)'}
          data-testid="availability-text"
        >
          <Typography tabIndex={0}>{productInstance}</Typography>
          {renderAvailability(availability)}
        </Typography>
      ))}
    </Stack>
  );
}
