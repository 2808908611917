import { Grid } from '@mui/material';
import { UnifiedFooter, UnifiedHeaderV2 } from 'betterlesson-library-react';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { getUserNavLinksV2 } from '@apis/users';
import { navLinksAtom } from '@atoms/navLinksAtoms';

export default function NavigationContainer({
  children,
  disableFooter = false, // Add a prop to disable the footer
}: {
  children: JSX.Element | JSX.Element[];
  disableFooter?: boolean;
}): JSX.Element {
  const [navLinks, setNavLinks] = useAtom(navLinksAtom);

  const getNavLinks = async () => {
    const results = await getUserNavLinksV2();
    setNavLinks(results);
  };

  useEffect(() => {
    void getNavLinks();
  }, []);
  return (
    <>
      <div id="content">
        <UnifiedHeaderV2 navData={navLinks} featureFlagsTurnedOn={true} />

        <Grid container minHeight="100%">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </div>
      {!disableFooter && <UnifiedFooter />}{' '}
    </>
  );
}
