import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { COLOR, COLOR_PALETTE } from 'betterlesson-library-react';
import {
  ModificationLevel,
  SessionGroupType,
  SessionStatus,
} from '@app-types/Workshop';

export type Props = {
  sessionGroup: SessionGroupType;
};
export const TEST_ID = {
  MODIFIED_FINALIZED: 'workshop-status-chips-modified-finalized',
  MODIFIED_PENDING: 'workshop-status-chips-modified-pending',
  CUSTOM_FINALIZED: 'workshop-status-chips-custom-finalized',
  CUSTOM_PENDING: 'workshop-status-chips-custom-pending',
  READY_FOR_DELIVERY: 'workshop-status-chips-ready',
  CANCELED: 'workshop-status-chips-canceled',
};

type StatusChipType = {
  label: string;
  color: string;
  tooltip: string;
  dataTestId: string;
  shouldDisplay: () => boolean;
};

export default function WorkshopStatusChips({
  sessionGroup,
}: Props): JSX.Element {
  const STATUS_CHIP: Record<string, StatusChipType> = Object.freeze({
    MODIFIED_FINALIZED: {
      label: 'Modified',
      color: COLOR_PALETTE.primary.main,
      tooltip: 'Materials modified complete',
      dataTestId: TEST_ID.MODIFIED_FINALIZED,
      shouldDisplay: () =>
        sessionGroup.allModifications.some(
          (m) => m.modificationLevel === ModificationLevel.MODIFICATION
        ) && sessionGroup.modificationsFinalized,
    },
    MODIFIED_PENDING: {
      label: 'Modified',
      color: COLOR.grey[700],
      tooltip: 'Materials awaiting modification',
      dataTestId: TEST_ID.MODIFIED_PENDING,
      shouldDisplay: () =>
        sessionGroup.allModifications.some(
          (m) => m.modificationLevel === ModificationLevel.MODIFICATION
        ) && !sessionGroup.modificationsFinalized,
    },
    CUSTOM_FINALIZED: {
      label: 'Custom',
      color: COLOR_PALETTE.primary.main,
      tooltip: 'Materials customization complete',
      dataTestId: TEST_ID.CUSTOM_FINALIZED,
      shouldDisplay: () =>
        sessionGroup.isSessionCustom && sessionGroup.modificationsFinalized,
    },
    CUSTOM_PENDING: {
      label: 'Custom',
      color: COLOR.grey[700],
      tooltip: 'Materials awaiting customization',
      dataTestId: TEST_ID.CUSTOM_PENDING,
      shouldDisplay: () =>
        sessionGroup.isSessionCustom && !sessionGroup.modificationsFinalized,
    },
    READY_FOR_DELIVERY: {
      label: 'Ready',
      color: COLOR_PALETTE.primary.main,
      tooltip: 'Ready to deliver',
      dataTestId: TEST_ID.READY_FOR_DELIVERY,
      shouldDisplay: () => sessionGroup.isReadyForDelivery,
    },
    CANCELED: {
      label: 'Canceled',
      color: COLOR_PALETTE.error.main,
      tooltip: '',
      dataTestId: TEST_ID.CANCELED,
      shouldDisplay: () =>
        sessionGroup.sessionStatus === SessionStatus.CANCELLED,
    },
  });

  const renderChip = (statusChip: StatusChipType) => {
    if (!statusChip.shouldDisplay()) {
      return <></>;
    }

    return (
      <Tooltip arrow tabIndex={0} title={statusChip.tooltip}>
        <Chip
          data-testid={statusChip.dataTestId}
          label={
            <Typography
              component="a"
              fontWeight={700}
              fontSize={13}
              fontStyle="normal"
              style={{
                backgroundColor: statusChip.color,
                color: COLOR_PALETTE.textLight.high,
                textDecoration: 'none',
              }}
              href={`${process.env.REACT_APP_WORKSHOP_WEB_URL}/${sessionGroup.workshopUuid}`}
              target="_blank"
            >
              {statusChip.label}
            </Typography>
          }
          style={{
            backgroundColor: statusChip.color,
            marginBottom: '.5rem',
            marginRight: '.25rem',
          }}
          variant="filled"
        />
      </Tooltip>
    );
  };

  return (
    <Stack width="fit-content">
      {renderChip(STATUS_CHIP.MODIFIED_FINALIZED)}
      {renderChip(STATUS_CHIP.MODIFIED_PENDING)}
      {renderChip(STATUS_CHIP.CUSTOM_FINALIZED)}
      {renderChip(STATUS_CHIP.CUSTOM_PENDING)}
      {renderChip(STATUS_CHIP.READY_FOR_DELIVERY)}
      {renderChip(STATUS_CHIP.CANCELED)}
    </Stack>
  );
}
