import { Button, Grid, Stack, Typography } from '@mui/material/';
import structuredClone from '@ungap/structured-clone';
import { toast } from 'betterlesson-library-react';
import { useContext } from 'react';
import {
  updateRolesAndProducts,
  updateUserPerms,
} from '@apis/deprecated/roles-products';
import { HIDDEN_PRODUCTS } from '@constants/product';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import MultiEdit from './components/MultiEdit';
import SingleEdit from './components/SingleEdit';
import { PermissionsProps, ProductRole } from './types';

export default function Permissions({
  onClose,
  isSuperAdmin,
}: PermissionsProps): JSX.Element {
  const { checkedUserIds }: ProductsContextType = useContext(ProductsContext);
  let multiUserRoles: ProductRole[] = [];
  let singleUserRoles: Record<string, string[]> = {};

  const handleMultiUserRoleChange = (newRoles: ProductRole[]) => {
    multiUserRoles.length = 0;
    multiUserRoles = [...newRoles];
  };

  const handleSingleUserRoleChange = (newRoles: Record<string, string[]>) => {
    singleUserRoles = structuredClone(newRoles);
  };

  const showToast = (response: boolean): void => {
    if (response) {
      toast.success('Roles and Products successfully updated!', {
        className: 'toast-notify-success',
      });
      onClose();
    } else
      toast.error('Roles and Products not updated!', {
        className: 'toast-notify-error',
      });
  };

  const onSubmit = async () => {
    let response;

    if (Array.from(checkedUserIds).length > 1) {
      response = await updateRolesAndProducts(
        multiUserRoles.map((role) => role.id),
        Array.from(checkedUserIds)
      );
      showToast(response);
    } else {
      const update = Object.entries(singleUserRoles)
        .filter(([productName]) => !HIDDEN_PRODUCTS.includes(productName))
        .map(([productName, roleNames]) => ({
          product: productName,
          roles: roleNames,
        }));

      response = await updateUserPerms(Array.from(checkedUserIds)[0], update);
      showToast(response);
    }
  };

  const isDisabled = (): boolean =>
    !isSuperAdmin || !Array.from(checkedUserIds).length;

  return (
    <Stack
      p={2}
      bgcolor="var(--color-light)"
      sx={{ pointerEvents: 'auto', overflowY: 'auto' }}
      height="calc(100% - 32px)"
    >
      <Typography mb={1} component="h3" fontWeight="bold">
        APPLICATION AND ROLE PERMISSIONS
      </Typography>

      <Typography mb={2} paragraph variant="body2">
        Add a role for the users selected to the ones selected below. A user
        with existing permissions to a product will have their role updated.
      </Typography>

      <Stack marginBottom="1rem" paddingTop=".5rem">
        {Array.from(checkedUserIds).length === 1 ? (
          <SingleEdit
            isSuperAdmin={isSuperAdmin}
            onRoleChange={handleSingleUserRoleChange}
          />
        ) : (
          <MultiEdit onRoleChange={handleMultiUserRoleChange} />
        )}
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            data-testid="cancel"
            fullWidth
            variant="outlined"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            data-testid="apply-changes"
            disabled={isDisabled()}
            fullWidth
            variant="contained"
            onClick={() => onSubmit()}
          >
            Apply Changes
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}
