import { DateTime, DateTimeFormatOptions } from 'luxon';

export const convertDate = (
  d: number,
  format: DateTimeFormatOptions
): string => {
  if (!d || !format) return '';
  const date = DateTime.fromMillis(d).toLocaleString(format);
  return date;
};
