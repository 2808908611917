/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import ReactSelect, { Props, GroupBase, StylesConfig } from 'react-select';
import { OptionType } from '@app-types/Select';
import ChevronIcon from '@images/icons/chevron.svg';
import styles from './Select.module.css';
import SingleValue from './components/SingleValue/SingleValue';
import variables from '@variables.module.css';

type SelectProps = Props<OptionType, false, GroupBase<OptionType>> & {
  error?: boolean;
  label?: string;
  displayClearableCard?: boolean;
  isSecondaryPlaceholderStyle?: boolean;
};

export default function Select({
  error,
  label,
  displayClearableCard = true,
  isSecondaryPlaceholderStyle,
  ...selectProps
}: SelectProps): JSX.Element {
  const getBorderStyle = (isFocused: boolean) => {
    if (error) {
      return {
        borderColor: variables.error,
        boxShadow: '',
      };
    }

    if (isFocused) {
      return {
        borderColor: variables['vivid-blue'],
        boxShadow: `0 0 0 1px ${variables['vivid-blue']}`,
      };
    }

    return {
      borderColor: variables['slate-1'],
      boxShadow: '',
    };
  };

  const customStyles: StylesConfig<OptionType, false> = {
    indicatorSeparator: () => ({}),
    control: (provided, state) => ({
      ...provided,
      height: '40px',
      cursor: 'pointer',
      font: 'normal normal 300 14px/16px "Circular Std"',
      ...getBorderStyle(state.isFocused),
    }),
    singleValue: (provided) => ({
      ...provided,
      color: variables['link-blue'],
      font: 'normal normal 300 14px/18px "Circular Std"',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '5px 0 5px 10px',
      height: '100%',
    }),
    option: (provided, state) => ({
      ...provided,
      font: 'normal normal 300 14px/18px "Circular Std"',
      backgroundColor: state.isSelected
        ? variables['vivid-blue']
        : provided.backgroundColor,
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      ...(isSecondaryPlaceholderStyle
        ? {
            color: variables['slate-1'],
            font: 'normal normal 300 14px/16px "Circular Std"',
          }
        : {
            color: variables['link-blue'],
            font: 'normal normal 300 15px/19px "Circular Std"',
          }),
    }),
  };

  const renderLabel = () =>
    label ? <div className={styles.label}>{label}</div> : null;

  const DropdownIndicator = () =>
    displayClearableCard && !selectProps.value ? (
      <img className={styles.chevronIcon} src={ChevronIcon} alt="chevron" />
    ) : null;

  const components = displayClearableCard
    ? {
        ...selectProps.components,
        SingleValue,
      }
    : selectProps.components;

  return (
    <div>
      {renderLabel()}
      <ReactSelect
        {...selectProps}
        components={{
          DropdownIndicator,
          ...components,
        }}
        value={selectProps.value || null}
        styles={customStyles}
      />
    </div>
  );
}
