import { Link, ListItem, Tooltip, Typography } from '@mui/material';
import { Organization } from '@apis/deprecated/organizations';
import { UserRow } from '../../Types';
import styles from '../../UserTable.module.css';

type Props = {
  user: UserRow;
  handleClick: (user: UserRow) => void;
};

export default function UserTable({ user, handleClick }: Props): JSX.Element {
  const getOrgList = (organizations: Organization[]): JSX.Element[] => {
    return organizations.slice(1).map((org: Organization, index: number) => {
      return (
        <Typography
          color="var(--color-light)"
          component={ListItem}
          data-testid="tooltip"
          key={index}
          padding={0}
          textAlign="center"
          variant="caption"
        >
          {org.identifier}
        </Typography>
      );
    });
  };

  return (
    <div
      className={`${styles.rowCell} ${
        user.organizations.length > 1 ? styles.showMore : ''
      }`}
    >
      {user.organizations.length > 0 && (
        <Link
          data-testid="link"
          onClick={() => handleClick(user)}
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          underline="none"
        >
          <Typography
            color="var(--color-dark)"
            component="p"
            tabIndex={0}
            variant="caption"
          >
            {user.organizations[0].identifier}
          </Typography>

          <Typography
            color="var(--color-dark)"
            component="p"
            tabIndex={0}
            variant="caption"
          >
            {user.organizations[0].address &&
              user.organizations[0].address.region}
          </Typography>

          {user.organizations.length > 1 && (
            <Tooltip
              arrow
              data-testid="tooltip"
              tabIndex={0}
              title={getOrgList(user.organizations)}
            >
              <Typography
                color="var(--color-primary)"
                tabIndex={0}
                variant="caption"
              >
                +{user.organizations.length - 1} more
              </Typography>
            </Tooltip>
          )}
        </Link>
      )}
    </div>
  );
}
