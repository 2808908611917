import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import * as yup from 'yup';
import { createUsersProfessionalRole } from '@apis/professional-roles';
import { setCoachingPreferences } from '@apis/users';
import { educatorProfileAtom } from '@atoms/profile';
import ProfileActionBar from '@components/ProfileActionBar/ProfileActionBar';
import {
  ROLE_OPTIONS,
  TEACHER_EXPERIENCE_OPTIONS,
} from '@constants/professional-roles';
import EducatorProfileSelect from './components/EducatorProfileSelect/EducatorProfileSelect';
import GradeAutocomplete from './components/GradeAutocomplete/GradeAutocomplete';
import OrganizationInput from './components/OrganizationInput/OrganizationInput';
import SubjectAreaAutocomplete from './components/SubjectAreaAutocomplete/SubjectAreaAutocomplete';
import SubjectMultiSelect from './components/SubjectMultiSelect/SubjectMultiSelect';
import { EducatorProfileType } from './types/EducatorProfile';

const educatorProfileSchema = yup.object().shape({
  organization: yup.string(),
  professionalRole: yup.object().shape({
    role: yup.string().required('Cannot be empty!'),
    experience: yup.string().required('Cannot be empty!'),
    subjects: yup
      .array(yup.object().shape({ subject: yup.string() }))
      .min(1, 'Cannot be empty!'),
  }),
  coachingPreferences: yup.object().shape({
    grades: yup
      .array(yup.object().shape({ grade: yup.string() }))
      .min(1, 'Cannot be empty!'),
    subjectAreas: yup
      .array(yup.object().shape({ subjectArea: yup.string() }))
      .min(1, 'Cannot be empty!'),
  }),
});

export default function EducatorProfile(): JSX.Element {
  const { user } = useAuth();
  const userSub = user?.profile.sub ?? '';
  const [educatorProfile, setEducatorProfile] = useAtom(educatorProfileAtom);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<EducatorProfileType>({
    resolver: yupResolver(educatorProfileSchema),
    defaultValues: {
      organization: educatorProfile.organization,

      professionalRole: {
        role: educatorProfile.professionalRole.role,
        experience: educatorProfile.professionalRole.experience,
        subjects: educatorProfile.professionalRole.subjects.map((subject) => ({
          subject,
        })),
      },

      coachingPreferences: {
        grades: educatorProfile.coachingPreferences.grades.map((grade) => ({
          grade,
        })),
        subjects: educatorProfile.coachingPreferences.subjects.map(
          (subject) => ({ subject })
        ),
      },
    },
  });

  const onSubmit = async (updatedEducatorProfile: EducatorProfileType) => {
    const [professionalRole, userProfile] = await Promise.all([
      createUsersProfessionalRole(userSub, {
        role: updatedEducatorProfile.professionalRole.role,
        experience: updatedEducatorProfile.professionalRole.experience,
        subjects: updatedEducatorProfile.professionalRole.subjects.map(
          ({ subject }) => subject
        ),
      }),
      setCoachingPreferences(userSub, {
        ...educatorProfile.coachingPreferences,
        subjects: updatedEducatorProfile.coachingPreferences.subjects.map(
          ({ subject }) => subject
        ),
        grades: updatedEducatorProfile.coachingPreferences.grades.map(
          ({ grade }) => grade
        ),
      }),
    ]);

    if (!professionalRole || !userProfile) {
      return toast.error('Update to Educator profile failed.', {
        className: 'toast-notify-error',
      });
    }

    toast.success('Educator profile has been updated', {
      className: 'toast-notify-success',
    });

    reset(updatedEducatorProfile);
    setEducatorProfile({ professionalRole, userProfile });
  };

  return (
    <Stack py={2.5} spacing={2}>
      <OrganizationInput control={control} />

      <EducatorProfileSelect
        control={control}
        error={errors.professionalRole?.role?.message}
        name="role"
        label="Job Role"
        options={ROLE_OPTIONS}
      />

      <EducatorProfileSelect
        control={control}
        error={errors.professionalRole?.experience?.message}
        name="experience"
        label="Experience"
        options={TEACHER_EXPERIENCE_OPTIONS}
      />

      <SubjectMultiSelect
        control={control}
        error={errors.professionalRole?.subjects?.message}
      />

      <GradeAutocomplete
        control={control}
        error={errors.coachingPreferences?.grades?.message}
      />

      <SubjectAreaAutocomplete
        control={control}
        error={errors?.coachingPreferences?.subjects?.message}
      />

      <ProfileActionBar
        onSubmitClick={handleSubmit(onSubmit)}
        isSubmitDisabled={!isDirty}
      />
    </Stack>
  );
}
