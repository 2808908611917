import { toast } from 'betterlesson-library-react';

export const copyText = (text: string): void => {
  try {
    if (isSecureContext && navigator.clipboard) {
      void navigator.clipboard.writeText(text);
      toast.success('Copied to clipboard!');
    } else throw new Error('failed to copy!');
  } catch (err) {
    toast.error(`${err}`);
  }
};
