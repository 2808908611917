import axios from 'axios';
import {
  ProfessionalRole,
  ProfessionalRoleFormType,
} from '@app-types/ProfessionalRoles';
import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

export const getUsersProfessionalRole = async (
  userIdentifier?: string
): Promise<ProfessionalRole | null> => {
  if (!userIdentifier) {
    return null;
  }

  try {
    const response = await getUserServiceAxios().post(
      '/api/v2/professional-roles/queries/find-latest-professional-role',
      { userIdentifier }
    );
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err) || err?.response?.status !== 404) {
      console.error(err);
    }

    return null;
  }
};

export const createUsersProfessionalRole = async (
  userIdentifier: string,
  professionalRole: ProfessionalRoleFormType
): Promise<ProfessionalRole | null> => {
  try {
    const reponse = await getUserServiceAxios().post(
      '/api/v2/professional-roles/commands/create-professional-role',
      { ...professionalRole, userIdentifier }
    );

    return reponse.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
