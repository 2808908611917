import { AxiosResponse } from 'axios';
import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

type Membership = {
  uuid: string;
  organizationId: string;
  memberRole: string;
};

export type OrganizationAddress = {
  uuid: string;
  streetAddress: string;
  locality: string;
  region: string;
  postalCode: number;
  country: string;
};

export type Organization = {
  address?: OrganizationAddress;
  identifier: string;
  organizationType: string;
  parentOrganizationId: null;
  rootOrganizationId: null;
  salesforceAccountId: string;
  uuid: string;
  membership?: Membership;
};

export const getOrganizations = async (): Promise<Organization[]> => {
  const response = await getUserServiceAxios().get('/api/v1/organizations');

  return response.data;
};

export type SeatSet = {
  uuid: string;
  productType: string;
  quantityTotal: number;
  quantityAssigned: number;
  quantityActivated: number;
  quantityRemaining: number;
  quantityReturnable: number;
  start: number;
  end: number;
  displayName: string | null;
};

export type OrganizationOrder = {
  orderUUID: string;
  identifier: string;
  opportunityName: string;
  salesforceOrderId: string;
  seatSets: SeatSet[];
};

export const getOrganizationOrders = async (
  organizationUUID: string
): Promise<OrganizationOrder[] | undefined> => {
  try {
    const response: AxiosResponse = await getUserServiceAxios().get(
      `/api/v1/organizations/${organizationUUID}/orders`
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const removeOrganizations = async (
  userId: string,
  orgIds: string[]
): Promise<AxiosResponse | boolean> => {
  if (!userId || !orgIds.length) return false;

  try {
    const response: AxiosResponse = await getUserServiceAxios().delete(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/users/user-identifier/${userId}/organizations`,
      {
        data: orgIds,
      }
    );

    return response ? true : false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
