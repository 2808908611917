import { Button } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getOrgInformation } from '@apis/deprecated/org-info';
import LinkText from '@components/LinkText/LinkText';
import bubbles1 from '@images/icons/bubbles1.svg';
import bubbles2 from '@images/icons/bubbles2.svg';
import bubbles3 from '@images/icons/bubbles3.svg';
import teachersAndStudents from '@images/icons/teachers.svg';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';
import styles from './OrgInvitation.module.scss';

export default function OrgInvitation(): JSX.Element {
  const [isMobile, setIsMobile] = useState(false);
  const { orgUuid } = useParams();
  const [searchParams] = useSearchParams();
  const [orgName, setOrgName] = useState('');
  const sub = searchParams.get('sub') ?? '';
  const pwToken = searchParams.get('pw_token') ?? '';

  let redirectToRegister = `${window.location.origin}/register?orgUuid=${orgUuid}`;

  if (sub !== '' && pwToken !== '') {
    redirectToRegister += `&sub=${sub}&pw_token=${pwToken}`;
  }

  const handleSubmit = () => {
    window.location.assign(redirectToRegister);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const teacherNode = isMobile ? (
    <div className={styles['svg-overlay-teacher-mobile']}>
      <img src={teachersAndStudents} alt="Teachers and students smiling" />
    </div>
  ) : (
    <></>
  );
  useEffect(() => {
    const fetchData = async () => {
      if (orgUuid) {
        const data = await getOrgInformation(orgUuid);
        if (data) {
          const orgName =
            data.displayName && data.displayName !== 'null'
              ? data.displayName
              : data.orgIdentifier;
          setOrgName(orgName);
        } else {
          toast.error(
            'Could not fetch this organization. Please contact your network administrator',
            {
              className: 'toast-notify-error',
            }
          );
        }
      }
    };

    fetchData();
  }, [orgUuid]);

  return (
    <div className={styles['registration-page']}>
      <div className={styles['registration-form']}>
        <div className={styles['welcome-to-org']}>
          <div className={styles['registration-header']}>
            <a href={`${process.env.REACT_APP_LAB_URL}/home`}>
              <img
                src={BetterLessonLogo}
                className={styles.logo}
                data-testid="betterlesson-logo"
                alt="BetterLesson"
              />
            </a>
            <h2>Welcome {orgName}</h2>
            <p>
              To get started with Betterlesson, create an account using the
              email from your organization
            </p>
            <div className={styles['navigation-section']}>
              <Button
                data-testid="continue-button"
                variant="contained"
                color="secondary"
                fullWidth={isMobile}
                onClick={handleSubmit}
                sx={CONTAINED_GRADIENT_STYLE}
              >
                Continue
              </Button>

              <LinkText
                dataTestId="login-org-specific-page"
                to={`/login?orgUuid=${orgUuid}`}
                text="Already have an account with BetterLesson? Log In."
                preserveQueryParams={false}
              />
            </div>
            {teacherNode}
          </div>
        </div>
        <div className={styles['description']}>
          <p className={styles['short-description']}>
            We are so glad you are here!
          </p>
          <p className={styles['short-description']}>
            Effective classrooms filled with happy, high-performing students
            start with you. And with BetterLesson, you gain access to thousands
            of courses, lesson plans and instructional strategies to help
            support you in becoming the best educator you can be.
          </p>
          <p className={styles['short-description']}>
            Thank you for all the work you do.
          </p>
        </div>
      </div>
      <div className={styles['svg-overlay-bubble1']}>
        <img src={bubbles1} alt="Bubble 1" />
      </div>
      <div className={styles['svg-overlay-bubble2']}>
        <img src={bubbles2} alt="Bubble 2" />
      </div>
      <div className={styles['svg-overlay-bubble3']}>
        <img src={bubbles3} alt="Bubble 3" />
      </div>
      <div className={styles['svg-overlay-teacher']}>
        <img src={teachersAndStudents} alt="Teachers and students smiling" />
      </div>
    </div>
  );
}
