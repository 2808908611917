import { Typography, Button } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { AccessToken } from '@app-types/Token';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import styles from './components/BLConnectWelcome.module.scss';

export default function BLConnectWelcome(): JSX.Element {
  const { user } = useAuth();

  const redirectToLab = (): void => {
    window.location.assign(`${process.env.REACT_APP_LAB_URL}/lab/home` || '/');
  };

  const shouldRedirect = (): void => {
    if (!user?.access_token) {
      redirectToLab();
    } else {
      const { product_roles } = jwtDecode<AccessToken>(user?.access_token);
      if (!product_roles.BL_CONNECT) {
        redirectToLab();
      }
    }
  };

  useEffect(() => {
    shouldRedirect();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.logoContainer}>
        <img
          src={BetterLessonLogo}
          className={styles.logo}
          data-testid="betterlesson-logo"
          alt="BetterLesson Logo"
        />
        <Typography
          align="center"
          sx={{ mx: 'auto', mb: 6.75 }}
          fontSize="24px"
          variant="h1"
        >
          Welcome!
        </Typography>
        <Typography sx={{ mx: 'auto', width: 450, mb: 6.75 }} align="center">
          We&#39;re so glad you&#39;re here. You&#39;re now part of a growing
          community of educators with access to professional development and
          thousands of instructional resources in the BetterLesson Lab.
        </Typography>
        <Typography sx={{ mx: 'auto', width: 450, mb: 5.675 }} align="center">
          In a few steps, you can create your account, and experience all
          BetterLesson has to offer.
        </Typography>
        <div>
          <Button
            data-testid="home-button"
            variant="contained"
            href={`${process.env.REACT_APP_LAB_URL}/lab/home`}
            sx={{ width: 160 }}
          >
            Let&#39;s get started
          </Button>
        </div>
      </div>
    </div>
  );
}
