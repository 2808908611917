import { AxiosResponse } from 'axios';
import { ParticipantType } from '@app-types/Participant';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

export const getParticipants = async (
  workshopUUID: string
): Promise<ParticipantType[]> => {
  if (!workshopUUID) return [];
  const BASE_ROUTE = `${process.env.REACT_APP_WORKSHOP_SERVICE_URL}/api/v2/participants`;

  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/queries/find-by-workshop`,
      { workshopUUID }
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
