export const CONTAINED_GRADIENT_STYLE = {
  background:
    'linear-gradient(135deg, var(--color-ocean) 0%, var(--color-vivid-blue) 100%)',

  '&:disabled': {
    background: 'unset',
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
};
