export const ALL_TIME_SLOTS = Object.freeze([
  '6:00 a',
  '7:00 a',
  '8:00 a',
  '9:00 a',
  '10:00 a',
  '11:00 a',
  '12:00 p',
  '1:00 p',
  '2:00 p',
  '3:00 p',
  '4:00 p',
  '5:00 p',
  '6:00 p',
  '7:00 p',
  '8:00 p',
]);
