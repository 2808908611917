import { MultipleFieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';
import EntryFormContainer from '@components/EntryFormContainer/EntryFormContainer';
import FormInput from '@components/FormInput/FormInput';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import PasswordInput from '@pages/Register/components/RegisterView/components/PasswordInput/PasswordInput';
import PasswordRequirements from '@pages/Register/components/RegisterView/components/PasswordRequirements/PasswordRequirements';
import { buildEntryFormUrl } from '@utils/Redirect';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';
import styles from './SetPasswordView.module.scss';

type Props = {
  email: string;
  loading: boolean;
  givenNameRegister: UseFormRegisterReturn;
  giveNameError?: string;
  familyNameRegister: UseFormRegisterReturn;
  familyNameError?: string;
  passwordRegister: UseFormRegisterReturn;
  passwordError: boolean;
  confirmPasswordRegister: UseFormRegisterReturn;
  confirmPasswordError?: string;
  password: string;
  requirements?: MultipleFieldErrors;
  isSubmitted: boolean;
  displayMainError: boolean;
  onSubmit: () => void;
};

export default function SetPasswordView({
  email,
  loading,
  givenNameRegister,
  giveNameError,
  familyNameRegister,
  familyNameError,
  passwordRegister,
  passwordError,
  confirmPasswordRegister,
  confirmPasswordError,
  password,
  requirements,
  isSubmitted,
  displayMainError,
  onSubmit,
}: Props): JSX.Element {
  const renderMainError = () =>
    displayMainError ? (
      <div className={styles.errorText} data-testid="register-error">
        Please enter required fields
      </div>
    ) : (
      <div data-testid="register-spacer" style={{ height: '30px' }} />
    );

  const renderSetPWHeader = () => (
    <>
      <div className={styles.headerText}>Set Your Password</div>
      <div className={styles.subHeaderText}>{email}</div>
      {renderMainError()}
    </>
  );

  const renderSetPWForm = () => (
    <form className={styles.form}>
      <FormInput
        register={givenNameRegister}
        placeholder="First Name"
        error={giveNameError}
        type="text"
      />
      <FormInput
        register={familyNameRegister}
        placeholder="Last Name"
        error={familyNameError}
        type="text"
      />
      <PasswordInput
        register={passwordRegister}
        placeholder="Create Password"
        error={passwordError}
      />
      <PasswordRequirements
        isSubmitted={isSubmitted}
        password={password}
        requirements={requirements}
      />
      <PasswordInput
        register={confirmPasswordRegister}
        placeholder="Confirm Password"
        error={confirmPasswordError}
      />
      <div className={styles.createButton}>
        <LoadingButton
          disabled={email == ''}
          color="secondary"
          variant="contained"
          data-testid="create-button"
          onClick={onSubmit}
          fullWidth
          isLoading={loading}
          text="Set password"
          loadingText="Setting password"
          sx={CONTAINED_GRADIENT_STYLE}
        />
      </div>
      <div className={styles.codeLink} style={{ paddingBottom: '20px' }}>
        <Link
          data-testid="forgot-btn"
          to={buildEntryFormUrl('/forgot-password')}
        >
          Send me another code
        </Link>
      </div>
    </form>
  );

  return (
    <EntryFormContainer dataTestId="set-password-form">
      {renderSetPWHeader()}
      {renderSetPWForm()}
    </EntryFormContainer>
  );
}
