import { Paper, Slide } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { sendWelcomeEmail } from '@apis/deprecated/provisioning';
import { getProductsRoles } from '@apis/deprecated/roles-products';
import { getUsers, GetUsersRequestParams, User } from '@apis/users';
import { OptionType } from '@app-types/Select';
import { AccessToken } from '@app-types/Token';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';
import { PRODUCT_TYPE_LABEL } from '@constants/product';
import { SORT_DIR } from '@constants/sort';
import usePrompt from '@hooks/usePrompt';
import { ProductsProvider } from './ProductsContext';
import styles from './UserManagement.module.css';
import AddUserModal from './components/ManageUsersAndOrgs/ManageUsersAndOrgs';
import Permissions from './components/Permissions/Permissions';
import { Product } from './components/Permissions/types';
import SeatAssignmentOverlay from './components/SeatAssignmentOverlay/SeatAssignmentOverlay';
import UserFilters from './components/UserFilters/UserFilters';
import Users from './components/Users/Users';
import { UserRow } from './components/Users/components/UserTable/Types';

const USERS_PER_PAGE = 500;

export type FilterType = {
  organization?: OptionType;
  productTypes?: string[];
  states?: OptionType;
  accountType?: OptionType;
  createdAfter?: number;
  createdBefore?: number;
};

const DEBOUNCE_TIMEOUT = 300;

export const formatUser = (user: User): UserRow => ({
  userId: user.sub,
  givenName: user.givenName,
  familyName: user.familyName,
  email: user.email,
  organizations: user.organizations,
  productTypes: user.seatAssignments.map(
    ({ productType }: { productType: string }) =>
      PRODUCT_TYPE_LABEL[productType]
  ),
  dateCreated: DateTime.fromISO(user.createdAt).toMillis(),
  lastLogin: user.lastLogin
    ? DateTime.fromISO(user.lastLogin).toMillis()
    : undefined,
  lastActive: undefined,
});

const PAPER_STYLES = {
  width: '25%',
  top: 0,
  right: 0,
  pl: 2,
  bgcolor: 'var(--color-slate-4)',
  boxShadow: 'none',
  position: 'absolute',
  height: 'calc(100% - 32px)',
  zIndex: 1,
};

export default function UserManagement(): JSX.Element {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [checkedUserIds, setCheckedUserIds] = useState(new Set<string>());
  const [users, setUsers] = useState<UserRow[]>([]);
  const [states, setStates] = useState<OptionType[]>([]);
  const [accountTypes, setSetAccountTypes] = useState<OptionType[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userSearch, setUserSearch] = useState('');
  const [userTablePage, setUserTablePage] = useState(1);
  const [filters, setFilters] = useState<FilterType>({});
  const [isPermissionSlideOpen, setIsPermissionSlideOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const { user } = useAuth();

  usePrompt(
    'Do you want to leave this page?\n\nChanges you made may not be saved.',
    !!checkedUserIds.size
  );

  const fetchUsers = async (newUserSearch?: string) => {
    newUserSearch && setUserTablePage(1);
    const { organization, states, accountType, ...restOfFilters } = filters;

    const data = await getUsers({
      pageSize: USERS_PER_PAGE,
      page: userTablePage - 1,
      search: newUserSearch || userSearch || undefined,
      // sortField: 'familyName',
      sortDir: SORT_DIR.ASC,
      organization: organization?.label,
      states: states?.value,
      accountType: accountType?.value,
      ...restOfFilters,
    } as GetUsersRequestParams);

    const formattedUsers = data.users.map((user) => formatUser(user));
    setUsers(formattedUsers);
    setTotalUsers(data.totalHits);
    setStates(data.stateCounts);
    setSetAccountTypes(data.accountTypeCounts);
  };

  const searchUsers = useMemo(
    () => debounce(fetchUsers, DEBOUNCE_TIMEOUT),
    [userTablePage, filters]
  );

  useEffect(() => {
    fetchUsers();
  }, [userTablePage, filters]);

  useEffect(() => {
    return () => {
      searchUsers.cancel();
    };
  }, []);

  const getAllRolesProducts = async () => {
    const response: Product[] | null = await getProductsRoles();
    if (response) setProducts(response);
  };

  const checkIsAdmin = () => {
    if (!user || !user?.access_token) return;
    const { product_roles } = jwtDecode<AccessToken>(user?.access_token);
    product_roles['USER_MANAGEMENT'].forEach((role: string) => {
      if (role.toLowerCase() === 'super_admin') {
        setIsSuperAdmin(true);
      }
    });
  };

  useEffect(() => {
    getAllRolesProducts();
    checkIsAdmin();
  }, []);

  const handleApplyFiltersClick = (newFilters: FilterType) => {
    setUserTablePage(1);
    setFilters(newFilters);
  };

  const handleUserSearch = (value: string) => {
    setUserSearch(value);
    searchUsers(value);
  };

  const handleCheckboxClick = (userId: string | null | void) => {
    if (!userId) {
      return;
    }

    if (checkedUserIds.has(userId)) {
      checkedUserIds.delete(userId);
    } else {
      checkedUserIds.add(userId);
    }

    setCheckedUserIds(new Set(checkedUserIds));
  };

  const handlePageChange = (pageNumber: number) => {
    setUserTablePage(pageNumber);
  };

  const handleInviteToProductClick = () => {
    setIsOverlayOpen(true);
  };

  const handleSendWelcomeEmailClick = async () => {
    try {
      await sendWelcomeEmail(Array.from(checkedUserIds));
      toast.success('Welcome emails successfully sent', {
        className: 'toast-notify-success',
      });
    } catch (e) {
      console.error(e);
      toast.error('Welcome emails failed to send', {
        className: 'toast-notify-error',
      });
    }
  };

  const handleChangePermissionsClick = async () =>
    setIsPermissionSlideOpen(true);

  const isAllCurrentUsersChecked = () =>
    !!users.length && users.every(({ userId }) => checkedUserIds.has(userId));

  const handleHeaderCheckboxClick = () => {
    const allUserInCurrentViewsAreChecked = isAllCurrentUsersChecked();
    users.forEach(({ userId }) => {
      if (allUserInCurrentViewsAreChecked) {
        checkedUserIds.delete(userId);
      } else {
        checkedUserIds.add(userId);
      }
    });
    setCheckedUserIds(new Set(checkedUserIds));
  };

  const handleHeaderClearClick = () => {
    setCheckedUserIds(new Set());
  };

  const handleOverlayClose = () => setIsOverlayOpen(false);
  const handleAddUserClick = () => setIsAddUserModalOpen(true);
  const updateUsers = (updatedUsers: UserRow[]) => setUsers(updatedUsers);

  const handleAddUserOverlayClose = (refreshUsers?: boolean) => {
    setIsAddUserModalOpen(false);
    if (refreshUsers) fetchUsers();
  };

  return (
    <NavigationContainer disableFooter={true}>
      <div className={styles.container} data-testid="user-management-page">
        <div className={styles.summaryContainer}>
          <UserFilters
            userCount={totalUsers}
            userSearchValue={userSearch}
            onUserSearchChange={handleUserSearch}
            onApplyFiltersClick={handleApplyFiltersClick}
            states={states}
            accountTypes={accountTypes}
          />
          <ProductsProvider
            value={{ products, users, checkedUserIds, updateUsers }}
          >
            <Users
              isAllChecked={isAllCurrentUsersChecked()}
              totalUsers={totalUsers}
              currentPage={userTablePage}
              usersPerPage={USERS_PER_PAGE}
              disableSelectedUsersButtons={isOverlayOpen}
              isSuperAdmin={isSuperAdmin}
              onAddUserClick={handleAddUserClick}
              onPageChange={handlePageChange}
              onCheckboxClick={handleCheckboxClick}
              onHeaderCheckboxClick={handleHeaderCheckboxClick}
              onClearClick={handleHeaderClearClick}
              onInviteToProductClick={handleInviteToProductClick}
              onSendWelcomeEmailClick={handleSendWelcomeEmailClick}
              onChangePermissionsClick={handleChangePermissionsClick}
            />

            <AddUserModal
              isOpen={isAddUserModalOpen}
              onClose={handleAddUserOverlayClose}
            />

            <Slide
              direction="left"
              in={isOverlayOpen}
              mountOnEnter
              unmountOnExit
            >
              <Paper sx={PAPER_STYLES}>
                <SeatAssignmentOverlay onClose={handleOverlayClose} />
              </Paper>
            </Slide>

            <Slide
              direction="left"
              in={isPermissionSlideOpen}
              mountOnEnter
              unmountOnExit
            >
              <Paper sx={PAPER_STYLES}>
                <Permissions
                  isSuperAdmin={isSuperAdmin}
                  onClose={() => setIsPermissionSlideOpen(false)}
                />
              </Paper>
            </Slide>
          </ProductsProvider>
        </div>
      </div>
    </NavigationContainer>
  );
}
