import { AxiosResponse } from 'axios';
import UserToProvision from '@pages/DeprecatedPages/UserManagement/components/ProvisioningBulkUpload/models/UserToProvision';
import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

export const ProvisioningBulkUploadUsers = ({
  orgId,
  userId,
  usersToImport,
}: {
  orgId: string;
  userId: string;
  usersToImport: Array<UserToProvision>;
}): Promise<AxiosResponse> => {
  return getUserServiceAxios().post('/api/v1/provisioning/invitation', {
    orgId: orgId,
    userId: userId,
    invitationRequest: usersToImport,
  });
};

export const UpdateIndividualUser = ({
  email,
  firstName,
  lastName,
  userExternalId,
}: {
  email: string;
  firstName: string;
  lastName: string;
  userExternalId: string;
}): Promise<AxiosResponse> => {
  return getUserServiceAxios().put(
    `/api/v1/provisioning/invitation/${userExternalId}/`,
    {
      email: email,
      firstName: firstName,
      lastName: lastName,
    }
  );
};

export const DeleteIndividualUser = ({
  userId,
}: {
  userId: string;
}): Promise<AxiosResponse> => {
  return getUserServiceAxios().post(
    `/api/v1/provisioning/invitation/${userId}/delete`
  );
};

export const Submit = ({
  orgId,
  sendWelcomeEmail,
  sendWelcomeEmailToExistingUsers,
}: {
  orgId: string;
  sendWelcomeEmail: boolean;
  sendWelcomeEmailToExistingUsers: boolean;
}): Promise<AxiosResponse> => {
  return getUserServiceAxios().post(
    `/api/v1/provisioning/invitation/${orgId}/submit`,
    {
      sendWelcomeEmail,
      sendWelcomeEmailToExistingUsers,
    }
  );
};

export const sendWelcomeEmail = (userUUIDs: string[]): Promise<AxiosResponse> =>
  getUserServiceAxios().post(
    '/api/v1/provisioning/invitation/welcome-email',
    {
      userUUIDs,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );

export const addUser = (addUserBody: {
  organizationUUID?: string;
  userEmail: string;
  sendWelcomeEmail: boolean;
}): Promise<AxiosResponse> => {
  return getUserServiceAxios().post('/api/v1/provisioning/user', addUserBody);
};
