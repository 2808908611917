import { useState } from 'react';
import { onEnterKey } from '@utils/key-handler';
import styles from './Input.module.css';

type Props = {
  className?: string;
  dataTestId?: string;
  error?: string | boolean;
  icon?: string;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  type?: string;
  value: string;
  onChange: (e: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onIconClick?: () => void;
};

export default function Input({
  className,
  dataTestId = '',
  error,
  icon,
  label,
  maxLength,
  placeholder,
  type = 'text',
  value,
  onBlur,
  onChange,
  onKeyDown,
  onIconClick,
}: Props): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur && onBlur();
  };

  const renderErrorText = () =>
    error && typeof error === 'string' ? (
      <span className={styles.errorText} data-testid={`${dataTestId}-error`}>
        {error}
      </span>
    ) : null;

  const renderLabel = () =>
    label ? (
      <div className={styles.label} data-testid={`${dataTestId}-input-label`}>
        {label}
      </div>
    ) : null;

  const renderIcon = () =>
    icon ? (
      <img // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        className={styles.icon}
        src={icon}
        data-testid={`${dataTestId}-input-icon`}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onClick={onIconClick}
        onKeyDown={onEnterKey(onIconClick)}
        alt="icon"
      />
    ) : null;

  return (
    <div className={styles.inputContainer}>
      {renderLabel()}
      <div
        className={`
        ${styles.inputContent} 
        ${isFocused && styles.focusInput}
        ${error && styles.errorInput}
        `}
        data-testid={`${dataTestId}-content`}
      >
        <input
          className={`${styles.input} ${className}`}
          data-testid={dataTestId}
          maxLength={maxLength}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
        />
        {renderIcon()}
      </div>
      {renderErrorText()}
    </div>
  );
}
