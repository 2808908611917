import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Organization, getOrganizations } from '@apis/deprecated/organizations';

type OrganizationContextType = {
  organizations: Organization[];
};

export const OrganizationContext = createContext<OrganizationContextType>(
  {} as OrganizationContextType
);

export const OrganizationProvider = (): JSX.Element => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchOrganizations = async () => {
    const response = await getOrganizations();
    response.sort((a, b) => a.identifier.localeCompare(b.identifier));
    setOrganizations(response);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        organizations,
      }}
    >
      <Outlet />
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = (): OrganizationContextType =>
  useContext(OrganizationContext);
