import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { FocusAreaType } from '@app-types/Invite';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import FocusAreaPopovers from './FocusAreaPopovers';

interface WelcomeProps {
  focusAreas?: FocusAreaType[];
  onContinue: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ focusAreas = [], onContinue }) => {
  const { user } = useAuth();
  const firstName = user?.profile.given_name;

  return (
    <Stack
      alignItems="center"
      height="100%"
      data-testid="coaching-registration-welcome"
      flex="1 0 0"
    >
      <a href={`${process.env.REACT_APP_LAB_URL}/home`}>
        <img
          src={BetterLessonLogo}
          height={'50em'}
          data-testid="betterlesson-logo"
          alt="BetterLesson"
        />
      </a>

      <Stack height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h5" component="div" fontSize="24px" py={4}>
          Welcome {firstName},
        </Typography>
        <Typography width={{ xs: '80%', sm: '60%' }} textAlign="center" pb={1}>
          We&apos;re honored to be chosen to work with you to help with
          improving student outcomes through coaching.
        </Typography>

        <Typography pb={1}>We&apos;ll be focusing on</Typography>
        <FocusAreaPopovers focusAreas={focusAreas} />
      </Stack>

      <Button
        sx={{ width: '50%', mt: 4 }}
        variant="contained"
        onClick={onContinue}
        data-testid="welcome-continue-button"
      >
        Continue
      </Button>
    </Stack>
  );
};

export default Welcome;
