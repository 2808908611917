import { Unauthorized as UnauthorizedComponent } from 'betterlesson-library-react';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';

export default function UnauthorizedPage(): JSX.Element {
  return (
    <NavigationContainer>
      <UnauthorizedComponent />
    </NavigationContainer>
  );
}
