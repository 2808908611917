import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { EngagementSummaryForCoach } from '@app-types/CoachParticipant';
import { EngagementDataType } from '@app-types/Engagement';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_COACHING_SERVICE_URL}/api/v2/engagements`;

export const registerEngagement = async (
  cohortUuid: string
): Promise<boolean> => {
  try {
    await getServiceAxios().post(`${BASE_ROUTE}/commands/register`, {
      cohortUuid,
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserEngagements = async (
  userUuid?: string
): Promise<EngagementDataType[]> => {
  if (!userUuid) {
    return [];
  }

  try {
    const response: AxiosResponse<EngagementDataType[]> =
      await getServiceAxios().post(`${BASE_ROUTE}/queries/find-by-user`, {
        userUuid,
      });

    return response.data.sort((a, b) => {
      const endDateA = DateTime.fromISO(a.endDate);
      const endDateB = DateTime.fromISO(b.endDate);
      return endDateB.diff(endDateA).toMillis();
    });
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getEngagementsForCoach = async (
  accessToken?: string
): Promise<EngagementSummaryForCoach[]> => {
  try {
    const response: AxiosResponse<EngagementSummaryForCoach[]> =
      await getServiceAxios(accessToken).post(
        `${BASE_ROUTE}/queries/find-by-coach`,
        {}
      );

    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
