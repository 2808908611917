import BetterLessonLogo from '@images/logos/betterlesson.svg';
import styles from './EntryFormContainer.module.css';

export default function EntryFormContainer({
  children,
  dataTestId,
}: {
  children: JSX.Element | JSX.Element[];
  dataTestId: string;
}): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.formContainer} data-testid={dataTestId}>
        <div className={styles.formContent}>
          <a href={`${process.env.REACT_APP_LAB_URL}/home`}>
            <img
              src={BetterLessonLogo}
              className={styles.logo}
              data-testid="betterlesson-logo"
              alt="BetterLesson"
            />
          </a>
          {children}
        </div>
      </div>
    </div>
  );
}
