import { CreateSigninRequestArgs } from 'oidc-client-ts';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { authenticateWithUserService } from '@apis/auth';
import { initializeLabRedirectPathFromSearchParams } from '@utils/LabRedirectPath';
import { redirectAuthenticatedUser } from '@utils/Redirect';
import Login, { LoginType } from './Login';

export default function LabLogin(): JSX.Element {
  const { signinRedirect } = useAuth();
  const [searchParams] = useSearchParams();

  const initiateAuthorization = () => {
    if (searchParams.has('sessionId')) {
      return;
    }

    initializeLabRedirectPathFromSearchParams();

    if (searchParams.has('orgUuid')) {
      signinRedirect({
        redirect_uri: window.location.origin,
        extraQueryParams: {
          orgUuid: searchParams.get('orgUuid'),
        },
      } as CreateSigninRequestArgs);
    } else {
      signinRedirect({
        redirect_uri: window.location.origin,
      } as CreateSigninRequestArgs);
    }
  };

  useEffect(() => {
    initiateAuthorization();
  }, []);

  const handleAuthentication = async (data: LoginType) => {
    const response = await authenticateWithUserService(data);
    initializeLabRedirectPathFromSearchParams();
    redirectAuthenticatedUser(response);
  };

  return <Login onAuthenticate={handleAuthentication} />;
}
