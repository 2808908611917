import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

export const activateSeatSet = async (sfID: string): Promise<string> => {
  const res = await getUserServiceAxios().put(
    `/api/v1/seat-sets/salesforce-id/${sfID}/activate`
  );
  return res.data.redirectURI;
};

export const clickOrderProductLink = async (
  orderProductId: string
): Promise<string> => {
  const res = await getUserServiceAxios().put(
    `/api/v1/order-product/${orderProductId}/clicks`
  );
  return res.data.redirectURI;
};
