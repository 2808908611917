export enum Workshop {
  VIRTUAL = 'Virtual',
  IN_PERSON = 'In Person',
  LEARNING_WALK = 'Learning Walk',
  NONE = 'None',
}

export enum SessionGroupStatus {
  ACTIVE = 'Active',
  DOWNSIZED = 'Downsized',
}
export enum ModificationLevel {
  FACILITATOR_ACTION = 'FACILITATOR_ACTION',
  MODIFICATION = 'MODIFICATION',
  CUSTOM_FROM_PRODUCT = 'CUSTOM_FROM_PRODUCT',
  NOVEL_CUSTOM = 'NOVEL_CUSTOM',
}

export type ModificationType = {
  modificationLevel: ModificationLevel;
  description: string;
};

export enum SessionStatus {
  DRAFT = 'Draft',
  READY_TO_STAFF = 'Ready to Staff',
  APPROVED = 'Approved',
  CONFIRMED = 'Confirmed',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
}

export type SessionDataType = {
  uuid: string;
  sessionNumber: number;
  name: string;
  status: SessionStatus;
  timeframe: string;
  focusAreaTitle: string;
  productCode: string;
  productCodeTitle: string;
  sessionDate: string;
  sessionStartTime: number;
  sessionEndTime: number;
  coachingLaunchDetails: {
    coachLaunchType: string | null;
    coachJoinTime: number | null;
    participantRegistrationNeeded: boolean;
    additionalDetails: string | null;
  };
  customRequestId: string | null;
  isCustom: boolean;
  modifications: ModificationType[];
};

export type GroupDataType = {
  groupNumber: number;
  name: string;
  uuid: string;
};

export type SessionGroupDataType = {
  groupUuid: string;
  sessionUuid: string;
  status: SessionGroupStatus;
  uuid: string;
};

export type FacilitatorUserType = {
  firstName: string;
  lastName: string;
  sessionGroupUUIDs: string[];
  userUUID: string;
  email: string;
};

export type SessionGroupMaterialsDataType = {
  agendaLink?: string;
  designLink?: string;
  modificationsFinalized: boolean;
  materialsReadyForDelivery: boolean;
  sessionGroupUuid?: string;
  slideDeckLink?: string;
  zoomLink?: string;
};

export type WorkshopDataDetailsType = {
  workshopUuid?: string;
  curriculumDetails: {
    implementationExpectation: string | null;
    mindset: string | null;
    module: string | null;
    moduleUnit: string | null;
    physicalMaterials: string | null;
    platform: string | null;
    priorPlatformSpecificTraining: boolean;
    additionalContext: string | null;
  } | null;
  genericDetails: {
    anticipatedCapacity: number | null;
    audienceDescription: string | null;
    framing: string | null;
    gradeBandLower: string | null;
    gradeBandUpper: string | null;
    subject: string | null;
  };
  onsiteDetails: {
    additionalDetails: string | null;
    lunchProvidedByPartner: boolean;
    lunchTimeEnd: string | null;
    lunchTimeStart: string | null;
    location: string | null;
    materialsReady: boolean;
    onsiteContact: string | null;
  } | null;
  customDetails: {
    audienceModificationDetails: string | null;
    requiredDistrictMaterials: string | null;
    additionalAction: string | null;
  } | null;
};

export interface WorkshopInternalUserDetails {
  sub: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export type WorkshopDataType = {
  uuid: string;
  accountUuid: string;
  orderProductUuid: string;
  name: string;
  displayName: string;
  type: string;
  account: string;
  status: string;
  location: string;
  timezone: string;
  isCurriculum?: boolean;
  details: WorkshopDataDetailsType;
  sessions: SessionDataType[];
  groups: GroupDataType[];
  sessionGroups: SessionGroupDataType[];
  firstSessionDate: string;
  finalSessionDate: string;
  facilitatorUsers: FacilitatorUserType[];
  sessionGroupMaterials: SessionGroupMaterialsDataType[];
  sessionOverview: {
    sessionSeriesDetails: string | null;
    staffingContext: string | null;
  };
  approvalRequest: {
    status: string;
    rejectionReason?: string;
    reviewNotes?: string;
    createdAt: number;
    updatedAt?: number;
    creator: WorkshopInternalUserDetails;
    editor?: WorkshopInternalUserDetails;
  } | null;
  opp?: {
    uuid: string;
    salesforceId: string;
    schoolYear: number;
    orders: [
      {
        uuid: string;
        salesforceId: string;
        items: [
          {
            uuid: string;
            salesforceId: string;
            productCode: string;
            productSubType: string;
            quantity: number;
            orderProduct: {
              uuid: string;
              salesforceId: string;
              name: string;
              description: string;
              code: string;
              serviceType: string;
              serviceSubType: string;
              category: string;
              active: boolean;
              audience: string;
              modality: string;
              isCurriculum: boolean;
            };
          }
        ];
      }
    ];
  };
  modifications: ModificationType[];
};

export type SessionGroupType = {
  uuid: string;
  facilitatorUsers: FacilitatorUserType[];
  registeredCount: number;
  zoomLink: string;
  isReadyForDelivery: boolean;
  modificationsFinalized: boolean;
  allModifications: ModificationType[];

  sessionName: string;
  sessionNumber: number;
  sessionDate: string;
  sessionStartTime: number;
  sessionEndTime: number;
  sessionCount: number;
  sessionStatus: SessionStatus;
  isSessionCustom: boolean;

  firstSessionDate: string;
  finalSessionDate: string;

  account: string;
  location: string;
  timezone: string;

  workshopName: string;
  workshopUuid: string;
  workshopType: string;
  isWorkshopDetailsUpdated: boolean;
};

type UserGroupResponseType = {
  groupNumber: number;
  name: string;
  uuid: string;
};

export type SessionType = {
  uuid: string;
  name: string;
  status: string;
  startTime: number;
  endTime: number;
  productCode: string;
};

export type UserWorkshopResponseType = {
  uuid: string;
  displayName: string;
  type: string;
  status: UserGroupResponseType;
  sessions: SessionType[];
  facilitators: FacilitatorUserType[];
};
