import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Stack, Avatar, Typography } from '@mui/material';
import { getUserIconColor, COLOR_PALETTE } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import { ROLE_LABEL } from '@constants/professional-roles';

export type Props = {
  firstName: string;
  lastName: string;
  professionalRole: string;
  zoneInfo: string;
  isRetracted: boolean;
};

export const TEST_ID = {
  CONTAINER: 'participant-info',
  REQUEST_NEW_COACH_TEXT: 'participant-info-request-new-coach',
};

export default function ParticipantInfo({
  firstName,
  lastName,
  professionalRole,
  zoneInfo,
  isRetracted,
}: Props): JSX.Element {
  const initials = firstName?.charAt(0) + lastName?.charAt(0);

  const renderNewCoachText = () =>
    isRetracted ? (
      <Stack
        data-testid={TEST_ID.REQUEST_NEW_COACH_TEXT}
        direction="row"
        alignItems="center"
        spacing={0.5}
        py={0.5}
      >
        <ErrorRoundedIcon
          sx={{
            width: '16px',
            height: '16px',
            fill: COLOR_PALETTE.error.main,
            mb: 1,
          }}
        />
        <Typography variant="overline2" color={COLOR_PALETTE.text.primary}>
          REQUESTING NEW COACH
        </Typography>
      </Stack>
    ) : (
      <></>
    );

  const getParticipantDetails = () => {
    const role = ROLE_LABEL[professionalRole] || '';
    const timezone =
      zoneInfo && DateTime.local().setZone(zoneInfo).toFormat('ZZZZ');

    if (role && timezone) {
      return `${role}, ${timezone}`;
    }

    return `${role}${timezone}`;
  };

  return (
    <Stack data-testid={TEST_ID.CONTAINER}>
      {renderNewCoachText()}
      <Stack direction="row" alignItems="center">
        <Avatar
          alt={firstName + ' ' + lastName}
          sx={{
            backgroundColor: getUserIconColor(initials),
            mr: 1,
          }}
        >
          <Typography color={COLOR_PALETTE.textLight.high} fontWeight={600}>
            {initials}
          </Typography>
        </Avatar>

        <Stack>
          <Typography
            color={COLOR_PALETTE.text.primary}
            fontWeight={500}
            fontSize={18}
          >
            {firstName} {lastName}
          </Typography>
          <Typography fontWeight={300} fontSize={16}>
            {getParticipantDetails()}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
