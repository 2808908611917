import { atom } from 'jotai';
import { DateTime } from 'luxon';
import { CoachMatchDataType, Coaching } from '@app-types/CoachMatch';
import { EngagementSummaryForCoach } from '@app-types/CoachParticipant';

export const COACHING_EMPTY: Coaching = {
  coachMatches: [],
  coachParticipants: [],
};

export const coachMatchesDataAtom = atom<CoachMatchDataType[]>([]);
export const coachParticipantsDataAtom = atom<EngagementSummaryForCoach[]>([]);

export const coachingAtom = atom<Coaching>((get) => {
  const coachMatches = get(coachMatchesDataAtom);
  const coachParticipants = get(coachParticipantsDataAtom);

  return {
    coachMatches: coachMatches.map((cm) => ({
      uuid: cm.uuid,
      status: cm.status || '',
      participantUserUuid: cm.participant.uuid,
      firstName: cm.participant.givenName,
      lastName: cm.participant.familyName,
      zoneInfo: cm.participant.zoneinfo,
      matchNote: cm.matchNote,
      proposedAt: cm.createdAt,
      expiredAt: cm.expiresAt,
      startDate: DateTime.fromISO(cm.engagement.startDate).toMillis(),
      endDate: DateTime.fromISO(cm.engagement.endDate).toMillis(),
      coachUserUuid: cm.engagement.coachUserUuid,
      professionalRole: cm.participant.professionalRole || '',
      cohortName: cm.cohort.displayName,
      productName: cm.engagement.productName,
    })),
    coachParticipants: coachParticipants,
  };
});
