import { Box, Typography } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { DateTime } from 'luxon';

type Props = {
  sessionDate: string;
  sessionStartTime: number;
  sessionEndTime: number;
  timezone: string;
};

export const DateColumn = ({
  sessionDate,
  sessionStartTime,
  sessionEndTime,
  timezone,
}: Props): JSX.Element => {
  const convertDate = (date: string) => {
    if (!date) return '';
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
  };

  const convertTime = (date: number, tz?: string) => {
    if (!date) return;

    return DateTime.fromMillis(date)
      .setZone(tz || undefined)
      .toLocaleString(DateTime.TIME_SIMPLE);
  };

  const findTimezone = (tz: string) => {
    if (!tz) return '';
    return DateTime.now().setZone(tz).toFormat('ZZZZ');
  };

  return (
    <Box data-testid="upcoming-workshops-date-column">
      <Typography
        display="block"
        fontStyle="normal"
        fontWeight="bold"
        variant="body3"
      >
        {convertDate(sessionDate)}
      </Typography>

      <Typography fontStyle="normal" fontWeight="bold" variant="body3">
        {convertTime(sessionStartTime, timezone)}
        {' - '}
        {convertTime(sessionEndTime, timezone)} {findTimezone(timezone)}
      </Typography>

      <Typography
        color={COLOR_PALETTE.text.secondary}
        display="block"
        fontStyle="normal"
        variant="body4"
      >
        {convertTime(sessionStartTime)}
        {' - '}
        {convertTime(sessionEndTime)} {findTimezone(DateTime.local().zone.name)}
      </Typography>
    </Box>
  );
};
