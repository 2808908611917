import { createContext } from 'react';
import { Product } from '@pages/DeprecatedPages/UserManagement/components/Permissions/types';
import { UserRow } from './components/Users/components/UserTable/Types';

export type ProductsContextType = {
  products: Product[];
  users: UserRow[];
  checkedUserIds: Set<string>;
  updateUsers: (updatedUsers: UserRow[]) => void;
};

const ProductsContext = createContext<ProductsContextType>({
  products: [],
  users: [],
  checkedUserIds: new Set(),
  updateUsers: () => ({}),
});
export const ProductsProvider = ProductsContext.Provider;
export default ProductsContext;
