import { TextField } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ProvisioningBulkUploadUsers,
  Submit,
} from '@apis/deprecated/provisioning';
import { OptionType } from '@app-types/Select';
import Breadcrumbs from '@components/Breadcrumbs/Breadcrumbs';
import Button, { BUTTON_VARIANT } from '@components/Button/Button';
import CheckboxOld from '@components/CheckboxOld/CheckboxOld';
import VirtualizedAutocomplete from '@components/VirtualizedAutocomplete/VirtualizedAutocomplete';
import Account from '../../models/Account';
import ProvisionedUser from '../../models/ProvisionedUser';
import UserToProvision from '../../models/UserToProvision';
import CSVReader from '../CSVReader/CSVReader';
import style from './ProvisioningOptions.module.css';

type Props = {
  accountList: OptionType[];
  currentAccount: OptionType;
  currentUserId: string;
  usersToImport: Array<UserToProvision>;
  duplicateUsers: Array<ProvisionedUser>;
  validUsersCount: number;
  handleSetCurrentAccount: (e: SyntheticEvent, account: Account | null) => void;
  clearAllUsers: () => void;
  setUsersToImport: (usersToImport: Array<UserToProvision>) => void;
  handleSetInvalidUsers: (invalidUsers: Array<ProvisionedUser>) => void;
  handleSetDuplicateUsers: (duplicateUsers: Array<ProvisionedUser>) => void;
  handleSetRegisteredUsers: (registeredUsers: Array<ProvisionedUser>) => void;
  handleSetNotRegisteredUsers: (
    notRegisteredUsers: Array<ProvisionedUser>
  ) => void;
};

const TIMEOUT_DURATION = 6 * 1000;

export default function ProvisioningOptions({
  accountList,
  currentAccount,
  currentUserId,
  usersToImport,
  handleSetCurrentAccount,
  duplicateUsers,
  validUsersCount,
  clearAllUsers,
  setUsersToImport,
  handleSetDuplicateUsers,
  handleSetInvalidUsers,
  handleSetRegisteredUsers,
  handleSetNotRegisteredUsers,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const [previewUsers, setPreviewUsers] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [sendWelcomeEmailToExistingUsers, setSendWelcomeEmailToExistingUsers] =
    useState(false);

  const handleCancel = () => {
    clearAllUsers();
    setPreviewUsers(false);
  };

  const handleCancelFile = () => {
    clearAllUsers();
    setUsersToImport([]);
    setPreviewUsers(false);
  };

  const handleSubmit = async () => {
    if (duplicateUsers.length >= 1) {
      toast.error('A unique email is required for every user', {
        className: 'toast-notify-error',
      });
      return;
    }

    try {
      await Submit({
        orgId: currentAccount.value,
        sendWelcomeEmail,
        sendWelcomeEmailToExistingUsers,
      });
    } catch {
      toast.error(
        'Oops - something went wrong. Contact an admin and try again later',
        {
          className: 'toast-notify-error',
        }
      );
      return;
    }

    toast.success(
      `${validUsersCount} users added on ${DateTime.now().toFormat(
        'MM-dd-yyyy'
      )} to ${currentAccount.label}`,
      {
        className: 'toast-notify-success',
      }
    );
    clearAllUsers();
    setTimeout(() => navigate('/users'), TIMEOUT_DURATION);
  };

  const handlePreview = async (
    orgId: string,
    userId: string,
    usersToImport: Array<UserToProvision>
  ) => {
    try {
      if (!userId) {
        toast.error(
          'A valid userid needs to be specified to complete this operation',
          {
            className: 'toast-notify-error',
          }
        );

        return;
      }
      if (usersToImport.length <= 0) {
        toast.error('Upload a CSV file first to preview users', {
          className: 'toast-notify-error',
        });
        return;
      }
      const response = await ProvisioningBulkUploadUsers({
        orgId,
        userId,
        usersToImport,
      });
      handleSetDuplicateUsers(response.data.duplicateUsers);
      handleSetInvalidUsers(response.data.invalidUsers);
      handleSetRegisteredUsers(response.data.registeredUsers);
      handleSetNotRegisteredUsers(response.data.notRegisteredUsers);
      setPreviewUsers(true);
    } catch (e) {
      console.error(e);
      toast.error('Bulk Upload failed.', {
        className: 'toast-notify-error',
      });
    }
  };

  const handleSendWelcomeEmailClick = () =>
    setSendWelcomeEmail((sendWelcomeEmail) => !sendWelcomeEmail);

  const handleSendWelcomeEmailClickToExistingUsers = () =>
    setSendWelcomeEmailToExistingUsers(
      (sendWelcomeEmailToExistingUsers) => !sendWelcomeEmailToExistingUsers
    );

  const openGoogleSheetsCopyUrl = () => {
    window.open(
      'https://docs.google.com/spreadsheets/d/1dlRQxH0qASa-zRR37VF2L46SNYkOAS872P8aoSEZEyo/copy',
      '_blank'
    );
  };

  const postUploadingClass = previewUsers
    ? style.postUploadingContainer
    : `${style.postUploadingContainer} ${style.disabledSection}`;

  const downloadCsvText = (
    <div className={style.csvDescription}>
      Download this{' '}
      <button
        data-testid="download-csv"
        className={style.downloadCsv}
        onClick={openGoogleSheetsCopyUrl}
      >
        CSV template.{' '}
      </button>
      Fill it in with the users you want to upload at Betterlesson
    </div>
  );

  return (
    <div className={style.container} data-testid="provisioning-options">
      <div className={style.searchContainer}>
        <div>
          <Breadcrumbs />
          <h3>Bulk Import</h3>
          <div className={style.accountContainer}>
            <h4>Account</h4>

            <VirtualizedAutocomplete
              isOptionEqualToValue={(o, v) => o.value === v.value}
              data-testid="organization-select"
              options={accountList}
              value={currentAccount}
              onChange={handleSetCurrentAccount}
              clearOnBlur={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: { height: '40px' },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: { padding: 0 },
                  }}
                />
              )}
            />
          </div>
          <h5 className={style.headerText}>Invite Users to Betterlesson</h5>
          {downloadCsvText}
          <CSVReader
            setUsersToImport={setUsersToImport}
            onHandleCancelFile={handleCancelFile}
          />
          <Button
            className={style.previewButton}
            dataTestId="preview-button"
            text="Preview"
            variant={BUTTON_VARIANT.DEFAULT}
            disabled={previewUsers}
            onClick={() =>
              handlePreview(currentAccount.value, currentUserId, usersToImport)
            }
          />
          <div className={postUploadingClass}>
            <div className={style.checkboxContainer}>
              <CheckboxOld
                checked={sendWelcomeEmail}
                onClick={handleSendWelcomeEmailClick}
                dataTestId="new-users"
              />
              <span className={style.checkboxText}>
                Send Welcome Emails to new users
              </span>
            </div>
            <div className={style.checkboxContainer}>
              <CheckboxOld
                checked={sendWelcomeEmailToExistingUsers}
                onClick={handleSendWelcomeEmailClickToExistingUsers}
                dataTestId="existing-users"
              />
              <span className={style.checkboxText}>
                Send Welcome Emails to existing users
              </span>
            </div>
            <div className={style.postUploadingSection}>
              <Button
                className={style.cancelButton}
                dataTestId="clear-users-button"
                text="Clear users"
                variant={BUTTON_VARIANT.DEFAULT}
                onClick={handleCancel}
              />
              <Button
                className={style.submitButton}
                dataTestId="submit-button"
                text="Submit"
                variant={BUTTON_VARIANT.DEFAULT}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
