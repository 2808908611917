import { IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '@images/icons/close.svg';
import styles from './ModalHeader.module.scss';

export default function ModalHeader({
  text,
  onCloseClick,
}: {
  text: string;
  onCloseClick: () => void;
}): JSX.Element {
  return (
    <div className={styles.header} data-testid="usermanagement-modal-header">
      {text}
      <IconButton
        aria-label="close"
        data-testid="usermanagement-modal-header-icon-button"
        onClick={onCloseClick}
      >
        <CloseIcon className={styles.removeIcon} />
      </IconButton>
    </div>
  );
}
