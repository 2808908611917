import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'betterlesson-library-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { validateTokenAndGetEmail } from '@apis/auth';
import { userServiceResetPassword } from '@apis/users';
import ResetPasswordView from './components/ResetPasswordView/ResetPasswordView';

type ResetPasswordFormType = {
  password: string;
  confirmPassword: string;
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(8)
    .matches(/[a-zA-Z]/, 'letter')
    .matches(/\d/, 'digit')
    .matches(/[@$!%*#?&]/, 'special'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const sub = searchParams.get('sub') ?? '';
  const code = searchParams.get('pw_token') ?? '';
  const [email, setEmail] = useState('');

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitted, isValid },
    trigger,
    watch,
  } = useForm<ResetPasswordFormType>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
  });

  const watchPassword = watch('password');

  const fetchEmailAndValidateToken = async (): Promise<void> => {
    try {
      const email = await validateTokenAndGetEmail(sub, code);
      setEmail(email);
    } catch (e) {
      console.error(e);
      toast.error('Invalid token. Please try again', {
        className: 'toast-notify-error',
      });
      setTimeout(() => navigate('/forgot-password'), 3_000);
    }
  };

  const onSubmit = async (data: ResetPasswordFormType) => {
    setIsLoading(true);
    try {
      const res = await userServiceResetPassword(data.password, code);
      if (res.success) {
        toast.success('Your password was set successfully. Please login.', {
          className: 'toast-notify-success',
        });

        setTimeout(() => navigate('/login'), 4000);
      } else {
        console.error(res.errorMessage);
        toast.error('Failed to set password.', {
          className: 'toast-notify-error',
        });
      }
    } catch (e) {
      console.error(e);
      toast.error('Failed to set password.', {
        className: 'toast-notify-error',
      });
    }

    setIsLoading(false);
    trigger();
  };

  useEffect(() => {
    trigger('password');
  }, [watchPassword]);

  useEffect(() => {
    fetchEmailAndValidateToken();
  }, [email]);

  return (
    <ResetPasswordView
      email={email}
      loading={isLoading}
      passwordRegister={register('password')}
      passwordError={!!(isSubmitted && errors.password?.message)}
      confirmPasswordRegister={register('confirmPassword')}
      confirmPasswordError={errors.confirmPassword?.message}
      password={getValues('password')}
      requirements={errors.password?.types}
      displayMainError={isSubmitted && !isValid}
      isSubmitted={isSubmitted}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
}
