import { toast } from 'betterlesson-library-react';
import { ParseResult } from 'papaparse';
import { useState } from 'react';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import UserToProvision from '@pages/DeprecatedPages/UserManagement/components/ProvisioningBulkUpload/models/UserToProvision';
import { validateAndTransformCSVData } from './CSVDataParser';
import styles, {
  DEFAULT_REMOVE_HOVER_COLOR,
  REMOVE_HOVER_COLOR_LIGHT,
} from './CSVReaderStyles';

type Props = {
  setUsersToImport: (usersToImport: Array<UserToProvision>) => void;
  onHandleCancelFile: () => void;
};

export default function CSVReader({
  setUsersToImport,
  onHandleCancelFile,
}: Props): JSX.Element {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const handleUploadResults = (results: ParseResult<string[]>) => {
    const result = validateAndTransformCSVData(results);
    result
      .map((usersToProvision) => {
        setUsersToImport(usersToProvision);
      })
      .mapLeft((error) => {
        toast.error(error.message, {
          className: 'toast-notify-error',
        });
      });
  };
  /* eslint-disable */
  return (
    <CSVReader
      onUploadAccepted={(results: ParseResult<string[]>) => {
        handleUploadResults(results);
        setZoneHover(false);
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
      noDrag
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }: any): JSX.Element => (
        <div
          {...getRootProps()}
          style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
        >
          {acceptedFile ? (
            <div style={styles.file}>
              <div style={styles.info}>
                <span style={styles.size}>
                  {formatFileSize(acceptedFile.size)}
                </span>
                <span style={styles.name}>{acceptedFile.name}</span>
              </div>
              <div style={styles.progressBar}>
                <ProgressBar />
              </div>
              <div
                {...getRemoveFileProps()}
                style={styles.remove}
                onMouseOver={(event: Event) => {
                  event.preventDefault();
                  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                }}
                onMouseOut={(event: Event) => {
                  event.preventDefault();
                  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                }}
                onClick={(event: Event) => {
                  getRemoveFileProps().onClick(event);
                  onHandleCancelFile();
                }}
              >
                <Remove color={removeHoverColor} />
              </div>
            </div>
          ) : (
            <div data-testid="upload-csv-file">Upload CSV File</div>
          )}
        </div>
      )}
    </CSVReader>
  );
  /* eslint-enable */
}
