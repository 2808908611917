export const ROLE = Object.freeze({
  TEACHER: 'TEACHER',
  PARAPROFESSIONAL: 'PARAPROFESSIONAL',
  INSTRUCTIONAL_COACH: 'INSTRUCTIONAL_COACH',
  PRINCIPAL: 'PRINCIPAL',
  SUPERINTENDENT: 'SUPERINTENDENT',
  OTHER_DISTRICT_ADMIN: 'OTHER_DISTRICT_ADMIN',
  OTHER: 'OTHER',
});

export const ROLE_LABEL: Record<string, string> = Object.freeze({
  TEACHER: 'Teacher',
  PARAPROFESSIONAL: 'Paraprofessional/Asst. Teacher',
  INSTRUCTIONAL_COACH: 'Instructional Coach',
  PRINCIPAL: 'Principal/Asst. Principal',
  SUPERINTENDENT: 'Superintendent/Asst. Sup.',
  OTHER_DISTRICT_ADMIN: 'Other District Admin',
  OTHER: 'Another type of educator',
});

export const ROLE_OPTIONS = Object.entries(ROLE_LABEL).map(
  ([value, label]) => ({ value, label })
);

export const ROLE_TEXT: Record<string, string> = Object.freeze({
  [ROLE.TEACHER]: 'a teacher',
  [ROLE.PARAPROFESSIONAL]: 'a paraprofessional/asst. teacher',
  [ROLE.INSTRUCTIONAL_COACH]: 'an instructional coach',
  [ROLE.PRINCIPAL]: 'a principal/asst. principal',
  [ROLE.SUPERINTENDENT]: 'a superintendent/asst. sup',
  [ROLE.OTHER_DISTRICT_ADMIN]: 'a district admin',
  [ROLE.OTHER]: 'another type of educator',
});

export const TEACHER_EXPERIENCE = Object.freeze({
  NEW_TEACHER: 'NEW_TEACHER',
  EXPERIENCED_CHANGE_ROLE: 'EXPERIENCED_CHANGE_ROLE',
  EXPERIENCED: 'EXPERIENCED',
  OTHER: 'OTHER',
});

export const TEACHER_EXPERIENCE_OPTIONS = Object.entries({
  NEW_TEACHER: 'New to education',
  EXPERIENCED_CHANGE_ROLE: 'Experienced in education',
  EXPERIENCED: 'Experienced in this role',
  OTHER: 'Skilled at other education work',
}).map(([value, label]) => ({ value, label }));

export const TEACHER_EXPERIENCE_TEXT: Record<string, string> = Object.freeze({
  [TEACHER_EXPERIENCE.NEW_TEACHER]: 'new at this role',
  [TEACHER_EXPERIENCE.EXPERIENCED_CHANGE_ROLE]: 'experienced',
  [TEACHER_EXPERIENCE.EXPERIENCED]: 'experienced',
  [TEACHER_EXPERIENCE.OTHER]: 'skilled at other education work',
});

export const TEACHER_SUBJECT = Object.freeze({
  CORE: 'CORE',
  ELECTIVE: 'ELECTIVE',
  SPECIAL_NEED: 'SPECIAL_NEED',
  EL: 'EL',
  OTHER: 'OTHER',
});

export const TEACHER_SUBJECT_LABEL_MAP = new Map(
  Object.entries({
    CORE: 'Core Subjects - Math, ELA, etc',
    ELECTIVE: 'Special Subjects or Electives, Art, Music, etc',
    SPECIAL_NEED:
      'Students with Special Needs, Inclusive or outside of General Ed',
    EL: 'English Language Learners, Limited proficiency',
    OTHER: "Focusing on other topics, that don't match above",
  })
);

export const TEACHER_SUBJECT_ORDER = [
  TEACHER_SUBJECT.CORE,
  TEACHER_SUBJECT.ELECTIVE,
  TEACHER_SUBJECT.SPECIAL_NEED,
  TEACHER_SUBJECT.EL,
  TEACHER_SUBJECT.OTHER,
];

export const TEACHER_SUBJECT_TEXT: Record<string, string> = Object.freeze({
  [TEACHER_SUBJECT.CORE]: 'supporting core subjects',
  [TEACHER_SUBJECT.ELECTIVE]: 'supporting special subjects or electives',
  [TEACHER_SUBJECT.SPECIAL_NEED]: 'supporting students with special needs',
  [TEACHER_SUBJECT.EL]: 'supporting English language learners',
  [TEACHER_SUBJECT.OTHER]: 'focusing on other topics',
});
