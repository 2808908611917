import {
  Table,
  TableBody,
  TableContainer,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  Paper,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { isPastWorkshopsAtom, sessionGroupsAtom } from '@atoms/workshop';
import { SORT_COLUMN } from '@constants/workshop';
import { SearchBar } from '../SearchBar/SearchBar';
import { TableHeaders } from '../TableHeaders/TableHeaders';
import WorkshopTableRow from '../WorkshopTableRow/WorkshopTableRow';
import { WorkshopsToggle } from '../WorkshopsToggle/WorkshopsToggle';
import {
  dateSort,
  sessionSort,
  workshopSort,
} from '../utils/UpcomingWorkshops';

const SORT_DIRECTION = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export default function WorkshopsTable(): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState<string>(SORT_COLUMN.DATE);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
    SORT_DIRECTION.DESC
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sessionGroups = useAtomValue(sessionGroupsAtom);
  const isPastWorkshops = useAtomValue(isPastWorkshopsAtom);

  const handleSearchBarFilter = (term: string) => {
    setSearchText(term);
  };

  const toggleDirection = (resetDirection?: boolean) => {
    if (sortDirection === SORT_DIRECTION.ASC || resetDirection) {
      setSortDirection(SORT_DIRECTION.DESC);
      return;
    }

    setSortDirection(SORT_DIRECTION.ASC);
  };

  const handleSortChange = (updatedSortColumn: string) => {
    toggleDirection(updatedSortColumn !== sortColumn);
    setSortColumn(updatedSortColumn);
  };

  const getSortFn = () => {
    switch (sortColumn) {
      case SORT_COLUMN.SESSION:
        return sessionSort;
      case SORT_COLUMN.WORKSHOP:
        return workshopSort;
      default:
        return dateSort;
    }
  };

  const getSortedFilteredSessionGroups = () => {
    const sortFn = getSortFn();
    const sortedSessionGroups = sortFn(
      sessionGroups,
      sortDirection === SORT_DIRECTION.ASC
    );

    const relevantSessionGroups = sortedSessionGroups.filter((sg) =>
      isPastWorkshops
        ? sg.sessionEndTime <= Date.now()
        : sg.sessionEndTime > Date.now()
    );

    return relevantSessionGroups.filter((sg) =>
      `${sg.workshopName}${sg.account}${sg.sessionName}`
        .toLowerCase()
        .includes(searchText)
    );
  };

  const renderTableBody = () =>
    getSortedFilteredSessionGroups().map((sessionGroup) => (
      <WorkshopTableRow
        sessionGroup={sessionGroup}
        key={sessionGroup.uuid}
        isMobile={isMobile}
        isPast={isPastWorkshops}
      />
    ));

  const renderTable = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table
        aria-label="upcoming workshops"
        style={{ width: '100%', overflow: 'hidden' }}
      >
        <TableHeaders
          sortDirection={sortDirection}
          sortColumn={sortColumn}
          isMobile={isMobile}
          onSortChange={handleSortChange}
        />
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Stack data-testid="upcoming-workshops">
      <Typography py="1rem" variant="h4">
        {`${isPastWorkshops ? 'Past' : 'Upcoming'} Workshops`}
      </Typography>

      <Stack
        alignItems={isMobile ? 'flex-start' : 'center'}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <SearchBar
          isMobile={isMobile}
          searchText={searchText}
          handleSearchBarFilter={handleSearchBarFilter}
        />
        <WorkshopsToggle />
      </Stack>

      {renderTable()}
    </Stack>
  );
}
