import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material/';
import { Fragment, useContext, useState } from 'react';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import styles from '../Permissions.module.scss';
import { Product, ProductRole } from '../types';

type Props = {
  onRoleChange: (roles: ProductRole[]) => void;
};

export default function MultiEdit({ onRoleChange }: Props): JSX.Element {
  const [count, setCount] = useState<number>(1);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [roleOptions, setRoleOptions] = useState<ProductRole[][]>([]);
  const [selectedRoles, setSelectedRoles] = useState<ProductRole[]>([]);
  const { products }: ProductsContextType = useContext(ProductsContext);

  const handleProductChange = (e: SelectChangeEvent<string>, index: number) => {
    const {
      target: { value },
    } = e;

    const parsed: Product = JSON.parse(value);

    const prods = [...selectedProducts];
    prods[index] = { ...parsed };
    setSelectedProducts(prods);

    const roles: ProductRole[][] = [...roleOptions];
    if (typeof roles[index] === 'undefined') roles[index] = [];
    roles[index] = [...parsed.productRoles];
    setRoleOptions(roles);
  };

  const handleRoleChange = (e: SelectChangeEvent<string>, index: number) => {
    const {
      target: { value },
    } = e;

    const parsed: ProductRole = JSON.parse(value);

    setSelectedRoles((prevState: ProductRole[]) => {
      prevState[index] = parsed;
      return [...prevState];
    });

    onRoleChange(selectedRoles);
  };

  const duplicate = (): void => setCount((prevState: number) => prevState + 1);

  const remove = (): void => {
    const products: Product[] = [...selectedProducts];
    products.pop();
    setSelectedProducts(products);

    const roles: ProductRole[] = [...selectedRoles];
    roles.pop();
    setSelectedRoles(roles);
    onRoleChange(selectedRoles);

    setCount((prevState: number) => prevState - 1);
  };

  return (
    <Grid container spacing={1}>
      {[...Array(count)].map((num, index) => (
        <Fragment key={index}>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel>Product</InputLabel>
              <Select
                data-testid="product"
                defaultValue=""
                label="Product"
                name="product"
                style={{ height: '100%' }}
                value={JSON.stringify(selectedProducts[index]) || ''}
                onChange={(e) => handleProductChange(e, index)}
              >
                {products.map((product: Product) => {
                  return (
                    <MenuItem
                      value={JSON.stringify(product)}
                      key={product.product.id}
                    >
                      {product.product.productName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={8} mb={2}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                data-testid="role"
                defaultValue=""
                label="Role"
                name="role"
                style={{ height: '100%' }}
                value={JSON.stringify(selectedRoles[index]) || ''}
                onChange={(e) => handleRoleChange(e, index)}
              >
                {Array.isArray(roleOptions) &&
                  roleOptions.map((roles: ProductRole[], i: number) => {
                    if (i === index) {
                      return roles.map((role: ProductRole) => {
                        return (
                          <MenuItem value={JSON.stringify(role)} key={role.id}>
                            {role.roleName}
                          </MenuItem>
                        );
                      });
                    }
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <Button
              className={index !== count - 1 ? 'display-none' : styles.addIcon}
              data-testid="duplicate"
              color="inherit"
              fullWidth
              size="small"
              style={{ minWidth: 'auto' }}
              onClick={() => duplicate()}
            >
              <AddCircleOutlinedIcon />
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              className={
                index !== count - 1 || index === 0
                  ? 'display-none'
                  : styles.removeIcon
              }
              color="error"
              data-testid="remove"
              fullWidth
              size="small"
              style={{ minWidth: 'auto' }}
              onClick={remove}
            >
              <CloseOutlinedIcon />
            </Button>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}
