import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

type RequestList = {
  userUUID: string;
  seatSetUUID: string;
}[];

export const createSeatAssignments = async ({
  requestList,
  sendAdminEmail,
}: {
  requestList: RequestList;
  sendAdminEmail: boolean;
}): Promise<boolean> => {
  try {
    const response = await getUserServiceAxios().post(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/seat-assignments/`,
      {
        requestList,
        sendAdminEmail,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );

    return response.status === 201 ? true : false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
