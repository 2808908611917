export const SUBJECTS = [
  { category: 'General Ed', subject: 'General Ed' },
  { category: 'English / Language Arts', subject: 'English / Language Arts' },
  { category: 'English / Language Arts', subject: 'Reading' },
  { category: 'English / Language Arts', subject: 'Writing' },
  { category: 'English / Language Arts', subject: 'Reading Intervention' },
  { category: 'Math', subject: 'Math' },
  { category: 'Math', subject: 'Algebra' },
  { category: 'Math', subject: 'Geometry' },
  { category: 'Math', subject: 'Calculus' },
  { category: 'Math', subject: 'Statistics' },
  { category: 'Math', subject: 'Trigonometry' },
  { category: 'Science', subject: 'Science' },
  { category: 'Science', subject: 'Biology' },
  { category: 'Science', subject: 'Chemistry' },
  { category: 'Science', subject: 'Physical Sciences / Physics' },
  { category: 'Science', subject: 'Environmental / Earth Sciences' },
  { category: 'Social Studies', subject: 'Social Studies' },
  { category: 'Social Studies', subject: 'World / European History' },
  { category: 'Social Studies', subject: 'US History' },
  { category: 'Social Studies', subject: 'Civics / Government' },
  { category: 'Social Studies', subject: 'Economics' },
  { category: 'Social Studies', subject: 'Geography' },
  { category: 'Social Studies', subject: 'Psychology / Sociology' },
  {
    category: 'Technology / Engineering',
    subject: 'Technology / Engineering',
  },
  { category: 'Technology / Engineering', subject: 'Computer Science' },
  { category: 'World Languages', subject: 'World Languages' },
  { category: 'Art / Music', subject: 'Art / Music' },
  { category: 'Library / Media Science', subject: 'Library / Media Science' },
  { category: 'Physical Ed / Health', subject: 'Physical Ed / Health' },
  { category: 'Judaic Studies / Hebrew', subject: 'Judaic Studies / Hebrew' },
  {
    category: 'School Counselor / Psychologist',
    subject: 'School Counselor / Psychologist',
  },
  {
    category: 'Instructional Technology',
    subject: 'Instructional Technology',
  },
  { category: 'Other', subject: 'Other' },
];

export const SUBJECT_OPTIONS = SUBJECTS.map(({ subject }) => subject);

export const SUBJECT_CATEGORY_MAP = new Map(
  SUBJECTS.map((subject) => [subject.subject, subject.category])
);
