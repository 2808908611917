import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  SxProps,
  TypographyProps,
} from '@mui/material';

type Props = {
  imageSrc: string;
  imageAlt: string;
  imageStyle?: SxProps;
  cardContentStyle?: SxProps;
  title: string;
  titleProps?: TypographyProps<'p'>;
  description: string;
  selected: boolean;
  onClick: () => void;
};

export default function ProfessionalRoleCard({
  imageSrc,
  imageAlt,
  imageStyle,
  cardContentStyle = {},
  title,
  titleProps,
  description,
  selected,
  onClick,
}: Props): JSX.Element {
  const handleClick = () => {
    onClick();
  };

  return (
    <Stack
      width={1}
      borderRadius="0 4px 4px 0"
      border="2px solid"
      borderColor={selected ? 'var(--color-secondary-light)' : 'transparent'}
      data-testid={selected ? 'selected-answer-card' : undefined}
    >
      <Card
        sx={{
          width: 1,
          borderRadius: '0 4px 4px 0',
          boxShadow: '0 2px 9px 0 rgba(170,170,170,0.5)',
        }}
      >
        <CardActionArea
          data-testid={'answer-card'}
          sx={{
            display: 'flex',
            alignItems: 'normal',
            justifyContent: 'flex-start',
          }}
          onClick={handleClick}
        >
          <CardMedia
            sx={imageStyle}
            component="img"
            image={imageSrc}
            alt={imageAlt}
          />
          <CardContent
            sx={{
              p: 0,
              pl: 2.25,
              pr: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              ...cardContentStyle,
            }}
          >
            <Typography
              variant="h5"
              component="p"
              color="rgba(0,0,0,0.87)"
              {...titleProps}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              fontSize="14px"
              color="rgba(0,0,0,0.87)"
            >
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Stack>
  );
}
