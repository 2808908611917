import { LoadingPage } from 'betterlesson-library-react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { LaunchDarklyFactory } from '@utils/LaunchDarklyFactory';
import { handleRedirectAfterLogin } from '@utils/RedirectHandler';

export default function LabRedirect(): JSX.Element {
  const { isAuthenticated, isLoading, user } = useAuth();

  const redirect = async () => {
    if (!isAuthenticated) {
      await LaunchDarklyFactory.closeLDClient();
      window.location.assign('/lab/login');
      return;
    }

    await handleRedirectAfterLogin(user ?? undefined);
  };

  useEffect(() => {
    if (!isLoading) {
      void redirect();
    }
  }, []);

  return <LoadingPage data-testid="lab-redirect-loading-page" />;
}
