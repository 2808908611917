import { Autocomplete, AutocompleteProps } from '@mui/material';
import { VirtualizedListbox } from '@components/VirtualizedAutocomplete/VirtualizedListbox';

export interface VirtualizedAutocompleteProps<T>
  extends AutocompleteProps<T, false, false, false> {
  label?: string;
}

export default function VirtualizedAutocomplete<T>({
  value,
  ...autocompleteProps
}: VirtualizedAutocompleteProps<T>): JSX.Element {
  return (
    <Autocomplete
      {...autocompleteProps}
      openOnFocus
      multiple={false}
      disableClearable={false}
      freeSolo={false}
      ListboxComponent={VirtualizedListbox}
      value={value}
      blurOnSelect={true}
      renderOption={(props, option, state) =>
        [props, option, state] as React.ReactNode
      }
    />
  );
}
