import {
  Stack,
  Typography,
  Autocomplete,
  Chip,
  TextField,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { SUBJECT_CATEGORY_MAP, SUBJECT_OPTIONS } from '@constants/subjects';
import { EducatorProfileType } from '@pages/EducatorProfile/types/EducatorProfile';

type Props = {
  control: Control<EducatorProfileType>;
  error?: string;
};

export default function SubjectAreaAutocomplete({
  control,
  error,
}: Readonly<Props>): JSX.Element {
  const { fields, replace } = useFieldArray({
    rules: { minLength: 1 },
    control,
    name: 'coachingPreferences.subjects',
  });

  const handleGradeChange = (
    _event: SyntheticEvent,
    subjectAreas: string[]
  ) => {
    replace(subjectAreas.map((subject) => ({ subject })));
  };

  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle1" component="div">
        Subject Areas
      </Typography>

      <Autocomplete
        multiple
        id="autocomplete"
        data-testid="subject-area-autocomplete"
        options={SUBJECT_OPTIONS}
        groupBy={(option) => SUBJECT_CATEGORY_MAP.get(option) ?? ''}
        value={fields.map(({ subject }) => subject)}
        onChange={handleGradeChange}
        disableCloseOnSelect
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              sx={{
                '& .MuiChip-deleteIcon': {
                  color: '#fff',
                },
              }}
              label={option}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              sx: { height: '100%' },
              style: { paddingTop: '2.5px', paddingBottom: '2.5px' },
            }}
          />
        )}
      />
    </Stack>
  );
}
