import { Stack } from '@mui/material';
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { FocusAreaType } from '@app-types/Invite';
import ProfessionalRolePage from '@pages/CoachingRegistration/components/ProfessionalRolePage/ProfessionalRolePage';
import CoachingRegistration from './CoachingRegistration';

interface CoachingProps {
  serviceUuid: string;
  focusAreas: FocusAreaType[];
}

const PAGE = {
  PROFESSIONAL_ROLES: 'professional-roles-page',
  COACHING_PARTICIPANT: 'coaching-participant-page',
};

const CoachingRegistrationContainer: React.FC<CoachingProps> = ({
  focusAreas,
  serviceUuid,
}) => {
  const [currentPage, setCurrentPage] = useState(PAGE.PROFESSIONAL_ROLES);
  const navigate = useNavigate();

  const handleProfessionalRoleConfirmation = () => {
    setCurrentPage(PAGE.COACHING_PARTICIPANT);
  };

  const handleCoachingRegistrationConfirmation = () => {
    return navigate('/participant/home');
  };

  const renderPage = () => {
    switch (currentPage) {
      case PAGE.PROFESSIONAL_ROLES:
        return (
          <ProfessionalRolePage
            onConfirm={handleProfessionalRoleConfirmation}
          />
        );
      case PAGE.COACHING_PARTICIPANT:
        return (
          <CoachingRegistration
            focusAreas={focusAreas}
            serviceUuid={serviceUuid}
            onConfirm={handleCoachingRegistrationConfirmation}
          />
        );
    }
  };

  return (
    <Stack data-testid="coaching-registration-container">{renderPage()}</Stack>
  );
};

export default CoachingRegistrationContainer;
