import { AxiosResponse } from 'axios';
import { CoachMatchDataType } from '@app-types/CoachMatch';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_COACHING_SERVICE_URL}/api/v2/coach-match`;

export const getAvailableMatches = async (
  accessToken?: string
): Promise<CoachMatchDataType[]> => {
  try {
    const response: AxiosResponse = await getServiceAxios(accessToken).post(
      `${BASE_ROUTE}/queries/find-available`,
      {}
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const deleteMatch = async (coachMatchUuid: string): Promise<boolean> => {
  try {
    await getServiceAxios().post(
      `${BASE_ROUTE}/commands/delete-retracted-match`,
      { coachMatchUuid }
    );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
