import { UrlObject } from 'url';
import { Session } from '@apis/auth';
import { EVENT, trackPendoEvent } from './Pendo';

const isBLConnectRedirect = (redirectUrl: UrlObject) => {
  const blConnectUrl = new URL(`${process.env.REACT_APP_BL_CONNECT_URL}`);
  const isBLConnect = redirectUrl.host === blConnectUrl.host;
  return isBLConnect;
};

const trackCourseRedirect = (redirectUrl: UrlObject) => {
  if (!isBLConnectRedirect(redirectUrl)) {
    return;
  }

  const sanitizedUrl = redirectUrl.toString().split('?')[0];
  trackPendoEvent(EVENT.COURSE_REDIRECT, {
    redirectUrl: sanitizedUrl,
  });
};

export const redirectAuthenticatedUser = (session: Session): void => {
  const url = new URL(session.redirectURI);
  url.searchParams.set('code', session.authCode);
  url.searchParams.set('state', session.state);
  trackCourseRedirect(url);
  window.location.assign(url.toString());
};

export const buildEntryFormUrl = (url: string): string => {
  if (!window.location.pathname?.startsWith('/lab')) {
    return url;
  }

  return `/lab${url}`;
};
