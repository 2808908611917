import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'betterlesson-library-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { registerUser } from '@apis/auth';
import { getOrgInformation } from '@apis/deprecated/org-info';
import { APIError } from '@utils/BLApiError';
import { redirectAuthenticatedUser } from '@utils/Redirect';
import RegisterView from './components/RegisterView/RegisterView';

type RegisterType = {
  givenName: string;
  familyName: string;
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
};

export default function Register(): JSX.Element {
  const [searchParams] = useSearchParams();
  const orgUuid = searchParams.get('orgUuid');
  const [emailDomains, setEmailDomains] = useState<string[]>([]);
  const [selectedEmailDomain, setSelectedEmailDomain] = useState<string>();

  const requireEmail = () =>
    yup
      .string()
      .required('Please enter your email address')
      .email('Please enter a valid email address');

  const requireUsername = () =>
    yup.string().required('Please enter your username');

  const requirePassword = () =>
    yup
      .string()
      .required()
      .min(8)
      .matches(/[a-zA-Z]/, 'letter')
      .matches(/\d/, 'digit')
      .matches(/[@$!%*#?&]/, 'special');

  const confirmPassword = () =>
    yup.string().oneOf([yup.ref('password'), null], 'Passwords must match');

  const validationSchema = yup.object().shape({
    givenName: yup.string().required('Please enter your first name'),
    familyName: yup.string().required('Please enter your last name'),
    email: !emailDomains.length ? requireEmail() : yup.string(),
    username: emailDomains.length ? requireUsername() : yup.string(),
    password: requirePassword(),
    confirmPassword: confirmPassword(),
  });

  const [isRegistering, setIsRegistering] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitted, isValid },
    trigger,
    watch,
    setValue,
  } = useForm<RegisterType>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
  });

  const watchPassword = watch('password');

  const init = async () => {
    if (!orgUuid) return;
    const org = await getOrgInformation(orgUuid);
    if (!org) return;
    setEmailDomains(org.emailDomains);
    setSelectedEmailDomain(org.emailDomains[0]);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    trigger('password');
  }, [watchPassword]);

  const onSubmit = async (data: RegisterType) => {
    if (orgUuid && emailDomains.length)
      setValue('email', `${data.username}${selectedEmailDomain}`);

    setIsRegistering(true);

    try {
      const response = await registerUser(watch());
      redirectAuthenticatedUser(response);
    } catch (e) {
      console.error(e);
      const getToast = (e: unknown): string => {
        if (!(e instanceof APIError)) return 'Account creation failed.';
        switch (e.apiErrorCode) {
          case 'DuplicateEntity':
            return 'Hi, nice to see you. Please log in, or reset your password.';
          case 'SSOFailureSetPasswordForLabBackfillUser':
            return 'Hi we haven’t seen you in awhile, so please check your email for a link to reset your password.';
          case 'SSOFailureNoPassword':
            return 'Hi there! Please check your email for a link to set your password.';
          default:
            return 'Account creation failed.';
        }
      };

      const toastMessage = getToast(e);
      toast.error(toastMessage, {
        className: 'toast-notify-error',
      });

      if (orgUuid && emailDomains.length) setValue('username', data.username);
    }

    setIsRegistering(false);
    trigger();
  };

  const handleUpdateEmailDomain = (domain: string) =>
    setSelectedEmailDomain(domain);

  return (
    <RegisterView
      isRegistering={isRegistering}
      givenNameRegister={register('givenName')}
      giveNameError={errors.givenName?.message}
      familyNameRegister={register('familyName')}
      familyNameError={errors.familyName?.message}
      emailRegister={register('email')}
      emailError={errors.email?.message}
      usernameRegister={register('username')}
      usernameError={errors.username?.message}
      passwordRegister={register('password')}
      passwordError={!!(isSubmitted && errors.password?.message)}
      confirmPasswordRegister={register('confirmPassword')}
      confirmPasswordError={errors.confirmPassword?.message}
      password={getValues('password')}
      requirements={errors.password?.types}
      displayMainError={isSubmitted && !isValid}
      isSubmitted={isSubmitted}
      emailDomains={emailDomains}
      selectedEmailDomain={selectedEmailDomain}
      onSubmit={handleSubmit(onSubmit)}
      handleUpdateEmailDomain={handleUpdateEmailDomain}
    />
  );
}
