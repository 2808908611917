import {
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { EducatorProfileType } from '@pages/EducatorProfile/types/EducatorProfile';

type Props = {
  control: Control<EducatorProfileType>;
  name: 'role' | 'experience';
  label: string;
  error?: string;
  disabled?: boolean;
  options: { value: string; label: string }[];
};

export default function EducatorProfileSelect({
  control,
  label,
  name,
  disabled,
  error,
  options,
}: Readonly<Props>): JSX.Element {
  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle1" component="div">
        {label}
      </Typography>

      <Controller
        control={control}
        name={`professionalRole.${name}`}
        disabled={disabled}
        render={({ field: { onChange, value } }) => (
          <FormControl error={!!error}>
            <Select
              data-testid="educator-profile-select"
              disabled={disabled}
              error={!!error}
              value={value || ''}
              onChange={onChange}
            >
              {options.map((o) => (
                <MenuItem
                  data-testid={`educator-profile-select-menu-item-${o.value}`}
                  key={o.value}
                  value={o.value}
                >
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
          </FormControl>
        )}
      />
    </Stack>
  );
}
