import {
  Typography,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

interface PreviousCoachPreferenceProps {
  previousCoach: string;
  prefersPreviousCoach: boolean | null;
  onCoachChange: (prefersPreviousCoach: boolean | null) => void;
}

const PREFERENCE_OPTIONS: { label: string; value: boolean | null }[] = [
  { label: 'No Preference', value: null },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export default function PreviousCoachPreference({
  previousCoach,
  prefersPreviousCoach,
  onCoachChange,
}: Readonly<PreviousCoachPreferenceProps>): JSX.Element {
  const handleCoachChange = (event: SelectChangeEvent<string>) => {
    onCoachChange(JSON.parse(event.target.value));
  };

  return (
    <Stack pt={5} data-testid="previous-coach-preference">
      <Typography fontWeight={500} textAlign="center" pb={2}>
        Would you like to work with your previous coach {previousCoach}?
      </Typography>
      <Select
        labelId="previous-coach-preference-select"
        id="previous-coach-preference"
        data-testid={'previous-coach-preference-select'}
        value={JSON.stringify(prefersPreviousCoach)}
        onChange={handleCoachChange}
      >
        {PREFERENCE_OPTIONS.map((option) => (
          <MenuItem
            data-testid={`previous-coach-preference-option-${option.label}`}
            key={option.label}
            value={JSON.stringify(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Typography
        variant="caption"
        pt={0.5}
        sx={{ color: 'var(--color-grey-2)', textAlign: 'start' }}
      >
        Requests cannot be guaranteed
      </Typography>
    </Stack>
  );
}
