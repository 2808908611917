import { Checkbox as BaseCheckbox, CheckboxProps } from '@mui/material';
import styles from './Checkbox.module.scss';

export default function Checkbox(props: CheckboxProps): JSX.Element {
  const renderIcon = () => <div className={styles.icon} />;
  return (
    <BaseCheckbox
      {...props}
      classes={{ checked: styles.checked }}
      icon={renderIcon()}
    />
  );
}
