import { Stack, Typography, TextField, Tooltip } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { EducatorProfileType } from '@pages/EducatorProfile/types/EducatorProfile';

type Props = { control: Control<EducatorProfileType> };

export default function OrganizationInput({
  control,
}: Readonly<Props>): JSX.Element {
  return (
    <Tooltip
      title="Your organization is auto-populated based on the account you are associated with. If you feel this is incorrect, contact support below."
      arrow
    >
      <Stack spacing={0.5} data-testid="organization-input">
        <Typography variant="subtitle1" component="div">
          Organization
        </Typography>

        <Controller
          control={control}
          name="organization"
          disabled
          render={({ field }) => <TextField {...field} />}
        />
      </Stack>
    </Tooltip>
  );
}
