import { toast } from 'betterlesson-library-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { getOrganizationOrders, SeatSet } from '@apis/deprecated/organizations';
import { OrganizationOrder } from '@apis/deprecated/organizations';
import { Organization } from '@apis/deprecated/organizations';
import { createSeatAssignments } from '@apis/deprecated/seat-assignments';
import { PRODUCT_TYPE_LABEL } from '@constants/product';
import {
  MODAL_KEY,
  useModalContext,
} from '@contexts/ModalContext/ModalContext';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import { UserRow } from '../Users/components/UserTable/Types';
import SeatAssignmentOverlayView from './components/SeatAssignmentOverlayView/SeatAssignmentOverlayView';

type Props = {
  onClose: () => void;
};

const formatDate = (d: Date) => {
  return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
};

const formatProductLabel = ({
  productType,
  start,
  end,
  displayName,
}: SeatSet) => {
  if (!!displayName && displayName !== '') {
    return displayName;
  }
  const startDate = new Date(start);
  const formattedStart = formatDate(startDate);
  const endDate = new Date(end);
  const formattedEnd = formatDate(endDate);

  return `${PRODUCT_TYPE_LABEL[productType]} ${formattedStart} - ${formattedEnd}`;
};

export default function SeatAssignmentOverlay({ onClose }: Props): JSX.Element {
  const { openModal } = useModalContext();
  const [seatSets, setSeatSets] = useState<SeatSet[]>([]);
  const [organization, setOrganization] = useState<Organization>();
  const seatSetMap = useRef(new Map<string, SeatSet[]>());
  const [orderOptions, setOrderOptions] = useState<OrganizationOrder[]>([]);
  const [productInstanceOptions, setProductInstanceOptions] = useState<
    SeatSet[]
  >([]);
  const { checkedUserIds, users, updateUsers }: ProductsContextType =
    useContext(ProductsContext);

  const fetchOrganizationOrders = async () => {
    seatSetMap.current.clear();

    if (!organization) {
      setOrderOptions([]);
      setProductInstanceOptions([]);
      setSeatSets([]);
      return;
    }

    const organizationOrders: OrganizationOrder[] | undefined =
      await getOrganizationOrders(organization.uuid);

    if (!organizationOrders) return;

    const orders: OrganizationOrder[] = [];

    organizationOrders.forEach((order: OrganizationOrder) => {
      orders.push(order);
      seatSetMap.current.set(order.orderUUID, order.seatSets);
    });

    setOrderOptions(orders);
  };

  useEffect(() => {
    fetchOrganizationOrders();
  }, [organization]);

  const handleOrganizationChange = (organization: Organization | null) => {
    if (!organization) {
      setOrganization(undefined);
      return;
    }
    setOrganization(organization);
  };

  const handleOrderChange = (order: OrganizationOrder): void => {
    setSeatSets([]);
    setProductInstanceOptions(order.seatSets);
  };

  const handleInstanceChange = (instances: SeatSet[]) => setSeatSets(instances);
  const handleCancelClick = () => onClose();

  const getProductInstanceAvailabilities = () => {
    return seatSets.map((seatSet: SeatSet) => {
      return {
        productInstance: formatProductLabel(seatSet),
        quantityTotal: seatSet.quantityTotal,
        quantityAssigned: seatSet.quantityAssigned,
        quantityActivated: seatSet.quantityActivated,
      };
    });
  };

  const handleSubmitClick = async () => {
    if (!seatSets.length) return;
    const result = await openModal(MODAL_KEY.WELCOME);
    if (!result) return;

    try {
      const requestList = seatSets.flatMap((seatSet) =>
        Array.from(checkedUserIds).map((userId) => ({
          userUUID: userId,
          seatSetUUID: seatSet.uuid,
        }))
      );

      const response: boolean = await createSeatAssignments({
        requestList,
        sendAdminEmail: result.isLeader,
      });

      if (response) {
        const productTypes: string[] = [];

        seatSets.forEach((seatSet: SeatSet) => {
          productTypes.push(seatSet.productType);
        });

        toast.success(
          `${
            Array.from(checkedUserIds).length
          } users invited to product ${productTypes.join(', ').toUpperCase()}`,
          {
            className: 'toast-notify-success',
          }
        );

        users.forEach((user: UserRow) => {
          checkedUserIds.forEach((id) => {
            if (user.userId === id)
              user.productTypes = [...user.productTypes, ...productTypes];
          });
        });

        updateUsers(users);
        onClose();
      } else {
        toast.error('Users not invited! Try again.', {
          className: 'toast-notify-error',
        });
      }
    } catch (e) {
      console.error(e);
      toast.error('Seat Assignment Failed.', {
        className: 'toast-notify-error',
      });
    }
  };

  return (
    <SeatAssignmentOverlayView
      availabilities={getProductInstanceAvailabilities()}
      orderOptions={orderOptions}
      productInstanceOptions={productInstanceOptions}
      onOrganizationChange={handleOrganizationChange}
      onOrderChange={handleOrderChange}
      onInstanceChange={handleInstanceChange}
      onCancelClick={handleCancelClick}
      onSubmitClick={handleSubmitClick}
    />
  );
}
