import { Stack, Typography } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';
import { contextUserTimezoneAtom } from '@atoms/user';

export const TimezoneLink = (): JSX.Element => {
  const timezone =
    useAtomValue(contextUserTimezoneAtom) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Stack direction="row">
      <Typography mr="1rem" color={COLOR_PALETTE.text.secondary}>
        {timezone.replace(/_/g, ' ')} Time Zone
      </Typography>

      <Link
        data-testid="timezone"
        to="/profile"
        style={{ textDecoration: 'none' }}
        target="_blank"
      >
        <Typography variant="link2">Change</Typography>
      </Link>
    </Stack>
  );
};
