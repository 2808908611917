import { InputProps } from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SyntheticEvent } from 'react';
import { OptionType } from '@app-types/Select';
import Button, { BUTTON_VARIANT } from '@components/Button/Button';
import Input from '@components/Input/Input';
import PartnerAccountInput from '@components/PartnerAccountInput/PartnerAccountInput';
import Search from '@images/icons/search.svg';
import style from './UserFiltersView.module.css';

type Props = {
  userCount?: number;
  dateFromValue: Date | undefined;
  dateToValue: Date | undefined;
  userSearchValue: string;
  accountValue?: OptionType;
  onDateFromChange: (value?: Date) => void;
  onDateToChange: (value?: Date) => void;
  onUserSearchChange: (value: string) => void;
  onAccountChange: (e: SyntheticEvent, option: OptionType | null) => void;
  onApplyFiltersClick: () => void;
  stateValue?: OptionType;
  accountTypeValue?: OptionType;
  onStateChange?: (option: OptionType | null) => void;
  onAccountTypeChange?: (option: OptionType | null) => void;

  states?: OptionType[];
  accountTypes?: OptionType[];
};

export default function UserFiltersView({
  userCount,
  dateFromValue,
  dateToValue,
  userSearchValue,
  accountValue,
  onDateFromChange,
  onDateToChange,
  onUserSearchChange,
  onAccountChange,
  onApplyFiltersClick,
  stateValue,
  accountTypeValue,
  onStateChange,
  onAccountTypeChange,
  states,
  accountTypes,
}: Props): JSX.Element {
  const currentDate: Date = new Date(Date.now());

  const handleFromDateChange = (val: Date | null): void => {
    onDateFromChange(val || undefined);
  };

  const handleToDateChange = (val: Date | null): void => {
    onDateToChange(val || undefined);
  };

  const renderDateRangeFilter = () => (
    <div className={style.dateRangeContainer}>
      <Stack spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            InputProps={{ 'data-testid': 'date' } as Partial<InputProps>}
            label="date created"
            inputFormat="MM/dd/yyyy"
            maxDate={currentDate}
            value={dateFromValue || null}
            onChange={handleFromDateChange}
            renderInput={(params) => <TextField {...params} />}
          />

          <DesktopDatePicker
            InputProps={{ 'data-testid': 'date' } as Partial<InputProps>}
            label="end date"
            inputFormat="MM/dd/yyyy"
            minDate={dateFromValue}
            maxDate={currentDate}
            value={dateToValue || null}
            onChange={handleToDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>
    </div>
  );

  const renderResultsFound = () => {
    if (!userCount) {
      return 'no results found';
    }

    if (userCount > 999999) {
      return '999999+ results found';
    }

    return `${userCount} result${userCount === 1 ? '' : 's'} found`;
  };

  return (
    <div className={style.container} data-testid="filter-inputs">
      <div className={style.searchContainer}>
        <p className={style.userResultsText} data-testid="search-results">
          {renderResultsFound()}
        </p>
        <h6 className={style.headerText}>Search</h6>
        <Input
          dataTestId="user-search-input"
          label="name"
          icon={Search}
          placeholder="Search by name or email"
          value={userSearchValue}
          onChange={onUserSearchChange}
        />
      </div>
      <h6 className={style.headerText}>Filter</h6>
      <PartnerAccountInput
        accountValue={accountValue}
        onAccountChange={onAccountChange}
        stateValue={stateValue}
        accountTypeValue={accountTypeValue}
        onStateChange={onStateChange}
        onAccountTypeChange={onAccountTypeChange}
        states={states}
        accountTypes={accountTypes}
      />
      {renderDateRangeFilter()}
      <div className={style.buttonContainer}>
        <Button
          contentMinWidth="85px"
          dataTestId="apply-filter-button"
          text="Apply Filters"
          variant={BUTTON_VARIANT.OUTLINE}
          onClick={onApplyFiltersClick}
        />
      </div>
    </div>
  );
}
