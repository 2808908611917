import { Box, Stack } from '@mui/material';
import { LoadingPage, toast } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  getUsersProfessionalRole,
  createUsersProfessionalRole,
} from '@apis/professional-roles';
import { ProfessionalRoleFormType } from '@app-types/ProfessionalRoles';
import { AccessToken } from '@app-types/Token';
import ProfessionalRoleForm from '@components/ProfessionalRoleForm/ProfessionalRoleForm';
import ProfessionalRoleWrapper from '@components/ProfessionalRoleWrapper/ProfessionalRoleWrapper';
import { initializeLabRedirectPathFromSearchParams } from '@utils/LabRedirectPath';
import { handleRedirectAfterLogin } from '@utils/RedirectHandler';
import ProfessionalRoleConfirmation from './components/ProfessionalRoleConfirmation/ProfessionalRoleConfirmation';

const QUESTION = Object.freeze({
  FORM: 'form',
  CONFIRMATION: 'confirmation',
});

export const SHOULD_BYPASS_CONFIRMATION_KEY = 'should_bypass_confirmation_page';

export const setBypassConfirmPage = (shouldByPass: boolean): void => {
  window.sessionStorage.setItem(
    SHOULD_BYPASS_CONFIRMATION_KEY,
    String(shouldByPass)
  );
};

export default function ProfessionalRoles(): JSX.Element {
  const shouldBypassConfirmPage = JSON.parse(
    window.sessionStorage.getItem(SHOULD_BYPASS_CONFIRMATION_KEY) ?? 'false'
  );

  const { user } = useAuth();
  const [question, setQuestion] = useState<string>(QUESTION.FORM);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [professionalRole, setProfessionalRole] =
    useState<ProfessionalRoleFormType>();

  const getUserUUID = () => {
    const accessToken = user?.access_token;
    const { sub } = jwtDecode<AccessToken>(`${accessToken}`);
    return sub;
  };

  const fetchProfessionalRole = async () => {
    const userUUID = getUserUUID();
    const response = await getUsersProfessionalRole(userUUID);

    if (!response) {
      return;
    }

    setProfessionalRole(response);
  };

  useEffect(() => {
    initializeLabRedirectPathFromSearchParams();
    fetchProfessionalRole();
  }, []);

  const submitProfessionalRole = async (
    updatedProfessionalRole = professionalRole
  ) => {
    const userUUID = getUserUUID();

    if (!updatedProfessionalRole || isRedirecting || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const isCreated = await createUsersProfessionalRole(
      userUUID,
      updatedProfessionalRole
    );

    setIsSubmitting(false);
    if (!isCreated) {
      toast.error('Professional Role creation failed.', {
        className: 'toast-notify-error',
      });
      return;
    }

    setIsRedirecting(true);
    void handleRedirectAfterLogin(user, true);
  };

  const handleConfirmClick = () => {
    submitProfessionalRole();
  };

  const handleProfessionalRolesChange = (
    newProfessionalRole: ProfessionalRoleFormType
  ) => {
    setProfessionalRole(newProfessionalRole);

    if (!shouldBypassConfirmPage) {
      setQuestion(QUESTION.CONFIRMATION);
      return;
    }

    submitProfessionalRole(newProfessionalRole);
  };

  const getVisibility = (isVisible: boolean) => {
    if (isVisible) {
      return '';
    }

    return 'none';
  };

  if (isRedirecting) {
    return <LoadingPage data-testid="professional-roles-loading-page" />;
  }

  const renderConfirmation = () =>
    question === QUESTION.CONFIRMATION && professionalRole ? (
      <ProfessionalRoleConfirmation
        role={professionalRole.role}
        experience={professionalRole.experience}
        subjects={professionalRole.subjects}
        onConfirmClick={handleConfirmClick}
        onGoBackClick={() => setQuestion(QUESTION.FORM)}
      />
    ) : (
      <></>
    );

  return (
    <Stack
      data-testid="professional-roles"
      alignItems="center"
      justifyContent="center"
      py={4}
    >
      <ProfessionalRoleWrapper>
        <Box display={getVisibility(question === QUESTION.FORM)}>
          <ProfessionalRoleForm
            professionalRole={professionalRole}
            onChange={handleProfessionalRolesChange}
          />
        </Box>

        {renderConfirmation()}
      </ProfessionalRoleWrapper>
    </Stack>
  );
}
