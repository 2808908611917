import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { coachingAtom } from '@atoms/coach';
import MatchesToReview from './components/MatchesToReview/MatchesToReview';
import ParticipantsTable from './components/ParticipantsTable/ParticipantsTable';
import { ZoomScheduler } from './components/ZoomScheduler/ZoomScheduler';

export const TEST_ID = {
  CONTAINER: 'coaching-home',
};

export default function CoachingHome(): JSX.Element {
  const coaching = useAtomValue(coachingAtom);

  return (
    <Stack
      data-testid={TEST_ID.CONTAINER}
      width="100%"
      pl={{ md: 2.5 }}
      maxWidth="70rem"
    >
      {coaching.coachMatches.length > 0 && (
        <MatchesToReview coachMatches={coaching.coachMatches} />
      )}
      <ParticipantsTable coachParticipants={coaching.coachParticipants} />
      <ZoomScheduler />
    </Stack>
  );
}
