import { Stack, Button } from '@mui/material';
import DeleteDialog from './components/DeleteAccountDialog/DeleteAccountDialog';

type Props = {
  onSubmitClick: () => void;
  isSubmitDisabled?: boolean;
};

export default function ProfileActionBar({
  onSubmitClick,
  isSubmitDisabled,
}: Readonly<Props>): JSX.Element {
  return (
    <Stack
      data-testid="profile-action-bar"
      direction="row"
      justifyContent="space-between"
      pt={2}
    >
      <Button
        data-testid="profile-action-bar-submit-button"
        variant="contained"
        onClick={onSubmitClick}
        disabled={isSubmitDisabled}
      >
        Save
      </Button>
      <DeleteDialog />
    </Stack>
  );
}
