import {
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Typography,
} from '@mui/material';
import { SORT_COLUMN } from '@constants/workshop';

export type Props = {
  sortDirection: 'asc' | 'desc';
  sortColumn: string;
  isMobile: boolean;
  onSortChange: (sortColumn: string) => void;
};

export const TableHeaders = ({
  sortDirection,
  sortColumn,
  isMobile,
  onSortChange,
}: Props): JSX.Element => {
  const handleSessionClick = () => {
    onSortChange(SORT_COLUMN.SESSION);
  };

  const handleWorkshopClick = () => {
    onSortChange(SORT_COLUMN.WORKSHOP);
  };

  const handleDateClick = () => {
    onSortChange(SORT_COLUMN.DATE);
  };

  return (
    <TableHead data-testid="upcoming-workshops-table-headers">
      <TableRow>
        <TableCell width="5%" />

        <TableCell width={isMobile ? '5%' : '20%'} sx={{ pl: 0 }}>
          <TableSortLabel
            active={sortColumn === SORT_COLUMN.SESSION}
            direction={sortDirection}
            onClick={handleSessionClick}
          >
            <Typography fontStyle="normal" variant="body4">
              Session
            </Typography>
          </TableSortLabel>
        </TableCell>

        {!isMobile && (
          <TableCell width="20%">
            <TableSortLabel
              active={sortColumn === SORT_COLUMN.WORKSHOP}
              direction={sortDirection}
              onClick={handleWorkshopClick}
            >
              <Typography fontStyle="normal" variant="body4">
                Workshop
              </Typography>
            </TableSortLabel>
          </TableCell>
        )}

        <TableCell>
          <TableSortLabel
            active={sortColumn == SORT_COLUMN.DATE}
            direction={sortDirection}
            onClick={handleDateClick}
          >
            <Typography fontStyle="normal" variant="body4">
              Date
            </Typography>
          </TableSortLabel>
        </TableCell>

        {!isMobile && (
          <TableCell>
            <Typography fontStyle="normal" variant="body4">
              Registered
            </Typography>
          </TableCell>
        )}

        <TableCell>&nbsp;</TableCell>
        {!isMobile && <TableCell>&nbsp;</TableCell>}
      </TableRow>
    </TableHead>
  );
};
