import { Link, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { CSSProperties, useContext, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as ReactList } from 'react-window';
import CheckboxOld from '@components/CheckboxOld/CheckboxOld';
import ProfileCard from '@components/ProfileCard/ProfileCard';
import UserCard from '@images/icons/user-card.svg';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import { UserRow } from './Types';
import styles from './UserTable.module.css';
import AccountCell from './components/AccountCell/AccountCell';
import RemoveOrganizationModal from './components/RemoveOrganizationModal/RemoveOrganizationModal';

type Props = {
  isHeaderChecked: boolean;
  onCheckboxClick: (userId: string | null | void) => void;
  onHeaderCheckboxClick: () => void;
};

type autoResize = {
  height: number;
  width: number;
};

export default function UserTable({
  isHeaderChecked,
  onCheckboxClick,
  onHeaderCheckboxClick,
}: Props): JSX.Element {
  const [currentUser, setCurrentUser] = useState<UserRow>();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (user: UserRow) => {
    setCurrentUser(user);
    setModalOpen(true);
  };

  const { checkedUserIds, users }: ProductsContextType =
    useContext(ProductsContext);

  const formatDay = (dateInMilliseconds: number) => {
    return DateTime.fromMillis(dateInMilliseconds).toFormat('MM/dd/yyyy');
  };

  const formatTime = (dateInMilliseconds: number) => {
    const date = DateTime.fromMillis(dateInMilliseconds);
    return `${date.toFormat('h:mma').toLowerCase()} ${date.toFormat('ZZZZ')}`;
  };

  const formatDate = (date: number) => {
    return `${formatDay(date)} - ${formatTime(date)}`;
  };

  const renderHeaderButton = () => {
    return (
      <div className={styles.checkbox}>
        <CheckboxOld
          checked={isHeaderChecked}
          onClick={onHeaderCheckboxClick}
        />
      </div>
    );
  };

  const renderNameHeaderCell = () => (
    <div className={styles.headerCell}>
      {renderHeaderButton()}
      <div className={styles.sortableHeaderText}>Name&nbsp;</div>
    </div>
  );

  const renderHeaderCell = (primaryText: string, secondaryText?: string) => (
    <div className={styles.headerCell}>
      {primaryText}
      {secondaryText && (
        <span className={styles.italic}>&nbsp;{secondaryText}</span>
      )}
    </div>
  );

  const renderTableHeader = () => (
    <div className={styles.headerRowContainer}>
      {renderNameHeaderCell()}
      {renderHeaderCell('Account', '(State)')}
      {renderHeaderCell('Date Created')}
      {renderHeaderCell('Last Login', '(Last Active)')}
      <div />
    </div>
  );

  const renderAccountCell = (user: UserRow) => (
    <AccountCell user={user} handleClick={handleClick} />
  );

  const renderDateCreatedCell = (date: number) => (
    <div className={`${styles.rowCell} ${styles.dateCreatedCell}`}>
      <span
        className={styles.noWrapText}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      >
        {formatDay(date)} -{' '}
      </span>
      <span
        className={styles.noWrapText}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      >
        &nbsp;{formatTime(date)}
      </span>
    </div>
  );

  const renderLastLoginCell = (lastActive?: number, lastLogin?: number) => (
    <div className={`${styles.rowCell} ${styles.noWrapText}`}>
      <div>
        {lastLogin && (
          <div
            tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          >
            {formatDate(lastLogin)}
          </div>
        )}
        {lastActive && (
          <div
            className={`${styles.italic} ${styles.noWrapText}`}
            tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          >
            {formatDate(lastActive)}
          </div>
        )}
      </div>
    </div>
  );

  const renderParticipantDetailCard = (userSub: string) => (
    <div className={styles.rowCell}>
      <Tooltip arrow title="Participant Details" tabIndex={0}>
        <Link
          href={`${process.env.REACT_APP_COACHING_URL}/participant/${userSub}/details`}
          target="_blank"
        >
          <img className={styles.userCardIcon} src={UserCard} alt="user-card" />
        </Link>
      </Tooltip>
    </div>
  );

  const renderCheckboxCell = (userId: string) => (
    <div className={styles.checkbox}>
      <CheckboxOld
        dataId={userId}
        checked={checkedUserIds.has(userId)}
        onClick={onCheckboxClick}
      />
    </div>
  );

  const renderProfileCardCell = ({
    email,
    familyName,
    givenName,
    userId,
  }: UserRow) => (
    <div className={styles.rowCell}>
      {renderCheckboxCell(userId)}
      <ProfileCard
        email={email}
        familyName={familyName}
        givenName={givenName}
        userId={userId}
      />
    </div>
  );

  const renderTableRow = ({
    style,
    index,
  }: {
    style: CSSProperties;
    index: number;
  }) => {
    const user: UserRow = users[index];

    return (
      <div
        className={`${styles.tableRowContainer} ${
          index % 2 !== 0 && styles.oddRowContainer
        }`}
        data-testid="user-table-row"
        key={user.userId}
        style={style}
      >
        {renderProfileCardCell(user)}
        {renderAccountCell(user)}
        {renderDateCreatedCell(user.dateCreated)}
        {renderLastLoginCell(user.lastActive, user.lastLogin)}
        {renderParticipantDetailCard(user.userId)}
      </div>
    );
  };

  const renderModal = () => {
    return (
      <RemoveOrganizationModal
        currentUser={currentUser ? currentUser : undefined}
        modalOpen={modalOpen}
        toggleModal={setModalOpen}
      />
    );
  };

  return (
    <div className={styles.tableContainer} data-testid="user-table">
      <div className={styles.headerContainer}>
        {renderTableHeader()}
        <div className={styles.divider} />
      </div>
      <div className={styles.tableContent}>
        <AutoSizer>
          {({ height, width }: autoResize) => (
            <ReactList
              height={height}
              itemCount={users.length}
              itemSize={100}
              width={width}
            >
              {renderTableRow}
            </ReactList>
          )}
        </AutoSizer>
      </div>
      {renderModal()}
    </div>
  );
}
