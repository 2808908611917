import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import { setBypassConfirmPage } from '@pages/ProfessionalRoles/ProfessionalRoles';
import { initializeLabRedirectUrl } from '@utils/LabRedirectPath';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';

type Props = {
  accountName: string;
  serviceUuid: string | null;
};

export default function AccountWelcomeSection({
  accountName,
  serviceUuid,
}: Readonly<Props>): JSX.Element {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const accountUuid = searchParams.get('accountUuid') ?? '';
  const sub = searchParams.get('sub') ?? '';
  const pwToken = searchParams.get('pw_token') ?? '';
  const serviceType = (searchParams.get('serviceType') ?? '').toUpperCase();
  const redirectToServiceHandlerPath = `/service-handler?serviceUuid=${serviceUuid}&accountUuid=${accountUuid}&serviceType=${serviceType}`;

  const initializeLabRedirect = () => {
    if (serviceUuid) {
      setBypassConfirmPage(true);
    }

    initializeLabRedirectUrl(
      `${window.location.origin}${redirectToServiceHandlerPath}`
    );
  };

  useEffect(() => {
    initializeLabRedirect();
  }, []);

  const getContinueUrl = () => {
    if (isAuthenticated) {
      return redirectToServiceHandlerPath;
    }

    return sub !== '' && pwToken !== ''
      ? `/register?sub=${sub}&pw_token=${pwToken}`
      : '/register';
  };

  const getLoginUrl = () => {
    if (isAuthenticated) {
      return redirectToServiceHandlerPath;
    }

    return '/login';
  };

  return (
    <Stack
      data-testid="account-welcome-section"
      direction="column"
      alignItems="center"
      textAlign="center"
      flexBasis={0}
      flexGrow={1}
      mt="auto"
      mb="auto"
    >
      <a href={`${process.env.REACT_APP_LAB_URL}/home`}>
        <img
          src={BetterLessonLogo}
          width="100%"
          height="100%"
          data-testid="betterlesson-logo"
          alt="BetterLesson"
        />
      </a>

      <h2>Welcome {accountName}</h2>

      <p>
        To get started with BetterLesson, register using the email from your
        organization.
      </p>
      <Stack pt={1} direction="column" gap="1em">
        <Button
          data-testid="account-welcome-section-continue-button"
          variant="contained"
          color="secondary"
          fullWidth={isMobile}
          href={getContinueUrl()}
          sx={CONTAINED_GRADIENT_STYLE}
        >
          Set up your account to register
        </Button>

        <Button
          data-testid="account-welcome-section-link-text"
          variant="outlined"
          fullWidth={isMobile}
          href={getLoginUrl()}
        >
          Log in with your BetterLesson account
        </Button>
      </Stack>
    </Stack>
  );
}
