import { LoadingPage } from 'betterlesson-library-react';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserEngagements } from '@apis/engagements';
import { getMeetings } from '@apis/meetings';
import { getUser } from '@apis/users';
import { getUserWorkshops, getWorkshopDetails } from '@apis/workshop';
import { EngagementDataType } from '@app-types/Engagement';
import { engagementAtom } from '@atoms/engagement';
import { userWorkshopResponseAtom } from '@atoms/facilitator';
import { meetingAtom } from '@atoms/meeting';
import { contextUserDataAtom } from '@atoms/user';
import { workshopsDataAtom } from '@atoms/workshop';
import ParticipantHome from '@pages/ParticipantHome/ParticipantHome';
import {
  ImpersonateFactory,
  useImpersonatedUserErrorHandler,
} from '@utils/ImpersonateFactory';

const TEST_ID = {
  LOADING: 'participant-home-data-provider-loading',
};

export default function ParticipantDataProvider(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const handleImpersonatedUserError = useImpersonatedUserErrorHandler();
  const { participantUuid = '' } = useParams();
  const setUserWorkshops = useSetAtom(userWorkshopResponseAtom);
  const setEngagement = useSetAtom(engagementAtom);
  const setMeetings = useSetAtom(meetingAtom);
  const setContextUserData = useSetAtom(contextUserDataAtom);
  const setWorkshopsData = useSetAtom(workshopsDataAtom);

  const fetchWorkshops = async () => {
    const userWorkshops = await getUserWorkshops(participantUuid);
    setUserWorkshops(userWorkshops);
    const workshopUuids = userWorkshops.map((uw) => uw.uuid);
    const workshops = await getWorkshopDetails(workshopUuids);
    setWorkshopsData(workshops);
  };

  const getMeeting = async (engagement?: EngagementDataType) => {
    const meetings = await getMeetings(engagement?.uuid);
    setMeetings(meetings);
  };

  const fetchEngagements = async () => {
    const [engagement] = await getUserEngagements(participantUuid);
    setEngagement(engagement);
    getMeeting(engagement);
  };

  const init = async () => {
    const impersonatedUser = await ImpersonateFactory.impersonateUser(
      participantUuid
    );

    if (impersonatedUser.isInvalid()) {
      handleImpersonatedUserError(impersonatedUser.invalidReason);
      return;
    }

    const [user] = await Promise.all([
      getUser(impersonatedUser.accessToken),
      fetchWorkshops(),
      fetchEngagements(),
    ]);

    setContextUserData(user || null);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return isLoading ? (
    <LoadingPage data-testid={TEST_ID.LOADING} />
  ) : (
    <ParticipantHome />
  );
}
