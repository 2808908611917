import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { ROLE } from '@constants/professional-roles';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import AnswerCard from '../AnswerCard/AnswerCard';

type Props = {
  role?: string;
  onRoleCardClick: (newRole: string) => void;
};

export default function ProfessionalRoleQuestion({
  role,
  onRoleCardClick,
}: Readonly<Props>): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const handleRoleCardClick = (newRole: string) => () => {
    onRoleCardClick(newRole);
  };

  const styles = isMobile
    ? {
        image: { width: '100px', minHeight: '100px' },
        container: { px: 2.5 },
        cardTitle: { pb: 0.75, fontSize: '16px' },
      }
    : {
        image: { width: '150px' },
        container: { px: 6.25 },
        cardTitle: { pb: 0.75 },
      };

  const getOtherDistrictAdminStyle = () => {
    if (!isMobile) {
      return { imageStyle: styles.image };
    }

    return {
      imageStyle: {
        ...styles.image,
        width: '160px',
        transform: 'translateX(-60px)',
      },
      cardContentStyle: {
        position: 'relative',
        left: '-60px',
        mr: '-60px',
      },
    };
  };

  return (
    <Stack alignItems="center" {...styles.container} py={2.5} spacing={2.5}>
      <img
        width={245}
        src={BetterLessonLogo}
        data-testid="betterlesson-logo"
        alt="BetterLesson"
      />

      <Stack
        pt={1.5}
        pb={0.5}
        alignItems="center"
        data-testid="professional-role-question"
      >
        <Typography variant="h5" component="p">
          Hi {user?.profile.given_name || 'there'}, tell us about yourself.
        </Typography>
        <Typography variant="body1">
          How do you spend the majority of your time?
        </Typography>
      </Stack>

      <AnswerCard
        title="Teacher"
        description="I directly support students in the classroom"
        imageSrc="/professional-roles/teacher.svg"
        imageAlt="Math teacher writing on the board"
        imageStyle={styles.image}
        titleProps={styles.cardTitle}
        selected={role === ROLE.TEACHER}
        onClick={handleRoleCardClick(ROLE.TEACHER)}
      />

      <AnswerCard
        title="Paraprofessional/Asst. Teacher"
        description="I work with another teacher to support students"
        imageSrc="/professional-roles/paraprofessional.svg"
        imageAlt="Teacher pointing to colors on a board"
        imageStyle={styles.image}
        titleProps={styles.cardTitle}
        selected={role === ROLE.PARAPROFESSIONAL}
        onClick={handleRoleCardClick(ROLE.PARAPROFESSIONAL)}
      />

      <AnswerCard
        title="Instructional Coach"
        description="I support classroom teachers or educators"
        imageSrc="/professional-roles/instructional-coach.svg"
        imageAlt="Group of education professionals collaborating"
        imageStyle={styles.image}
        titleProps={styles.cardTitle}
        selected={role === ROLE.INSTRUCTIONAL_COACH}
        onClick={handleRoleCardClick(ROLE.INSTRUCTIONAL_COACH)}
      />

      <AnswerCard
        title="Principal/Asst. Principal"
        description="I am a school administrator supporting one school building"
        imageSrc="/professional-roles/principal.svg"
        imageAlt="Teacher pointing to shapes on a board"
        imageStyle={styles.image}
        titleProps={styles.cardTitle}
        selected={role === ROLE.PRINCIPAL}
        onClick={handleRoleCardClick(ROLE.PRINCIPAL)}
      />

      <AnswerCard
        title="Superintendent/Asst. Sup"
        description="I am a district administrator supporting multiple school buildings"
        imageSrc="/professional-roles/superintendent.svg"
        imageAlt="Teacher lecturing"
        imageStyle={styles.image}
        titleProps={styles.cardTitle}
        selected={role === ROLE.SUPERINTENDENT}
        onClick={handleRoleCardClick(ROLE.SUPERINTENDENT)}
      />

      <AnswerCard
        title="Other District Admin"
        description="I support curriculum & instruction in multiple schools"
        imageSrc="/professional-roles/other-district-admin.svg"
        imageAlt="Teacher at the board"
        {...getOtherDistrictAdminStyle()}
        titleProps={styles.cardTitle}
        selected={role === ROLE.OTHER_DISTRICT_ADMIN}
        onClick={handleRoleCardClick(ROLE.OTHER_DISTRICT_ADMIN)}
      />

      <AnswerCard
        title="Another type of educator"
        description="I support education using other methods"
        imageSrc="/professional-roles/other-educator.svg"
        imageAlt="Teacher teaching geometry"
        imageStyle={styles.image}
        titleProps={styles.cardTitle}
        selected={role === ROLE.OTHER}
        onClick={handleRoleCardClick(ROLE.OTHER)}
      />
    </Stack>
  );
}
