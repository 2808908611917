import { Stack, useMediaQuery, useTheme } from '@mui/material';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function ProfessionalRoleWrapper({
  children,
}: Readonly<Props>): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <Stack
      data-testid="professional-role-wrapper"
      border="1px solid var(--color-slate-2)"
      borderRadius="4px"
      alignItems="center"
      sx={{ width: { xs: '700px' } }}
    >
      {children}
    </Stack>
  );
}
