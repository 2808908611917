import structuredClone from '@ungap/structured-clone';
import { SessionGroupType } from '@app-types/Workshop';

export const dateSort = (
  sessionGroups: SessionGroupType[],
  dateOrderByAsc: boolean
): SessionGroupType[] => {
  const sorted = structuredClone(sessionGroups).sort((a, b) => {
    const dateA = new Date(a.firstSessionDate).getTime();
    const dateB = new Date(b.firstSessionDate).getTime();
    return dateOrderByAsc ? dateA - dateB : dateB - dateA;
  });

  return sorted;
};

export const sessionSort = (
  sessionGroups: SessionGroupType[],
  dateOrderByAsc: boolean
): SessionGroupType[] => {
  const sorted = structuredClone(sessionGroups).sort((a, b) =>
    a.sessionName.localeCompare(b.sessionName)
  );

  if (dateOrderByAsc) return sorted.reverse();
  return sorted;
};

export const workshopSort = (
  sessionGroups: SessionGroupType[],
  workshopOrderByAsc: boolean
): SessionGroupType[] => {
  const sorted = structuredClone(sessionGroups).sort((a, b) => {
    const displayNameA = a.workshopName.toLowerCase();
    const displayNameB = b.workshopName.toLowerCase();

    return workshopOrderByAsc
      ? displayNameA.toLowerCase().localeCompare(displayNameB.toLowerCase())
      : displayNameB.toLowerCase().localeCompare(displayNameA.toLowerCase());
  });

  return sorted;
};

export const copyUrl = async (url?: string): Promise<boolean> => {
  if (!url) return false;

  try {
    if (isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(url);
      return true;
    } else throw new Error('failed to copy!');
  } catch (err) {
    return false;
  }
};
