import { FormControl, TextField, Typography } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useState } from 'react';
import * as yup from 'yup';
import { ForgotPasswordResult } from '@apis/users';
import EntryFormContainer from '@components/EntryFormContainer/EntryFormContainer';
import LinkText from '@components/LinkText/LinkText';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { buildEntryFormUrl } from '@utils/Redirect';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export default function ForgotPassword({
  onForgotPassword: onForgotPassword,
}: {
  onForgotPassword: (email: string) => Promise<ForgotPasswordResult>;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const onSubmit = async () => {
    setIsLoading(true);
    const results = await onForgotPassword(emailAddress);

    results.success
      ? toast.success(`Email sent to ${emailAddress}`, {
          className: 'toast-notify-success',
        })
      : toast.error(results.errorMessage ?? 'Email not sent! Try again.', {
          className: 'toast-notify-error',
        });

    setIsLoading(false);
  };

  const updateEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    validationSchema
      .isValid({
        email: e.target.value,
      })
      .then((valid) => {
        valid ? setEmailAddress(e.target.value) : setEmailAddress('');
      });
  };

  const renderForgotPasswordHeader = () => (
    <>
      <Typography marginTop={10} variant="h3" fontSize="24px" fontWeight="bold">
        Reset Your Password
      </Typography>

      <Typography
        align="center"
        marginTop={2}
        fontWeight={500}
        fontSize="14px"
        fontStyle="normal"
        color="var(--color-grey-3)"
      >
        Forgot your password? Don&apos;t worry! Enter your account email, and
        we&apos;ll send you a link to reset your password
      </Typography>
    </>
  );

  const renderForgotPasswordForm = () => (
    <>
      <FormControl fullWidth>
        <TextField
          inputProps={{ 'data-testid': 'reset-password-textfield' }}
          placeholder="Email"
          required
          style={{ marginBottom: '2rem', marginTop: '3rem' }}
          type="email"
          variant="outlined"
          onChange={updateEmailAddress}
        />

        <LoadingButton
          color="secondary"
          data-testid="reset-password-button"
          disabled={!emailAddress}
          variant="contained"
          text="Reset Password"
          isLoading={isLoading}
          onClick={onSubmit}
          sx={CONTAINED_GRADIENT_STYLE}
        />
      </FormControl>

      <Typography marginTop={7}>Don&apos;t remember your email?</Typography>

      <Typography
        variant="body2"
        component="a"
        color="var(--color-primary)"
        fontSize={14}
        href="https://betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4"
        rel="noreferrer"
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        Contact Us.
      </Typography>

      <Typography marginTop={3}>
        Don&apos;t need to reset your password?
      </Typography>

      <Typography>
        <LinkText
          dataTestId="login"
          to={buildEntryFormUrl('/login')}
          text="Log in"
          preserveQueryParams={true}
        />
        &nbsp;or&nbsp;
        <LinkText
          dataTestId="create-acct"
          to={buildEntryFormUrl('/login')}
          text="Create an account"
          preserveQueryParams={true}
        />
        &nbsp;instead.
      </Typography>
    </>
  );

  return (
    <EntryFormContainer dataTestId="reset-password-form">
      {renderForgotPasswordHeader()}
      {renderForgotPasswordForm()}
    </EntryFormContainer>
  );
}
