export class APIError {
  constructor(
    public requestId: string,
    public correlationId: string,
    public message: string,
    public apiErrorCode: string
  ) {}
}

// eslint-disable-next-line
export const tryGetApiError = (error: any): APIError | undefined => {
  if (error.response?.data?.apiErrorCode) {
    const e = new APIError(
      `${error.response?.data.requestId}`,
      `${error.response?.data.correlationId}`,
      `${error.response?.data.message}`,
      `${error.response?.data.apiErrorCode}`
    );
    return e;
  }
  return undefined;
};
