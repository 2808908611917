import { onEnterKey } from '@utils/key-handler';
import styles from './Button.module.css';

export const BUTTON_VARIANT = {
  DEFAULT: 'defaultButton',
  OUTLINE: 'outlineButton',
  SECONDARY: 'secondaryButton',
  OUTLINE_SECONDARY: 'outlineSecondaryButton',
};

type Props = {
  className?: string;
  color?: string;
  contentMinWidth?: string;
  disabled?: boolean;
  dataTestId: string;
  leadingIcon?: string;
  text: string;
  variant?: string;
  onClick: () => void;
};

export default function Button({
  className,
  contentMinWidth = 'unset',
  disabled,
  dataTestId,
  leadingIcon,
  text,
  variant = BUTTON_VARIANT.DEFAULT,
  onClick,
}: Props): JSX.Element {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      data-testid={dataTestId}
      className={`${styles.container} ${className || styles[variant]} ${
        disabled && styles.disabled
      }`}
      onClick={handleClick}
      onKeyDown={onEnterKey(handleClick)}
      tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
    >
      <div
        className={styles.content}
        data-testid={`${dataTestId}-content`}
        style={{ minWidth: contentMinWidth }}
      >
        {leadingIcon && (
          <img
            src={leadingIcon}
            className={styles.leadingIcon}
            data-testid={`${dataTestId}-icon`}
            alt="button-con"
          />
        )}
        <span className={styles.text}>{text}</span>
      </div>
    </div>
  );
}
