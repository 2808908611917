import { CreateSigninRequestArgs } from 'oidc-client-ts';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import SetPassword from '@pages/SetPassword/SetPassword';
import { initializeLabRedirectPathFromSearchParams } from '@utils/LabRedirectPath';
import Register from './Register';

export default function UserServiceRegister(): JSX.Element {
  const { isAuthenticated, signinRedirect } = useAuth();
  const [searchParams] = useSearchParams();
  const sub = searchParams.get('sub') ?? '';
  const code = searchParams.get('pw_token') ?? '';

  if (sub !== '' && code !== '' && !isAuthenticated) {
    return <SetPassword sub={sub} code={code}></SetPassword>;
  }

  const shouldAuthRedirect = (): boolean => {
    return !searchParams.has('sessionId') || isAuthenticated;
  };
  const executeAuthRedirect = () => {
    initializeLabRedirectPathFromSearchParams();

    if (searchParams.has('orgUuid')) {
      signinRedirect({
        redirect_uri: window.location.origin,
        prompt: 'create',
        extraQueryParams: {
          orgUuid: searchParams.get('orgUuid'),
        },
      } as CreateSigninRequestArgs);
    } else {
      signinRedirect({
        redirect_uri: window.location.origin,
        prompt: 'create',
      } as CreateSigninRequestArgs);
    }
  };

  const effect = async () => {
    if (shouldAuthRedirect()) {
      executeAuthRedirect();
      return;
    }
  };

  useEffect(() => {
    effect();
  }, []);

  return <Register />;
}
