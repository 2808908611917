import {
  Button,
  Stack,
  Autocomplete,
  TextField,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  FormHelperText,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useState, SyntheticEvent } from 'react';
import { AvailabilityType } from '@app-types/CoachingRegistrationStages';
import { ALL_TIME_SLOTS } from '@constants/availability';

interface AvailabilityProps {
  onContinue: () => void;
  onGoBack: () => void;
  availability: AvailabilityType;
  onChangeAvailability: (availability: AvailabilityType) => void;
}

const Availability: React.FC<AvailabilityProps> = ({
  onContinue,
  onGoBack,
  availability,
  onChangeAvailability,
}) => {
  const guessLocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const allTimeZones = moment.tz.names();
  const AmericaTimeZones = allTimeZones.filter((timezone) =>
    timezone.includes('America')
  );

  const USTimeZones = allTimeZones.filter((timezone) =>
    timezone.includes('US')
  );

  const allNonUSOrAmericaTimeZones = allTimeZones.filter(
    (timezone) => !timezone.includes('US') && !timezone.includes('America')
  );

  const AllTimezonesInOurOrder = USTimeZones.concat(AmericaTimeZones).concat(
    allNonUSOrAmericaTimeZones
  );

  const [timeZone, setTimeZone] = useState<string>(
    availability.zoneinfo || guessLocalTimeZone
  );

  const [preferredTimeslot, setPreferredTimeSlots] = useState<Set<string>>(
    new Set(availability.preferredTimeslot)
  );
  const [availableTimeslot, setAvailableTimeSlots] = useState<Set<string>>(
    new Set(availability.availableTimeslot)
  );
  const [weekendAvailability, setWeekendAvailability] = useState<boolean>(
    availability.weekendAvailability
  );
  const [atLeastTwoError, setAtLeastTwoError] = useState<string>('');
  const [uniqueError, setUniqueError] = useState<string>('');

  const handleSubmitAvailabilityAndContinue = () => {
    const hasAtLeastTwoError =
      preferredTimeslot.size +
        availableTimeslot.size +
        (weekendAvailability ? 1 : 0) <
      2;
    const hasUniqueError = Array.from(preferredTimeslot).some((slot) =>
      availableTimeslot.has(slot)
    );

    setAtLeastTwoError(
      hasAtLeastTwoError ? 'Please select at least two time slots' : ''
    );
    setUniqueError(
      hasUniqueError
        ? 'A selected time slot can only be preferred OR available. Please unselect any duplicate time slots.'
        : ''
    );

    if (hasAtLeastTwoError || hasUniqueError) return;

    onChangeAvailability({
      zoneinfo: timeZone,
      preferredTimeslot: Array.from(preferredTimeslot),
      availableTimeslot: Array.from(availableTimeslot),
      weekendAvailability: weekendAvailability,
    });
    onContinue();
  };

  const handleSubmitAvailabilityAndGoBack = () => {
    onChangeAvailability({
      zoneinfo: timeZone,
      preferredTimeslot: Array.from(preferredTimeslot),
      availableTimeslot: Array.from(availableTimeslot),
      weekendAvailability: weekendAvailability,
    });
    onGoBack();
  };

  const handlePreferredTimeSlotsClick = (buttonLabel: string) => {
    if (preferredTimeslot.has(buttonLabel)) {
      const updatedSet = new Set(preferredTimeslot);
      updatedSet.delete(buttonLabel);
      setPreferredTimeSlots(updatedSet);
    } else {
      setPreferredTimeSlots(new Set(preferredTimeslot).add(buttonLabel));
    }
  };

  const handleAvailableTimeSlotsClick = (buttonLabel: string) => {
    if (availableTimeslot.has(buttonLabel)) {
      const updatedSet = new Set(availableTimeslot);
      updatedSet.delete(buttonLabel);
      setAvailableTimeSlots(updatedSet);
    } else {
      setAvailableTimeSlots(new Set(availableTimeslot).add(buttonLabel));
    }
  };

  const handleTimeZoneChange = (
    event: SyntheticEvent,
    value: string | null
  ) => {
    if (value != null) setTimeZone(value);
  };

  const handleWeekendAvailability = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWeekendAvailability(event.target.checked);
  };

  const renderHeader = () => (
    <Box textAlign="center" pb={2}>
      <Typography fontWeight={500}>
        What is your availability for coaching sessions?
      </Typography>
      <Typography fontWeight={300} fontSize={14}>
        Don&apos;t worry, you can schedule specific meeting times later
      </Typography>
      <Typography fontWeight={300} fontSize={14}>
        Coaching meetings are 30 minutes in length
      </Typography>
    </Box>
  );

  const renderNavigationButtons = () => (
    <Stack width="50%" spacing={1} pt={2}>
      <Button
        data-testid="availability-continue-button"
        onClick={handleSubmitAvailabilityAndContinue}
        variant="contained"
      >
        Continue
      </Button>
      <Button
        data-testid="availability-go-back-button"
        onClick={handleSubmitAvailabilityAndGoBack}
        variant="text"
      >
        Go Back
      </Button>
    </Stack>
  );

  return (
    <Stack
      alignItems={'center'}
      height="100%"
      flex="1 0 0"
      data-testid="coaching-registration-availability"
    >
      {renderHeader()}

      <Box width="50%" pb={2}>
        <Autocomplete
          id="autocomplete"
          data-testid="availability-timezone"
          options={AllTimezonesInOurOrder}
          value={timeZone}
          onChange={handleTimeZoneChange}
          getOptionLabel={(option) => option.replace(/_/g, ' ')}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { paddingTop: 0, paddingBottom: 0 },
              }}
              label="Timezone"
              variant="outlined"
              data-testid={`availability-timezone-input-${timeZone}`}
            />
          )}
        />
      </Box>

      <Grid direction="row" container pb={1} width="70%">
        <Grid item xs={6}>
          <Typography fontWeight={300} textAlign="center">
            Preferred
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontWeight={300} textAlign="center">
            Available
          </Typography>
        </Grid>
      </Grid>

      <Grid
        direction="row"
        container
        border="1px solid var(--color-slate-2)"
        borderRadius="4px"
        p={1}
        rowGap={1}
        width="70%"
        overflow="auto"
        flex="1 0 0"
        minHeight="250px"
      >
        {ALL_TIME_SLOTS.map((timeSlot) => (
          <React.Fragment key={timeSlot}>
            <Grid item xs={6} textAlign="center">
              <Button
                variant={
                  preferredTimeslot.has(timeSlot) ? 'contained' : 'outlined'
                }
                data-testid={`availability-preferred-${timeSlot}`}
                onClick={() => handlePreferredTimeSlotsClick(timeSlot)}
                sx={{ width: '100px' }}
              >
                {timeSlot}
              </Button>
            </Grid>

            <Grid item xs={6} textAlign="center">
              <Button
                variant={
                  availableTimeslot.has(timeSlot) ? 'contained' : 'outlined'
                }
                data-testid={`availability-available-${timeSlot}`}
                onClick={() => handleAvailableTimeSlotsClick(timeSlot)}
                sx={{ width: '100px' }}
              >
                {timeSlot}
              </Button>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <FormHelperText color="error">{atLeastTwoError}</FormHelperText>
      <FormHelperText color="error">{uniqueError}</FormHelperText>
      <Typography textAlign="center">
        Select at least two unique time slots
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            data-testid="availability-weekend-availability"
            inputProps={{ 'aria-label': 'Weekend Availability' }}
            checked={weekendAvailability}
            onChange={handleWeekendAvailability}
          />
        }
        label="I am available for coaching on weekends"
      />

      {renderNavigationButtons()}
    </Stack>
  );
};

export default Availability;
