import { MultipleFieldErrors } from 'react-hook-form';
import { ReactComponent as SuccessIcon } from '@images/icons/success.svg';
import styles from './PasswordRequirements.module.css';
import variables from '@variables.module.css';

type Props = {
  isSubmitted: boolean;
  password: string;
  requirements?: MultipleFieldErrors;
};

export default function PasswordRequirements({
  isSubmitted,
  password,
  requirements = {} as MultipleFieldErrors,
}: Props): JSX.Element {
  const getRequirementTextColor = (meetsRequirement: boolean) => {
    if (isSubmitted && !meetsRequirement) {
      return variables.error;
    }

    if (password && meetsRequirement) {
      return variables['spring-green'];
    }

    return variables.blueberry;
  };

  const renderSuccessIcon = (meetsRequirement: boolean) =>
    password && meetsRequirement ? (
      <SuccessIcon data-testid="success-icon" style={{ width: '20px' }} />
    ) : (
      <div style={{ width: '20px' }} />
    );

  const renderRequirement = (
    meetsRequirement: boolean,
    requirementText: string
  ) => (
    <div data-testid="password-requirement" className={styles.textContainer}>
      {renderSuccessIcon(meetsRequirement)}
      <span
        className={styles.text}
        style={{
          color: getRequirementTextColor(meetsRequirement),
        }}
      >
        {requirementText}
      </span>
    </div>
  );

  const hasNoMatchingError = (error: string) => {
    const { matches } = requirements;

    if (!matches) {
      return true;
    }

    if (Array.isArray(requirements.matches)) {
      return !(matches as Array<string>).includes(error);
    }

    return matches !== error;
  };

  return (
    <div className={styles.container}>
      {renderRequirement(
        !requirements.min,
        'Create a password at least 8 characters in length'
      )}
      {renderRequirement(
        hasNoMatchingError('letter'),
        'Must contain at least one letter'
      )}
      {renderRequirement(
        hasNoMatchingError('digit'),
        'Must contain at least one number'
      )}
      {renderRequirement(
        hasNoMatchingError('special'),
        'Must contain at least one special character: @$!%*#?&'
      )}
    </div>
  );
}
