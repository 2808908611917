import { Button, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { UseFormRegisterReturn } from 'react-hook-form';
import EntryFormContainer from '@components/EntryFormContainer/EntryFormContainer';
import FormInput from '@components/FormInput/FormInput';
import LinkText from '@components/LinkText/LinkText';
// import ClasslinkLogo from '@images/logos/classlink.png';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import CleverLogo from '@images/logos/clever.png';
// import GoogleLogo from '@images/logos/google.png';
import { buildEntryFormUrl } from '@utils/Redirect';
import styles from './LoginView.module.scss';

type Props = {
  isLoggingIn: boolean;
  emailRegister: UseFormRegisterReturn;
  emailError?: string;
  usernameRegister: UseFormRegisterReturn;
  usernameError?: string;
  passwordRegister: UseFormRegisterReturn;
  passwordError?: string;
  emailDomains: string[];
  selectedEmailDomain?: string;
  onSubmit: () => void;
  onGoogleClick: () => void;
  onCleverClick: () => void;
  onClasslinkClick: () => void;
  handleUpdateEmailDomain?: (domain: string) => void;
};

const networkTestUrl = `${process.env.REACT_APP_LAB_URL}/networkcheck`;

export default function LoginView({
  isLoggingIn,
  emailRegister,
  emailError,
  usernameRegister,
  usernameError,
  passwordRegister,
  passwordError,
  emailDomains,
  selectedEmailDomain,
  onSubmit,
  // onGoogleClick,
  // onClasslinkClick,
  onCleverClick,
  handleUpdateEmailDomain,
}: Props): JSX.Element {
  const updateEmailDomain = (e: SelectChangeEvent) => {
    if (handleUpdateEmailDomain) handleUpdateEmailDomain(e.target.value);
  };

  const rendeLoginHeader = () => (
    <>
      <div className={styles.headerText}>Sign In</div>
      <div className={styles.subHeaderText}>Continue with</div>
    </>
  );

  const renderIdentityProviderButtons = () => (
    <div className={styles.identityProviderContainer}>
      {/* TODO: Show the google button when it is implemented*/}
      {/* <Button
        className={styles.googleButton}
        contentMinWidth="100px"
        dataTestId="google-button"
        leadingIcon={GoogleLogo}
        text="Google"
        onClick={onGoogleClick}
      /> */}
      <Button
        className={styles.cleverButton}
        data-testid="clever-button"
        variant="outlined"
        startIcon={
          <img className={styles.cleverIcon} src={CleverLogo} alt="clever" />
        }
        style={{ color: '#4274f6', borderColor: '#4274f6' }}
        onClick={onCleverClick}
      >
        Clever
      </Button>
      {/* TODO: Show the classlink button when it is implemented*/}
      {/* <Button
        className={styles.classlinkButton}
        contentMinWidth="100px"
        dataTestId="classlink-button"
        leadingIcon={ClasslinkLogo}
        text="Classlink"
        onClick={onClasslinkClick}
      /> */}
    </div>
  );

  const renderDivider = () => (
    <div className={styles.dividerContainer}>
      <hr className={styles.divider} />
      <div className={styles.subHeaderText}>or</div>
      <hr className={styles.divider} />
    </div>
  );

  const renderLoginForm = () => (
    <form
      className={styles.form}
      name="login-form"
      onSubmit={onSubmit}
      data-testid="login-form-submit"
    >
      <FormInput
        register={emailDomains.length ? usernameRegister : emailRegister}
        placeholder={emailDomains.length ? 'Username' : 'Email'}
        error={emailDomains.length ? usernameError : emailError}
        type="text"
      >
        {emailDomains.length ? (
          <Select
            data-testid="email-domain"
            value={selectedEmailDomain || ''}
            onChange={updateEmailDomain}
          >
            {emailDomains.map((domain) => (
              <MenuItem key={domain} value={domain}>
                {domain}
              </MenuItem>
            ))}
          </Select>
        ) : undefined}
      </FormInput>
      <FormInput
        register={passwordRegister}
        autoComplete="current-password"
        placeholder="Password"
        error={passwordError}
        type="password"
      />
      <div className={styles.loginButton}>
        <LoadingButton
          color="secondary"
          data-testid="login-button"
          type="submit"
          variant="contained"
          fullWidth
          text="Sign in with BetterLesson"
          loadingText="Signing in with BetterLesson"
          isLoading={isLoggingIn}
          sx={{
            background:
              'linear-gradient(135deg, var(--color-ocean) 0%, var(--color-vivid-blue) 100%)',

            '&:disabled': {
              background: 'unset',
              color: 'rgba(0, 0, 0, 0.26)',
              boxShadow: 'none',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          }}
        />
      </div>
      <div style={{ paddingBottom: '20px' }}>
        <LinkText
          dataTestId="forgot-btn"
          text="Forgot your email or password?"
          to={buildEntryFormUrl('/forgot-password')}
          preserveQueryParams={true}
        />
      </div>
      <div className={styles.text}>
        Need an account?
        <LinkText
          dataTestId="signup"
          text=" Sign Up Now!"
          to={buildEntryFormUrl('/register')}
          preserveQueryParams={true}
        />
      </div>
      <div className={styles.networkTestLink}>
        <a href={networkTestUrl} target="_blank" rel="noreferrer">
          {' '}
          Test your network connection to Betterlesson{' '}
        </a>
      </div>
    </form>
  );

  return (
    <EntryFormContainer dataTestId="login-form">
      {rendeLoginHeader()}
      {renderIdentityProviderButtons()}
      {renderDivider()}
      {renderLoginForm()}
    </EntryFormContainer>
  );
}
