import { AccessToken } from '@app-types/Token';
import { hasStoredRedirect, redirectToLab } from './LabRedirectPath';

const redirectToReportsApp = (): void => {
  window.location.assign(`${process.env.REACT_APP_REPORTS_URL}`);
};

const redirectToCoachHome = (coachUuid: string): void => {
  window.location.assign(`/coach/${coachUuid}/home`);
};

const redirectToParticipantHome = (participantUuid: string): void => {
  window.location.assign(`/participant/${participantUuid}/home`);
};

export const redirectToHome = (token?: AccessToken): void => {
  if (hasStoredRedirect()) {
    redirectToLab();
    return;
  }

  const isPL = token?.product_roles?.LAB?.includes('partner_leader') || false;

  const isParticipant =
    token?.product_roles?.LAB?.includes('participant') || false;

  const isCoach = token?.product_roles?.LAB?.includes('coach') || false;

  const hasReportsAccess =
    token?.product_roles?.UTILIZATION_REPORTING?.includes('viewer') || false;

  const shouldRedirectToReports = isPL && hasReportsAccess;

  const userSub = token?.sub || '';

  if (shouldRedirectToReports) {
    redirectToReportsApp();
    return;
  }

  if (isCoach) {
    redirectToCoachHome(userSub);
    return;
  }

  if (isParticipant) {
    redirectToParticipantHome(userSub);
    return;
  }

  redirectToLab();
};
