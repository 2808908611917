import { Stack, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { LaunchDarklyFactory } from '@utils/LaunchDarklyFactory';

export default function DynamicOnboardingFeatureRequired({
  children,
}: Readonly<{
  children: JSX.Element;
}>): JSX.Element {
  const [hasFeatureFlag, setHasFeatureFlag] = useState(false);

  const initializeFeatureFlag = async () => {
    const hasDynamicOnboarding =
      await LaunchDarklyFactory.hasDynamicOnboarding();

    if (hasDynamicOnboarding) {
      setHasFeatureFlag(hasDynamicOnboarding);
      return;
    }

    return window.location.replace(`${process.env.REACT_APP_LAB_URL}/404`);
  };

  useEffect(() => {
    initializeFeatureFlag();
  }, []);

  if (!hasFeatureFlag) {
    return (
      <Stack
        data-testid="professional-role-page-loading"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  return children;
}
