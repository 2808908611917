import { AxiosResponse } from 'axios';
import { AccountType } from '@app-types/Account';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_ACCOUNT_SERVICE_URL}/api/v2/accounts`;

export const getAccountName = async (
  accountUUID: string
): Promise<AccountType | null> => {
  if (!accountUUID) {
    return null;
  }

  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/queries/find-name`,
      { accountUUID }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
