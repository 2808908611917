import { TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { Organization } from '@apis/deprecated/organizations';
import { OptionType } from '@app-types/Select';
import Select from '@components/Select/Select';
import VirtualizedAutocomplete from '@components/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { useOrganizationContext } from '@contexts/OrganizationContext/OrganizationContext';
import { formatOrganizationToOption } from '@utils/SelectFormatter';
import style from './PartnerAccountInput.module.css';

export default function PartnerAccountInput({
  showFilters = true,
  label,
  accountValue,
  onAccountChange,
  stateValue,
  accountTypeValue,
  onStateChange,
  onAccountTypeChange,
  states,
  accountTypes,
}: {
  accountError?: boolean;
  showFilters?: boolean;
  label?: string;
  accountValue?: OptionType;
  onAccountChange: (e: SyntheticEvent, value: OptionType | null) => void;
  stateValue?: OptionType;
  accountTypeValue?: OptionType;
  onStateChange?: (option: OptionType | null) => void;
  onAccountTypeChange?: (option: OptionType | null) => void;
  states?: OptionType[];
  accountTypes?: OptionType[];
}): JSX.Element {
  const { organizations } = useOrganizationContext();

  let filteredOrganizations: Organization[] = [...organizations];

  if (stateValue) {
    filteredOrganizations = filteredOrganizations.filter(
      (org) => org.address?.region === stateValue.value
    );
  }

  if (accountTypeValue) {
    filteredOrganizations = filteredOrganizations.filter(
      (org) => org.organizationType === accountTypeValue.value
    );
  }

  const accountOptions = filteredOrganizations.map((org) =>
    formatOrganizationToOption(org)
  );

  accountOptions.sort((a, b) => a.label.localeCompare(b.label));

  const renderFilters = () =>
    showFilters ? (
      <>
        <Select
          label="partner account"
          isSearchable={false}
          options={states}
          menuPlacement="auto"
          placeholder="State"
          value={stateValue}
          onChange={onStateChange}
        />
        <Select
          isSearchable={false}
          options={accountTypes}
          menuPlacement="auto"
          placeholder="Account Type"
          value={accountTypeValue}
          onChange={onAccountTypeChange}
        />
      </>
    ) : null;

  return (
    <div className={style.partnerContainer}>
      {renderFilters()}

      <VirtualizedAutocomplete
        popupIcon={null}
        isOptionEqualToValue={(o, v) => o.value === v.value}
        data-testid="account-select"
        options={accountOptions}
        value={accountValue || null}
        onChange={onAccountChange}
        clearOnBlur={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputLabelProps={{
              sx: {
                top: '-8px',
                color: 'var(--color-primary)',
                '&.MuiInputLabel-shrink': {
                  top: 0,
                },
              },
            }}
            placeholder="Search by account"
            InputProps={{
              ...params.InputProps,
              style: { paddingRight: '30px' },

              sx: {
                height: '40px',
              },
            }}
            inputProps={{
              ...params.inputProps,
              style: { padding: 0 },
            }}
          />
        )}
      />
    </div>
  );
}
