import { Box, CircularProgress, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAccountName } from '@apis/accounts';
import { getInvite } from '@apis/invites';
import { InviteData, WorkshopPropsType } from '@app-types/Invite';
import { SERVICE_TYPE } from '@constants/product';
import teachersAndStudents from '@images/icons/teachers.svg';
import RegistrationExpired from '@pages/RegistrationExpired/RegistrationExpired';
import AccountWelcomeSection from './components/AccountWelcomeSection/AccountWelcomeSection';
import AccountWrapper from './components/AccountWrapper/AccountWrapper';
import ProductInvitationSection from './components/ProductInvitationSection/ProductInvitationSection';

export default function AccountInvitation(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [accountName, setAccountName] = useState('');
  const [serviceInvite, setServiceInvite] = useState<InviteData | null>(null);
  const [expired, setExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const accountUuid = searchParams.get('accountUuid') ?? '';
  const serviceUuid = searchParams.get('serviceUuid') ?? '';
  const serviceType = (searchParams.get('serviceType') ?? '').toUpperCase();

  const getEndDate = (invite: InviteData | null): DateTime => {
    if (!invite) {
      return DateTime.invalid('No invite data');
    }

    if (invite.serviceType === SERVICE_TYPE.COACHING) {
      return DateTime.fromISO(invite?.endDate as string);
    }

    const lastEndTime = Math.max(
      ...(((invite.inviteSpecificProps as WorkshopPropsType).sessions || [])
        .map((session) => session.endTime)
        .filter((endTime) => endTime) as number[])
    );

    return DateTime.fromMillis(lastEndTime);
  };

  const fetchData = async () => {
    const invite = await getInvite(serviceUuid, serviceType);
    const account = await getAccountName(invite?.accountUuid || accountUuid);

    if (!account) {
      return window.location.replace(`${process.env.REACT_APP_LAB_URL}/404`);
    }

    setAccountName(account?.name || '');
    setServiceInvite(invite);

    const endDate = getEndDate(invite);
    if (endDate.isValid && endDate.toMillis() < Date.now()) {
      setExpired(endDate.isValid && endDate.toMillis() < Date.now());
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <Stack
        data-testid="account-invitation-page-loading"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  const renderAccountInvitation = () => {
    return (
      <AccountWrapper>
        <AccountWelcomeSection
          accountName={accountName}
          serviceUuid={serviceUuid}
        />
        <Box pt="2em" display={{ xs: 'visible', sm: 'none' }}>
          <img
            width="70%"
            height="70%"
            src={teachersAndStudents}
            alt="Teachers and students smiling"
          />
        </Box>
        <ProductInvitationSection
          serviceName={serviceInvite?.displayName}
          serviceType={serviceInvite?.serviceType}
          serviceDetail={serviceInvite?.inviteSpecificProps}
        />
      </AccountWrapper>
    );
  };

  return (
    <>
      {expired ? (
        <RegistrationExpired serviceDisplayName={serviceInvite?.displayName} />
      ) : (
        renderAccountInvitation()
      )}
    </>
  );
}
