import { Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { BUTTON_VARIANT } from '@components/Button/Button';
import IconButton from '@components/IconButton/IconButton';
import Pagination from '@components/Pagination/Pagination';
import PeopleIcon from '@images/icons/people.svg';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import styles from './Users.module.css';
import UserTable from './components/UserTable/UserTable';

type Props = {
  isAllChecked: boolean;
  totalUsers: number;
  currentPage: number;
  usersPerPage: number;
  disableSelectedUsersButtons: boolean;
  isSuperAdmin: boolean;
  onAddUserClick: () => void;
  onPageChange: (pageNumber: number) => void;
  onCheckboxClick: (userId: string | null | void) => void;
  onClearClick: () => void;
  onHeaderCheckboxClick: () => void;
  onInviteToProductClick: () => void;
  onSendWelcomeEmailClick: () => void;
  onChangePermissionsClick: () => void;
};

export default function Users({
  isAllChecked,
  totalUsers,
  currentPage,
  usersPerPage,
  disableSelectedUsersButtons,
  isSuperAdmin,
  onAddUserClick,
  onPageChange,
  onCheckboxClick,
  onClearClick,
  onHeaderCheckboxClick,
  onInviteToProductClick,
  onSendWelcomeEmailClick,
  onChangePermissionsClick,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { checkedUserIds }: ProductsContextType = useContext(ProductsContext);

  const handleBulkUpload = () => navigate('/users/provisioning');

  const handleManageUsersAndOrgs = () => onAddUserClick();

  const renderUsersSelectedText = () => (
    <span>
      {checkedUserIds.size} user{checkedUserIds.size === 1 ? ' ' : 's '}
      selected
      <Typography
        component="a"
        color="error"
        data-testid="clear-all"
        marginLeft="1rem"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={onClearClick}
      >
        clear all
      </Typography>
    </span>
  );

  const renderHeader = () =>
    !checkedUserIds.size && !disableSelectedUsersButtons ? (
      <div className={styles.titleContainer}>
        <h2 className={styles.titleText} data-testid="users-title">
          User Management
        </h2>
        <IconButton
          dataTestId="bulk-upload"
          icon={PeopleIcon}
          iconClassName={styles.icon}
          text="Bulk Upload"
          onClick={handleBulkUpload}
        />
        <IconButton
          dataTestId="manage-users-orgs"
          iconClassName={styles.icon}
          text="Manage Users & Orgs"
          onClick={handleManageUsersAndOrgs}
        />
      </div>
    ) : (
      <div className={styles.headerContainer} data-testid="button-container">
        {renderUsersSelectedText()}
        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <Button
              contentMinWidth="85px"
              dataTestId="send-welcome-email-button"
              disabled={disableSelectedUsersButtons}
              text="Send Welcome Email"
              variant={BUTTON_VARIANT.OUTLINE_SECONDARY}
              onClick={onSendWelcomeEmailClick}
            />
          </div>
          <div className={styles.button}>
            {isSuperAdmin && (
              <Button
                contentMinWidth="85px"
                dataTestId="invite-to-product-button"
                disabled={disableSelectedUsersButtons}
                text="Invite to Product"
                variant={BUTTON_VARIANT.SECONDARY}
                onClick={onInviteToProductClick}
              />
            )}
          </div>
          <div className={styles.button}>
            <Button
              contentMinWidth="85px"
              dataTestId="view-permissions-button"
              disabled={
                disableSelectedUsersButtons ||
                (!isSuperAdmin && checkedUserIds.size > 1)
              }
              text="View Permissions"
              variant={BUTTON_VARIANT.OUTLINE_SECONDARY}
              onClick={onChangePermissionsClick}
            />
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      {renderHeader()}
      <UserTable
        isHeaderChecked={isAllChecked}
        onCheckboxClick={onCheckboxClick}
        onHeaderCheckboxClick={onHeaderCheckboxClick}
      />
      <div className={styles.paginationContainer}>
        <Pagination
          currentPage={currentPage}
          totalItems={totalUsers}
          itemsPerPage={usersPerPage}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
