import { onEnterKey } from '@utils/key-handler';
import styles from './IconButton.module.css';

type Props = {
  dataTestId?: string;
  icon?: string;
  containerClassName?: string;
  iconClassName?: string;
  text: string;
  onClick: () => void;
};

export default function IconButton({
  dataTestId,
  icon,
  containerClassName,
  iconClassName,
  text,
  onClick,
}: Props): JSX.Element {
  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={`${styles.container} ${containerClassName}`}
      data-testid={dataTestId}
      onClick={onClick}
      onKeyDown={onEnterKey(onClick)}
      tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
    >
      {icon && (
        <img
          className={`${styles.icon} ${iconClassName}`}
          src={icon}
          data-testid={`${dataTestId}-icon`}
          alt="icon"
        />
      )}
      <div className={styles.text}>{text}</div>
    </div>
  );
}
