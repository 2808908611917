import { AxiosResponse } from 'axios';
import { InviteData } from '@app-types/Invite';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_ACCOUNT_SERVICE_URL}/api/v2/invites`;

export const getInvite = async (
  identifier: string,
  serviceType: string
): Promise<InviteData | null> => {
  if (!identifier) {
    return null;
  }

  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/queries/find`,
      { identifiers: [identifier], serviceType }
    );

    if (response.data.length !== 1) {
      throw Error('Invite was not found');
    }

    return response.data[0];
  } catch (err) {
    console.error(err);
    return null;
  }
};
