import { Cancel } from '@mui/icons-material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Stack, Typography, Button, IconButton, Box } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { AccessToken } from '@app-types/Token';
import { ReactComponent as AccountDetailsIcon } from '@images/icons/account-details.svg';

export type Props = {
  uuid: string;
  isRetracted: boolean;
  participantUserUuid: string;
  proposedAt: number;
  expiredAt: number;
  onParticipantRemove: () => void;
};

export const TEST_ID = {
  CONTAINER: 'match-button',
  VIEW_MATCH_BUTTON: 'match-button-view-match-button',
  PARTICIPANT_DETAIL_BUTTON: 'match-button-participant-detail-button',
  REMOVE_PARTICIPANT_BUTTON: 'match-button-remove-participant-button',
};

export default function MatchButton({
  uuid,
  isRetracted,
  participantUserUuid,
  proposedAt,
  expiredAt,
  onParticipantRemove,
}: Props): JSX.Element {
  const { user } = useAuth();
  const userSub = user?.profile.sub || '';
  const { coachUuid = '' } = useParams();

  const checkIsAdmin = (): boolean => {
    if (!user || !user?.access_token) return false;
    const { product_roles } = jwtDecode<AccessToken>(user?.access_token);
    return product_roles['USER_MANAGEMENT'].some(
      (role: string) => role.toLowerCase() === 'super_admin'
    );
  };

  const isExpired = DateTime.fromMillis(expiredAt) < DateTime.now();

  const dateText = isExpired
    ? `Expired Match: ${DateTime.fromMillis(proposedAt).toLocaleString(
        DateTime.DATE_FULL
      )}`
    : `Proposed: ${DateTime.fromMillis(proposedAt).toLocaleString(
        DateTime.DATE_FULL
      )}`;

  const renderButton = () =>
    isRetracted ? (
      <>
        <Button
          disabled={userSub !== coachUuid && !checkIsAdmin()}
          data-testid={TEST_ID.REMOVE_PARTICIPANT_BUTTON}
          startIcon={<Cancel />}
          variant="outlined"
          sx={{ py: 0.5 }}
          onClick={onParticipantRemove}
        >
          Remove Participant
        </Button>
        <Box width={12.5} />
      </>
    ) : (
      <>
        <Button
          data-testid={TEST_ID.VIEW_MATCH_BUTTON}
          variant="outlined"
          sx={{ px: 1, py: 0.5 }}
          href={`${process.env.REACT_APP_COACHING_URL}/coach-match/${uuid}`}
        >
          <Stack direction="row" alignItems="center">
            <AccountDetailsIcon
              fill={COLOR_PALETTE.primary.main}
              style={{
                paddingRight: '4px',
              }}
            />
            View Match Details
          </Stack>
        </Button>

        <IconButton
          data-testid={TEST_ID.PARTICIPANT_DETAIL_BUTTON}
          color="success"
          href={`${process.env.REACT_APP_COACHING_URL}/participant/${participantUserUuid}/details`}
        >
          <ArticleOutlinedIcon fill={COLOR_PALETTE.success.main} />
        </IconButton>
      </>
    );

  const renderText = () =>
    isRetracted ? (
      <Box height={13} />
    ) : (
      <Typography
        fontWeight={500}
        fontSize={14}
        color={
          isExpired ? COLOR_PALETTE.error.main : COLOR_PALETTE.textDark.high
        }
      >
        {dateText}
      </Typography>
    );

  return (
    <Stack data-testid={TEST_ID.CONTAINER}>
      {renderText()}

      <Stack direction="row" pl={1} pt={0.5}>
        {renderButton()}
      </Stack>
    </Stack>
  );
}
