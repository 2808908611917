import { endOfDay, startOfDay } from 'date-fns';
import { SyntheticEvent, useState } from 'react';
import { OptionType } from '@app-types/Select';
import { FilterType } from '@pages/DeprecatedPages/UserManagement/UserManagement';
import UserFiltersView from './components/UserFiltersView';

export default function UserFilters({
  userCount,
  userSearchValue,
  onUserSearchChange,
  onApplyFiltersClick,
  states,
  accountTypes,
}: {
  userCount?: number;
  userSearchValue: string;
  onUserSearchChange: (value: string) => void;
  onApplyFiltersClick: (newFilters: FilterType) => void;
  states?: OptionType[];
  accountTypes?: OptionType[];
}): JSX.Element {
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [account, setAccount] = useState<OptionType>();
  const [state, setState] = useState<OptionType>();
  const [accountType, setAccountType] = useState<OptionType>();

  const getFormattedFilters = () => {
    const newFilters = {} as FilterType;

    if (account) {
      newFilters.organization = account;
    }

    if (state) {
      newFilters.states = state;
    }

    if (accountType) {
      newFilters.accountType = accountType;
    }

    if (dateFrom && dateTo) {
      newFilters.createdAfter = startOfDay(dateFrom).getTime();
      newFilters.createdBefore = endOfDay(dateTo).getTime();
    }

    return newFilters;
  };

  const handleApplyFiltersClick = () => {
    onApplyFiltersClick(getFormattedFilters());
  };

  const handleDateFromChange = (value?: Date) => setDateFrom(value);
  const handleDateToChange = (value?: Date) => setDateTo(value);

  const handleAccountChange = (
    _e: SyntheticEvent,
    option: OptionType | null
  ) => {
    if (!option) {
      setAccount(undefined);
      return;
    }

    setAccount(option);
  };

  const handleStateChange = (option: OptionType | null) => {
    if (!option) {
      setState(undefined);
      return;
    }

    setState(option);
  };

  const handleAccountTypeChange = (option: OptionType | null) => {
    if (!option) {
      setAccountType(undefined);
      return;
    }

    setAccountType(option);
  };

  return (
    <UserFiltersView
      userCount={userCount}
      dateFromValue={dateFrom}
      dateToValue={dateTo}
      userSearchValue={userSearchValue}
      accountValue={account}
      onDateFromChange={handleDateFromChange}
      onDateToChange={handleDateToChange}
      onUserSearchChange={onUserSearchChange}
      onAccountChange={handleAccountChange}
      onApplyFiltersClick={handleApplyFiltersClick}
      stateValue={state}
      accountTypeValue={accountType}
      onStateChange={handleStateChange}
      onAccountTypeChange={handleAccountTypeChange}
      states={states}
      accountTypes={accountTypes}
    />
  );
}
