import { Box, Link, Stack, Typography } from '@mui/material';
import blLab from '@images/logos/bl-lab.png';

export const EmptyState = (): JSX.Element => {
  return (
    <Stack
      data-testid="empty-state"
      direction="row"
      mb="2rem"
      mt="1rem"
      spacing="1rem"
    >
      <Box pl="5%" pr="5%">
        <img
          alt="BetterLesson Lab"
          loading="lazy"
          src={blLab}
          style={{ maxWidth: '20rem', minWidth: '10rem' }}
          width="100%"
        />
      </Box>

      <Stack spacing="2rem">
        <Typography variant="subtitle1">
          The BetterLesson Lab is your hub for professional learning.
        </Typography>
        <Typography variant="subtitle1">
          While you{"'"}re waiting to join a workshop or meet with a coach, take
          a look around.
        </Typography>
        <Typography variant="subtitle1">
          We{"'"}re sure we have some{' '}
          <Link
            underline="none"
            href={`${process.env.REACT_APP_TEACHING_URL}/search`}
          >
            lessons and strategies
          </Link>{' '}
          that will delight your students.
        </Typography>
      </Stack>
    </Stack>
  );
};
