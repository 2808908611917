export enum MeetingStatusType {
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  COACH_CANCELLED = 'COACH_CANCELLED',
  CANCELLED = 'CANCELLED',
  NO_SHOW = 'NO_SHOW',
}

export type MeetingDataType = {
  uuid: string;
  engagementUuid: string;
  startTime: number;
  duration: number;
  status: string;
  cancelledAt: number | null;
  sharedNotes: string | null;
  sharedNotesUpdatedAt: number | null;
  internalNotes: string | null;
  internalNotesUpdatedAt: number | null;
  videoLinks: string[];
  updatedAt: number;
  joinUrl: string;
};
