export const SESSION_STATUS: Record<string, string> = Object.freeze({
  DRAFT: 'Draft',
  READY_TO_STAFF: 'Ready to Staff',
  APPROVED: 'Approved',
  CONFIRMED: 'Confirmed',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
});

export const SORT_COLUMN = Object.freeze({
  SESSION: 'Session',
  WORKSHOP: 'Workshop',
  DATE: 'Date',
});
