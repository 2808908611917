import { LoadingPage, setToken } from 'betterlesson-library-react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { exchangeRefreshToken } from '@apis/auth';

export default function Clever(): JSX.Element {
  const { isAuthenticated, isLoading } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const authenticateCleverUser = async () => {
    const refreshToken = searchParams.get('refresh-token');

    if (!refreshToken) {
      navigate('/login');
      return;
    }

    if (isAuthenticated) {
      navigate('/');
      return;
    }

    const token = await exchangeRefreshToken(refreshToken);

    if (token) {
      setToken(token);
    }

    navigate('/');
  };

  useEffect(() => {
    if (!isLoading) {
      authenticateCleverUser();
    }
  }, [isLoading]);

  return <LoadingPage data-testid="clever-loading-page" />;
}
