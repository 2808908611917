import { LoadingPage } from 'betterlesson-library-react';
import { CreateSigninRequestArgs } from 'oidc-client-ts';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useSearchParams } from 'react-router-dom';
import { LaunchDarklyFactory } from '@utils/LaunchDarklyFactory';

export default function AuthenticationRequired(): JSX.Element {
  const { isAuthenticated, isLoading, signinRedirect } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsAreNotTrimmed = (): boolean => {
    return searchParams.has('code') || searchParams.has('state');
  };

  const removeCodeAndStateQueryParams = () => {
    if (searchParams.has('code')) {
      searchParams.delete('code');
    }

    if (searchParams.has('state')) {
      searchParams.delete('state');
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      void LaunchDarklyFactory.closeLDClient();
      signinRedirect({
        redirect_uri: window.location.href,
      } as CreateSigninRequestArgs);
    }

    if (isAuthenticated) {
      removeCodeAndStateQueryParams();
      return;
    }
  }, []);

  if (!isAuthenticated || searchParamsAreNotTrimmed()) {
    void LaunchDarklyFactory.closeLDClient();
    return <LoadingPage data-testid="authentication-loading-page" />;
  }

  return <Outlet />;
}
