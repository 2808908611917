import { Box, Grid, Stack, Typography } from '@mui/material';
import { COLOR, ContactSupport } from 'betterlesson-library-react';
import { useAtomValue } from 'jotai';
import { eventsAtom, pastEventsAtom } from '@atoms/event';
import { contextUserFirstNameAtom } from '@atoms/user';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';
import { EmptyState } from './components/EmptyState/EmptyState';
import { Event } from './components/Event/Event';
import { TimezoneLink } from './components/TimezoneLink/TimezoneLink';
import { UpcomingEvents } from './components/UpcomingEvents/UpcomingEvents';

export default function ParticipantHome(): JSX.Element {
  const contextUserFirstName = useAtomValue(contextUserFirstNameAtom);
  const events = useAtomValue(eventsAtom);
  const pastEvents = useAtomValue(pastEventsAtom);
  const availableEvents = events.filter(
    (e) => e.isAvailable && !e.isStatusCompleted
  );
  const upcomingEvents = events.filter(
    (e) => !e.isAvailable && !e.isStatusCompleted
  );
  const completedEvents = pastEvents.filter((e) => e.isStatusCompleted);

  return (
    <NavigationContainer>
      <Grid container p="1rem" spacing="1rem" pb={5}>
        <Grid item xs={12} md={9}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography data-testid="participant-home" variant="h1">
              Welcome, {contextUserFirstName}!
            </Typography>

            <TimezoneLink />
          </Stack>

          {availableEvents.map((e, i: number) => (
            <Event key={i} event={e} />
          ))}

          {availableEvents.length === 0 && <EmptyState />}

          <UpcomingEvents events={upcomingEvents} />
          <Box sx={{ marginTop: '20px' }}>
            <UpcomingEvents events={completedEvents} isStatusCompleted={true} />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Stack
            borderRadius=".5rem"
            data-testid="help"
            padding="1rem"
            spacing={2}
            sx={{ backgroundColor: COLOR.lightBlue[50] }}
          >
            <ContactSupport />
          </Stack>
        </Grid>
      </Grid>
    </NavigationContainer>
  );
}
