import { Link, Typography } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';

type Props = {
  sessionGroupUuid: string;
  sessionGroupName: string;
  sessionNumber: number;
  workshopUuid: string;
  length: number;
};

export const SessionColumn = ({
  sessionGroupUuid,
  sessionGroupName,
  sessionNumber,
  workshopUuid,
  length,
}: Props): JSX.Element => (
  <>
    <Link
      data-testid="upcoming-workshops-session-column"
      href={`${process.env.REACT_APP_WORKSHOP_WEB_URL}/${workshopUuid}?sessionGroupUuid=${sessionGroupUuid}`}
      target="_blank"
      underline="none"
    >
      <Typography
        color={COLOR_PALETTE.info.dark}
        display="block"
        variant="link2"
      >
        {sessionGroupName}
      </Typography>
    </Link>

    <Typography color={COLOR_PALETTE.text.secondary} variant="body5">
      Session {sessionNumber} of {length}
    </Typography>
  </>
);
