import axios, { AxiosInstance } from 'axios';
import { getToken } from 'betterlesson-library-react';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';

export const getUserServiceAxiosWithCorrelationId = (
  correlationId: string
): AxiosInstance => {
  const userServiceAxios = axios.create({
    baseURL: process.env.REACT_APP_USER_SERVICE_URL,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

  const token = getToken();
  if (token) {
    userServiceAxios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token.access_token}`;
  }
  userServiceAxios.defaults.headers.common['x-correlation-id'] = correlationId;

  return userServiceAxios;
};

export const getUserServiceAxios = (): AxiosInstance => {
  const correlationId = uuidv4();
  return getUserServiceAxiosWithCorrelationId(correlationId);
};
