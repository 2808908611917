import { AxiosResponse } from 'axios';
import { MeetingDataType } from '@app-types/meeting';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_COACHING_SERVICE_URL}/api/v2/meetings`;

export const getMeetings = async (
  engagementUuid?: string
): Promise<MeetingDataType[]> => {
  if (!engagementUuid) {
    return [];
  }

  try {
    const response: AxiosResponse<MeetingDataType[]> =
      await getServiceAxios().post(`${BASE_ROUTE}/queries/find-by-engagement`, {
        engagementUuid,
      });

    return response.data.sort((a, b) => b.startTime - a.startTime);
  } catch (err) {
    console.error(err);
    return [];
  }
};
