import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { MultipleFieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import EntryFormContainer from '@components/EntryFormContainer/EntryFormContainer';
import FormInput from '@components/FormInput/FormInput';
import LinkText from '@components/LinkText/LinkText';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { buildEntryFormUrl } from '@utils/Redirect';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';
import styles from './RegisterView.module.css';
import PasswordInput from './components/PasswordInput/PasswordInput';
import PasswordRequirements from './components/PasswordRequirements/PasswordRequirements';

type Props = {
  isRegistering: boolean;
  givenNameRegister: UseFormRegisterReturn;
  giveNameError?: string;
  familyNameRegister: UseFormRegisterReturn;
  familyNameError?: string;
  emailRegister: UseFormRegisterReturn;
  emailError?: string;
  usernameRegister: UseFormRegisterReturn;
  usernameError?: string;
  passwordRegister: UseFormRegisterReturn;
  passwordError: boolean;
  confirmPasswordRegister: UseFormRegisterReturn;
  confirmPasswordError?: string;
  password: string;
  requirements?: MultipleFieldErrors;
  isSubmitted: boolean;
  displayMainError: boolean;
  emailDomains: string[];
  selectedEmailDomain?: string;
  onSubmit: () => void;
  handleUpdateEmailDomain?: (domain: string) => void;
};

export default function RegisterView({
  isRegistering,
  givenNameRegister,
  giveNameError,
  familyNameRegister,
  familyNameError,
  emailRegister,
  emailError,
  usernameRegister,
  usernameError,
  passwordRegister,
  passwordError,
  confirmPasswordRegister,
  confirmPasswordError,
  password,
  requirements,
  isSubmitted,
  displayMainError,
  emailDomains,
  selectedEmailDomain,
  onSubmit,
  handleUpdateEmailDomain,
}: Props): JSX.Element {
  const updateEmailDomain = (e: SelectChangeEvent) => {
    if (handleUpdateEmailDomain) handleUpdateEmailDomain(e.target.value);
  };

  const renderMainError = () =>
    displayMainError ? (
      <div className={styles.errorText} data-testid="register-error">
        Please enter required fields
      </div>
    ) : (
      <div data-testid="register-spacer" style={{ height: '30px' }} />
    );

  const renderRegisterHeader = () => (
    <>
      <div className={styles.headerText}>Create Account</div>
      {renderMainError()}
    </>
  );

  const renderRegisterForm = () => (
    <form className={styles.form}>
      <FormInput
        register={givenNameRegister}
        placeholder="First Name"
        error={giveNameError}
        type="text"
      />
      <FormInput
        register={familyNameRegister}
        placeholder="Last Name"
        error={familyNameError}
        type="text"
      />

      <FormInput
        register={emailDomains.length ? usernameRegister : emailRegister}
        placeholder={emailDomains.length ? 'Username' : 'Email'}
        error={emailDomains.length ? usernameError : emailError}
        type="text"
      >
        {emailDomains.length ? (
          <Select
            data-testid="email-domain"
            value={selectedEmailDomain || ''}
            onChange={updateEmailDomain}
          >
            {emailDomains.map((domain) => (
              <MenuItem key={domain} value={domain}>
                {domain}
              </MenuItem>
            ))}
          </Select>
        ) : undefined}
      </FormInput>

      <PasswordInput
        register={passwordRegister}
        placeholder="Create Password"
        error={passwordError}
      />
      <PasswordRequirements
        isSubmitted={isSubmitted}
        password={password}
        requirements={requirements}
      />
      <PasswordInput
        register={confirmPasswordRegister}
        placeholder="Confirm Password"
        error={confirmPasswordError}
      />
      <div className={styles.createButton}>
        <LoadingButton
          color="secondary"
          variant="contained"
          data-testid="create-button"
          onClick={onSubmit}
          fullWidth
          isLoading={isRegistering}
          text="Create my account"
          loadingText="Creating account"
          sx={CONTAINED_GRADIENT_STYLE}
        />
      </div>
      <LinkText
        dataTestId="login-button"
        text="Already have an account? Log in."
        to={buildEntryFormUrl('/login')}
        preserveQueryParams={true}
      />
    </form>
  );

  return (
    <EntryFormContainer dataTestId="register-form">
      {renderRegisterHeader()}
      {renderRegisterForm()}
    </EntryFormContainer>
  );
}
