import styles from './UserIcon.module.scss';

const COLORS = [
  '#004493',
  '#1A7CD9',
  '#2196F3',
  '#0B8484',
  '#0AABB1',
  '#53E6EB',
  '#95FFEE',
  '#107D54',
  '#33B55F',
  '#2ACAA1',
  '#25E8C8',
  '#FF6B2A',
  '#F4AB2A',
];

type Props = {
  className?: string;
  givenName?: string;
  familyName?: string;
  userId?: string;
};

export default function UserIcon({
  className,
  givenName = ' ',
  familyName = ' ',
  userId,
}: Props): JSX.Element {
  const initials = `${(givenName && givenName[0]?.toUpperCase()) || ''}${
    (familyName && familyName[0]?.toUpperCase()) || ''
  }`;
  const colorIndex =
    `${givenName}${familyName}${userId}`
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc, 0) % COLORS.length;

  return (
    <div className={`${styles.container} ${className}`} data-testid="user-icon">
      <div
        className={styles.circle}
        data-testid="user-icon-circle"
        style={{ backgroundColor: COLORS[colorIndex] }}
      >
        {initials}
      </div>
    </div>
  );
}
