import { Button, ButtonProps, CircularProgress } from '@mui/material';

type Props = ButtonProps & {
  isLoading?: boolean;
  text?: string;
  loadingText?: string;
};

export default function LoadingButton({
  disabled,
  isLoading,
  text,
  loadingText,
  children,
  ...buttonProps
}: Props): JSX.Element {
  const renderChildren = () => {
    if (children) {
      return children;
    }

    if (isLoading && loadingText) {
      return loadingText;
    }

    if (text) {
      return text;
    }

    return null;
  };

  return (
    <Button
      {...buttonProps}
      disabled={disabled || isLoading}
      endIcon={
        isLoading ? (
          <CircularProgress data-testid="loading-icon" size={20} />
        ) : undefined
      }
    >
      {renderChildren()}
    </Button>
  );
}
