import { Stack, TextField, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { BasicProfileType } from '@app-types/User';

type Props = {
  control: Control<BasicProfileType>;
  name: 'familyName' | 'givenName' | 'zoomSchedulerUrl';
  label: string;
  error?: string;
  disabled?: boolean;
};

export default function BasicProfileInput({
  control,
  name,
  label,
  error,
  disabled,
}: Readonly<Props>): JSX.Element {
  return (
    <Stack spacing={0.5} data-testid="basic-profile-input">
      <Typography variant="subtitle1" component="div">
        {label}
      </Typography>

      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            data-testid="basic-profile-input-text-field"
            inputProps={{
              'data-testid': 'basic-profile-input-text-field-input',
            }}
            disabled={disabled}
            error={!!error}
            helperText={error}
          />
        )}
      />
    </Stack>
  );
}
