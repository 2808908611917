import { CircularProgress, Stack } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  createUsersProfessionalRole,
  getUsersProfessionalRole,
} from '@apis/professional-roles';
import { ProfessionalRoleFormType } from '@app-types/ProfessionalRoles';
import ProfessionalRoleForm from '@components/ProfessionalRoleForm/ProfessionalRoleForm';
import ProfessionalRoleWrapper from '@components/ProfessionalRoleWrapper/ProfessionalRoleWrapper';

type Props = {
  onConfirm: () => void;
};

const ONE_DAY = 24 * 60 * 60 * 1000;

export default function ProfessionalRolePage({
  onConfirm,
}: Readonly<Props>): JSX.Element {
  const { user } = useAuth();
  const userUUID = user?.profile?.sub ?? '';
  const [isInitialized, setIsInitialized] = useState(false);
  const [professionalRole, setProfessionalRole] =
    useState<ProfessionalRoleFormType>();

  const initializeProfessionalRole = async () => {
    const response = await getUsersProfessionalRole(userUUID);

    if (!response) {
      setIsInitialized(true);
      return;
    }

    setProfessionalRole(response);
    const lastUpdated = new Date(response.createdAt);
    const timeDiff = new Date().getTime() - lastUpdated.getTime();

    if (timeDiff < ONE_DAY) {
      onConfirm();
      return;
    }

    setIsInitialized(true);
  };

  useEffect(() => {
    initializeProfessionalRole();
  }, []);

  const handleProfessionalRoleChange = async (
    professionalRole: ProfessionalRoleFormType
  ) => {
    setProfessionalRole(professionalRole);

    const isCreated = await createUsersProfessionalRole(
      userUUID,
      professionalRole
    );

    if (!isCreated) {
      toast.error('Professional Role creation failed.', {
        className: 'toast-notify-error',
      });
    }

    onConfirm();
  };

  if (!isInitialized) {
    return (
      <Stack
        data-testid="professional-role-page-loading"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  return (
    <Stack
      data-testid="professional-role-page"
      alignItems="center"
      justifyContent="center"
      py={4}
    >
      <ProfessionalRoleWrapper>
        <ProfessionalRoleForm
          professionalRole={professionalRole}
          onChange={handleProfessionalRoleChange}
        />
      </ProfessionalRoleWrapper>
    </Stack>
  );
}
