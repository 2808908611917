import { Stack, Typography } from '@mui/material';
import structuredClone from '@ungap/structured-clone';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import { WorkshopPropsType, WorkshopSessionPropsType } from '@app-types/Invite';
import { SESSION_STATUS } from '@constants/workshop';
import { InviteProps } from '../utils';

export default function WorkshopInvite({
  serviceName,
  serviceDetail,
}: Readonly<InviteProps>): JSX.Element {
  const sessions = structuredClone(
    (serviceDetail as WorkshopPropsType)?.sessions || []
  )
    .sort((a, b) => Number(a.startTime) - Number(b.startTime))
    .filter(
      (s) =>
        s.status === SESSION_STATUS.READY_TO_STAFF ||
        s.status === SESSION_STATUS.CONFIRMED ||
        s.status === SESSION_STATUS.DELIVERED
    );

  const nextSession = sessions.find((s) => Number(s.startTime) >= Date.now());

  const renderDate = (session: WorkshopSessionPropsType) => {
    const workshopStartTime = session.startTime
      ? DateTime.fromMillis(session.startTime)
      : DateTime.invalid('Milliseconds is invalid');

    const workshopEndTime = session.endTime
      ? DateTime.fromMillis(session.endTime)
      : DateTime.invalid('Milliseconds is invalid');

    const hasValidTimes = workshopStartTime.isValid && workshopEndTime.isValid;

    const workshopTimes = hasValidTimes
      ? `, ${workshopStartTime.toFormat('h:mm')} - ${workshopEndTime.toFormat(
          'h:mm a ZZZZ'
        )}`
      : '';

    const textColor =
      workshopEndTime.toMillis() < Date.now()
        ? COLOR_PALETTE.text.secondary
        : COLOR_PALETTE.text.primary;

    return (
      <Stack key={session.name}>
        <Typography color={textColor} variant="subtitle1">
          {session.name}
        </Typography>

        <Typography color={textColor} variant="subtitle1">
          {workshopStartTime.toLocaleString(DateTime.DATE_FULL)}
          {workshopTimes}
        </Typography>
      </Stack>
    );
  };

  const renderServiceDetails = () => {
    if (!serviceDetail) {
      return <></>;
    }

    const props = serviceDetail as WorkshopPropsType;

    return (
      <Stack>
        <Typography variant="h4">{props.type}</Typography>

        <Stack pt={3} spacing={3}>
          {sessions.map(renderDate)}
        </Stack>
        {nextSession?.description && (
          <>
            <Typography
              pb={2}
              pt={5}
              tabIndex={0}
              variant="overline2"
              color="black"
            >
              About the upcoming session
            </Typography>
            <Typography>{nextSession?.description}</Typography>
          </>
        )}
      </Stack>
    );
  };

  return (
    <Stack data-testid="workshop-invite" textAlign="left" spacing={2.5}>
      <Typography variant="h3">{serviceName}</Typography>
      {renderServiceDetails()}
    </Stack>
  );
}
