import Confirmation from '@components/Confirmation/Confirmation';
import {
  ROLE_TEXT,
  TEACHER_EXPERIENCE_TEXT,
  TEACHER_SUBJECT_TEXT,
  TEACHER_SUBJECT_ORDER,
} from '@constants/professional-roles';

type Props = {
  role: string;
  experience: string;
  subjects: string[];
  onConfirmClick: () => void;
  onGoBackClick: () => void;
};

export default function ProfessionalRoleConfirmation({
  role,
  experience,
  subjects,
  onConfirmClick,
  onGoBackClick,
}: Readonly<Props>): JSX.Element {
  const sortedSubjects = [...subjects].sort((a, b) => {
    const aIndex = TEACHER_SUBJECT_ORDER.findIndex((subject) => subject === a);
    const bIndex = TEACHER_SUBJECT_ORDER.findIndex((subject) => subject === b);
    return aIndex - bIndex;
  });

  const getRoleText = () => {
    const roleText = `${ROLE_TEXT[role]}, `;
    const experienceText = `${TEACHER_EXPERIENCE_TEXT[experience]}`;
    const subjectText = sortedSubjects.reduce((text, subject, index) => {
      const isLastSubject = sortedSubjects.length - 1 === index;

      if (isLastSubject) {
        return `${text}, and ${TEACHER_SUBJECT_TEXT[subject]}`;
      }

      return `${text}, ${TEACHER_SUBJECT_TEXT[subject]}`;
    }, '');

    return [
      "You're ",
      <strong key={role}>
        {roleText}
        {experienceText}
        {subjectText}.
      </strong>,
    ];
  };

  return (
    <Confirmation
      confirmButtonText="Submit and let's go"
      topText={getRoleText()}
      onConfirmClick={onConfirmClick}
      onGoBackClick={onGoBackClick}
    />
  );
}
