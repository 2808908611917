import SearchIcon from '@mui/icons-material/Search';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  IconButton,
  Link,
  TableSortLabel,
} from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import { useState, useEffect } from 'react';
import { EngagementSummaryForCoach } from '@app-types/CoachParticipant';
import ProfileCardV2 from '@components/ProfileCardV2/ProfileCardV2';
import { ReactComponent as StarFilled } from '@images/icons/StarFilled.svg';
import { ReactComponent as UserCard } from '@images/icons/user-card.svg';

export type Props = {
  coachParticipants: EngagementSummaryForCoach[];
};
const tableCellStyle = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
};

const tableLinkStyle = {
  fontSize: '14px',
  letterSpacing: '1px',
  lineHeight: '20px',
  fontStyle: 'normal',
};

const ParticipantsTable = ({
  coachParticipants: coachEngagements,
}: Props): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEngagements, setFilteredEngagements] =
    useState(coachEngagements);
  const [sortField, setSortField] = useState<'name' | 'nextMeeting'>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    setFilteredEngagements(
      coachEngagements.filter((engagement) =>
        `${engagement.participant.givenName} ${engagement.participant.familyName} ${engagement.participant.email}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, coachEngagements]);

  useEffect(() => {
    setFilteredEngagements((prevEngagements) =>
      [...prevEngagements].sort((a, b) => {
        if (sortField === 'name') {
          const nameA =
            `${a.participant.givenName} ${a.participant.familyName}`.toLowerCase();
          const nameB =
            `${b.participant.givenName} ${b.participant.familyName}`.toLowerCase();
          if (nameA < nameB) return sortDirection === 'asc' ? -1 : 1;
          if (nameA > nameB) return sortDirection === 'asc' ? 1 : -1;
          return 0;
        } else {
          const dateA = a.meeting ? a.meeting.meetingStart : 0;
          const dateB = b.meeting ? b.meeting.meetingStart : 0;
          if (dateA < dateB) return sortDirection === 'asc' ? -1 : 1;
          if (dateA > dateB) return sortDirection === 'asc' ? 1 : -1;
          return 0;
        }
      })
    );
  }, [sortField, sortDirection]);

  function formatDate(epochMs: number) {
    const dateTime = DateTime.fromMillis(epochMs);
    const formattedDate = dateTime.toFormat('cccc LLLL d, h:mm a');

    const timeZoneName = new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'short',
    }).format(dateTime.toJSDate());

    const timeZoneAbbreviation = timeZoneName.split(' ').pop();

    return `${formattedDate} ${timeZoneAbbreviation}`;
  }

  const handleSortRequest = (field: 'name' | 'nextMeeting') => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  if (coachEngagements.length === 0) {
    return (
      <Box>
        <Typography
          variant="h4"
          fontWeight={500}
          fontSize={22}
          color={COLOR_PALETTE.text.primary}
        >
          Participants
        </Typography>
        <Typography marginTop={3} marginBottom={3}>
          No Participants to Display
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight={500}
        fontSize={22}
        color={COLOR_PALETTE.text.primary}
      >
        Participants
      </Typography>
      <Box display="flex" mt={2} mb={2}>
        <TextField
          variant="outlined"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: '20em',
            fontSize: '14px',
            input: {
              color: COLOR_PALETTE.text.primary,
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableCellStyle}>
                <TableSortLabel
                  active={sortField === 'name'}
                  direction={sortField === 'name' ? sortDirection : 'asc'}
                  onClick={() => handleSortRequest('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell style={tableCellStyle}>Cohort</TableCell>
              <TableCell style={tableCellStyle}>Activity</TableCell>
              <TableCell style={tableCellStyle}>Highlights</TableCell>
              <TableCell style={tableCellStyle}>
                <TableSortLabel
                  active={sortField === 'nextMeeting'}
                  direction={
                    sortField === 'nextMeeting' ? sortDirection : 'asc'
                  }
                  onClick={() => handleSortRequest('nextMeeting')}
                >
                  Next Meeting
                </TableSortLabel>
              </TableCell>
              <TableCell style={tableCellStyle}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEngagements.map((engagement, index) => (
              <TableRow
                key={index}
                sx={{ '&:hover': { backgroundColor: '#3d3d3d0a' } }}
              >
                <TableCell sx={{ flexGrow: 1 }}>
                  <ProfileCardV2
                    givenName={engagement.participant.givenName}
                    familyName={engagement.participant.familyName}
                    email={engagement.participant.email}
                    profileUrl={`${process.env.REACT_APP_COACHING_URL}/participant/${engagement.participant.uuid}/workspace`}
                    userId="0"
                  />
                </TableCell>
                <TableCell>
                  <Link
                    href={`${process.env.REACT_APP_COACHING_URL}/cohort/${engagement.cohortUuid}`}
                    underline="none"
                    color="primary"
                    style={tableLinkStyle}
                    target="_blank"
                  >
                    {engagement.cohortName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    href="#"
                    underline="none"
                    color="primary"
                    style={tableLinkStyle}
                    target="_blank"
                  >
                    -
                  </Link>
                </TableCell>
                <TableCell style={{ fontStyle: 'normal' }}>
                  <Box display={'flex'} alignItems={'center'}>
                    <StarFilled />
                    <Typography sx={{ marginLeft: '0.5em' }}>0</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Link
                    href={
                      engagement.meeting
                        ? `${process.env.REACT_APP_COACHING_URL}/participant/${engagement.participant.uuid}/meeting/${engagement.meeting.uuid}/details`
                        : `${engagement.zoomSchedulerUrl || '-'}`
                    }
                    underline="none"
                    color="primary"
                    target="_blank"
                    style={tableLinkStyle}
                  >
                    {engagement.meeting
                      ? formatDate(engagement.meeting?.meetingStart)
                      : 'Add'}
                  </Link>
                </TableCell>
                <TableCell>
                  <IconButton
                    href={`${process.env.REACT_APP_COACHING_URL}/participant/${engagement.participant.uuid}/details`}
                  >
                    <UserCard style={{ color: '#209348' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ParticipantsTable;
