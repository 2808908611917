import { Box, Stack } from '@mui/material';

export default function AccountInvite(): JSX.Element {
  return (
    <Stack data-testid="account-invite" spacing={4}>
      <Box>We are so glad you are here!</Box>
      <Box>
        Effective classrooms filled with happy, high-performing students start
        with you. And with BetterLesson, you gain access to thousands of
        courses, lesson plans and instructional strategies to help support you
        in becoming the best educator you can be.
      </Box>
      <Box>Thank you for all the work you do.</Box>
    </Stack>
  );
}
