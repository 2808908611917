import { Clear, Search } from '@mui/icons-material/';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  isMobile: boolean;
  searchText: string;
  handleSearchBarFilter: (term: string) => void;
};

export const SearchBar = ({
  isMobile,
  searchText,
  handleSearchBarFilter,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>(searchText);

  useEffect(() => {
    setSearchTerm(searchText);
  }, [searchText]);

  const updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const handleClickSearch = () => handleSearchBarFilter(searchTerm);

  const handleClickClear = () => {
    setSearchTerm('');
    handleSearchBarFilter('');
  };

  return (
    <TextField
      data-testid="upcoming-workshops-search-bar"
      label="Search by workshop or session name"
      sx={{ width: isMobile ? '100%' : '25rem', my: '1rem' }}
      variant="outlined"
      value={searchTerm}
      onChange={updateSearchTerm}
      onKeyDown={(e) => e.key === 'Enter' && handleClickSearch()}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search" onClick={handleClickSearch}>
              <Search />
            </IconButton>

            {searchTerm && (
              <IconButton aria-label="clear" onClick={handleClickClear}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
