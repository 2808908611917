import { yupResolver } from '@hookform/resolvers/yup';
import LinkIcon from '@mui/icons-material/Link';
import {
  Alert,
  Backdrop,
  Button,
  Box,
  Fade,
  IconButton,
  Modal,
  OutlinedInput,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'betterlesson-library-react';
import { IdTokenClaims } from 'oidc-client-ts';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import * as yup from 'yup';
import { addUser } from '@apis/deprecated/provisioning';
import { OptionType } from '@app-types/Select';
import Checkbox from '@components/Checkbox/Checkbox';
import PartnerAccountInput from '@components/PartnerAccountInput/PartnerAccountInput';
import { ReactComponent as CloseIcon } from '@images/icons/close.svg';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import { LaunchDarklyFactory } from '@utils/LaunchDarklyFactory';
import styles from './ManageUsersAndOrgs.module.scss';
import { alertStyle, boxStyle, snackbarStyle } from './styles';

type AddUserType = {
  email: string;
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
});

export default function ManageUsersAndOrgs({
  onClose,
  isOpen,
}: {
  onClose: (refreshUsers?: boolean) => void;
  isOpen: boolean;
}): JSX.Element {
  const [account, setAccount] = useState<OptionType>();
  const [shouldSendWelcomeEmail, setShouldSendWelcomeEmail] = useState(false);
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const url = `${window.location.origin}/invite/${account?.value}`;
  const [showOrgLink, setShowOrgLink] = useState(false);
  const { user } = useAuth();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AddUserType>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchFeatureFlag = async (userData?: IdTokenClaims) => {
      const flagValue = await LaunchDarklyFactory.shouldShowOrgLink(userData);
      setShowOrgLink(flagValue);
    };

    fetchFeatureFlag(user?.profile).catch((error) => {
      console.error('Error fetching shouldShowOrgLink feature flag:', error);
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setShouldSendWelcomeEmail(false);
      setAccount(undefined);
    }
  }, [isOpen]);

  const handleModalClose = () => {
    setSnackbarIsOpen(false);
    onClose(false);
  };

  const copyLink = () => {
    if (!isSecureContext || !navigator.clipboard || !account) return;

    try {
      navigator.clipboard.writeText(url);
      toast.success('Copied to clipboard!', {
        className: 'toast-notify-success',
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleAccountChange = (
    _e: SyntheticEvent,
    option: OptionType | null
  ) => {
    if (!option) {
      setAccount(undefined);
      return;
    }

    setAccount(option);
    setSnackbarIsOpen(true);
  };

  const handleSendWelcomeEmailClick = () => {
    setShouldSendWelcomeEmail((value) => !value);
  };

  const getInvalidUserToastMessage = () => {
    return `User invalid.${
      shouldSendWelcomeEmail ? ' (Welcome email not sent!)' : ''
    }`;
  };

  const onSubmit: SubmitHandler<AddUserType> = async ({ email }) => {
    try {
      await addUser({
        userEmail: email,
        sendWelcomeEmail: shouldSendWelcomeEmail,
        organizationUUID: account?.value || undefined,
      });

      toast.success(
        `User added!${
          shouldSendWelcomeEmail ? ' (Invitation email sent!)' : ''
        }`,
        {
          className: 'toast-notify-success',
        }
      );

      onClose(true);
    } catch (e) {
      console.error(e);

      const message =
        axios.isAxiosError(e) && e?.response?.status === 400
          ? '(Email) already exists.'
          : getInvalidUserToastMessage();

      toast.error(message, {
        className: 'toast-notify-error',
      });
    }
  };

  const renderEmailError = (message?: string) =>
    message ? (
      <Typography
        data-testid="email-error"
        variant="body1"
        color="error"
        fontSize="12px"
        pt={1}
      >
        {message}
      </Typography>
    ) : null;

  return (
    <Modal
      BackdropComponent={Backdrop}
      data-testid="manage-users-orgs-modal"
      open={isOpen}
      onClose={handleModalClose}
    >
      <Fade in={isOpen}>
        <Box sx={boxStyle} p={4} pt={6}>
          <Stack alignItems="center" pb={10}>
            <IconButton
              data-testid="add-user-close-icon"
              aria-label="close"
              classes={{ root: styles.closeIconContainer }}
              style={{ position: 'absolute' }}
              onClick={handleModalClose}
            >
              <CloseIcon className={styles.closeIcon} />
            </IconButton>

            <img
              src={BetterLessonLogo}
              className={styles.logo}
              data-testid="betterlesson-logo"
              alt="BetterLesson Logo"
            />

            <Typography variant="h3" pt={3} pb={2}>
              Manage Users &amp; Orgs
            </Typography>

            <Box
              className={styles.input}
              pt={4}
              data-testid="manage-users-org-account"
            >
              <PartnerAccountInput
                label="Account Name"
                accountValue={account}
                showFilters={false}
                onAccountChange={handleAccountChange}
              />
            </Box>

            <Box className={styles.input} pt={4}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    error={!!errors.email?.message}
                    data-testid="email-input"
                    placeholder="first.name@school.edu"
                    fullWidth
                  />
                )}
              />
              {renderEmailError(errors.email?.message)}
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              pt={6}
              pb={7}
              spacing={1}
            >
              <Checkbox
                data-testid="welcome-email-checkbox"
                checked={shouldSendWelcomeEmail}
                onClick={handleSendWelcomeEmailClick}
              />

              <Typography
                id="welcome-email"
                variant="body1"
                color="var(--color-primary)"
              >
                Send Welcome Email
              </Typography>
            </Stack>

            <Box width="300px">
              <Button
                data-testid="save-users-orgs-button"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Box>

            {showOrgLink && account && (
              <Snackbar
                open={snackbarIsOpen}
                sx={{
                  '@media (min-width:0)': snackbarStyle,
                  '@media (min-width:600px)': {
                    ...snackbarStyle,
                    display: 'block',
                  },
                }}
              >
                <Alert icon={false} style={alertStyle}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Typography
                      color="var(--color-blue-caret)"
                      component="p"
                      fontSize=".875rem"
                      fontWeight="500"
                    >
                      <Button onClick={copyLink}>
                        <Typography
                          color="var(--color-blue-copy-link)"
                          component="a"
                          data-testid="share-link"
                          fontSize=".75rem"
                          fontWeight="900"
                          marginLeft=".5rem"
                        >
                          Share Link{' '}
                          <LinkIcon
                            sx={{
                              color: 'var(--color-blue-caret)',
                              mr: '.5rem',
                              transform: 'translateY(.5rem) rotate(-45deg) ',
                            }}
                          />
                          {url}
                        </Typography>
                      </Button>
                    </Typography>
                  </Stack>
                </Alert>
              </Snackbar>
            )}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}
