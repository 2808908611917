import { ParseResult } from 'papaparse';
import { Either, Left, Right } from 'purify-ts/Either';
import { Maybe, Just, Nothing } from 'purify-ts/Maybe';
import UserToProvision from '@pages/DeprecatedPages/UserManagement/components/ProvisioningBulkUpload/models/UserToProvision';

const malformedError: Error = new Error(
  'A row is malformed. Check the CSV file for errors and try again.'
);

export const validateAndTransformCSVData = (
  results: ParseResult<string[]>
): Either<Error, UserToProvision[]> => {
  if (results.data.length <= 0) {
    return Left(new Error('File returned is blank'));
  }

  const emailIndex = results.data[0].indexOf('email');
  const firstNameIndex = results.data[0].indexOf('givenName');
  const lastNameIndex = results.data[0].indexOf('familyName');

  if (emailIndex === -1 || firstNameIndex === -1 || lastNameIndex === -1) {
    return Left(
      new Error(
        'Must provide a valid csv file with the following fields: email, givenName, familyName'
      )
    );
  }

  const rowMapper = (row: string[]): Either<Error, Maybe<UserToProvision>> => {
    if (row.length === 0) {
      // Probably an empty row at the end - skip it and move on
      return Right(Nothing);
    }
    try {
      const userToProvision: UserToProvision = {
        email: row[emailIndex],
        firstName: row[firstNameIndex],
        lastName: row[lastNameIndex],
      };
      const allData =
        !!userToProvision.email &&
        !!userToProvision.firstName &&
        !!userToProvision.lastName;
      const anyData =
        !!userToProvision.email ||
        !!userToProvision.firstName ||
        !!userToProvision.lastName;
      if (!anyData) {
        // Probably an empty row at the end - skip it and move on
        return Right(Nothing);
      }
      if (allData) {
        return Right(Just(userToProvision));
      }
      return Left(malformedError);
    } catch (error) {
      return Left(malformedError);
    }
  };

  const init: Either<Error, UserToProvision[]> = Right([]);

  return results.data
    .slice(1)
    .map((row: string[]) => rowMapper(row))
    .reduce(
      (
        arrayEither: Either<Error, UserToProvision[]>,
        currentEither: Either<Error, Maybe<UserToProvision>>
      ): Either<Error, UserToProvision[]> => {
        if (arrayEither.isLeft()) {
          return arrayEither;
        }
        if (currentEither.isLeft()) {
          return currentEither;
        }
        const array = arrayEither.unsafeCoerce();
        const currentMaybe = currentEither.unsafeCoerce();
        currentMaybe.map((currentValue) => {
          array.push(currentValue);
        });
        return Right(array);
      },
      init
    );
};
