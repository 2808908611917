import axios, { AxiosInstance } from 'axios';
import { getToken } from 'betterlesson-library-react';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';

export const getServiceAxiosWithCorrelationId = (
  correlationId: string,
  overrideToken?: string
): AxiosInstance => {
  const userServiceAxios = axios.create({
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

  const accessToken = overrideToken || getToken()?.access_token;

  if (accessToken) {
    userServiceAxios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`;
  }
  userServiceAxios.defaults.headers.common['x-correlation-id'] = correlationId;

  return userServiceAxios;
};

export const getServiceAxios = (overrideToken?: string): AxiosInstance => {
  const correlationId = uuidv4();
  return getServiceAxiosWithCorrelationId(correlationId, overrideToken);
};
