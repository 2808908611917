import { LoadingPage, ToastContainer } from 'betterlesson-library-react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { userServiceForgotPassword } from '@apis/users';
import AuthenticationRequired from '@components/AuthRequired/AuthenticationRequired';
import DynamicOnboardingFeatureRequired from '@components/DynamicOnboardingFeatureRequired/DynamicOnboardingFeatureRequired';
import ExistingAuth from '@components/ExistingAuth/ExistingAuth';
import { PendoContainer } from '@components/PendoContainer/PendoContainer';
import ProfileTabs from '@components/ProfileTabs/ProfileTabs';
import UserHomepagesFeatureRequired from '@components/UserHomepagesFeatureRequired/UserHomepagesFeatureRequired';
import UserProfilesFeatureRequired from '@components/UserProfilesFeatureRequired/UserProfilesFeatureRequired';
import { ModalProvider } from '@contexts/ModalContext/ModalContext';
import AccountInvitation from '@pages/AccountInvitation/AccountInvitation';
import BasicProfile from '@pages/BasicProfile/BasicProfile';
import Clever from '@pages/Clever/Clever';
import CoachHomeDataProvider from '@pages/CoachHomeDataProvider/CoachHomeDataProvider';
import CoachingHome from '@pages/CoachingHome/CoachingHome';
import EducatorProfile from '@pages/EducatorProfile/EducatorProfile';
import ForgotPassword from '@pages/ForgotPassword/ForgotPassword';
import UserServiceLogin from '@pages/Login/UserServiceLogin';
import NavigateHome from '@pages/NavigateHome/NavigateHome';
import ParticipantDataProvider from '@pages/ParticipantDataProvider/ParticipantDataProvider';
import ProfessionalRoles from '@pages/ProfessionalRoles/ProfessionalRoles';
import UserServiceRegister from '@pages/Register/UserServiceRegister';
import ResetPassword from '@pages/ResetPassword/ResetPassword';
import ServiceHandler from '@pages/ServiceHandler/ServiceHandler';
import UnauthorizedPage from '@pages/UnauthorizedPage/UnauthorizedPage';
import WorkshopsHome from '@pages/WorkshopsHome/WorkshopsHome';
import deprecatedRoutes from './routes/DeprecatedRoutes';

function App(): JSX.Element {
  const { isLoading, events, user, startSilentRenew } = useAuth();

  useEffect(() => {
    return events.addAccessTokenExpiring(startSilentRenew);
  }, [events, startSilentRenew]);

  if (isLoading || user?.expired) {
    return <LoadingPage data-testid="app-loading-page" />;
  }

  return (
    <ModalProvider>
      <BrowserRouter>
        <PendoContainer />
        <Routes>
          <Route element={<ExistingAuth />}>
            <Route path="/login" element={<UserServiceLogin />} />

            <Route path="/register" element={<UserServiceRegister />} />
            <Route
              path="/forgot-password"
              element={
                <ForgotPassword onForgotPassword={userServiceForgotPassword} />
              }
            />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>

          <Route
            path="/invite"
            element={
              <DynamicOnboardingFeatureRequired>
                <AccountInvitation />
              </DynamicOnboardingFeatureRequired>
            }
          />

          <Route element={<AuthenticationRequired />}>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            <Route path="/professional-roles" element={<ProfessionalRoles />} />

            <Route
              path="/service-handler"
              element={
                <DynamicOnboardingFeatureRequired>
                  <ServiceHandler />
                </DynamicOnboardingFeatureRequired>
              }
            />

            <Route path="/home/:userUuid" element={<NavigateHome />} />

            <Route
              path="/profile"
              element={
                <UserProfilesFeatureRequired>
                  <ProfileTabs />
                </UserProfilesFeatureRequired>
              }
            >
              <Route path="" element={<BasicProfile />} />
              <Route path="educator" element={<EducatorProfile />} />
            </Route>

            <Route
              path="/participant/home"
              element={
                <Navigate
                  to={`/participant/${user?.profile.sub}/home`}
                  replace
                />
              }
            />

            <Route
              path="/participant/:participantUuid/home"
              element={
                <DynamicOnboardingFeatureRequired>
                  <ParticipantDataProvider />
                </DynamicOnboardingFeatureRequired>
              }
            />

            {['home', 'home/coaching', 'home/workshops'].map((path) => (
              <Route
                key={path}
                path={`/coach/${path}`}
                element={
                  <Navigate
                    to={`/coach/${user?.profile.sub}/${path}`}
                    replace
                  />
                }
              />
            ))}

            <Route
              path="/coach/:coachUuid/home"
              element={
                <UserHomepagesFeatureRequired>
                  <CoachHomeDataProvider />
                </UserHomepagesFeatureRequired>
              }
            >
              {['', 'coaching'].map((path) => (
                <Route key={path} path={path} element={<CoachingHome />} />
              ))}

              <Route path="workshops" element={<WorkshopsHome />} />
            </Route>
          </Route>

          <Route path="/clever" element={<Clever />} />

          {deprecatedRoutes}
        </Routes>

        <ToastContainer />
      </BrowserRouter>
    </ModalProvider>
  );
}

export default App;
