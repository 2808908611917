const LAB_REDIRECT_PATH_KEY = 'lab_redirect_path';
const LAB_REDIRECT_URL_PATH_KEY = 'lab_redirect_url_path';

const TEACHING_URL_PATH_PREFIXES = Object.freeze([
  '/browse',
  '/lesson',
  '/search',
  '/strategy',
]);

export const LAB_SIGN_IN_REDIRECT_ARGS = Object.freeze({
  redirect_uri: `${window.location.origin}/lab`,
  extraQueryParams: { base_route: '/lab' },
});

export const initializeLabRedirectUrl = (labRedirectUrl: string): void => {
  window.sessionStorage.setItem(LAB_REDIRECT_URL_PATH_KEY, labRedirectUrl);
};

export const initializeLabRedirectPathFromSearchParams = (): void => {
  const searchParams = new URLSearchParams(window.location.search);
  const labRedirect = searchParams.get('redirect');
  const labRedirectUrl = searchParams.get('redirect_url');

  if (labRedirect && !window.sessionStorage.getItem(LAB_REDIRECT_PATH_KEY)) {
    window.sessionStorage.setItem(LAB_REDIRECT_PATH_KEY, labRedirect);
  }

  if (
    labRedirectUrl &&
    !window.sessionStorage.getItem(LAB_REDIRECT_URL_PATH_KEY)
  ) {
    window.sessionStorage.setItem(LAB_REDIRECT_URL_PATH_KEY, labRedirectUrl);
  }
};

const buildRedirectUrl = (labRedirectUrlPath: string) => {
  const shouldRedirectToTeaching = TEACHING_URL_PATH_PREFIXES.some(
    (teachingUrlPathPrefix) =>
      labRedirectUrlPath.startsWith(teachingUrlPathPrefix)
  );

  const baseUrl = shouldRedirectToTeaching
    ? process.env.REACT_APP_TEACHING_URL
    : process.env.REACT_APP_LAB_URL;

  return `${baseUrl}${labRedirectUrlPath}`;
};

export const hasStoredRedirect = (): boolean => {
  return (
    !!window.sessionStorage.getItem(LAB_REDIRECT_PATH_KEY) ||
    !!window.sessionStorage.getItem(LAB_REDIRECT_URL_PATH_KEY)
  );
};

const _redirectToLab = (labRedirectUrlPath: string): void => {
  const labRedirectUrl = buildRedirectUrl(labRedirectUrlPath);
  window.location.assign(labRedirectUrl);
};

const _isValidRedirectUrl = (redirectUrl: string): boolean => {
  const url = new URL(redirectUrl);

  if (location.hostname.endsWith('bl.com') && url.hostname.endsWith('bl.com')) {
    return true;
  }

  if (url.hostname.endsWith('betterlesson.com')) {
    return true;
  }

  return false;
};

export const redirectToLab = (defaultPath = ''): void => {
  const storedRedirectPath = window.sessionStorage.getItem(
    LAB_REDIRECT_PATH_KEY
  );

  const storedRedirectUrl = window.sessionStorage.getItem(
    LAB_REDIRECT_URL_PATH_KEY
  );

  window.sessionStorage.removeItem(LAB_REDIRECT_PATH_KEY);
  window.sessionStorage.removeItem(LAB_REDIRECT_URL_PATH_KEY);

  if (storedRedirectUrl && _isValidRedirectUrl(storedRedirectUrl)) {
    window.location.assign(storedRedirectUrl);
    return;
  }

  _redirectToLab(storedRedirectPath || defaultPath);
};
