import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ReactComponent as InvisibleIcon } from '@images/icons/invisible.svg';
import { ReactComponent as VisibleIcon } from '@images/icons/visible.svg';
import { onEnterKey } from '@utils/key-handler';
import styles from './PasswordInput.module.css';

type Props = {
  register: UseFormRegisterReturn;
  placeholder: string;
  error: string | boolean | undefined;
};

export default function PasswordInput({
  register,
  placeholder,
  error,
}: Props): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const renderErrorField = () => {
    if (typeof error === 'boolean') {
      return null;
    }

    return error ? (
      <span className={styles.errorText} data-testid={`${register.name}-error`}>
        {error}
      </span>
    ) : (
      <div data-testid={`${register.name}-spacer`} style={{ height: '25px' }} />
    );
  };

  const renderVisibilityIcon = () =>
    showPassword ? (
      <InvisibleIcon
        className={styles.icon}
        data-testid={`${register.name}-invisible-icon`}
        tabIndex={0}
        onClick={() => setShowPassword(false)}
        onKeyDown={onEnterKey(() => setShowPassword(false))}
      />
    ) : (
      <VisibleIcon
        className={styles.icon}
        data-testid={`${register.name}-visible-icon`}
        tabIndex={0}
        onClick={() => setShowPassword(true)}
        onKeyDown={onEnterKey(() => setShowPassword(true))}
      />
    );

  return (
    <div className={styles.inputContainer}>
      <div
        className={`
        ${styles.inputContent} 
        ${isFocused && styles.focusInput}
        ${error && styles.errorInput} 
        `}
      >
        <input
          {...register}
          id={register.name}
          className={styles.input}
          data-testid={`${register.name}-input`}
          placeholder={placeholder}
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {renderVisibilityIcon()}
      </div>
      {renderErrorField()}
    </div>
  );
}
