import { ErrorRounded } from '@mui/icons-material';
import { Link, Stack, Tooltip } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { ModificationLevel, SessionGroupType } from '@app-types/Workshop';
import { ReactComponent as DocumentError } from '@images/icons/document-error.svg';
import { ReactComponent as DocumentSettings } from '@images/icons/document-settings.svg';

export type Props = {
  sessionGroup: SessionGroupType;
};

export const TEST_ID = {
  WORKSHOP_DETAIL_ICON: 'workshop-status-icons-workshop-detail',
  FACILITATOR_ACTION_ICON: 'workshop-status-icons-facilitator-action',
  MATERIAL_PERSONALIZATION_ICON:
    'workshop-status-icons-material-personalization',
};

type StatusIconType = {
  icon: JSX.Element;
  tooltip: string;
  dataTestId: string;
  shouldDisplay: () => boolean;
};

export default function WorkshopStatusIcons({
  sessionGroup,
}: Props): JSX.Element {
  const STATUS_ICON: Record<string, StatusIconType> = Object.freeze({
    WORKSHOP_DETAIL: {
      icon: <DocumentError />,
      tooltip: 'Workshop Details have been updated!',
      dataTestId: TEST_ID.WORKSHOP_DETAIL_ICON,
      shouldDisplay: () => sessionGroup.isWorkshopDetailsUpdated,
    },
    FACILITATOR_ACTION: {
      icon: <ErrorRounded sx={{ color: COLOR_PALETTE.error.main }} />,
      tooltip: 'Facilitator Action needed!',
      dataTestId: TEST_ID.FACILITATOR_ACTION_ICON,
      shouldDisplay: () =>
        sessionGroup.allModifications.some(
          (m) => m.modificationLevel === ModificationLevel.FACILITATOR_ACTION
        ) && !sessionGroup.isReadyForDelivery,
    },
    MATERIAL_PERSONALIZATION: {
      icon: <DocumentSettings />,
      tooltip: 'Materials personalization needed!',
      dataTestId: TEST_ID.MATERIAL_PERSONALIZATION_ICON,
      shouldDisplay: () => !sessionGroup.isReadyForDelivery,
    },
  });

  const renderStatusIcon = (statusIcon: StatusIconType) => {
    if (!statusIcon.shouldDisplay()) {
      return <></>;
    }

    return (
      <Tooltip arrow tabIndex={0} title={statusIcon.tooltip}>
        <Link
          data-testid={statusIcon.dataTestId}
          href={`${process.env.REACT_APP_WORKSHOP_WEB_URL}/${sessionGroup.workshopUuid}`}
          target="_blank"
        >
          {statusIcon.icon}
        </Link>
      </Tooltip>
    );
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {renderStatusIcon(STATUS_ICON.WORKSHOP_DETAIL)}
      {renderStatusIcon(STATUS_ICON.FACILITATOR_ACTION)}
      {renderStatusIcon(STATUS_ICON.MATERIAL_PERSONALIZATION)}
    </Stack>
  );
}
