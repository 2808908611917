import equal from 'fast-deep-equal';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { IdTokenClaims } from 'oidc-client-ts';

export class LaunchDarklyFactory {
  // Lifecycle methods
  private static instance: LDClient.LDClient | null;
  private static anonymousUser = { kind: 'user', anonymous: true };

  private static initializeLDClient = (
    profile?: IdTokenClaims
  ): LDClient.LDClient => {
    let context: LDClient.LDContext;
    if (profile) {
      context = LaunchDarklyFactory.buildLoggedInUserContextForLD(profile);
    } else {
      context = LaunchDarklyFactory.anonymousUser;
    }

    const clientId: string =
      process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '';
    return LDClient.initialize(clientId, context);
  };

  private static getLDClient = async (context?: IdTokenClaims) => {
    if (!this.instance) {
      this.instance = this.initializeLDClient(context);
      return this.instance;
    }

    await LaunchDarklyFactory.identifyUpdatedContext(context);
    return this.instance;
  };

  private static SHARE_ORG_LINK_TOGGLE_FLAG = {
    key: 'share-org-link-toggle',
    fallback: false,
  };

  private static DYNAMIC_ONBOARDING_FLAG = {
    key: 'dynamic-onboarding',
    fallback: false,
  };

  private static USER_PROFILES_FLAG = {
    key: 'user-profiles',
    fallback: false,
  };

  private static USER_HOMEPAGES_FLAG = {
    key: 'user-homepages',
    fallback: false,
  };

  private static identifyUpdatedContext = (context?: IdTokenClaims) => {
    if (!this.instance) {
      return;
    }

    const newContext = context
      ? LaunchDarklyFactory.buildLoggedInUserContextForLD(context)
      : LaunchDarklyFactory.anonymousUser;

    const previousContext = this.instance.getContext();

    if (equal(newContext, previousContext)) {
      return;
    }

    return this.instance.identify(newContext);
  };

  private static buildLoggedInUserContextForLD = (context?: IdTokenClaims) => {
    const ldContext: LDClient.LDContext = {
      email: context?.email,
      key: context?.sub,
      firstName: context?.given_name,
      lastName: context?.family_name,
      kind: 'user',
    };
    return ldContext;
  };

  public static shouldShowOrgLink = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    const flag = LaunchDarklyFactory.SHARE_ORG_LINK_TOGGLE_FLAG;
    if (!context) return flag.fallback;
    const client: LDClient.LDClient = await LaunchDarklyFactory.getLDClient(
      context
    );
    try {
      await client.waitForInitialization();
      return client.variation(flag.key, flag.fallback) as boolean;
    } catch (err) {
      return flag.fallback;
    }
  };

  public static hasDynamicOnboarding = async (): Promise<boolean> => {
    const flag = LaunchDarklyFactory.DYNAMIC_ONBOARDING_FLAG;
    const client: LDClient.LDClient = await LaunchDarklyFactory.getLDClient();
    try {
      await client.waitForInitialization();
      return client.variation(flag.key, flag.fallback) as boolean;
    } catch (err) {
      return flag.fallback;
    }
  };

  public static hasUserProfiles = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    const flag = LaunchDarklyFactory.USER_PROFILES_FLAG;
    if (!context) return flag.fallback;
    const client: LDClient.LDClient = await LaunchDarklyFactory.getLDClient(
      context
    );
    try {
      await client.waitForInitialization();
      return client.variation(flag.key, flag.fallback) as boolean;
    } catch (err) {
      return flag.fallback;
    }
  };

  public static hasUserHomepages = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    const flag = LaunchDarklyFactory.USER_HOMEPAGES_FLAG;
    if (!context) return flag.fallback;
    const client: LDClient.LDClient = await LaunchDarklyFactory.getLDClient(
      context
    );
    try {
      await client.waitForInitialization();
      return client.variation(flag.key, flag.fallback) as boolean;
    } catch (err) {
      return flag.fallback;
    }
  };

  public static closeLDClient = async (): Promise<void> => {
    if (this.instance) {
      await this.instance.close();
      this.instance = null;
    }
  };
}
