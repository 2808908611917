import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useState } from 'react';
import { removeOrganizations } from '@apis/deprecated/organizations';
import { Organization } from '@apis/deprecated/organizations';
import UserIcon from '@components/UserIcon/UserIcon';
import { MEMBER_ROLE } from '@constants/memberships';
import { UserRow } from '../../Types';
import styles from './RemoveOrganzationModal.module.scss';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  width: 500,
  p: 4,
};

type Props = {
  currentUser?: UserRow;
  modalOpen: boolean;
  toggleModal: (show: boolean) => void;
};

export default function RemoveOrganizationModal({
  currentUser,
  modalOpen,
  toggleModal,
}: Props): JSX.Element {
  const [checked, setChecked] = useState<Organization[]>([]);

  const handleClose = () => {
    toggleModal(false);
    setChecked([]);
  };

  const handleToggle = (value: Organization) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const isCheckable =
      value.membership?.memberRole !== MEMBER_ROLE.PARTNER_LEADER;

    if (!isCheckable) {
      return;
    }

    if (currentIndex < 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const renderList = () => {
    return (
      <List disablePadding>
        {currentUser?.organizations.map((org) => {
          const labelId = `checkbox-list-label-${org.identifier}`;
          const isDisabled =
            org.membership?.memberRole === MEMBER_ROLE.PARTNER_LEADER;

          return (
            <ListItem
              disablePadding
              key={org.identifier}
              data-testid="remove-organization-modal-organizations"
            >
              <ListItemButton
                data-testid="remove-organization-modal-button"
                onClick={handleToggle(org)}
                dense
                disabled={isDisabled}
                style={{ opacity: 1 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(org) !== -1}
                    data-testid="org-checkbox"
                    inputProps={{ 'aria-labelledby': labelId }}
                    indeterminate={isDisabled}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <Stack pt={isDisabled ? 2.5 : 0}>
                  <ListItemText
                    id={labelId}
                    primary={org.identifier}
                    sx={{ m: 0 }}
                  />

                  {isDisabled && (
                    <FormHelperText disabled sx={{ m: 0 }}>
                      Leader for orgs cannot be removed
                    </FormHelperText>
                  )}
                </Stack>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const removeAccount = async () => {
    if (!currentUser) return;

    const response = await removeOrganizations(
      currentUser.userId,
      checked.map((org: Organization) => org.uuid)
    );

    if (!response) {
      toast.error('Accounts not removed! Please try again.', {
        className: 'toast-notify-error',
      });
    } else {
      toast.success('Accounts successfully removed!', {
        className: 'toast-notify-success',
      });

      const newOrgs = currentUser.organizations.filter(
        (org) => !checked.includes(org)
      );

      currentUser.organizations = [...newOrgs];
      toggleModal(false);
    }
  };

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className="text-center"
      data-testid="remove-modal"
      open={modalOpen}
      onClose={handleClose}
    >
      <Fade in={modalOpen}>
        <Box sx={modalStyle}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <UserIcon
                className={styles.userIcon}
                givenName={currentUser?.givenName}
                familyName={currentUser?.familyName}
              />

              <Typography paddingTop={2} variant="h3" tabIndex={0}>
                Remove Account
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" tabIndex={0}>
                {currentUser?.givenName} {currentUser?.familyName}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {renderList()}
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                className="w-100"
                data-testid="cancel-button"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                className="w-100"
                color="error"
                data-testid="remove-button"
                disabled={!checked.length}
                variant="contained"
                onClick={removeAccount}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
