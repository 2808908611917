import { Typography } from '@mui/material';
import { MultipleFieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import EntryFormContainer from '@components/EntryFormContainer/EntryFormContainer';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import PasswordInput from '@pages/Register/components/RegisterView/components/PasswordInput/PasswordInput';
import PasswordRequirements from '@pages/Register/components/RegisterView/components/PasswordRequirements/PasswordRequirements';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';
import styles from './ResetPasswordView.module.scss';

type Props = {
  email: string;
  loading: boolean;
  passwordRegister: UseFormRegisterReturn;
  passwordError: boolean;
  confirmPasswordRegister: UseFormRegisterReturn;
  confirmPasswordError?: string;
  password: string;
  requirements?: MultipleFieldErrors;
  isSubmitted: boolean;
  displayMainError: boolean;
  onSubmit: () => void;
};

export default function SetPasswordView({
  email,
  loading,
  passwordRegister,
  passwordError,
  confirmPasswordRegister,
  confirmPasswordError,
  password,
  requirements,
  isSubmitted,
  displayMainError,
  onSubmit,
}: Props): JSX.Element {
  const renderMainError = () =>
    displayMainError ? (
      <div className={styles.errorText} data-testid="reset-error">
        Please enter required fields
      </div>
    ) : (
      <div data-testid="register-spacer" style={{ height: '50px' }} />
    );

  const renderResetPWHeader = () => (
    <>
      <div className={styles.headerText}>Set Your Password</div>
      <div className={styles.subHeaderText}>{email}</div>
      {renderMainError()}
    </>
  );

  const renderResetPWForm = () => (
    <form className={styles.form}>
      <PasswordInput
        register={passwordRegister}
        placeholder="New Password"
        error={passwordError}
      />
      <PasswordRequirements
        isSubmitted={isSubmitted}
        password={password}
        requirements={requirements}
      />
      <PasswordInput
        register={confirmPasswordRegister}
        placeholder="Confirm Password"
        error={confirmPasswordError}
      />
      <div className={styles.resetButton}>
        <LoadingButton
          // disabled={!displayMainError}
          color="secondary"
          variant="contained"
          data-testid="reset-button"
          onClick={onSubmit}
          fullWidth
          isLoading={loading}
          text="Set password"
          loadingText="Setting password"
          sx={CONTAINED_GRADIENT_STYLE}
        />
      </div>
      <Typography marginTop={7}>Having trouble with this?</Typography>

      <Typography
        variant="body2"
        component="a"
        color="var(--color-primary)"
        fontSize={14}
        href="https://betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4"
        rel="noreferrer"
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        Contact our support team.
      </Typography>
      {/* <div className={styles.codeLink} style={{ paddingBottom: '20px' }}>
        <Link
          data-testid="forgot-btn"
          to={buildEntryFormUrl('/forgot-password')}
        >
          Send me another code
        </Link>
      </div> */}
    </form>
  );

  return (
    <EntryFormContainer dataTestId="set-password-form">
      {renderResetPWHeader()}
      {renderResetPWForm()}
    </EntryFormContainer>
  );
}
