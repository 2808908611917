import { Stack } from '@mui/material';
import WorkshopsTable from './components/WorkshopsTable/WorkshopsTable';

export default function WorkshopsHome(): JSX.Element {
  return (
    <Stack data-testid="workshops-home" flexGrow={1} ml="1rem" overflow="auto">
      <WorkshopsTable />
    </Stack>
  );
}
