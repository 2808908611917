import React, { useEffect, useState } from 'react';
import { SingleValueProps } from 'react-select';
import { v4 as uuid } from 'uuid';
import { OptionType } from '@app-types/Select';
import { ReactComponent as CloseIcon } from '@images/icons/close.svg';
import { onEnterKey } from '@utils/key-handler';
import styles from './SingleValue.module.css';

const CLOSE_ICON_WIDTH = 22;
const ELLIPSIS_WIDTH = 32;

export default function SingleValue({
  data,
  clearValue,
}: SingleValueProps<OptionType, false>): JSX.Element {
  const { label } = data;
  const [ellipsisLabel, setEllipsisLabel] = useState('');
  const CONTAINER_ID = `single-value-container-${uuid()}`;

  const handleCloseIconClick = () => {
    clearValue();
  };

  const handleCloseIconMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const getLabelWidth = () => {
    const span = document.createElement('span');
    span.classList.add(styles.text);
    span.style.position = 'absolute';
    span.style.visibility = 'hidden';
    span.style.top = '0px';
    span.style.padding = '0px';
    document.body.appendChild(span);
    span.innerText = label;
    const labelWidth = span?.clientWidth || 0;
    document.body.removeChild(span);
    return labelWidth;
  };

  const getEllipsisLabel = (labelWidth: number, containerWidth: number) => {
    const letterWidth = Math.ceil(labelWidth / label.length);

    const totalLetterCount = Math.floor(
      (containerWidth - ELLIPSIS_WIDTH - CLOSE_ICON_WIDTH) / letterWidth
    );

    const firstHalf = label.slice(0, Math.ceil(totalLetterCount / 2));
    const lastHalf = label.slice(
      label.length - Math.floor(totalLetterCount / 2)
    );

    return `${firstHalf}...${lastHalf}`;
  };

  const setLabel = () => {
    const container = document.getElementById(CONTAINER_ID);
    const containerWidth = container?.clientWidth || 0;
    const labelWidth = getLabelWidth();

    if (labelWidth + CLOSE_ICON_WIDTH < containerWidth) {
      setEllipsisLabel(label);
      return;
    }

    const newLabel = getEllipsisLabel(labelWidth, containerWidth);
    setEllipsisLabel(newLabel);
  };

  useEffect(() => {
    setLabel();
  }, [data]);

  useEffect(() => {
    setLabel();
    window.addEventListener('resize', setLabel);
    return () => window.removeEventListener('resize', setLabel);
  }, [ellipsisLabel]);

  return (
    <div
      id={CONTAINER_ID}
      className={styles.container}
      data-testid="single-value"
    >
      <div className={styles.content}>
        <div className={styles.text}>{ellipsisLabel}</div>
        <div // eslint-disable-line  jsx-a11y/no-static-element-interactions
          className={styles.removeIconContainer}
          data-testid="single-value-close-icon"
          onClick={handleCloseIconClick}
          onMouseDown={handleCloseIconMouseDown}
          onKeyDown={onEnterKey(handleCloseIconClick)}
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        >
          <CloseIcon className={styles.removeIcon} />
        </div>
      </div>
    </div>
  );
}
