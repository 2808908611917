import { getToken, toast } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { impersonateUser } from '@apis/users';
import { AccessToken } from '@app-types/Token';

class ImpersonatedUser {
  accessToken: string;
  userSub: string;
  invalidReason: string | null;

  constructor(
    accessToken: string,
    userSub: string,
    invalidReason: string | null = null
  ) {
    this.accessToken = accessToken;
    this.userSub = userSub;
    this.invalidReason = invalidReason;
  }

  public isInvalid() {
    return !!this.invalidReason;
  }
}

export class ImpersonateFactory {
  public static INVALID_REASON = Object.freeze({
    NO_AUTHENTICATION: 'User is not authenticated.',
    NO_AUTHORIZATION: 'User is not allowed to impersonate other users.',
    FAILED_REQUEST: 'Failed to fetch user tokens.',
  });

  public static async impersonateUser(
    contextUserUuid: string
  ): Promise<ImpersonatedUser> {
    const loggedInToken = getToken();

    if (!loggedInToken) {
      return new ImpersonatedUser(
        '',
        contextUserUuid,
        this.INVALID_REASON.NO_AUTHENTICATION
      );
    }

    const { product_roles, sub } = jwtDecode<AccessToken>(
      loggedInToken.access_token
    );

    if (sub === contextUserUuid) {
      return new ImpersonatedUser(loggedInToken.access_token, contextUserUuid);
    }

    if (!this.canImpersonateUser(product_roles)) {
      return new ImpersonatedUser(
        '',
        contextUserUuid,
        this.INVALID_REASON.NO_AUTHORIZATION
      );
    }

    const tokens = await impersonateUser(contextUserUuid);

    if (!tokens) {
      return new ImpersonatedUser(
        '',
        contextUserUuid,
        this.INVALID_REASON.FAILED_REQUEST
      );
    }

    return new ImpersonatedUser(tokens.access_token, contextUserUuid);
  }

  private static canImpersonateUser = (
    productRoles: Record<string, string[]>
  ): boolean => {
    const labProduct = productRoles['LAB'];
    const userManagementProduct = productRoles['USER_MANAGEMENT'];

    const isInternal = labProduct && labProduct.includes('internal');
    const isCoach = labProduct && labProduct.includes('coach');
    const isSuperAdmin =
      userManagementProduct && userManagementProduct.includes('super_admin');

    return isCoach || isInternal || isSuperAdmin;
  };
}

export const useImpersonatedUserErrorHandler = (): ((
  errorReason: string | null
) => void) => {
  const navigate = useNavigate();

  const handleImpersonatedUserError = (errorReason: string | null) => {
    switch (errorReason) {
      case ImpersonateFactory.INVALID_REASON.FAILED_REQUEST:
      case ImpersonateFactory.INVALID_REASON.NO_AUTHENTICATION:
        return toast.error(errorReason);

      case ImpersonateFactory.INVALID_REASON.NO_AUTHORIZATION:
        navigate('/unauthorized');
        return toast.error(errorReason);
    }
  };

  return handleImpersonatedUserError;
};
