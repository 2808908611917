import jwtDecode from 'jwt-decode';
import { User } from 'oidc-client-ts';
import { getUsersProfessionalRole } from '@apis/professional-roles';
import { AccessToken } from '@app-types/Token';
import { isDevEmailForTesting } from '@utils/DevUtil';
import { redirectToLab } from '@utils/LabRedirectPath';
import { redirectToHome } from './HomePageHandler';

export const getProfessionalRolePageUrl = (): string => {
  return `${process.env.REACT_APP_USER_MANAGEMENT_URL}/professional-roles`;
};

const redirectToProfessionalRolePage = (): void => {
  window.location.assign(getProfessionalRolePageUrl());
};

export const redirectToLabRedirectPage = (): void => {
  window.location.assign('/lab');
};

export const shouldCheckProfessionalRoleRedirect = (user?: User): boolean => {
  if (!user?.profile?.email) {
    return false;
  }

  return !isDevEmailForTesting(user.profile.email);
};

export const handleRedirectAfterLogin = async (
  user?: User | null | undefined,
  skipProfessionalRoleChecks = false
): Promise<void> => {
  const profile = user?.profile;

  if (!profile) {
    redirectToLab();
    return;
  }
  if (
    !skipProfessionalRoleChecks &&
    shouldCheckProfessionalRoleRedirect(user)
  ) {
    try {
      const validProfessionalRolesData = await getUsersProfessionalRole(
        profile.sub
      );

      if (!validProfessionalRolesData) {
        redirectToProfessionalRolePage();
        return;
      }
    } catch (e) {
      console.error('error evaluating redirect path:', e);
    }
  }

  const token = user?.access_token
    ? jwtDecode<AccessToken>(user?.access_token)
    : undefined;

  redirectToHome(token);
};
