import { LoadingPage, setToken } from 'betterlesson-library-react';
import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useSearchParams } from 'react-router-dom';
import { authenticateWithRefreshToken } from '@apis/auth';
import usePendoStatus from '@hooks/usePendoStatus';
import { redirectAuthenticatedUser } from '@utils/Redirect';

const PENDO_WAIT_TIMEOUT = 5000;

export default function ExistingAuth(): JSX.Element {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const { isPendoLoaded } = usePendoStatus();
  const isAuthenticating = useRef(false);

  const silentlyAuthenticate = async () => {
    if (
      !user?.refresh_token ||
      !searchParams.has('sessionId') ||
      isAuthenticating.current
    ) {
      return;
    }

    isAuthenticating.current = true;
    const { session, parentToken } = await authenticateWithRefreshToken(
      user.refresh_token
    );

    setToken({
      id_token: user.id_token || '',
      access_token: user.access_token,
      refresh_token: parentToken,
    });

    redirectAuthenticatedUser(session);
    isAuthenticating.current = false;
  };

  useEffect(() => {
    setTimeout(() => {
      silentlyAuthenticate();
    }, PENDO_WAIT_TIMEOUT);
  }, []);

  useEffect(() => {
    if (isPendoLoaded) {
      silentlyAuthenticate();
    }
  }, [isPendoLoaded]);

  if (user?.refresh_token && searchParams.has('sessionId')) {
    return <LoadingPage data-testid="existing-auth-loading-page" />;
  }

  return <Outlet />;
}
