import { AxiosResponse } from 'axios';
import { FacilitatorWorkshopType } from '@app-types/Facilitator';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_WORKSHOP_SERVICE_URL}/api/v2/facilitators`;

export const getFacilitatorWorkshops = async (
  accessToken?: string
): Promise<FacilitatorWorkshopType[]> => {
  try {
    const response: AxiosResponse = await getServiceAxios(accessToken).post(
      `${BASE_ROUTE}/queries/find-by-user`
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
