import { FocusAreaType } from '@app-types/Invite';
import FocusAreaPopover from './FocusAreaPopover';

type Props = {
  focusAreas: FocusAreaType[];
};

export default function FocusAreaPopovers({ focusAreas }: Props): JSX.Element {
  return (
    <>
      {focusAreas.map((focusArea) => {
        return (
          <FocusAreaPopover focusArea={focusArea} key={focusArea.quickbaseId} />
        );
      })}
    </>
  );
}
