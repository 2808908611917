import { LoadingPage, toast } from 'betterlesson-library-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { clickOrderProductLink } from '@apis/deprecated/ActivateSeatSet';

export default function RegisterBLConnect(): JSX.Element {
  const { Id } = useParams();

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    clickOrderProductLink(Id!)
      .then((redirectURI) => {
        const url = new URL(redirectURI);
        window.location.assign(url.toString());
      })
      .catch((e) => {
        console.error(e);
        toast.error('Something went wrong', {
          className: 'toast-notify-error',
        });
        setTimeout(() => `${process.env.REACT_APP_LAB_URL}/lab/home`, 5_000);
      });
  }, []);

  return <LoadingPage data-testid="register-order-loading-page" />;
}
