import { Stack, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAccountName } from '@apis/accounts';
import { getInvite } from '@apis/invites';
import { AccountType } from '@app-types/Account';
import { CoachingPropsType, InviteData } from '@app-types/Invite';
import { SERVICE_TYPE } from '@constants/product';
import AccountRegistration from '@pages/AccountRegistration/AccountRegistration';
import CoachingRegistrationContainer from '@pages/CoachingRegistrationContainer/CoachingRegistrationContainer';
import WorkshopRegistration from '@pages/WorkshopRegistration/WorkshopRegistration';

export default function ServiceHandler(): JSX.Element {
  const [searchParams] = useSearchParams();
  const accountUuid = searchParams.get('accountUuid') ?? '';
  const serviceUuid = searchParams.get('serviceUuid') ?? '';
  const serviceType = (searchParams.get('serviceType') ?? '').toUpperCase();

  const [service, setService] = useState<InviteData | null>();
  const [account, setAccount] = useState<AccountType | null>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchServiceTypes = async () => {
    const invite = await getInvite(serviceUuid, serviceType);
    const account = await getAccountName(invite?.accountUuid || accountUuid);

    if (!invite && !account) {
      return window.location.replace(`${process.env.REACT_APP_LAB_URL}/404`);
    }

    setService(invite);
    setAccount(account);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchServiceTypes();
  }, []);

  const renderServiceHandler = () => {
    switch (service?.serviceType) {
      case SERVICE_TYPE.COACHING:
        return (
          <CoachingRegistrationContainer
            serviceUuid={serviceUuid}
            focusAreas={
              (service.inviteSpecificProps as CoachingPropsType)
                .cohortFocusAreas ?? []
            }
          />
        );
      case SERVICE_TYPE.WORKSHOP:
        return <WorkshopRegistration serviceUuid={serviceUuid} />;
      default:
        return <AccountRegistration account={account} />;
    }
  };

  if (isLoading) {
    return (
      <Stack
        data-testid="service-handler-page-loading"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  return <>{renderServiceHandler()}</>;
}
