import { Stack, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

interface WorkshopRegistrationProps {
  serviceUuid: string;
}

const WorkshopRegistration: React.FC<WorkshopRegistrationProps> = ({
  serviceUuid,
}) => {
  const acceptWorkshopInvite = async () => {
    return window.location.replace(
      `${process.env.REACT_APP_WORKSHOP_WEB_URL}/${serviceUuid}/confirm`
    );
  };

  useEffect(() => {
    acceptWorkshopInvite();
  }, [serviceUuid]);

  return (
    <Stack
      data-testid="workshop-registration"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress size={80} />
    </Stack>
  );
};

export default WorkshopRegistration;
