import { Tabs, Tab, Container, CircularProgress, Stack } from '@mui/material';
import { COLOR, toast } from 'betterlesson-library-react';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getAccountsForParticipant } from '@apis/account-participants';
import { getUsersProfessionalRole } from '@apis/professional-roles';
import { getUser } from '@apis/users';
import { AccountParticipantType } from '@app-types/Account';
import { PROFILE_EMPTY_STATE, profileAtom } from '@atoms/profile';
import ContactSupport from '@components/ContactSupport/ContactSupport';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';

export default function ProfileTabs(): JSX.Element {
  const { user } = useAuth();
  const userSub = user?.profile.sub ?? '';

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const setProfile = useSetAtom(profileAtom);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  const fetchAccount = async (): Promise<AccountParticipantType | null> => {
    const accounts = await getAccountsForParticipant(userSub);
    return accounts[0] ?? null;
  };

  const fetchProfile = async () => {
    const [professionalRole, userProfile, account] = await Promise.all([
      getUsersProfessionalRole(userSub),
      getUser(),
      fetchAccount(),
    ]);

    if (!userProfile) {
      return toast.error('Failed to fetch profile.', {
        className: 'toast-notify-error',
      });
    }

    const {
      zoneinfo,
      givenName,
      familyName,
      zoomSchedulerUrl,
      ...coachingPreferences
    } = userProfile;

    setProfile({
      account: account ?? PROFILE_EMPTY_STATE.account,
      basicInfo: { zoneinfo, givenName, familyName, zoomSchedulerUrl },
      coachingPreferences: { ...coachingPreferences, zoneinfo },
      professionalRole:
        professionalRole ?? PROFILE_EMPTY_STATE.professionalRole,
    });

    setIsLoading(false);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const renderContent = () =>
    isLoading ? (
      <Stack
        data-testid="professional-tabs-loading"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress size={80} />
      </Stack>
    ) : (
      <Outlet />
    );

  return (
    <NavigationContainer>
      <Container sx={{ pt: 2 }} data-testid="profile-tabs">
        <Tabs
          value={location.pathname}
          onChange={handleChange}
          sx={{
            '.MuiTab-root.Mui-selected': {
              color: COLOR.blue[700],
            },
          }}
          TabIndicatorProps={{
            style: { backgroundColor: COLOR.blue[700] },
          }}
        >
          <Tab
            data-testid="profile-tabs-basic-profile-tab"
            label="Basic Profile"
            sx={{ textTransform: 'none' }}
            value="/profile"
          />
          <Tab
            data-testid="profile-tabs-educator-profile-tab"
            label="Educator Profile"
            sx={{ textTransform: 'none' }}
            value="/profile/educator"
          />
        </Tabs>
        {renderContent()}
        <ContactSupport />
      </Container>
    </NavigationContainer>
  );
}
