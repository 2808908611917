import { useEffect, useState } from 'react';
import { PENDO_LOADED, isPendoReady } from '@utils/Pendo';

export default function usePendoStatus(): { isPendoLoaded: boolean } {
  const [isPendoLoaded, setIsPendoLoaded] = useState(false);

  const handlePendoLoad = () => {
    setIsPendoLoaded(true);
  };

  useEffect(() => {
    if (isPendoReady()) {
      handlePendoLoad();
    }

    window.addEventListener(PENDO_LOADED, handlePendoLoad);
    return () => window.removeEventListener(PENDO_LOADED, handlePendoLoad);
  }, []);

  return { isPendoLoaded };
}
