interface Properties {
  [key: string]: string | number | boolean | string[] | null;
}

declare global {
  interface Window {
    pendo?: Record<string, unknown>;
  }
}

export const EVENT = Object.freeze({
  COURSE_REDIRECT: 'Redirected to BLC Course',
});
export const PENDO_LOADED = 'pendoloaded';
export const PENDO_LOADED_EVENT = new Event(PENDO_LOADED);

export const trackPendoEvent = (
  eventName: string,
  properties?: Properties
): void => {
  if (!window.pendo) {
    console.error('Pendo has not been instantiated yet.');
    return;
  }

  pendo.track(eventName, properties);
};

export const isPendoReady = (): boolean => {
  if (!window.pendo) {
    return false;
  }

  if (typeof pendo.isReady !== 'function') {
    return false;
  }

  return pendo?.isReady();
};
