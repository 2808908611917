import { SyntheticEvent, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { OptionType } from '@app-types/Select';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';
import { useOrganizationContext } from '@contexts/OrganizationContext/OrganizationContext';
import { formatOrganizationToOption } from '@utils/SelectFormatter';
import styles from './ProvisioningBulkUpload.module.css';
import ProvisioningOptions from './components/ProvisioningOptions/ProvisioningOptions';
import UserTable from './components/UserTable/UserTable';
import Account from './models/Account';
import ProvisionedUser from './models/ProvisionedUser';
import UserToProvision from './models/UserToProvision';

export default function ProvisioningBulkUpload(): JSX.Element {
  const { organizations } = useOrganizationContext();
  const [invalidUsers, setInvalidUsers] = useState<ProvisionedUser[]>([]);
  const [duplicateUsers, setDuplicateUsers] = useState<ProvisionedUser[]>([]);
  const [registeredUsers, setRegisteredUsers] = useState<ProvisionedUser[]>([]);
  const [notRegisteredUsers, setNotRegisteredUsers] = useState<
    ProvisionedUser[]
  >([]);

  const { user } = useAuth();
  let sub = '';
  if (user) {
    sub = user.profile.sub;
  }
  const [usersToImport, setUsersToImport] = useState<UserToProvision[]>([]);
  const [accountList, setAccountList] = useState<OptionType[]>([]);
  const [currentAccount, setCurrentAccount] = useState<OptionType>({
    label: '',
    value: '',
  });

  const fetchOrganizations = async () => {
    const accountOptions = organizations.map((org) =>
      formatOrganizationToOption(org)
    );

    if (!accountOptions.length) {
      return;
    }

    accountOptions.sort((a, b) => a.label.localeCompare(b.label));
    setCurrentAccount(accountOptions[0]);
    setAccountList(accountOptions);
  };

  useEffect(() => {
    fetchOrganizations();
  }, [organizations]);

  const clearAllUsers = () => {
    setInvalidUsers([]);
    setDuplicateUsers([]);
    setRegisteredUsers([]);
    setNotRegisteredUsers([]);
  };

  const handleSetCurrentAccount = (
    e: SyntheticEvent,
    currentAccount: Account | null
  ) => {
    if (!currentAccount) {
      return;
    }

    setCurrentAccount(currentAccount);
  };

  const handleSetInvalidUsers = (invalidUsers: Array<ProvisionedUser>) => {
    setInvalidUsers(invalidUsers);
  };

  const handleSetDuplicateUsers = (duplicateUsers: Array<ProvisionedUser>) => {
    setDuplicateUsers(duplicateUsers);
  };

  const handleSetRegisteredUsers = (
    registeredUsers: Array<ProvisionedUser>
  ) => {
    setRegisteredUsers(registeredUsers);
  };

  const handleSetNotRegisteredUsers = (
    notRegisteredUsers: Array<ProvisionedUser>
  ) => {
    setNotRegisteredUsers(notRegisteredUsers);
  };

  const handleSetUsersToImport = (usersToImport: Array<UserToProvision>) => {
    setUsersToImport(usersToImport);
  };

  const isTableInitialized = (countArray: number[]) => {
    if (countArray.reduce((a, b) => a + b, 0) > 0) return true;
    return false;
  };

  const validUsersCount = notRegisteredUsers.length + registeredUsers.length;

  const userTableNode = isTableInitialized([
    invalidUsers.length,
    duplicateUsers.length,
    registeredUsers.length,
    notRegisteredUsers.length,
  ]) ? (
    <UserTable
      invalidUsers={invalidUsers}
      duplicateUsers={duplicateUsers}
      registeredUsers={registeredUsers}
      notRegisteredUsers={notRegisteredUsers}
      handleSetInvalidUsers={handleSetInvalidUsers}
      handleSetDuplicateUsers={handleSetDuplicateUsers}
      handleSetNotRegisteredUsers={handleSetNotRegisteredUsers}
      handleSetRegisteredUsers={handleSetRegisteredUsers}
    />
  ) : null;

  return (
    <NavigationContainer disableFooter={true}>
      <div className={styles.container} data-testid="provisioning-bulk-upload">
        <div className={styles.summaryContainer}>
          <ProvisioningOptions
            accountList={accountList}
            currentAccount={currentAccount}
            handleSetCurrentAccount={handleSetCurrentAccount}
            currentUserId={sub}
            usersToImport={usersToImport}
            duplicateUsers={duplicateUsers}
            validUsersCount={validUsersCount}
            clearAllUsers={clearAllUsers}
            setUsersToImport={handleSetUsersToImport}
            handleSetInvalidUsers={handleSetInvalidUsers}
            handleSetDuplicateUsers={handleSetDuplicateUsers}
            handleSetRegisteredUsers={setRegisteredUsers}
            handleSetNotRegisteredUsers={setNotRegisteredUsers}
          ></ProvisioningOptions>
          {userTableNode}
        </div>
      </div>
    </NavigationContainer>
  );
}
