import { atom } from 'jotai';

type NavLink = {
  name: string;
  url: string;
  checkFeatureFlag: boolean;
  hideOnFeatureFlag: boolean;
};

type Tab = {
  name: string;
  navLinks: NavLink[];
};

export type NavLinkV2DataType = {
  logoUrl: string;
  tabs: Tab[];
};

export const navLinksAtom = atom<NavLinkV2DataType>({
  logoUrl: '',
  tabs: [],
});
