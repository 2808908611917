import {
  Alert,
  Box,
  Link,
  Snackbar,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import UserIcon from '@components/UserIcon/UserIcon';
import variables from '@variables.module.css';

type Props = {
  givenName: string;
  familyName: string;
  email: string;
  userId: string;
  profileUrl: string;
};

const NameText = styled(Link)(({ theme }: { theme: Theme }) => ({
  marginLeft: theme.spacing(2),
  fontWeight: 500,
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    color: variables['link-blue'],
    cursor: 'pointer',
  },
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '15px',
  letterSpacing: '0.5px',
}));

const EmailText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontStyle: 'normal',
  '&:hover': {
    cursor: 'pointer',
  },
  fontSize: '14px',
  letterSpacing: '1px',
  lineHeight: '20px',
}));

export default function ProfileCardV2({
  givenName,
  familyName,
  email,
  userId,
  profileUrl,
}: Props): JSX.Element {
  const theme = useTheme<Theme>();
  const [open, setOpen] = useState(false);

  const handleEmailClick = () => {
    navigator.clipboard.writeText(email).then(() => {
      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display={'flex'}>
      <UserIcon givenName={givenName} familyName={familyName} userId={userId} />
      <Box>
        <NameText href={profileUrl} target="_blank" tabIndex={0} theme={theme}>
          {givenName} {familyName}
        </NameText>
        <EmailText theme={theme} tabIndex={0} onClick={handleEmailClick}>
          {email}
        </EmailText>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={'success'}
          sx={{ width: '100%', whiteSpace: 'pre-line', fontStyle: 'normal' }}
        >
          {'Email copied to clipboard'}
        </Alert>
      </Snackbar>
    </Box>
  );
}
