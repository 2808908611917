import {
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Chip,
  Box,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState, SyntheticEvent } from 'react';
import { GradesAndSubjectsType } from '@app-types/CoachingRegistrationStages';
import { GRADE_OPTIONS, GRADE_LEVEL_OPTIONS } from '@constants/grades';
import { SUBJECT_CATEGORY_MAP, SUBJECT_OPTIONS } from '@constants/subjects';

interface GradesAndSubjectsProps {
  onContinue: () => void;
  onGoBack: () => void;
  gradesAndSubjects: GradesAndSubjectsType;
  onChangeGradesAndSubjects: (gradesAndSubjects: GradesAndSubjectsType) => void;
}

const GradesAndSubjects: React.FC<GradesAndSubjectsProps> = ({
  onContinue,
  onGoBack,
  gradesAndSubjects,
  onChangeGradesAndSubjects,
}) => {
  const [grades, setGrades] = useState<string[]>(
    gradesAndSubjects?.grades || []
  );
  const [gradeLevel, setGradeLevel] = useState<string>(
    gradesAndSubjects?.gradeLevel || ''
  );
  const [subjects, setSubjects] = useState<string[]>(
    gradesAndSubjects?.subjects || []
  );
  const [preferredSubject, setPreferredSubject] = useState<string>(
    gradesAndSubjects?.preferredSubject || ''
  );

  const [gradesError, setGradesError] = useState<string>('');
  const [gradeLevelError, setGradeLevelError] = useState<string>('');
  const [subjectsError, setSubjectsError] = useState<string>('');
  const [preferredSubjectError, setPreferredSubjectError] =
    useState<string>('');

  const handleSubmitGradesAndSubjectsAndContinue = () => {
    let hasError = false;

    if (grades.length === 0) {
      setGradesError('Please select at least one grade');
      hasError = true;
    } else {
      setGradesError('');
    }

    if (!gradeLevel) {
      setGradeLevelError('Please select a grade level');
      hasError = true;
    } else {
      setGradeLevelError('');
    }

    if (subjects.length === 0) {
      setSubjectsError('Please select at least one subject');
      hasError = true;
    } else {
      setSubjectsError('');
    }

    if (!preferredSubject) {
      setPreferredSubjectError('Please select one preferred subject');
      hasError = true;
    } else {
      setPreferredSubjectError('');
    }

    if (subjects.indexOf(preferredSubject) === -1) {
      setPreferredSubjectError(
        'Preferred subject must match one of the subject areas you are currently working in'
      );
      hasError = true;
    } else {
      setPreferredSubjectError('');
    }

    if (!hasError) {
      onChangeGradesAndSubjects({
        grades: grades,
        gradeLevel: gradeLevel,
        subjects: subjects,
        preferredSubject: preferredSubject,
      });
      onContinue();
    }
  };

  const handleSubmitGradesAndSubjectsAndGoBack = () => {
    onChangeGradesAndSubjects({
      grades: grades,
      gradeLevel: gradeLevel,
      subjects: subjects,
      preferredSubject: preferredSubject,
    });
    onGoBack();
  };

  const handleGradeChange = (event: SyntheticEvent, value: string[]) => {
    setGrades(value);
  };
  const handleGradeLevelChange = (
    event: SyntheticEvent,
    value: string | null
  ) => {
    if (value != null) setGradeLevel(value);
  };

  const handleSubjectsChange = (event: SyntheticEvent, value: string[]) => {
    setSubjects(value);
  };
  const handlePreferredSubjectChange = (
    event: SyntheticEvent,
    value: string | null
  ) => {
    if (value != null) setPreferredSubject(value);
  };

  const renderInputs = () => (
    <Stack
      justifyContent="space-between"
      flex="1 0 0"
      height="100%"
      width="70%"
      spacing={2}
    >
      <Box>
        <Typography textAlign="center" pb={1} fontWeight={500}>
          What grades are you currently working in?
        </Typography>
        <Autocomplete
          multiple
          id="autocomplete"
          data-testid="grades-and-subjects-grades"
          options={GRADE_OPTIONS}
          value={grades}
          onChange={handleGradeChange}
          disableCloseOnSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                    color: '#fff',
                  },
                }}
                label={option}
                {...getTagProps({ index })}
                key={index}
                data-testid={`grades-and-subjects-grade-${option}`}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!gradesError}
              helperText={gradesError}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                sx: { height: '100%' },
              }}
            />
          )}
        />
      </Box>

      <Box>
        <Typography textAlign="center" pb={1} fontWeight={500}>
          What grade level do you want to focus on for coaching?
        </Typography>
        <Autocomplete
          id="autocomplete"
          data-testid="grades-and-subjects-grade-level"
          options={GRADE_LEVEL_OPTIONS}
          value={gradeLevel}
          onChange={handleGradeLevelChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'grades-and-subjects-grade-level-input',
              }}
              error={!!gradeLevelError}
              helperText={gradeLevelError}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                sx: { height: '100%' },
              }}
            />
          )}
        />
      </Box>

      <Box>
        <Typography textAlign="center" pb={1} fontWeight={500}>
          What subject areas are you currently working in?
        </Typography>

        <Autocomplete
          multiple
          id="autocomplete"
          data-testid="grades-and-subjects-subjects"
          options={SUBJECT_OPTIONS}
          groupBy={(option) => SUBJECT_CATEGORY_MAP.get(option) ?? ''}
          fullWidth
          value={subjects}
          onChange={handleSubjectsChange}
          disableCloseOnSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                sx={{
                  '& .MuiChip-deleteIcon': {
                    color: '#fff',
                  },
                }}
                data-testid={`grades-and-subjects-subjects-${option}`}
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!subjectsError}
              helperText={subjectsError}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                sx: { height: '100%' },
              }}
            />
          )}
        />
      </Box>

      <Box>
        <Typography textAlign="center" pb={1} fontWeight={500}>
          Which subject area do you want to focus on for coaching?
        </Typography>
        <Autocomplete
          id="autocomplete"
          data-testid="grades-and-subjects-preferred-subject"
          options={subjects}
          groupBy={(option) => SUBJECT_CATEGORY_MAP.get(option) || ''}
          fullWidth
          value={preferredSubject}
          onChange={handlePreferredSubjectChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'grades-and-subjects-preferred-subject-input',
              }}
              error={!!preferredSubjectError}
              helperText={preferredSubjectError}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                sx: { height: '100%' },
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );

  const renderNavigationButtons = () => (
    <Stack width="50%" spacing={1} pt={6}>
      <Button
        onClick={handleSubmitGradesAndSubjectsAndContinue}
        variant="contained"
        data-testid="grades-and-subjects-continue-button"
      >
        Continue
      </Button>
      <Button
        onClick={handleSubmitGradesAndSubjectsAndGoBack}
        variant="text"
        data-testid="grades-and-subjects-go-back-button"
      >
        Go Back
      </Button>
    </Stack>
  );

  return (
    <Stack
      alignItems="center"
      height="100%"
      width="100%"
      data-testid="coaching-registration-grades-and-subjects"
      flex="1 0 0"
    >
      {renderInputs()}
      {renderNavigationButtons()}
    </Stack>
  );
};

export default GradesAndSubjects;
