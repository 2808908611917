import { Stack } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { getUserEngagements, registerEngagement } from '@apis/engagements';
import { getUser, setCoachingPreferences } from '@apis/users';
import {
  GradesAndSubjectsType,
  AvailabilityType,
  AdditionalInformationType,
} from '@app-types/CoachingRegistrationStages';
import { FocusAreaType } from '@app-types/Invite';
import CoachingRegistrationConfirmation from '@pages/CoachingRegistration/components/CoachingRegistrationConfirmation/CoachingRegistrationConfirmation';
import AdditionalInformation from './components/AdditionalInformation/AdditionalInformation';
import Availability from './components/Availability/Availability';
import GradesAndSubjects from './components/GradesAndSubjects/GradesAndSubjects';
import Welcome from './components/Welcome/Welcome';

interface CoachingProps {
  focusAreas?: FocusAreaType[];
  serviceUuid: string;
  onConfirm: () => void;
}

const DEFAULT_GRADES_AND_SUBJECTS = {
  grades: [],
  gradeLevel: '',
  subjects: [],
  preferredSubject: '',
};

const DEFAULT_AVAILABILITY = {
  zoneinfo: '',
  preferredTimeslot: [],
  availableTimeslot: [],
  weekendAvailability: false,
};

const DEFAULT_ADDITIONAL_INFO = {
  additionalText: '',
  prefersPreviousCoach: null,
};

const CoachingRegistration: React.FC<CoachingProps> = ({
  focusAreas = [],
  serviceUuid,
  onConfirm,
}) => {
  const { user } = useAuth();
  const userUUID = user?.profile?.sub ?? '';
  const [gradesAndSubjects, setGradesAndSubjects] =
    useState<GradesAndSubjectsType>(DEFAULT_GRADES_AND_SUBJECTS);
  const [availability, setAvailability] =
    useState<AvailabilityType>(DEFAULT_AVAILABILITY);
  const [additionalInformation, setAdditionalInformation] =
    useState<AdditionalInformationType>(DEFAULT_ADDITIONAL_INFO);
  const [previousCoach, setPreviousCoach] = useState<string>();

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const onContinue = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };
  const onGoBack = () => {
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const fetchPreviousCoach = async () => {
    const engagements = await getUserEngagements(userUUID);
    const sortedEngagements = engagements.sort(
      (a, b) =>
        DateTime.fromISO(b.startDate).toMillis() -
        DateTime.fromISO(a.startDate).toMillis()
    );

    return sortedEngagements[0]?.coach?.givenName;
  };

  const fetchUserProfile = async () => {
    const [userResponse, coachNamerResponse] = await Promise.all([
      getUser(),
      fetchPreviousCoach(),
    ]);

    if (!userResponse) {
      return toast.error('Failed to fetch user information.');
    }

    setGradesAndSubjects({
      grades: userResponse.grades,
      gradeLevel: userResponse.gradeLevel,
      subjects: userResponse.subjects,
      preferredSubject: userResponse.preferredSubject,
    });

    setAvailability({
      zoneinfo: userResponse.zoneinfo,
      preferredTimeslot: userResponse.preferredTimeslot,
      availableTimeslot: userResponse.availableTimeslot,
      weekendAvailability: userResponse.weekendAvailability,
    });

    setAdditionalInformation({
      additionalText: userResponse.additionalText,
      prefersPreviousCoach: userResponse.prefersPreviousCoach,
    });

    setPreviousCoach(coachNamerResponse);
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const onSubmitAll = async () => {
    const coachingPreferenceResponse = await setCoachingPreferences(userUUID, {
      ...gradesAndSubjects,
      ...availability,
      ...additionalInformation,
    });

    if (!coachingPreferenceResponse) {
      return toast.error('Update to coaching preferences failed.');
    }

    const registerEngagementResponse = await registerEngagement(serviceUuid);

    if (!registerEngagementResponse) {
      return toast.error('Registration for coaching failed.');
    }

    onConfirm();
  };

  const renderPage = () => {
    switch (currentPageIndex) {
      case 0:
        return <Welcome focusAreas={focusAreas} onContinue={onContinue} />;
      case 1:
        return (
          <GradesAndSubjects
            onContinue={onContinue}
            onGoBack={onGoBack}
            gradesAndSubjects={gradesAndSubjects}
            onChangeGradesAndSubjects={setGradesAndSubjects}
          />
        );
      case 2:
        return (
          <Availability
            onContinue={onContinue}
            onGoBack={onGoBack}
            availability={availability}
            onChangeAvailability={setAvailability}
          />
        );
      case 3:
        return (
          <AdditionalInformation
            previousCoach={previousCoach}
            onGoBack={onGoBack}
            onContinue={onContinue}
            additionalInformation={additionalInformation}
            onChangeAdditionalInformation={setAdditionalInformation}
          />
        );
      case 4:
        return (
          <CoachingRegistrationConfirmation
            onGoBackClick={onGoBack}
            onConfirmClick={onSubmitAll}
          />
        );
    }
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      p={2.5}
      data-testid="coaching-registration"
    >
      <Stack
        border={{ xs: 'none', sm: '1px solid var(--color-slate-2)' }}
        borderRadius="4px"
        alignItems="center"
        width={{ xs: '100%', sm: '700px' }}
        minHeight="85vh"
        py={4}
      >
        {renderPage()}
      </Stack>
    </Stack>
  );
};

export default CoachingRegistration;
