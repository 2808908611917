import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'betterlesson-library-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { setUserPW, validateTokenAndGetEmail } from '@apis/auth';
import SetPasswordView from './components/SetPasswordView/SetPasswordView';

type SetPasswordInputs = {
  sub: string;
  code: string;
};

type SetPasswordType = {
  givenName: string;
  familyName: string;
  password: string;
  confirmPassword: string;
};

const validationSchema = yup.object().shape({
  givenName: yup.string().required('Please enter your first name'),
  familyName: yup.string().required('Please enter your last name'),
  password: yup
    .string()
    .required()
    .min(8)
    .matches(/[a-zA-Z]/, 'letter')
    .matches(/\d/, 'digit')
    .matches(/[@$!%*#?&]/, 'special'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function SetPassword(inputs: SetPasswordInputs): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitted, isValid },
    trigger,
    watch,
  } = useForm<SetPasswordType>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
  });

  const watchPassword = watch('password');

  useEffect(() => {
    trigger('password');
  }, [watchPassword]);

  const onSubmit = async (data: SetPasswordType) => {
    setIsLoading(true);
    try {
      await setUserPW(
        data.givenName,
        data.familyName,
        email,
        data.password,
        data.confirmPassword,
        inputs.code
      );
      const queryParams = new URLSearchParams(location.search);
      const redirect = queryParams.get('redirect');
      const redirectUrl = queryParams.get('redirect_url');

      if (redirectUrl) {
        navigate(`/login?redirect_url=${redirectUrl}`);
        return;
      }

      if (redirect) {
        navigate(`/login?redirect=${redirect}`);
        return;
      }

      navigate('/login');
    } catch (e) {
      console.error(e);
      toast.error('Failed to set password.', {
        className: 'toast-notify-error',
      });
    }

    setIsLoading(false);
    trigger();
  };

  const fetchEmailAndValidateToken = async () => {
    try {
      const email = await validateTokenAndGetEmail(inputs.sub, inputs.code);
      setEmail(email);
    } catch (e) {
      console.error(e);
      toast.error('Invalid token. Please try again', {
        className: 'toast-notify-error',
      });
    }
  };

  useEffect(() => {
    fetchEmailAndValidateToken();
  }, [email]);

  return (
    <SetPasswordView
      email={email}
      loading={isLoading}
      givenNameRegister={register('givenName')}
      giveNameError={errors.givenName?.message}
      familyNameRegister={register('familyName')}
      familyNameError={errors.familyName?.message}
      passwordRegister={register('password')}
      passwordError={!!(isSubmitted && errors.password?.message)}
      confirmPasswordRegister={register('confirmPassword')}
      confirmPasswordError={errors.confirmPassword?.message}
      password={getValues('password')}
      requirements={errors.password?.types}
      displayMainError={isSubmitted && !isValid}
      isSubmitted={isSubmitted}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
}
