import { createContext, useContext, useState } from 'react';
import { ModalProps, ModalReturn } from '@app-types/Modal';
import ModalContainer, {
  ModalContainerProps,
} from './components/ModalContainer/ModalContainer';

type ModalContextType = {
  openModal: (key: string) => Promise<ModalReturn>;
};

export const MODAL_KEY = Object.freeze({
  WELCOME: 'welcome',
});

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType
);

export const ModalProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [modalProps, setModalProps] = useState<ModalContainerProps>({
    modalKey: null,
  });

  const openModal = async (
    key: string,
    modalProps?: ModalProps
  ): Promise<ModalReturn> => {
    const result = await new Promise<ModalReturn>((resolve) => {
      setModalProps({ modalKey: key, onClose: resolve, modalProps });
    });

    setModalProps({ modalKey: null });
    return result;
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      <ModalContainer {...modalProps} />
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = (): ModalContextType => useContext(ModalContext);
