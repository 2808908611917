import { Stack, Typography } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { TEACHER_EXPERIENCE } from '@constants/professional-roles';
import AnswerCard from '../AnswerCard/AnswerCard';

type Props = {
  experience?: string;
  onExperienceChange: (experience: string) => void;
};

export default function ExperienceQuestion({
  experience,
  onExperienceChange,
}: Readonly<Props>): JSX.Element {
  const handleExperienceCardClick = (newExperience: string) => () => {
    onExperienceChange(newExperience);
  };

  return (
    <Stack pt={1.5} pb={0.5} width={1} spacing={0.5}>
      <Typography variant="h6" component="p" fontSize="13px">
        I AM...
      </Typography>

      <Typography
        variant="body2"
        component="p"
        fontSize="13px"
        fontStyle="italic"
        color={COLOR_PALETTE.text.primary}
        pb={1}
      >
        Select one
      </Typography>

      <AnswerCard
        title="New to education"
        description="...with 3 years or less experience"
        imageSrc="/teacher-experience/new-teacher.svg"
        imageAlt="New Teacher Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={experience === TEACHER_EXPERIENCE.NEW_TEACHER}
        onClick={handleExperienceCardClick(TEACHER_EXPERIENCE.NEW_TEACHER)}
      />

      <AnswerCard
        title="Experienced in education"
        description="...but have recently begun a new role"
        imageSrc="/teacher-experience/experienced-teacher-new-role.svg"
        imageAlt="Experienced Teacher New Role Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={experience === TEACHER_EXPERIENCE.EXPERIENCED_CHANGE_ROLE}
        onClick={handleExperienceCardClick(
          TEACHER_EXPERIENCE.EXPERIENCED_CHANGE_ROLE
        )}
      />

      <AnswerCard
        title="Experienced in this role"
        description="...and have been in the same job for 3+ years"
        imageSrc="/teacher-experience/experienced-teacher-same-role.svg"
        imageAlt="Experienced Teacher Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={experience === TEACHER_EXPERIENCE.EXPERIENCED}
        onClick={handleExperienceCardClick(TEACHER_EXPERIENCE.EXPERIENCED)}
      />

      <AnswerCard
        title="Skilled at other education work"
        description="...but my experience doesn't match the options above"
        imageSrc="/teacher-experience/other.svg"
        imageAlt="New Teacher Icon"
        imageStyle={{ width: '60px' }}
        titleProps={{ fontSize: '16px' }}
        selected={experience === TEACHER_EXPERIENCE.OTHER}
        onClick={handleExperienceCardClick(TEACHER_EXPERIENCE.OTHER)}
      />
    </Stack>
  );
}
