import { Stack, Typography } from '@mui/material';
import { CoachingPropsType } from '@app-types/Invite';
import { InviteProps } from '../utils';

export default function CoachingInvite({
  serviceName,
  serviceDetail,
}: Readonly<InviteProps>): JSX.Element {
  const focusAreas = (
    (serviceDetail as CoachingPropsType)?.cohortFocusAreas || []
  )
    .map((fa) => fa.title)
    .join(', ');

  return (
    <Stack data-testid="coaching-invite" textAlign="left" spacing={2.5}>
      <Typography variant="h3">{serviceName}</Typography>
      <Typography variant="h4">
        {serviceDetail?.type}
        {focusAreas ? `- ${focusAreas}` : ''}
      </Typography>
      <Typography>
        Our expert coaches are ready to give you 1-on-1 personalized support to
        help you become the best educator you can be. Together, you&apos;ll
        identify your goals, learn about and implement new strategies, and
        reflect on your results. We&apos;re excited to get started!
      </Typography>
    </Stack>
  );
}
