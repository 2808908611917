import { atom } from 'jotai';
import { AccountParticipantType } from '@app-types/Account';
import { ProfessionalRole } from '@app-types/ProfessionalRoles';
import {
  BasicProfileType,
  CoachingPreferences,
  UserServiceUser,
} from '@app-types/User';

export type Profile = {
  account: AccountParticipantType;
  basicInfo: BasicProfileType;
  coachingPreferences: CoachingPreferences;
  professionalRole: ProfessionalRole;
};

export const PROFILE_EMPTY_STATE = {
  account: {
    userUUID: '',
    accountUUID: '',
    accountName: '',
    accountRole: '',
  },
  basicInfo: {
    givenName: '',
    familyName: '',
    zoneinfo: '',
    zoomSchedulerUrl: '',
  },
  coachingPreferences: {
    grades: [],
    gradeLevel: '',
    subjects: [],
    preferredSubject: '',
    zoneinfo: '',
    preferredTimeslot: [],
    availableTimeslot: [],
    weekendAvailability: false,
    additionalText: '',
    prefersPreviousCoach: null,
  },
  professionalRole: {
    role: '',
    experience: '',
    subjects: [],
    createdAt: '',
  },
};

export const profileAtom = atom<Profile>(PROFILE_EMPTY_STATE);

export const basicProfileAtom = atom(
  (get) => get(profileAtom).basicInfo,
  (get, set, args: BasicProfileType) => {
    const profile = get(profileAtom);
    set(profileAtom, { ...profile, basicInfo: args });
  }
);

export const educatorProfileAtom = atom(
  (get) => {
    const account = get(profileAtom).account;
    const coachingPreferences = get(profileAtom).coachingPreferences;
    const professionalRole = get(profileAtom).professionalRole;
    return {
      organization: account?.accountName ?? '',
      professionalRole,
      coachingPreferences,
    };
  },
  (
    get,
    set,
    educatorProfile: {
      professionalRole: ProfessionalRole;
      userProfile: UserServiceUser;
    }
  ) => {
    const profile = get(profileAtom);
    const coachingPreferences = get(profileAtom).coachingPreferences;

    set(profileAtom, {
      ...profile,
      coachingPreferences: {
        ...coachingPreferences,
        subjects: educatorProfile.userProfile.subjects,
        grades: educatorProfile.userProfile.grades,
      },
      professionalRole: educatorProfile.professionalRole,
    });
  }
);
