import { AxiosResponse } from 'axios';
import { AccountParticipantType } from '@app-types/Account';
import { ACCOUNT_ROLE } from '@constants/account';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_ACCOUNT_SERVICE_URL}/api/v2/participants`;

type CreateAccountRequestBody = {
  userUUID: string;
  accountUUID: string;
};

export const createAccountParticipant = async (
  body: CreateAccountRequestBody
): Promise<AccountParticipantType | null> => {
  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/commands/upsert`,
      { ...body, accountRole: ACCOUNT_ROLE.MEMBER }
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getAccountsForParticipant = async (
  userUUID: string
): Promise<AccountParticipantType[]> => {
  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/queries/find`,
      { userUUID }
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
