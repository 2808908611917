import { Radio as BaseRadio, RadioProps, Grow } from '@mui/material';
import styles from './Radio.module.scss';

type Props = RadioProps & {
  checkColor?: string;
};

export default function Radio({
  checkColor,
  ...radioProps
}: Props): JSX.Element {
  const renderIcon = () => <div className={styles.icon} />;

  const renderCheckedIcon = () => (
    <div className={styles.icon}>
      <Grow in={true}>
        <div className={styles.check} style={{ backgroundColor: checkColor }} />
      </Grow>
    </div>
  );

  return (
    <BaseRadio
      {...radioProps}
      icon={renderIcon()}
      checkedIcon={renderCheckedIcon()}
    />
  );
}
