import { LoadingPage } from 'betterlesson-library-react';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { getAvailableMatches } from '@apis/coach-match';
import { getEngagementsForCoach } from '@apis/engagements';
import { getFacilitatorWorkshops } from '@apis/facilitators';
import { getParticipants } from '@apis/participant';
import { getUser } from '@apis/users';
import { getWorkshopDetails } from '@apis/workshop';
import { WorkshopDataType } from '@app-types/Workshop';
import { coachMatchesDataAtom, coachParticipantsDataAtom } from '@atoms/coach';
import { facilitatorWorkshopsAtom } from '@atoms/facilitator';
import { userSubAtom } from '@atoms/user';
import { zoomSchedulerLinkAtom } from '@atoms/user';
import { registeredCountAtom, workshopsDataAtom } from '@atoms/workshop';
import {
  ImpersonateFactory,
  useImpersonatedUserErrorHandler,
} from '@utils/ImpersonateFactory';
import CoachHomeTabs from './components/CoachHomeTabs/CoachHomeTabs';

const TEST_ID = {
  LOADING: 'coach-home-data-provider-loading',
};

export default function CoachHomeDataProvider(): JSX.Element {
  const { coachUuid = '' } = useParams();
  const handleImpersonatedUserError = useImpersonatedUserErrorHandler();
  const setCoachMatches = useSetAtom(coachMatchesDataAtom);
  const setCoachParticipants = useSetAtom(coachParticipantsDataAtom);
  const setWorkshopDetails = useSetAtom(workshopsDataAtom);
  const setUserSubAtom = useSetAtom(userSubAtom);
  const setZoomSchedulerLink = useSetAtom(zoomSchedulerLinkAtom);
  const setFacilitatorWorkshops = useSetAtom(facilitatorWorkshopsAtom);
  const [isLoading, setIsLoading] = useState(true);
  const setRegisteredCount = useSetAtom(registeredCountAtom);

  const fetchParticipants = async (workshopDetails: WorkshopDataType[]) => {
    const registeredCount: Record<string, number> = {};

    const promises = workshopDetails.map(async (workshop) => {
      const groupNumberToGroupUuid = new Map(
        workshop.groups.map((g) => [g.groupNumber, g.uuid])
      );

      const participants = await getParticipants(workshop.uuid);

      participants.forEach((p) => {
        const groupUuid = groupNumberToGroupUuid.get(p.groupNumber);
        if (!groupUuid) return;

        if (!registeredCount[groupUuid]) {
          registeredCount[groupUuid] = 0;
        }

        registeredCount[groupUuid] += 1;
      });

      return participants.length;
    });

    await Promise.all(promises);
    setRegisteredCount(registeredCount);
    setIsLoading(false);
  };

  const getDetails = async (workshopUuids: string[]) => {
    const details = await getWorkshopDetails(workshopUuids);
    await fetchParticipants(details);
    setWorkshopDetails(details);
  };

  const fetchCoachHomeData = async () => {
    const impersonatedUser = await ImpersonateFactory.impersonateUser(
      coachUuid
    );

    if (impersonatedUser.isInvalid()) {
      handleImpersonatedUserError(impersonatedUser.invalidReason);
      return;
    }

    const [coachMatches, workshops, coachParticipants, user] =
      await Promise.all([
        getAvailableMatches(impersonatedUser.accessToken),
        getFacilitatorWorkshops(impersonatedUser.accessToken),
        getEngagementsForCoach(impersonatedUser.accessToken),
        getUser(impersonatedUser.accessToken),
      ]);

    setCoachMatches(coachMatches);
    setFacilitatorWorkshops(workshops);
    setUserSubAtom(coachUuid);
    setCoachParticipants(coachParticipants);
    setZoomSchedulerLink(user?.zoomSchedulerUrl || '');
    getDetails(workshops.map((w) => w.workshopUuid));
  };

  useEffect(() => {
    fetchCoachHomeData();
  }, []);

  if (isLoading) {
    return <LoadingPage data-testid={TEST_ID.LOADING} />;
  }

  return (
    <CoachHomeTabs>
      <Outlet />
    </CoachHomeTabs>
  );
}
