import { atom } from 'jotai';
import { UserServiceUser } from '@app-types/User';

export const userSubAtom = atom('');

export const zoomSchedulerLinkAtom = atom('');

export const contextUserDataAtom = atom<UserServiceUser | null>(null);

export const contextUserTimezoneAtom = atom(
  (get) => get(contextUserDataAtom)?.zoneinfo || ''
);

export const contextUserFirstNameAtom = atom(
  (get) => get(contextUserDataAtom)?.givenName || ''
);
