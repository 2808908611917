import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';

export default function DeleteAccountDialog(): JSX.Element {
  const { user } = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
    setIsAgreed(false);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDeleteAccount = () => {
    toast.success('Your request has been submitted', {
      className: 'toast-notify-success',
    });

    // TODO: add API call to actually delete account
    // TODO: ensure that delete account button is disabled if a request has already been made

    setIsDisabled(true);
    setIsDialogOpen(false);
    setIsAgreed(false);
  };

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed);
  };

  const renderDialog = () =>
    isDialogOpen ? (
      <Dialog
        data-testid="delete-account-dialog"
        PaperProps={{ sx: { py: 4 } }}
        open={true}
        onClose={handleClose}
      >
        <DialogTitle textAlign="center">
          <Typography component="div" fontSize="24px" fontWeight={600}>
            Delete Account
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" pb={2} textAlign="center">
            <Typography fontSize="1rem" fontWeight={300} pb={1.5}>
              Are you sure you want to delete the account linked to{' '}
              <strong>{user?.profile.email}</strong>?
            </Typography>

            <Typography fontSize="1rem" fontWeight={300} pb={1}>
              It can take up to 24 hours for account removal to finalize
            </Typography>

            <FormControlLabel
              componentsProps={{ typography: { color: 'primary' } }}
              control={
                <Checkbox
                  data-testid="delete-account-dialog-agree-checkbox"
                  checked={isAgreed}
                  onChange={handleAgreementChange}
                />
              }
              label="I understand I cannot recover my account"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            pr={2}
            spacing={1}
            width="100%"
            justifyContent="center"
          >
            <Button
              data-testid="delete-account-dialog-cancel-button"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              disabled={!isAgreed}
              data-testid="delete-account-dialog-confirm-button"
              variant="outlined"
              color="error"
              onClick={handleDeleteAccount}
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    ) : (
      <></>
    );

  return (
    <>
      {renderDialog()}
      <Tooltip title={isDisabled ? 'Delete account requested' : ''} arrow>
        <Box>
          <Button
            data-testid="delete-account-dialog-button"
            variant="outlined"
            disabled={isDisabled}
            color="error"
            onClick={handleDialogOpen}
          >
            Delete Account
          </Button>
        </Box>
      </Tooltip>
    </>
  );
}
