import { atom, Getter } from 'jotai';
import { DateTime, Duration, Interval } from 'luxon';
import { EventType } from '@app-types/Event';
import { SessionStatus, Workshop } from '@app-types/Workshop';
import { MeetingStatusType } from '@app-types/meeting';
import { engagementAtom } from '@atoms/engagement';
import { meetingAtom } from '@atoms/meeting';
import { EVENT_TYPE } from '@constants/event';
import { workshopsDataAtom } from './workshop';

const isAvailableMeeting = (startTime: number): boolean => {
  const start = DateTime.fromMillis(startTime);
  const isUpcomingRange = Interval.fromDateTimes(
    start.minus({ minutes: 30 }),
    start.plus({ minutes: 15 })
  );

  return isUpcomingRange.contains(DateTime.now());
};

const isAvailableSession = (startTime: number, endTime: number): boolean => {
  const start = DateTime.fromMillis(startTime);
  const end = DateTime.fromMillis(endTime);
  const isUpcomingRange = Interval.fromDateTimes(
    start.minus({ minutes: 30 }),
    end
  );

  return isUpcomingRange.contains(DateTime.now());
};

const generateEvents = (
  get: Getter,
  filterFn: (event: EventType) => boolean
): EventType[] => {
  const engagement = get(engagementAtom);
  const workshops = get(workshopsDataAtom);
  const meetings = get(meetingAtom);

  const coachMeetingEvents: EventType[] = meetings.map((meeting) => ({
    accessUrl: meeting?.joinUrl,
    startTime: meeting?.startTime,
    title: `${Duration.fromMillis(meeting.duration).toFormat(
      'mm'
    )} minute meeting with ${engagement?.coach?.givenName} ${
      engagement?.coach?.familyName
    }`,
    type: EVENT_TYPE.COACHING,
    isAvailable: isAvailableMeeting(meeting.startTime),
    isStatusCompleted: Boolean(meeting.status === MeetingStatusType.COMPLETED),
  }));

  const workshopSessionEvents: EventType[] = workshops.flatMap((workshop) =>
    workshop.sessions.map((session) => ({
      accessUrl: `${process.env.REACT_APP_WORKSHOP_WEB_URL}/${workshop.uuid}`,
      startTime: session.sessionStartTime,
      title: `${workshop.displayName}, ${session.name}`,
      type:
        workshop.type === 'Learning Walk'
          ? EVENT_TYPE.LEARNING_WALK
          : EVENT_TYPE.WORKSHOP,
      isAvailable: isAvailableSession(
        session.sessionStartTime,
        session.sessionEndTime
      ),
      isStatusCompleted: Boolean(session.status === SessionStatus.DELIVERED),
      location: workshop.type === Workshop.IN_PERSON ? workshop.location : '',
      detailUrl: `${process.env.REACT_APP_WORKSHOP_WEB_URL}/${workshop.uuid}`,
    }))
  );

  return [...coachMeetingEvents, ...workshopSessionEvents].filter(filterFn);
};

const upcomingEventsGetter = (get: Getter): EventType[] => {
  return generateEvents(get, (event) => event.startTime > Date.now()).sort(
    (a, b) => a.startTime - b.startTime
  );
};

const pastEventsGetter = (get: Getter): EventType[] => {
  return generateEvents(get, (event) => event.isStatusCompleted).sort(
    (a, b) => b.startTime - a.startTime
  );
};

export const eventsAtom = atom((get) => upcomingEventsGetter(get));
export const pastEventsAtom = atom((get) => pastEventsGetter(get));
