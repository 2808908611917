import { atom } from 'jotai';
import { EngagementDataType } from '@app-types/Engagement';

const _atom = atom<EngagementDataType | undefined>(undefined);

export const engagementAtom = atom(
  (get) => get(_atom),
  (_get, set, updateAtom: EngagementDataType) => {
    set(_atom, updateAtom);
  }
);
