import axios, { AxiosInstance } from 'axios';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';

export const getLabAxiosWithCorrelationId = (
  correlationId: string
): AxiosInstance => {
  const userServiceAxios = axios.create({
    baseURL: process.env.REACT_APP_LAB_URL,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
    withCredentials: true,
  });

  userServiceAxios.defaults.headers.common['x-correlation-id'] = correlationId;
  userServiceAxios.defaults.headers.common['X-Requested-With'] =
    'XMLHttpRequest';

  return userServiceAxios;
};

export const getLabAxios = (): AxiosInstance => {
  const correlationId = uuidv4();
  return getLabAxiosWithCorrelationId(correlationId);
};
