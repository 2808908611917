import { OIDC_CONFIG, ThemeWrapper } from 'betterlesson-library-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from 'react-oidc-context';
import './index.css';
import './scss/index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...OIDC_CONFIG}>
      <ThemeWrapper spacing={8}>
        <App />
      </ThemeWrapper>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
