import { Button, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useAuth } from 'react-oidc-context';
import HappyGroup from '@images/icons/happy-group.svg';
import BetterLessonLogo from '@images/logos/betterlesson.svg';
import { CONTAINED_GRADIENT_STYLE } from '@utils/button';

type Props = {
  topText?: ReactNode;
  confirmButtonText: string;
  bottomText?: ReactNode;
  onConfirmClick: () => void;
  onGoBackClick: () => void;
};

export default function Confirmation({
  topText,
  confirmButtonText,
  bottomText,
  onConfirmClick,
  onGoBackClick,
}: Readonly<Props>): JSX.Element {
  const { user } = useAuth();

  return (
    <Stack alignItems="center" px={{ xs: 2, sm: 15 }} py={2.5} spacing={2.5}>
      <img
        width={245}
        src={BetterLessonLogo}
        data-testid="betterlesson-logo"
        alt="BetterLesson"
      />

      <Stack pt={1.5} pb={0.5} alignItems="center" data-testid="confirmation">
        <Typography variant="body1" fontSize="18px" textAlign="center">
          Thank you so much
          {user?.profile.given_name ? `, ${user?.profile.given_name}` : ''}.
        </Typography>
        <Typography variant="body1" fontSize="18px" textAlign="center">
          {topText}
        </Typography>
      </Stack>

      <img
        width={200}
        src={HappyGroup}
        data-testid="happy-group-logo"
        alt="Happy Group"
      />

      <Typography component="div" variant="body1" textAlign="center">
        {bottomText}
      </Typography>
      <Stack
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#FFFFFF',
          width: '100%',
        }}
      >
        <Button
          data-testid="confirmation-confirm-button"
          variant="contained"
          color="secondary"
          onClick={onConfirmClick}
          sx={{ px: 10.5, ...CONTAINED_GRADIENT_STYLE }}
        >
          {confirmButtonText}
        </Button>

        <Button
          data-testid="confirmation-go-back-button"
          variant="text"
          onClick={onGoBackClick}
        >
          Go back
        </Button>
      </Stack>
    </Stack>
  );
}
