import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';

export default function Breadcrumbs(): JSX.Element {
  const { pathname } = useLocation();

  const getLinks = () => {
    const breadcrumbs = pathname.split('/').slice(1);
    return breadcrumbs;
  };

  return (
    <div className={styles.text} data-testid="breadcrumbs">
      {getLinks().map((link) =>
        pathname.endsWith(link) ? (
          <span key={link}>{link}</span>
        ) : (
          <React.Fragment key={link}>
            <Link className={styles.linkText} to={`/${link}`}>
              {link}
            </Link>
            <span> | </span>
          </React.Fragment>
        )
      )}
    </div>
  );
}
