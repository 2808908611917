import { Stack, Typography } from '@mui/material';
import { COLOR_PALETTE } from 'betterlesson-library-react';
import { CoachMatch } from '@app-types/CoachMatch';
import MatchCard from '../MatchCard/MatchCard';

export type Props = {
  coachMatches: CoachMatch[];
};

export const TEST_ID = {
  CONTAINER: 'matches-to-review',
};

export default function MatchesToReview({ coachMatches }: Props): JSX.Element {
  return (
    <Stack data-testid={TEST_ID.CONTAINER} width="100%" pb={3}>
      <Typography
        variant="h4"
        fontWeight={500}
        fontSize={22}
        color={COLOR_PALETTE.text.primary}
      >
        Matches to Review
      </Typography>

      {coachMatches.map((coachMatch) => (
        <MatchCard key={coachMatch.participantUserUuid} {...coachMatch} />
      ))}
    </Stack>
  );
}
