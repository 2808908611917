import { Stack } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useSetAtom } from 'jotai';
import { deleteMatch, getAvailableMatches } from '@apis/coach-match';
import { CoachMatch } from '@app-types/CoachMatch';
import { coachMatchesDataAtom } from '@atoms/coach';
import { COACH_MATCH_STATUS } from '@constants/coach-match';
import CohortInfo from '../CohortInfo/CohortInfo';
import MatchButton from '../MatchButton/MatchButton';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';

export const TEST_ID = {
  CONTAINER: 'match-card',
};

export type Props = CoachMatch;

export default function MatchCard({
  uuid,
  status,
  coachUserUuid,
  participantUserUuid,
  firstName,
  lastName,
  professionalRole,
  zoneInfo,
  cohortName,
  productName,
  matchNote,
  proposedAt,
  expiredAt,
  startDate,
  endDate,
}: Props): JSX.Element {
  const setCoachMatches = useSetAtom(coachMatchesDataAtom);

  const handleParticipantRemove = async () => {
    const isDeleted = await deleteMatch(uuid);

    if (!isDeleted) {
      return toast.error('Failed to remove participant.', {
        className: 'toast-notify-error',
      });
    }

    const updatedMatches = await getAvailableMatches();
    setCoachMatches(updatedMatches);
  };

  return (
    <Stack
      data-testid={TEST_ID.CONTAINER}
      height={{ xs: '100%', lg: 220 }}
      borderRadius="20px"
      boxShadow="0px 4px 4px 0px #00000040"
    >
      <Stack
        direction={{ sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ sm: 'center' }}
        spacing={{ xs: 2.5, sm: 0 }}
        mt={1}
        px={2.5}
        py={1}
      >
        <ParticipantInfo
          firstName={firstName}
          lastName={lastName}
          professionalRole={professionalRole}
          zoneInfo={zoneInfo}
          isRetracted={status === COACH_MATCH_STATUS.RETRACTED}
        />

        <MatchButton
          uuid={uuid}
          isRetracted={status === COACH_MATCH_STATUS.RETRACTED}
          participantUserUuid={participantUserUuid}
          proposedAt={proposedAt}
          expiredAt={expiredAt}
          onParticipantRemove={handleParticipantRemove}
        />
      </Stack>

      <Stack
        pt={2}
        bgcolor="var(--color-footer-bg)"
        width="100%"
        height="100%"
        mt={1}
        px={2.5}
        pb={2.5}
        sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
      >
        <CohortInfo
          cohortName={cohortName}
          productName={productName}
          matchNote={matchNote}
          startDate={startDate}
          endDate={endDate}
          hasCoach={!!coachUserUuid}
        />
      </Stack>
    </Stack>
  );
}
