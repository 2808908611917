import { Link, useSearchParams } from 'react-router-dom';
import styles from './LinkText.module.css';

type Props = {
  text: string;
  to: string;
  preserveQueryParams?: boolean;
  dataTestId: string;
};

export default function LinkText({
  text,
  to,
  preserveQueryParams,
  dataTestId,
}: Props): JSX.Element {
  const [searchParams] = useSearchParams();

  const getLink = () => {
    const queryParams = searchParams.toString();
    if (!queryParams || !preserveQueryParams) {
      return to;
    }

    return `${to}?${queryParams}`;
  };

  return (
    <Link data-testid={dataTestId} to={getLink()} className={styles.text}>
      {text}
    </Link>
  );
}
