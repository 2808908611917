import { Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';

export type Props = {
  cohortName: string;
  productName: string;
  matchNote: string;
  startDate: number;
  endDate: number;
  hasCoach: boolean;
};

export const TEST_ID = {
  COHORT_INFO: 'cohort-info',
  MATCH_NOTE: 'cohort-info-match-note',
};

export default function CohortInfo({
  cohortName,
  productName,
  matchNote,
  startDate,
  endDate,
  hasCoach,
}: Props): JSX.Element {
  if (hasCoach) {
    return (
      <Stack data-testid={TEST_ID.MATCH_NOTE}>
        <Typography variant="body3">Reason for Request</Typography>
        <Typography variant="body5">{matchNote}</Typography>
        <Typography variant="body5">
          If you have questions or concerns about this request, contact Jessi
          Anderson
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid container data-testid={TEST_ID.COHORT_INFO}>
      <Grid item xs={4}>
        <Stack pr={2}>
          <Typography variant="body3">Cohort</Typography>
          <Typography variant="body5">{cohortName}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack pr={2}>
          <Typography variant="body3">Product</Typography>
          <Typography variant="body5">{productName}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4} pr={2}>
        <Stack>
          <Typography variant="body3">Dates</Typography>
          <Typography variant="body5">
            {DateTime.fromMillis(startDate).toLocaleString(DateTime.DATE_FULL)}{' '}
            - {DateTime.fromMillis(endDate).toLocaleString(DateTime.DATE_FULL)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
