import { Button, Link, Stack, Typography } from '@mui/material';
import { COLOR, toast } from 'betterlesson-library-react';
import { MouseEvent } from 'react';
import { SessionGroupType, Workshop } from '@app-types/Workshop';
import WorkshopStatusChips from '../WorkshopStatusChips/WorkshopStatusChips';
import WorkshopStatusIcons from '../WorkshopStatusIcons/WorkshopStatusIcons';
import { copyUrl } from '../utils/UpcomingWorkshops';

type Props = {
  isPast: boolean;
  isMobile: boolean;
  workshopName: string;
  sessionGroup: SessionGroupType;
};

export const Details = ({
  isPast,
  isMobile,
  workshopName,
  sessionGroup,
}: Props): JSX.Element => {
  const handleClick = async (
    e: MouseEvent<HTMLButtonElement>,
    url?: string
  ) => {
    e.stopPropagation();
    const results = await copyUrl(url);
    if (!results) return toast.error('Failed to copy url');
    toast.success('Copied to clipboard!');
  };

  const renderMobileDetails = () =>
    isMobile ? (
      <>
        <Stack>
          <Typography fontWeight={700} fontSize={14}>
            Workshop
          </Typography>
          <Typography variant="body4">{workshopName}</Typography>

          <Stack pt={2} spacing={1} direction="row">
            <WorkshopStatusChips sessionGroup={sessionGroup} />
            <WorkshopStatusIcons sessionGroup={sessionGroup} />
          </Stack>
        </Stack>

        <Stack>
          <Typography fontWeight={700} fontSize={14}>
            Registered
          </Typography>
          <Typography fontWeight={700} fontSize={14}>
            <Link
              color={COLOR.blue[700]}
              fontStyle="normal"
              href={`${process.env.REACT_APP_WORKSHOP_WEB_URL}/${sessionGroup.workshopUuid}/participants`}
              underline="none"
              variant="subtitle1"
            >
              {sessionGroup.registeredCount}
            </Link>
          </Typography>
        </Stack>
      </>
    ) : (
      <></>
    );

  return (
    <Stack
      alignItems={isMobile ? 'flex-start' : 'center'}
      data-testid="upcoming-workshops-details"
      direction={isMobile ? 'column' : 'row'}
      fontStyle="normal"
      p="1rem"
      spacing="2rem"
      style={{ backgroundColor: 'rgba(25, 118, 210, 0.04)' }}
      onClick={(e) => e.stopPropagation()}
    >
      {renderMobileDetails()}

      <Stack
        alignSelf="flex-start"
        spacing={0.5}
        alignItems={{ xs: 'flex-start' }}
      >
        <Button
          disabled={isPast}
          disableRipple
          variant="text"
          sx={{ mt: 0, py: 0, pt: 0.5, px: 0 }}
          onClick={(e) =>
            handleClick(
              e,
              `${process.env.REACT_APP_WORKSHOP_WEB_URL}/${sessionGroup.workshopUuid}/confirm`
            )
          }
        >
          <Typography
            color={isPast ? COLOR.grey[700] : COLOR.blue[700]}
            variant="link2"
          >
            Check-In Link
          </Typography>
        </Button>

        {sessionGroup.workshopType !== Workshop.IN_PERSON && (
          <Button
            disabled={isPast}
            disableRipple
            style={{ justifyContent: 'flex-start' }}
            sx={{ mt: 0, p: 0 }}
            variant="text"
            onClick={(e) => handleClick(e, sessionGroup.zoomLink)}
          >
            <Typography
              color={isPast ? COLOR.grey[700] : COLOR.blue[700]}
              variant="link2"
            >
              Zoom Link
            </Typography>
          </Button>
        )}
      </Stack>

      {sessionGroup.facilitatorUsers.map((facilitatorUser, index: number) => (
        <Stack key={facilitatorUser.userUUID}>
          <Typography variant="body4">Facilitator {index + 1}</Typography>

          <Typography variant="caption1">
            {facilitatorUser.firstName} {facilitatorUser.lastName}
          </Typography>

          <Button
            disableRipple
            style={{ justifyContent: 'flex-start', paddingLeft: 0 }}
            variant="text"
            onClick={(e) => handleClick(e, facilitatorUser.email)}
          >
            <Typography variant="link2">{facilitatorUser.email}</Typography>
          </Button>
        </Stack>
      ))}
    </Stack>
  );
};
