import { useEffect, useState } from 'react';
import {
  ProfessionalRoleFormType,
  SubjectExperienceFormType,
} from '@app-types/ProfessionalRoles';
import ProfessionalRoleQuestion from './components/ProfessionalRoleQuestion/ProfessionalRoleQuestion';
import SubjectExperienceQuestion from './components/SubjectExperienceQuestion/SubjectExperienceQuestion';

export const QUESTION = Object.freeze({
  PROFESSIONAL_ROLE: 'professional-role',
  SUBJECT_EXPERIENCE: 'subject-experience',
});

type Props = {
  professionalRole?: ProfessionalRoleFormType;
  onChange: (professionalRoles: ProfessionalRoleFormType) => void;
};

export default function ProfessionalRoleForm({
  professionalRole,
  onChange,
}: Props): JSX.Element {
  const [question, setQuestion] = useState<string>(QUESTION.PROFESSIONAL_ROLE);
  const [role, setRole] = useState<string | undefined>(professionalRole?.role);
  const [subjectExperience, setSubjectExperience] =
    useState<SubjectExperienceFormType>({
      subjects: professionalRole?.subjects,
      experience: professionalRole?.experience,
    });

  useEffect(() => {
    setRole(professionalRole?.role);
    setSubjectExperience({
      subjects: professionalRole?.subjects,
      experience: professionalRole?.experience,
    });
  }, [professionalRole]);

  const defaultParams = {
    role,
    experience: subjectExperience?.experience,
    subjects: subjectExperience?.subjects,
  };

  const completeForm = ({
    role = defaultParams.role,
    subjects = defaultParams.subjects,
    experience = defaultParams.experience,
  }: {
    role?: string;
    subjects?: string[];
    experience?: string;
  }) => {
    if (!role) {
      return;
    }

    if (question !== QUESTION.SUBJECT_EXPERIENCE) {
      setQuestion(QUESTION.SUBJECT_EXPERIENCE);
      return;
    }

    if (!subjects?.length || !experience) {
      return;
    }

    onChange({ role, subjects, experience });
  };

  const handleRoleCardClick = (newRole: string) => {
    setRole(newRole);
    completeForm({ role: newRole });
  };

  const handleContinueClick = (
    newSubjectExperience: SubjectExperienceFormType
  ) => {
    setSubjectExperience(newSubjectExperience);
    completeForm({ ...newSubjectExperience });
  };

  const handleGoBackClick = (
    newSubjectExperience: SubjectExperienceFormType
  ) => {
    setSubjectExperience(newSubjectExperience);
    setQuestion(QUESTION.PROFESSIONAL_ROLE);
  };

  const renderQuestion = () => {
    if (question === QUESTION.PROFESSIONAL_ROLE) {
      return (
        <ProfessionalRoleQuestion
          role={role}
          onRoleCardClick={handleRoleCardClick}
        />
      );
    }

    return (
      <SubjectExperienceQuestion
        subjectExperience={subjectExperience}
        onContinueClick={handleContinueClick}
        onGoBackClick={handleGoBackClick}
      />
    );
  };

  return renderQuestion();
}
