import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import UserIcon from '@components/UserIcon/UserIcon';
import { copyText } from '@utils/copy';
import styles from './ProfileCard.module.css';

type Props = {
  givenName: string;
  familyName: string;
  email: string;
  userId: string;
};

export default function ProfileCard({
  givenName,
  familyName,
  email,
  userId,
}: Props): JSX.Element {
  return (
    <div className={styles.cardContainer} data-testid="profile-card">
      <UserIcon
        className={styles.userIcon}
        givenName={givenName}
        familyName={familyName}
        userId={userId}
      />
      <Link
        to={`/home/${userId}`}
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        <div className={styles.nameText}>
          {givenName} {familyName}
        </div>
      </Link>
      <Button onClick={() => copyText(email)} variant="text" sx={{ p: 0 }}>
        <div className={styles.emailText}>{email}</div>
      </Button>
    </div>
  );
}
