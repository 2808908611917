import { Modal, Box } from '@mui/material';
import { ModalProps, ModalReturn } from '@app-types/Modal';
import { MODAL_KEY } from '@contexts/ModalContext/ModalContext';
import WelcomeModal from '@contexts/ModalContext/components/WelcomeModal/WelcomeModal';

export type ModalContainerProps = {
  modalKey: string | null;
  onClose?: (value?: ModalReturn) => void;
  modalProps?: ModalProps;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export default function ModalContainer({
  modalKey,
  onClose,
  modalProps,
}: ModalContainerProps): JSX.Element | null {
  const getModalComponent = () => {
    if (!modalKey || !onClose) {
      return;
    }

    switch (modalKey) {
      case MODAL_KEY.WELCOME:
        return <WelcomeModal {...modalProps} onClose={onClose} />;
      default:
        return null;
    }
  };

  const handleModalClose = () => {
    if (!onClose) {
      return;
    }

    onClose();
  };

  const modal = getModalComponent();

  return modal ? (
    <Modal open={true} onClose={handleModalClose}>
      <Box sx={style}>{modal}</Box>
    </Modal>
  ) : null;
}
