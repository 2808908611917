import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { SubjectExperienceFormType } from '@app-types/ProfessionalRoles';
import ExperienceQuestion from './ExperienceQuestion';
import SubjectQuestion from './SubjectQuestion';

type Props = {
  subjectExperience: SubjectExperienceFormType;
  onContinueClick: (subjectExperience: SubjectExperienceFormType) => void;
  onGoBackClick: (subjectExperience: SubjectExperienceFormType) => void;
};

export default function SubjectExperienceQuestion({
  subjectExperience,
  onContinueClick,
  onGoBackClick,
}: Readonly<Props>): JSX.Element {
  const [experience, setExperience] = useState(subjectExperience?.experience);
  const [subjects, setSubjects] = useState(subjectExperience?.subjects ?? []);

  const handleExperienceChange = (newExperience: string) => {
    setExperience(newExperience);
  };

  const handleSubjectsChange = (newSubjects: string[]) => {
    setSubjects(newSubjects);
  };

  const handleContinueClick = () => {
    onContinueClick({ subjects, experience });
  };

  const handleGoBackClick = () => {
    onGoBackClick({ subjects, experience });
  };

  return (
    <Stack alignItems="center" px={{ xs: 1.25, sm: 9 }} pt={6} pb={4}>
      <Stack
        pt={1.5}
        pb={0.5}
        alignItems="center"
        data-testid="subject-experience-question"
      >
        <Typography variant="body1">
          Can you tell us a bit more about what you do?
        </Typography>
        <Typography variant="body1" fontStyle="italic">
          Please answer each section below.
        </Typography>
      </Stack>

      <ExperienceQuestion
        experience={experience}
        onExperienceChange={handleExperienceChange}
      />

      <SubjectQuestion
        subjects={subjects}
        onSubjectsChange={handleSubjectsChange}
      />

      <Button
        disabled={!subjects.length || !experience}
        data-testid="subject-experience-question-continue-button"
        variant="contained"
        sx={{ mb: 1 }}
        onClick={handleContinueClick}
      >
        Continue
      </Button>

      <Button
        data-testid="subject-experience-question-go-back-button"
        variant="text"
        onClick={handleGoBackClick}
      >
        Go back
      </Button>
    </Stack>
  );
}
