import {
  Box,
  Typography,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import NavigationContainer from '@components/NavigationContainer/NavigationContainer';
import LabInviteLogo from '@images/lab-invite-expired.png';

type Props = {
  serviceDisplayName?: string;
};

export default function RegistrationExpired({
  serviceDisplayName,
}: Props): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const directionStyles = isMobile ? 'column' : 'row';

  return (
    <NavigationContainer>
      <Stack
        bgcolor="#fff"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        data-testid="registration-expired"
      >
        <Box
          sx={{
            px: isMobile ? 2 : 10,
            py: isMobile ? 2 : 4,
            borderRadius: '8px',
            border: isMobile ? 'none' : '3px solid #0aabb1',
            display: 'flex',
            flexDirection: directionStyles,
            width: isMobile ? '100%' : '70%',
            textAlign: 'center',
            height: isMobile ? 'auto' : '60%',
            alignItems: 'center',
          }}
        >
          <Box
            flex="1"
            pr={isMobile ? 0 : 5}
            mb={isMobile ? 2 : 0}
            sx={{
              height: '70%',
              width: 'auto',
            }}
          >
            <img
              width="100%"
              height="100%"
              src={LabInviteLogo}
              alt="Dog watching a cat on a sailboat"
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
            />
          </Box>
          <Stack
            flex="1"
            justifyContent="center"
            alignItems="start"
            spacing={2}
            width="100%"
          >
            <Typography variant="h4">Oh shoot! You missed the boat.</Typography>
            <Typography>
              {serviceDisplayName} has closed registration.
            </Typography>
            <Typography align={isMobile ? 'center' : 'left'}>
              Check to see if you have the correct link and try again. If you
              think you are receiving this message in error, please{' '}
              <Link
                href={
                  'https://betterlesson.atlassian.net/servicedesk/customer/portals'
                }
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{
                  color: 'var(--color-link-blue-2)',
                }}
              >
                contact us.
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </NavigationContainer>
  );
}
