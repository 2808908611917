import React from 'react';
import Check from '@images/icons/check.svg';
import { onEnterKey } from '@utils/key-handler';
import styles from './CheckboxOld.module.css';

export default function CheckboxOld({
  dataId,
  checked,
  onClick,
  dataTestId,
}: {
  dataId?: string;
  checked: boolean;
  onClick: (value: string | null | void) => void;
  dataTestId?: string;
}): JSX.Element {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    onClick(dataId);
  };

  const handleKeyDown = (e: React.KeyboardEvent | void) => {
    if (!e) {
      return;
    }

    const dataId = e.currentTarget.getAttribute('data-id');
    onClick(dataId);
  };

  const dataTestIdValue = dataTestId ? dataTestId : 'checkbox';

  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={`${styles.checkbox} ${checked && styles.checked}`}
      data-testid={dataTestIdValue}
      data-id={dataId}
      onClick={handleClick}
      onKeyDown={onEnterKey(handleKeyDown)}
      tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
    >
      {checked && (
        <img
          alt="checkbox"
          className={styles.check}
          data-testid="check"
          src={Check}
        />
      )}
    </div>
  );
}
