import { Stack } from '@mui/material';
import { SERVICE_TYPE } from '@constants/product';
import AccountInvite from './Invite/AccountInvite';
import CoachingInvite from './Invite/CoachingInvite';
import WorkshopInvite from './Invite/WorkshopInvite';
import { InviteProps } from './utils';

type Props = InviteProps & {
  serviceType?: string;
};

export default function ProductInvitationSection({
  serviceType,
  ...serviceProps
}: Props): JSX.Element {
  const renderInvite = () => {
    switch (serviceType) {
      case SERVICE_TYPE.COACHING:
        return <CoachingInvite {...serviceProps} />;
      case SERVICE_TYPE.WORKSHOP:
        return <WorkshopInvite {...serviceProps} />;
      default:
        return <AccountInvite />;
    }
  };

  return (
    <Stack mt="auto" mb="auto" flexBasis={0} flexGrow={1}>
      {renderInvite()}
    </Stack>
  );
}
