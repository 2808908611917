import axios, { AxiosResponse } from 'axios';
import { ImpersonateUserTokenResponse } from '@app-types/Auth';
import { LabUser } from '@app-types/Lab';
import { OptionType } from '@app-types/Select';
import {
  BasicProfileType,
  CoachingPreferences,
  UserServiceUser,
} from '@app-types/User';
import { NavLinkV2DataType } from '@atoms/navLinksAtoms';
import { getLabAxios } from '@utils/LabAxiosFactory';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';
import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';
import { Organization } from './deprecated/organizations';

export const createUser = (user: {
  givenName: string;
  familyName: string;
  email: string;
  password: string;
}): Promise<AxiosResponse> =>
  axios.post(`${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/users/`, user);

export type GetUsersRequestParams = {
  search?: string;
  createdAfter?: number;
  createdBefore?: number;
  organization?: string;
  productTypes?: string[];
  sortField?: string;
  sortDir?: 'ASC' | 'DESC';
  page: number;
  pageSize: number;
};

type SeatAssignment = {
  activatedAt?: boolean;
  productType: string;
  seatSetId: number;
  uuid: string;
};

export type User = {
  createdAt: string;
  email: string;
  familyName: string;
  fullName: string;
  gender: string;
  givenName: string;
  id: number;
  locale: string;
  middleName: string;
  organizations: Organization[];
  phoneNumber: string;
  seatAssignments: SeatAssignment[];
  sub: string;
  userAddressId?: string;
  website?: string;
  zoneinfo?: string;
  lastLogin?: string;
};

export type GetUsersReponse = {
  users: User[];
  totalHits: number;
  stateCounts: OptionType[];
  accountTypeCounts: OptionType[];
};

export const getUsers = async (
  params: GetUsersRequestParams
): Promise<GetUsersReponse> => {
  const response = await getUserServiceAxios().get(
    `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/users/`,
    {
      params,
    }
  );

  return response.data;
};

export type ResetPasswordResult = {
  success: boolean;
  errorMessage?: string;
};

export const userServiceResetPassword = async (
  newPassword: string,
  resetToken: string
): Promise<ResetPasswordResult> => {
  return await getUserServiceAxios()
    .post(`${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/reset-password/`, {
      newPassword,
      resetToken,
    })
    .then((res) => {
      return res ? { success: true } : { success: false };
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status == 404) {
        return {
          success: false,
        };
      }
      return { success: false };
    });
};

export type ForgotPasswordResult = {
  success: boolean;
  errorMessage?: string;
};

export const userServiceForgotPassword = async (
  email: string
): Promise<ForgotPasswordResult> => {
  if (!email)
    return { success: false, errorMessage: 'Please supply an email address' };

  return await getUserServiceAxios()
    .post(`${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/forgot-password/`, {
      userIdentifier: email,
    })
    .then((res) => {
      return res ? { success: true } : { success: false };
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status == 404) {
        return {
          success: false,
          errorMessage:
            'The email provided does not match our records. Please check the email address and try again.',
        };
      }
      return { success: false };
    });
};

export const getLabUserInfo = async (): Promise<LabUser | null> => {
  try {
    const response = await getLabAxios().get('/api/userinfo');
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getUser = async (
  accessToken?: string
): Promise<UserServiceUser | null> => {
  try {
    const response = await getServiceAxios(accessToken).post(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v2/users/queries/find-self`,
      {}
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateBasicUserProfile = async (
  userSub: string,
  profile: BasicProfileType
): Promise<UserServiceUser | null> => {
  if (!userSub) {
    return null;
  }

  try {
    const response = await getUserServiceAxios().post(
      '/api/v2/users/commands/update-basic-profile',
      {
        ...profile,
        userSub,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const setCoachingPreferences = async (
  userUUID: string,
  {
    grades,
    gradeLevel,
    subjects,
    preferredSubject,
    zoneinfo,
    preferredTimeslot,
    availableTimeslot,
    weekendAvailability,
    additionalText,
    prefersPreviousCoach: preferPreviousCoach,
  }: CoachingPreferences
): Promise<UserServiceUser | null> => {
  try {
    const response = await getUserServiceAxios().post(
      '/api/v2/users/commands/set-coaching-preferences',
      {
        grades,
        gradeLevel,
        subjects,
        preferredSubject,
        zoneinfo,
        preferredTimeslot,
        availableTimeslot,
        weekendAvailability,
        additionalText,
        preferPreviousCoach,
        userUUID,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getUserNavLinksV2 = async (): Promise<NavLinkV2DataType> => {
  try {
    const response = await getUserServiceAxios().post(
      '/api/v2/users/queries/nav-links',
      {}
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return { logoUrl: '', tabs: [] };
  }
};

export const impersonateUser = async (
  userIdentifier: string
): Promise<ImpersonateUserTokenResponse | null> => {
  try {
    const response = await getUserServiceAxios().post(
      '/api/v2/users/queries/impersonate',
      { userIdentifier }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
