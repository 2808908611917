import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { toast } from 'betterlesson-library-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  DeleteIndividualUser,
  UpdateIndividualUser,
} from '@apis/deprecated/provisioning';
import FormInput from '@components/FormInput/FormInput';
import IconButton from '@components/IconButton/IconButton';
import DeleteUser from '@images/icons/delete-user.svg';
import ProvisionedUser from '../../../../models/ProvisionedUser';
import style from './UserTableRow.module.css';

type Props = {
  user: ProvisionedUser;
  disableDelete: boolean;
  disableEmailEdit: boolean;
  rowType: string;
  setUserToSpecificList: (
    userId: string,
    email: string,
    status: string
  ) => void;
  handleDeleteAndUpdateUserMaybe: (
    userExternalId: string,
    provisionedUser: ProvisionedUser
  ) => void;
};

export default function UserTableRow({
  user,
  disableDelete,
  disableEmailEdit,
  rowType,
  setUserToSpecificList,
  handleDeleteAndUpdateUserMaybe,
}: Props): JSX.Element {
  const [emailToggle, setEmailToggle] = useState(false);
  const [defaultEmailValue, setDefaultEmailValue] = useState(user.email);
  const userExternalId: string = user.uuid.toString();

  type EmailType = {
    email: string;
  };
  const validationSchema = yup.object().shape({
    email: yup.string().email('Email Invalid!').required('Email Required!'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmailType>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: defaultEmailValue },
  });

  const handleDelete = async () => {
    try {
      const response = await DeleteIndividualUser({ userId: userExternalId });
      handleDeleteAndUpdateUserMaybe(
        userExternalId,
        response.data.modifiedUser
      );
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error('Failed to delete the user, please try again', {
          className: 'toast-notify-error',
        });
      } else {
        toast.error('An error occured while trying to delete this user', {
          className: 'toast-notify-error',
        });
      }
    }
  };

  const toggleEmailVisibility = () => {
    setEmailToggle(!emailToggle);
  };

  const handleUpdateEmail = async (email: string) => {
    try {
      const response = await UpdateIndividualUser({
        email: email,
        firstName: user.firstName,
        lastName: user.lastName,
        userExternalId: userExternalId,
      });
      setUserToSpecificList(
        response.data.uuid,
        response.data.status,
        response.data.email
      );
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error('Failed to update email', {
          className: 'toast-notify-error',
        });
      } else {
        toast.error('An error occured while trying to update the email', {
          className: 'toast-notify-error',
        });
      }
    }
  };

  const onSubmit = (data: EmailType) => {
    setDefaultEmailValue(data.email);
    handleUpdateEmail(data.email);
    toggleEmailVisibility();
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };
  useEffect(() => {
    if (errors.email?.message && rowType === 'invalid') {
      toast.error('Please enter a valid email', {
        className: 'toast-notify-error',
      });
    }
  }, [errors.email?.message]);

  const isEmailBlank = !defaultEmailValue.replace(/\s/g, '').length;
  if (isEmailBlank) {
    setDefaultEmailValue('no email provided');
  }

  let emailTextNode = !emailToggle ? (
    <button
      onClick={toggleEmailVisibility}
      data-testid="email-text"
      className={
        defaultEmailValue === 'no email provided' ? style.noEmailProvided : ''
      }
    >
      {defaultEmailValue}
    </button>
  ) : null;

  const deleteUserNode = !disableDelete ? (
    <IconButton
      dataTestId="delete-user"
      icon={DeleteUser}
      containerClassName={style.deleteIconContainer}
      iconClassName={style.deleteIcon}
      text=""
      onClick={handleDelete}
    />
  ) : null;

  emailTextNode = disableEmailEdit ? (
    <span data-testid="email-text">{defaultEmailValue}</span>
  ) : (
    emailTextNode
  );

  const emailNode = emailToggle ? (
    <FormInput
      register={register('email')}
      placeholder="Email"
      error={errors.email?.message}
      type="text"
      className={style.provisioningInput}
      handleKeyDown={handleKeyDown}
      displayErrorMessage={false}
    />
  ) : null;

  return (
    <div
      className={style.userTableRow}
      key={user.uuid}
      data-testid="user-table-row"
    >
      {emailTextNode}
      {emailNode}
      <span>{user.firstName}</span>
      <span>{user.lastName}</span>
      {deleteUserNode}
    </div>
  );
}
