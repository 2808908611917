import { Box, Stack } from '@mui/material';
import Confirmation from '@components/Confirmation/Confirmation';

type Props = {
  onConfirmClick: () => void;
  onGoBackClick: () => void;
};

export default function CoachingRegistrationConfirmation({
  onConfirmClick,
  onGoBackClick,
}: Readonly<Props>): JSX.Element {
  const getDescriptionText = () => {
    return (
      <>
        <Box pb={2}>
          Once you click &quot;submit&quot;, we&apos;ll get to work on your
          personalized coach match! Please expect the process to take about a
          week, at which point your coach will reach out to schedule your first
          meeting.
        </Box>

        <Box pb={2}>
          While you&apos;re waiting, feel free to get to know the Lab, where you
          can access thousands of lesson plans, instructional resources, and
          strategies from some of the nation&apos;s most effective teachers.
        </Box>
        <Box pb={2}>
          We&apos;re honored to be part of your professional learning journey!
        </Box>
      </>
    );
  };

  return (
    <Stack
      data-testid="coaching-registration-confirmation"
      alignItems="center"
      justifyContent="center"
    >
      <Confirmation
        confirmButtonText="Submit to start finding your coach!"
        topText={<Box component="span" height={10} />}
        bottomText={getDescriptionText()}
        onConfirmClick={onConfirmClick}
        onGoBackClick={onGoBackClick}
      />
    </Stack>
  );
}
