import { Button } from '@mui/material';
import { useState } from 'react';
import Radio from '@components/Radio/Radio';
import ModalHeader from '@contexts/ModalContext/components/ModalHeader/ModalHeader';
import styles from './WelcomeModal.module.scss';

export type WelcomeModalReturn = {
  isLeader: boolean;
};

export type Props = {
  onClose: (returnedProps?: WelcomeModalReturn) => void;
};

export type WelcomeModalProps = Omit<Props, 'onClose'>;

export default function WelcomeModal({ onClose }: Props): JSX.Element {
  const [isLeader, setIsLeader] = useState(false);

  const handleCancelClick = () => {
    onClose();
  };

  const handleSubmitClick = () => {
    onClose({
      isLeader,
    });
  };

  const renderCheckbox = () => (
    <>
      <div className={styles.checkboxContainer}>
        <Radio
          checkColor="var(--color-blueberry)"
          data-testid="usermanagement-radio"
          checked={!isLeader}
          onClick={() => setIsLeader(false)}
        />
        <span className={styles.checkboxText}>
          Send Individual Participant Invites
        </span>
      </div>
      <div className={styles.checkboxContainer}>
        <Radio
          checkColor="var(--color-blueberry)"
          data-testid="usermanagement-radio"
          checked={isLeader}
          onClick={() => setIsLeader(true)}
        />
        <span className={styles.checkboxText}>
          Send Leader Invite with shareable links
        </span>
      </div>
    </>
  );

  const renderButtons = () => (
    <div className={styles.buttonContainer}>
      <div className={styles.button}>
        <Button
          fullWidth
          data-testid="welcome-cancel-button"
          variant="outlined"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
      </div>
      <div className={styles.button}>
        <Button
          fullWidth
          data-testid="welcome-ok-button"
          variant="contained"
          onClick={handleSubmitClick}
        >
          OK
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ModalHeader
        text="Send Product Invite Emails"
        onCloseClick={handleCancelClick}
      />
      <div className={styles.content}>
        <h5 className={styles.subText}>
          Select which type of email these users will receive.
        </h5>
        {renderCheckbox()}
        {renderButtons()}
      </div>
    </div>
  );
}
