import {
  Button,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { LoadingPage } from 'betterlesson-library-react';
import { SyntheticEvent, useContext, useState } from 'react';
import {
  Organization,
  OrganizationOrder,
  SeatSet,
} from '@apis/deprecated/organizations';
import VirtualizedAutocomplete from '@components/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { useOrganizationContext } from '@contexts/OrganizationContext/OrganizationContext';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import ProductInstanceAvailability, {
  Availability,
} from '../ProductInstanceAvailability/ProductInstanceAvailability';
import { boxStyle, inputStyle, selectStyle } from './styles';

type Props = {
  availabilities: Availability[];
  orderOptions: OrganizationOrder[];
  productInstanceOptions: SeatSet[];
  onOrganizationChange: (organization: Organization | null) => void;
  onOrderChange: (order: OrganizationOrder) => void;
  onInstanceChange: (options: SeatSet[]) => void;
  onCancelClick: () => void;
  onSubmitClick: () => void;
};

type OrganizationOption = Organization & { label: string };

export default function SeatAssignmentOverlay({
  availabilities,
  orderOptions,
  productInstanceOptions,
  onOrganizationChange,
  onOrderChange,
  onInstanceChange,
  onCancelClick,
  onSubmitClick,
}: Props): JSX.Element {
  const { checkedUserIds }: ProductsContextType = useContext(ProductsContext);
  const { organizations } = useOrganizationContext();
  const [organization, setOrganization] = useState<OrganizationOption | null>(
    null
  );

  const [order, setOrder] = useState<string>('');
  const [instances, setInstances] = useState<string[]>([]);

  const handleOrgChange = (
    _e: SyntheticEvent,
    value: OrganizationOption | null
  ) => {
    setOrganization(value);
    onOrganizationChange(value);
    setOrder('');
    setInstances([]);
  };

  const getOrganizationOptions = () =>
    organizations.map((o) => ({ ...o, label: o.identifier }));

  const handleOrderChange = (e: SelectChangeEvent<string>): void => {
    const {
      target: { value },
    } = e;

    setOrder(value);
    onOrderChange(JSON.parse(value) as OrganizationOrder);
    setInstances([]);
  };

  const handleInstancesChange = (e: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = e;

    setInstances(Array.from(value));
    const parsed = Array.from(value).map((val: string) => JSON.parse(val));
    onInstanceChange(parsed);
  };

  const isDisabled = (): boolean =>
    !Array.from(checkedUserIds).length ||
    !organization ||
    !order ||
    !instances.length;

  const getOrderLabel = (selected: string): string => {
    const obj = JSON.parse(selected);
    return (
      obj.salesforceOrderId ??
      obj.opportunityName ??
      `${obj.identifier} - ${obj.orderUUID}`
    );
  };

  const getInstanceLabel = (option: SeatSet): string => {
    if (typeof option === 'string') option = JSON.parse(option);
    return option.displayName
      ? option.displayName
      : `${option.productType} ${new Date(option.start).toLocaleDateString()} -
    ${new Date(option.end).toLocaleDateString()}`;
  };

  return (
    <Stack
      data-testid="seat-assignment-overlay-view"
      bgcolor="var(--color-light)"
      sx={{ pointerEvents: 'auto' }}
      height="100%"
    >
      {organizations.length > 0 ? (
        <>
          <Typography variant="h3" px={2} pt={2}>
            Invite Users to a Product
          </Typography>

          <Stack spacing={3} p={2} pb={4} mt={2} overflow="auto">
            <VirtualizedAutocomplete
              isOptionEqualToValue={(o, v) => o.uuid === v.uuid}
              data-testid="organization-select"
              style={selectStyle}
              options={getOrganizationOptions()}
              getOptionLabel={(option) => (option ? option.identifier : '')}
              value={organization}
              onChange={handleOrgChange}
              clearOnBlur={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by account"
                  InputProps={{
                    ...params.InputProps,
                    sx: { height: '56px' },
                  }}
                />
              )}
            />

            <FormControl disabled={!organization}>
              <InputLabel data-testid="order-label" style={inputStyle}>
                Orders
              </InputLabel>

              <Select
                data-testid="order-select"
                style={selectStyle}
                value={order}
                onChange={handleOrderChange}
                renderValue={(selected: string) => (
                  <Box sx={boxStyle}>
                    <Chip
                      data-testid="order-option-selected"
                      label={getOrderLabel(selected)}
                      size="small"
                    />
                  </Box>
                )}
              >
                {orderOptions.map(
                  (option: OrganizationOrder, index: number) => (
                    <MenuItem
                      data-testid="order-option"
                      key={index}
                      value={JSON.stringify(option)}
                    >
                      {getOrderLabel(JSON.stringify(option))}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <FormControl disabled={!organization || !order}>
              <InputLabel data-testid="instances-label" style={inputStyle}>
                Product Instances
              </InputLabel>

              <Select
                multiple
                data-testid="instances-select"
                value={instances || []}
                style={selectStyle}
                onChange={handleInstancesChange}
                renderValue={(selected) => (
                  <Box sx={boxStyle}>
                    {selected.map((option: string, index: number) => (
                      <Chip
                        data-testid="instances-option-selected"
                        key={index}
                        label={getInstanceLabel(JSON.parse(option))}
                        size="small"
                      />
                    ))}
                  </Box>
                )}
              >
                {productInstanceOptions.map(
                  (option: SeatSet, index: number) => (
                    <MenuItem
                      data-testid="instances-option"
                      key={index}
                      value={JSON.stringify(option)}
                    >
                      {getInstanceLabel(option)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <ProductInstanceAvailability availabilities={availabilities} />

            {Array.from(checkedUserIds).length < 1 && (
              <Typography
                color="error"
                data-testid="no-users-error"
                variant="body1"
              >
                Select some users to continue
              </Typography>
            )}

            <Stack direction="row" spacing={1}>
              <Button
                data-testid="cancel-button"
                fullWidth
                variant="outlined"
                onClick={onCancelClick}
              >
                Cancel
              </Button>

              <Button
                data-testid="submit-button"
                fullWidth
                disabled={isDisabled()}
                variant="contained"
                onClick={onSubmitClick}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <LoadingPage data-testid="seat-assignment-overlay-loading-page" />
      )}
    </Stack>
  );
}
