import { Link, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { zoomSchedulerLinkAtom } from '@atoms/user';

export const ZoomScheduler = (): JSX.Element => {
  const url = useAtomValue(zoomSchedulerLinkAtom);

  return (
    <Stack display="block" mt="2rem">
      <Typography tabIndex={0} variant="h4">
        Zoom Scheduler Link
      </Typography>

      <Link
        href={url}
        mt=".5rem"
        rel="noreferrer"
        tabIndex={0}
        target="_blank"
        underline="none"
        variant="link2"
      >
        {url}
      </Link>
    </Stack>
  );
};
