import { Getter, atom } from 'jotai';
import { DateTime } from 'luxon';
import {
  FacilitatorUserType,
  SessionDataType,
  SessionGroupMaterialsDataType,
  SessionGroupType,
  WorkshopDataType,
} from '@app-types/Workshop';
import { facilitatorWorkshopsAtom } from './facilitator';
import { userSubAtom } from './user';

export const workshopsDataAtom = atom<WorkshopDataType[]>([]);
export const registeredCountAtom = atom<Record<string, number>>({});

const getter = (get: Getter): SessionGroupType[] => {
  const workshopsData = get(workshopsDataAtom);
  const registeredCount = get(registeredCountAtom);
  const workshopToIsWorkshopDetailsUpdated = new Map(
    get(facilitatorWorkshopsAtom).map((fw) => [
      fw.workshopUuid,
      fw.isWorkshopDetailsUpdated,
    ])
  );

  const userSub = get(userSubAtom);
  if (!workshopsData) return [];

  const sessionGroupMaterialMap: Record<string, SessionGroupMaterialsDataType> =
    {};
  const facilitatorsMap: Record<string, FacilitatorUserType[]> = {};
  const usersSessionGroup = new Set<string>();
  const sessionGroupsToSessionsMap: Record<string, SessionDataType> = {};

  workshopsData.forEach((workshop) => {
    workshop.sessionGroups.forEach((sg) => {
      const material = workshop.sessionGroupMaterials.find(
        (m) => m.sessionGroupUuid === sg.uuid
      );
      if (material) sessionGroupMaterialMap[sg.uuid] = material;
    });

    workshop.facilitatorUsers.forEach((facilitator) => {
      if (facilitator.userUUID === userSub) {
        facilitator.sessionGroupUUIDs.forEach((sgUuid) => {
          usersSessionGroup.add(sgUuid);
        });
      }

      facilitator.sessionGroupUUIDs.forEach((sgUuid) => {
        if (!facilitatorsMap[sgUuid]) facilitatorsMap[sgUuid] = [];
        facilitatorsMap[sgUuid].push(facilitator);
      });
    });

    workshop.sessionGroups.forEach((sessionGroup) => {
      const sortedSessions = workshop.sessions.sort((a, b) => {
        const dateA = DateTime.fromMillis(a.sessionStartTime);
        const dateB = DateTime.fromMillis(b.sessionStartTime);
        return dateA.diff(dateB).milliseconds;
      });

      const sessions = sortedSessions.find(
        (session) => session.uuid === sessionGroup.sessionUuid
      );
      if (!sessions) return;
      sessionGroupsToSessionsMap[sessionGroup.uuid] = sessions;
    });
  });

  return workshopsData.flatMap((workshop) =>
    workshop.sessionGroups
      .filter((sg) => usersSessionGroup.has(sg.uuid))
      .map((sg) => {
        const facilitatorUsers = facilitatorsMap[sg.uuid] || [];
        const sessionGroupMaterials = sessionGroupMaterialMap[sg.uuid] || {};
        const session = sessionGroupsToSessionsMap[sg.uuid] || {};

        return {
          uuid: sg.uuid,
          facilitatorUsers,
          registeredCount: registeredCount[sg.groupUuid] || 0,
          zoomLink: sessionGroupMaterials.zoomLink || '',
          isReadyForDelivery:
            sessionGroupMaterials.materialsReadyForDelivery || false,
          modificationsFinalized:
            sessionGroupMaterials.modificationsFinalized || false,
          allModifications: workshop.modifications.concat(
            session.modifications || []
          ),

          sessionName: session.name,
          sessionNumber: session.sessionNumber,
          sessionDate: session.sessionDate,
          sessionStartTime: session.sessionStartTime,
          sessionEndTime: session.sessionEndTime,
          sessionCount: workshop.sessions.length,
          sessionStatus: session.status,
          isSessionCustom: session.isCustom,

          firstSessionDate: workshop.firstSessionDate,
          finalSessionDate: workshop.finalSessionDate,

          account: workshop.account,
          location: workshop.location,
          timezone: workshop.timezone,

          workshopName: workshop.displayName,
          workshopUuid: workshop.uuid,
          workshopType: workshop.type,
          isWorkshopDetailsUpdated:
            workshopToIsWorkshopDetailsUpdated.get(workshop.uuid) || false,
        };
      })
  );
};

export const sessionGroupsAtom = atom((get) => getter(get));
export const isPastWorkshopsAtom = atom(false);
