import { atom } from 'jotai';
import { MeetingDataType } from '@app-types/meeting';

const _atom = atom<MeetingDataType[]>([]);

export const meetingAtom = atom(
  (get) => get(_atom),
  (_get, set, updateAtom: MeetingDataType[]) => {
    set(_atom, updateAtom);
  }
);
