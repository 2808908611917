import moment from 'moment-timezone';

export const DEFAULT_TIMEZONE = 'America/New_York';

export const SORTED_TIMEZONES = [...moment.tz.names()].sort((a, b) => {
  const isMatching =
    (a.includes('US') && b.includes('US')) ||
    (a.includes('America') && b.includes('America'));

  if (isMatching) {
    return a.localeCompare(b);
  }

  if (a.includes('US')) {
    return -1;
  }

  if (b.includes('US')) {
    return 1;
  }

  if (a.includes('America')) {
    return -1;
  }

  if (b.includes('America')) {
    return 1;
  }

  return a.localeCompare(b);
});
