import {
  Product,
  ProductRole,
} from '@pages/DeprecatedPages/UserManagement/components/Permissions/types';
import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

export const getProductsRoles = async (): Promise<Product[] | null> => {
  try {
    const response = await getUserServiceAxios().get(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/products/roles`
    );

    if (response) return response.data;
    else return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateRolesAndProducts = async (
  productRoleIDs: number[],
  userUUIDs: string[],
  start: Date | null = null,
  end: Date | null = null
): Promise<boolean> => {
  try {
    await getUserServiceAxios().put(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/products/roles/users`,
      {
        productRoleIDs,
        userUUIDs,
        start,
        end,
      }
    );

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getUserRoles = async (
  userIdentifier: string
): Promise<ProductRole[] | null> => {
  try {
    const response = await getUserServiceAxios().get(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/users/user-identifier/${userIdentifier}/product-roles`
    );

    if (response) return response.data;
    else return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

type SingleUserRole = {
  product: string;
  roles: string[];
};

export const updateUserPerms = async (
  userIdentifier: string,
  products: SingleUserRole[]
): Promise<boolean> => {
  try {
    const response = await getUserServiceAxios().put(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/users/user-identifier/${userIdentifier}/product-roles`,
      products
    );

    if (response) return true;
    else return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
