import { EventAvailableRounded } from '@mui/icons-material';
import { Button, Typography, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { isPastWorkshopsAtom } from '@atoms/workshop';

export const WorkshopsToggle = (): JSX.Element => {
  const [isPastWorkshops, setIsPastWorkshops] = useAtom(isPastWorkshopsAtom);

  const handleClick = () => {
    setIsPastWorkshops(!isPastWorkshops);
  };

  return (
    <Button
      data-testid="upcoming-workshops-past-workshops"
      disableRipple
      onClick={handleClick}
    >
      <Stack alignItems="center" direction="row">
        <EventAvailableRounded />
        <Typography
          fontStyle="normal"
          color="inherit"
          ml=".25rem"
          variant="body4"
        >
          {`${isPastWorkshops ? 'Upcoming' : 'Past'} Workshops`}
        </Typography>
      </Stack>
    </Button>
  );
};
