import { AxiosResponse } from 'axios';
import {
  WorkshopDataType,
  UserWorkshopResponseType,
} from '@app-types/Workshop';
import { getServiceAxios } from '@utils/ServiceAxiosFactory';

const BASE_ROUTE = `${process.env.REACT_APP_WORKSHOP_SERVICE_URL}/api/v2/workshops`;

export const getWorkshopDetails = async (
  identifiers: string[]
): Promise<WorkshopDataType[]> => {
  if (!identifiers.length) return [];

  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/queries/find`,
      {
        identifiers,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getUserWorkshops = async (
  userUuid: string
): Promise<UserWorkshopResponseType[]> => {
  try {
    const response: AxiosResponse = await getServiceAxios().post(
      `${BASE_ROUTE}/queries/find-by-user`,
      {
        userUuid,
      }
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
