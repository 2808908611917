import { toast } from 'betterlesson-library-react';
import ProvisionedUser from '../../models/ProvisionedUser';
import style from './UserTable.module.css';
import UserTableRow from './components/UserTableRow/UserTableRow';

type Props = {
  invalidUsers: Array<ProvisionedUser>;
  duplicateUsers: Array<ProvisionedUser>;
  registeredUsers: Array<ProvisionedUser>;
  notRegisteredUsers: Array<ProvisionedUser>;
  handleSetInvalidUsers: (invalidUsers: Array<ProvisionedUser>) => void;
  handleSetDuplicateUsers: (duplicateUsers: Array<ProvisionedUser>) => void;
  handleSetRegisteredUsers: (registeredUsers: Array<ProvisionedUser>) => void;
  handleSetNotRegisteredUsers: (
    notRegisteredUsers: Array<ProvisionedUser>
  ) => void;
};

export default function UserTable({
  invalidUsers,
  duplicateUsers,
  registeredUsers,
  notRegisteredUsers,
  handleSetInvalidUsers,
  handleSetDuplicateUsers,
  handleSetRegisteredUsers,
  handleSetNotRegisteredUsers,
}: Props): JSX.Element {
  const setUserToSpecificList = (
    userExternalId: string,
    status: string,
    email: string
  ) => {
    const invalidUserToRemove: ProvisionedUser | undefined = invalidUsers.find(
      (user) => user.uuid === userExternalId
    );
    const invalidUsersFiltered: Array<ProvisionedUser> = invalidUsers.filter(
      (invalidUser) => invalidUser.uuid !== userExternalId
    );
    handleSetInvalidUsers(invalidUsersFiltered);
    if (!invalidUserToRemove) {
      toast.error('User was not found', {
        className: 'toast-notify-error',
      });
      return;
    }

    const userToMove = invalidUserToRemove;
    userToMove.email = email;
    switch (status) {
      case 'Not Registered': {
        const notRegisteredUsersUpdated: Array<ProvisionedUser> = [
          ...notRegisteredUsers,
        ];
        notRegisteredUsersUpdated.push(userToMove);
        handleSetNotRegisteredUsers(notRegisteredUsersUpdated);
        break;
      }

      case 'Registered': {
        const registeredUsersUpdated: Array<ProvisionedUser> = [
          ...registeredUsers,
        ];
        registeredUsersUpdated.push(userToMove);
        handleSetRegisteredUsers(registeredUsersUpdated);
        break;
      }

      case 'Duplicate': {
        const duplicateUsersUpdated: Array<ProvisionedUser> = [
          ...duplicateUsers,
        ];
        duplicateUsersUpdated.push(userToMove);
        handleSetDuplicateUsers(duplicateUsersUpdated);
        break;
      }
    }
  };

  const invalidNodeArray: Array<JSX.Element> = [];
  const duplicateNodeArray: Array<JSX.Element> = [];
  const notRegisteredNodeArray: Array<JSX.Element> = [];
  const registeredNodeArray: Array<JSX.Element> = [];

  const handleDeleteAndUpdateUserMaybe = (
    userExternalId: string,
    modifiedUser: ProvisionedUser | null
  ) => {
    const filteredListOfDuplicateUsers: Array<ProvisionedUser> =
      duplicateUsers.filter(function (el) {
        return el.uuid != userExternalId && el.uuid != modifiedUser?.uuid;
      });
    const filteredListOfRegisteredUsers: Array<ProvisionedUser> =
      registeredUsers.filter(function (el) {
        return el.uuid != userExternalId;
      });

    const filteredListOfNotRegisteredUsers: Array<ProvisionedUser> =
      notRegisteredUsers.filter(function (el) {
        return el.uuid != userExternalId;
      });

    if (modifiedUser !== null) {
      const status = modifiedUser.status;
      switch (status) {
        case 'Not Registered': {
          filteredListOfNotRegisteredUsers.push(modifiedUser);
          break;
        }
        case 'Registered': {
          filteredListOfRegisteredUsers.push(modifiedUser);
          break;
        }
      }
    }
    handleSetDuplicateUsers(filteredListOfDuplicateUsers);
    handleSetRegisteredUsers(filteredListOfRegisteredUsers);
    handleSetNotRegisteredUsers(filteredListOfNotRegisteredUsers);
  };

  invalidUsers.forEach((invalidUser) => {
    invalidNodeArray.push(
      <UserTableRow
        user={invalidUser}
        key={invalidUser.uuid}
        disableDelete={true}
        disableEmailEdit={false}
        rowType={'invalid'}
        setUserToSpecificList={setUserToSpecificList}
        handleDeleteAndUpdateUserMaybe={handleDeleteAndUpdateUserMaybe}
      ></UserTableRow>
    );
  });

  duplicateUsers.forEach((duplicateUser) => {
    duplicateNodeArray.push(
      <UserTableRow
        user={duplicateUser}
        key={duplicateUser.uuid}
        disableDelete={false}
        disableEmailEdit={true}
        rowType={'duplicate'}
        setUserToSpecificList={setUserToSpecificList}
        handleDeleteAndUpdateUserMaybe={handleDeleteAndUpdateUserMaybe}
      ></UserTableRow>
    );
  });

  notRegisteredUsers.forEach((notRegisteredUser) => {
    notRegisteredNodeArray.push(
      <UserTableRow
        user={notRegisteredUser}
        key={notRegisteredUser.uuid}
        disableDelete={false}
        disableEmailEdit={true}
        rowType={'notRegistered'}
        setUserToSpecificList={setUserToSpecificList}
        handleDeleteAndUpdateUserMaybe={handleDeleteAndUpdateUserMaybe}
      ></UserTableRow>
    );
  });

  registeredUsers.forEach((registeredUser) => {
    registeredNodeArray.push(
      <UserTableRow
        user={registeredUser}
        key={registeredUser.uuid}
        disableDelete={false}
        disableEmailEdit={true}
        rowType={'registered'}
        setUserToSpecificList={setUserToSpecificList}
        handleDeleteAndUpdateUserMaybe={handleDeleteAndUpdateUserMaybe}
      ></UserTableRow>
    );
  });

  const invalidUsersTableNode =
    invalidUsers.length > 0 ? (
      <div data-testid="invalid-users-table">
        <span className={style.tableTitle}>
          {invalidNodeArray.length} INVALID EMAILS
        </span>
        <div className={`${style.userTable} ${style.invalid}`}>
          {invalidNodeArray}
        </div>
        <div
          className={style.tableDescription}
          data-testid="invalid-table-description"
        >
          <span>
            Please correct any errors, any invalid accounts will not be added
          </span>
        </div>
      </div>
    ) : null;

  const duplicateUsersTableNode =
    duplicateUsers.length > 0 ? (
      <div data-testid="duplicate-users-table">
        <span className={style.tableTitle}>
          {duplicateNodeArray.length} DUPLICATE EMAILS
        </span>
        <div className={`${style.userTable} ${style.duplicate}`}>
          {duplicateNodeArray}
        </div>
      </div>
    ) : null;

  const registeredUsersTableNode =
    registeredUsers.length > 0 ? (
      <div data-testid="registered-users-table">
        <span className={style.tableTitle}>
          {registeredNodeArray.length} EXISTING BETTERLESSON ACCOUNTS
        </span>
        <div className={`${style.userTable} ${style.registered}`}>
          {registeredNodeArray}
        </div>
        <div
          className={style.registeredTableDescription}
          data-testid="registered-table-description"
        >
          <span>Users will be moved to this account</span>
        </div>
      </div>
    ) : null;

  const notRegisteredUsersTableNode =
    notRegisteredUsers.length > 0 ? (
      <div data-testid="not-registered-users-table">
        <span className={style.tableTitle}>
          {notRegisteredNodeArray.length} VALID EMAILS
        </span>
        <div className={`${style.userTable} ${style.notRegistered}`}>
          {notRegisteredNodeArray}
        </div>
      </div>
    ) : null;

  return (
    <div className={style.container} data-testid="user-table">
      {invalidUsersTableNode}
      {duplicateUsersTableNode}
      {registeredUsersTableNode}
      {notRegisteredUsersTableNode}
    </div>
  );
}
