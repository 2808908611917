import { LoadingPage } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';
import { AccessToken } from '@app-types/Token';
import { hasStoredRedirect } from '@utils/LabRedirectPath';
import { handleRedirectAfterLogin } from '@utils/RedirectHandler';

export default function AuthorizationRequired(): JSX.Element {
  const { user } = useAuth();

  const isAllowedAccessToUserManagement = () => {
    const accessToken = user?.access_token;
    if (!accessToken) {
      return false;
    }
    const { product_roles } = jwtDecode<AccessToken>(`${accessToken}`);
    const permissibleRoles: string[] = ['super_admin', 'admin'];
    const userManagementAccess: string[] = product_roles['USER_MANAGEMENT'];

    return (
      userManagementAccess &&
      permissibleRoles.some((role) => userManagementAccess.includes(role))
    );
  };

  useEffect(() => {
    if (hasStoredRedirect() || !isAllowedAccessToUserManagement()) {
      void handleRedirectAfterLogin(user ?? undefined);
    }
  }, []);

  if (hasStoredRedirect() || !isAllowedAccessToUserManagement()) {
    return <LoadingPage data-testid="authorization-loading-page" />;
  }

  return <Outlet></Outlet>;
}
