import { Stack, CircularProgress } from '@mui/material';
import { toast } from 'betterlesson-library-react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { createAccountParticipant } from '@apis/account-participants';
import { AccountType } from '@app-types/Account';

interface Props {
  account?: AccountType | null;
}

export default function AccountRegistration({
  account,
}: Readonly<Props>): JSX.Element {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userUUID = user?.profile.sub ?? '';

  const acceptInvite = async () => {
    const accountUuid = account?.uuid || '';

    const response = await createAccountParticipant({
      userUUID,
      accountUUID: accountUuid,
    });

    if (!response) {
      return toast.error(
        'Invitation to account failed, please refresh the page to try again.',
        {
          className: 'toast-notify-error',
        }
      );
    }

    return navigate('/participant/home');
  };

  useEffect(() => {
    acceptInvite();
  }, []);

  return (
    <Stack
      data-testid="account-registration"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress size={80} />
    </Stack>
  );
}
