import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { Stack, Typography, Box, Link } from '@mui/material';
import { COLOR } from 'betterlesson-library-react';

export default function ContactSupport(): JSX.Element {
  return (
    <Stack alignItems="center" py={2.5} data-testid="contact-support">
      <Typography variant="subtitle1">Something looks wrong?</Typography>
      <Link
        data-testid="contact-support-link"
        target="_blank"
        href="https://betterlesson.atlassian.net/servicedesk/customer/portals"
        variant="subtitle1"
        color={COLOR.blue[700]}
        sx={{ textDecoration: 'none' }}
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>Contact Support</Box> <ArrowCircleRightRoundedIcon />
        </Stack>
      </Link>
    </Stack>
  );
}
