import { Route } from 'react-router-dom';
import { userServiceForgotPassword } from '@apis/users';
import AuthenticationRequired from '@components/AuthRequired/AuthenticationRequired';
import AuthorizationRequired from '@components/AuthRequired/AuthorizationRequired';
import ExistingAuth from '@components/ExistingAuth/ExistingAuth';
import { OrganizationProvider } from '@contexts/OrganizationContext/OrganizationContext';
import BLConnectWelcome from '@pages/DeprecatedPages/BLConnectWelcome/BLConnectWelcome';
import OrgInvitation from '@pages/DeprecatedPages/OrgInvitation/OrgInvitation';
import RegisterBLConnect from '@pages/DeprecatedPages/ProductRegistration/BLConnect/RegisterBLConnect';
import RegisterCohort from '@pages/DeprecatedPages/ProductRegistration/Cohort/RegisterCohort';
import RegisterEvent from '@pages/DeprecatedPages/ProductRegistration/Event/RegisterEvent';
import RegisterOrderProduct from '@pages/DeprecatedPages/ProductRegistration/OrderProduct/RegisterOrderProduct';
import Redirect from '@pages/DeprecatedPages/Redirect/Redirect';
import UserManagement from '@pages/DeprecatedPages/UserManagement/UserManagement';
import ProvisioningBulkUpload from '@pages/DeprecatedPages/UserManagement/components/ProvisioningBulkUpload/ProvisioningBulkUpload';
import ForgotPassword from '@pages/ForgotPassword/ForgotPassword';
import LabRedirect from '@pages/LabRedirect/LabRedirect';
import UserServiceLogin from '@pages/Login/UserServiceLogin';
import UserServiceRegister from '@pages/Register/UserServiceRegister';

const deprecatedRoutes = (
  <>
    <Route element={<ExistingAuth />}>
      <Route path="/invite/:orgUuid" element={<OrgInvitation />} />
    </Route>

    <Route element={<AuthenticationRequired />}>
      <Route element={<AuthorizationRequired />}>
        <Route path="/" element={<Redirect />} />
        <Route element={<OrganizationProvider />}>
          <Route path="/users" element={<UserManagement />} />
          <Route
            path="/users/provisioning"
            element={<ProvisioningBulkUpload />}
          />
        </Route>
      </Route>
      <Route
        path="/bl-connect/:sfId/register"
        element={<RegisterBLConnect />}
      />
      <Route path="/order-product/:Id" element={<RegisterOrderProduct />} />
      <Route path="/cohort/:sfId/register" element={<RegisterCohort />} />
      <Route path="/event/:sfId/register" element={<RegisterEvent />} />
      <Route path="/bl-connect-welcome" element={<BLConnectWelcome />} />
    </Route>

    <Route path="/lab" element={<LabRedirect />} />
    <Route path="/lab/login" element={<UserServiceLogin />} />
    <Route path="/lab/register" element={<UserServiceRegister />} />
    <Route
      path="/lab/forgot-password"
      element={<ForgotPassword onForgotPassword={userServiceForgotPassword} />}
    />
  </>
);

export default deprecatedRoutes;
