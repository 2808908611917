import { LoadingPage } from 'betterlesson-library-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { activateSeatSet } from '@apis/deprecated/ActivateSeatSet';

export default function RegisterCohort(): JSX.Element {
  const { sfId } = useParams();

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    activateSeatSet(sfId!).then((redirectURI) => {
      const url = new URL(redirectURI);
      window.location.assign(url.toString());
    });
  }, []);

  return <LoadingPage data-testid="register-cohort-loading-page" />;
}
