import { Avatar, Button, Typography } from '@mui/material';
import { COLOR, getUserIconColor } from 'betterlesson-library-react';

type Props = {
  givenName: string;
  familyName: string;
};

export default function EditUserIcon({
  givenName,
  familyName,
}: Readonly<Props>): JSX.Element {
  const initials = givenName && familyName ? givenName[0] + familyName[0] : '';

  return (
    <Avatar
      data-testid="edit-user-icon"
      sx={{ height: '75px', width: '75px', position: 'relative' }}
      style={{ backgroundColor: getUserIconColor(initials) }}
    >
      <Typography
        fontWeight="medium"
        color="#fff"
        fontSize="30px"
        sx={{ textTransform: 'uppercase' }}
      >
        {initials}
      </Typography>

      <Button
        sx={{
          position: 'absolute',
          bottom: 0,
          bgcolor: COLOR.grey[800],
          height: '30px',
          width: '100%',
          borderRadius: 0,
          opacity: 0.5,
          color: '#fff',
          '&:hover': {
            backgroundColor: COLOR.grey[800],
          },
        }}
        component="label"
      >
        Edit
        <input type="file" hidden />
      </Button>
    </Avatar>
  );
}
