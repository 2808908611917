import {
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
} from '@mui/icons-material';
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Typography,
  Collapse,
  Link,
} from '@mui/material';
import { COLOR } from 'betterlesson-library-react';
import { Fragment, useState } from 'react';
import { SessionGroupType } from '@app-types/Workshop';
import { DateColumn } from '../DateColumn/DateColumn';
import { Details } from '../Details/Details';
import { SessionColumn } from '../SessionColumn/SessionColumn';
import WorkshopStatusChips from '../WorkshopStatusChips/WorkshopStatusChips';
import WorkshopStatusIcons from '../WorkshopStatusIcons/WorkshopStatusIcons';

export type Props = {
  isPast: boolean;
  isMobile: boolean;
  sessionGroup: SessionGroupType;
};

export default function WorkshopTableRow({
  isPast,
  isMobile,
  sessionGroup,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);
  const name = `${sessionGroup.account} - ${sessionGroup.workshopName} - ${sessionGroup.firstSessionDate}`;

  return (
    <Fragment key={sessionGroup.uuid}>
      <TableRow style={{ height: 'auto' }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <KeyboardArrowUpOutlined />
            ) : (
              <KeyboardArrowDownOutlined />
            )}
          </IconButton>
        </TableCell>

        <TableCell sx={{ pl: 0 }}>
          <Box fontStyle="normal">
            <SessionColumn
              sessionGroupUuid={sessionGroup.uuid}
              sessionGroupName={sessionGroup.sessionName}
              sessionNumber={sessionGroup.sessionNumber}
              workshopUuid={sessionGroup.workshopUuid}
              length={sessionGroup.sessionCount}
            />
          </Box>
        </TableCell>

        {!isMobile && (
          <TableCell>
            <Typography fontStyle="normal" variant="body5">
              {name}
            </Typography>
          </TableCell>
        )}

        <TableCell>
          <DateColumn
            sessionDate={sessionGroup.sessionDate}
            sessionStartTime={sessionGroup.sessionStartTime}
            sessionEndTime={sessionGroup.sessionEndTime}
            timezone={sessionGroup.timezone}
          />
        </TableCell>

        {!isMobile && (
          <TableCell>
            <Link
              color={COLOR.blue[700]}
              fontStyle="normal"
              href={`${process.env.REACT_APP_WORKSHOP_WEB_URL}/${sessionGroup.workshopUuid}/participants?sessionGroupUuid=${sessionGroup.uuid}`}
              underline="none"
              variant="subtitle1"
              target="_blank"
            >
              {sessionGroup.registeredCount}
            </Link>
          </TableCell>
        )}

        {!isMobile && (
          <TableCell>
            <WorkshopStatusChips sessionGroup={sessionGroup} />
          </TableCell>
        )}

        {!isPast && !isMobile && (
          <TableCell>
            <WorkshopStatusIcons sessionGroup={sessionGroup} />
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell colSpan={12} style={{ padding: 0 }}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Details
              isMobile={isMobile}
              isPast={isPast}
              workshopName={name}
              sessionGroup={sessionGroup}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
