import axios from 'axios';
import QueryString from 'qs';
import { ExchangeRefreshTokenResponse } from '@app-types/Auth';
import { tryGetApiError } from '@utils/BLApiError';

const getSessionId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get('sessionId');

  if (!sessionId) {
    throw Error('SessionId parameter is required');
  }

  return sessionId;
};

const getOrgUuid = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const orgUuid = searchParams.get('orgUuid');

  if (!orgUuid) {
    return null;
  }

  return orgUuid;
};

export type Session = {
  redirectURI: string;
  authCode: string;
  state: string;
};

export const authenticateWithUserService = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<Session> => {
  const sessionId = getSessionId();
  const orgUuid = getOrgUuid();

  const postData: Record<string, string> = {
    sessionID: sessionId,
    email: email,
    password: password,
  };
  if (orgUuid) postData.orgUuid = orgUuid;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/oauth/authenticate`,
      postData
    );

    return response.data;
  } catch (e) {
    const apiError = tryGetApiError(e);
    if (apiError) {
      throw apiError;
    }
    throw e;
  }
};

type AuthenticateWithRefreshTokenResponse = {
  session: Session;
  parentToken: string;
};

export const authenticateWithRefreshToken = async (
  refreshToken: string
): Promise<AuthenticateWithRefreshTokenResponse> => {
  const sessionId = getSessionId();

  const response = await axios.post(
    `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/oauth/authenticate-from-parent`,
    {
      sessionID: sessionId,
      parentRefreshToken: refreshToken,
    }
  );

  return response.data;
};

export const validateTokenAndGetEmail = async (
  sub: string,
  token: string
): Promise<string> => {
  const response = await axios.get(
    `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/password-reset-token?userIdentifier=${sub}&resetToken=${token}`
  );
  return response.data;
};

export const setUserPW = async (
  givenName: string,
  familyName: string,
  email: string,
  password: string,
  confirmPassword: string,
  resetToken: string
): Promise<Session> => {
  const orgUuid = getOrgUuid();
  const postData: Record<string, string> = {
    givenName: givenName,
    familyName: familyName,
    email: email,
    password: password,
    confirmPassword: confirmPassword,
    resetToken: resetToken,
  };
  if (orgUuid) postData.orgUuid = orgUuid;

  const response = await axios.put(
    `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/users/${email}/password`,
    postData
  );

  return response.data;
};
export const registerUser = async (user: {
  givenName: string;
  familyName: string;
  email: string;
  password: string;
}): Promise<Session> => {
  const sessionId = getSessionId();
  const orgUuid = getOrgUuid();
  const { givenName, familyName, email, password } = user;
  const postData: Record<string, string> = {
    givenName,
    familyName,
    email,
    password,
    sessionID: sessionId,
  };
  if (orgUuid) postData.orgUuid = orgUuid;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/oauth/register`,
      postData
    );

    return response.data;
  } catch (e) {
    const apiError = tryGetApiError(e);
    if (apiError) {
      throw apiError;
    }
    throw e;
  }
};

export const exchangeRefreshToken = async (
  refreshToken: string
): Promise<ExchangeRefreshTokenResponse | null> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/oauth/token`,
      QueryString.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      })
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
