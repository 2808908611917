import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { getLabUserInfo } from '@apis/users';
import { LabUser } from '@app-types/Lab';
import { AccessToken } from '@app-types/Token';
import { PENDO_LOADED_EVENT } from '@utils/Pendo';

const BL_ACCOUNT_ID = 111089;

export const PendoContainer = (): null => {
  const { isAuthenticated, user }: AuthContextProps = useAuth();

  const getProductRoles = () => {
    if (!user) {
      return [];
    }

    const { product_roles } = jwtDecode<AccessToken>(user.access_token);

    const validProductRoles = Object.entries(product_roles).filter(
      ({ 1: roles }) => roles.length
    );

    return validProductRoles.map(([product]) => product);
  };

  const getUserProfessionalRole = (labUser: LabUser) => {
    const userProfessionalRole = {
      professionalRole: '',
      teacherExperience: '',
      teacherSubject: '',
    };

    const professionalRole = labUser.professional_role;

    if (!professionalRole) {
      return userProfessionalRole;
    }

    const { role, subjectExperience } = professionalRole;
    userProfessionalRole.professionalRole = role;

    if (!subjectExperience) {
      return userProfessionalRole;
    }

    userProfessionalRole.teacherExperience = subjectExperience.experience;
    userProfessionalRole.teacherSubject = subjectExperience.subject;
    return userProfessionalRole;
  };

  const getPendoVisitor = (labUser: LabUser | null) => {
    if (!labUser) {
      return { id: '' };
    }

    return {
      id: String(labUser.id),
      email: labUser.email,
      authRoles: labUser.auth_roles,
      productRoles: getProductRoles(),
      firstName: labUser.first_name,
      full_name: `${labUser.first_name} ${labUser.last_name}`,
      lastLogin: labUser.last_login,
      ...getUserProfessionalRole(labUser),
    };
  };

  const getPendoAccount = (labUser: LabUser | null) => {
    if (!labUser) {
      return;
    }

    if (labUser.partner_id) {
      return { id: String(labUser.partner_id) };
    }

    if (
      labUser.auth_roles.includes('BL_TEAM_MEMBER') ||
      labUser.auth_roles.includes('BL_CONTRACT_COACH')
    ) {
      return { id: String(BL_ACCOUNT_ID) };
    }

    return;
  };

  const handlePendoReady = () => {
    window.dispatchEvent(PENDO_LOADED_EVENT);
  };

  const fetchUserInfo = async (): Promise<LabUser | null> => {
    const userInfo = isAuthenticated ? await getLabUserInfo() : null;

    if (!userInfo) {
      handlePendoReady();
    }

    return userInfo;
  };

  const initializePendo = async () => {
    const labUser = await fetchUserInfo();
    const visitor = getPendoVisitor(labUser);
    const account = getPendoAccount(labUser);

    // This is basically exactly copy pasted from pendo, it's simplest to ignore linting
    /* eslint-disable */
    (function (apiKey) {
      (function (p, e, n, d) {
        var v: any, w, x, y, z;
        // @ts-ignore
        const o = (p[d] = p[d] || {});
        // @ts-ignore
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            // @ts-ignore
            o[m] =
              // @ts-ignore
              o[m] ||
              function () {
                // @ts-ignore
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        y = e.createElement(n);
        // @ts-ignore
        y.async = !0;
        // @ts-ignore
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        // @ts-ignore
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');

      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      if (!labUser?.is_excluded_from_pendo) {
        if (visitor.id) {
          // @ts-ignore
          pendo.initialize({
            visitor,
            account,
            events: {
              ready: handlePendoReady,
            },
          });
        } else {
          const pendoVisitorId = localStorage.getItem(
            `_pendo_visitorId.${process.env.REACT_APP_PENDO_API_KEY}`
          );
          if (pendoVisitorId) {
            const parsedPendoVisitorId = JSON.parse(pendoVisitorId);
            if (!parsedPendoVisitorId?.value?.startsWith('_PENDO_T_')) {
              localStorage.removeItem(
                `_pendo_visitorId.${process.env.REACT_APP_PENDO_API_KEY}`
              );
            }
          }
          // @ts-ignore
          pendo.initialize({
            visitor: { id: 'VISITOR-UNIQUE-ID' },
            account: {},
          });
        }
      }
      // @ts-ignore
    })(process.env.REACT_APP_PENDO_API_KEY);
    /* eslint-enable */
  };

  useEffect(() => {
    initializePendo();
  }, []);

  return null;
};
