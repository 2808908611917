import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material/';
import structuredClone from '@ungap/structured-clone';
import { Fragment, useContext, useEffect, useState } from 'react';
import { getUserRoles } from '@apis/deprecated/roles-products';
import { HIDDEN_PRODUCTS } from '@constants/product';
import ProductsContext, {
  ProductsContextType,
} from '@pages/DeprecatedPages/UserManagement/ProductsContext';
import { boxStyle } from '../../SeatAssignmentOverlay/components/SeatAssignmentOverlayView/styles';
import { Product, ProductRole } from '../types';

type Props = {
  onRoleChange: (newRoles: Record<string, string[]>) => void;
  isSuperAdmin: boolean;
};

const sortProducts = (products: Product[]) => {
  const newProducts = structuredClone(products);

  newProducts.sort((a: Product, b: Product) =>
    a.product.productName.localeCompare(b.product.productName)
  );

  return newProducts;
};

export default function SingleEdit({
  onRoleChange,
  isSuperAdmin,
}: Props): JSX.Element {
  const [selectedRoles, setSelectedRoles] = useState<Record<string, string[]>>(
    {}
  );
  const { checkedUserIds, products }: ProductsContextType =
    useContext(ProductsContext);
  const [sortedProducts] = useState(sortProducts(products));

  const isHidden = (product: string): boolean =>
    HIDDEN_PRODUCTS.includes(product);

  const hydrateRoles = async (): Promise<void> => {
    const userProductRoles: ProductRole[] | null = await getUserRoles(
      Array.from(checkedUserIds).toString()
    );

    if (!userProductRoles) return;

    const productRolesMap: Record<string, string[]> = Object.fromEntries(
      sortedProducts.map(({ product }) => [product.productName, []])
    );

    userProductRoles.forEach(({ productName, roleName }) => {
      const productRoles = productRolesMap[productName];
      if (!productRoles) return;
      productRoles.push(roleName);
    });

    setSelectedRoles(productRolesMap);
    onRoleChange(productRolesMap);
  };

  const roleChange = (
    e: SelectChangeEvent<string[]>,
    productName: string
  ): void => {
    const {
      target: { value },
    } = e;

    const state = structuredClone(selectedRoles);
    state[productName] = Array.isArray(value) ? value : [value];
    setSelectedRoles(state);
    onRoleChange(state);
  };

  const renderRoleOptions = (roles: ProductRole[]): JSX.Element[] =>
    roles.map((role: ProductRole, index: number) => (
      <MenuItem key={index} value={role.roleName}>
        {role.roleName}
      </MenuItem>
    ));

  useEffect(() => {
    hydrateRoles();
  }, []);

  return (
    <Grid container spacing={1} data-testid="usermanagement-single-edit">
      <Grid item xs={12}>
        {sortedProducts.map(({ product, productRoles }, index: number) => (
          <Fragment key={index}>
            {!isHidden(product.productName) && (
              <FormControl fullWidth margin="normal">
                <InputLabel>{product.productName}</InputLabel>

                <Select
                  data-testid="usermanagement-single-edit--select"
                  disabled={!isSuperAdmin}
                  label={product.productName}
                  multiple
                  name="role"
                  renderValue={(selected: string[]) => (
                    <Box sx={boxStyle}>
                      {selected.map((roleName: string, i: number) => (
                        <Chip size="small" key={i} label={roleName} />
                      ))}
                    </Box>
                  )}
                  style={{ height: '100%' }}
                  value={selectedRoles[product.productName] || []}
                  onChange={(e) => roleChange(e, product.productName)}
                >
                  {renderRoleOptions(productRoles)}
                </Select>
              </FormControl>
            )}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
}
