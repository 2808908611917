export const PRODUCT_TYPE = Object.freeze({
  COACHING: 'Coaching',
  BL_CONNECT: 'BL Connect',
  EVENTS: 'Events',
});

export const PRODUCT_TYPE_DROPDOWN_OPTIONS = Object.entries(PRODUCT_TYPE).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

export const PRODUCT_TYPE_LABEL: Record<string, string> = Object.freeze({
  BL_CONNECT: 'BL Connect',
  COACHING: 'Coaching',
  EVENTS: 'Events',
  USER_MANAGEMENT: 'User Management',
  UTILIZATION_REPORTING: 'Utilization Reporting',
});

export const HIDDEN_PRODUCTS = ['EVENTS'];

export const SERVICE_TYPE = Object.freeze({
  COACHING: 'COACHING',
  WORKSHOP: 'WORKSHOP',
});
