import { Box, Stack } from '@mui/material';
import bubbles1 from '@images/icons/bubbles1.svg';
import bubbles2 from '@images/icons/bubbles2.svg';
import bubbles3 from '@images/icons/bubbles3.svg';
import teachersAndStudents from '@images/icons/teachers.svg';
import styles from './AccountWrapper.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function AccountWrapper({ children }: Props): JSX.Element {
  return (
    <>
      <Stack
        bgcolor="#e1f5fe"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: { sm: '100vh' },
          width: { sm: '100vw', xs: '100%' },
          overflow: { sm: 'hidden' },
        }}
      >
        <Stack
          sx={{
            px: { xs: 2.5, sm: '60px' },
            py: { xs: '40px' },
            my: { xs: 5, sm: 0 },
            borderRadius: '8px',
            border: '3px solid #0aabb1',
            height: '75%',
            bgcolor: '#fff',
            overflow: 'auto',
            alignItems: 'center',
            font: "normal normal 300 16px/20px 'Circular Std'",
            color: '#3d3d3d',
            zIndex: 1,
            gap: { xs: '1em', sm: '5em', md: '10em' },
            flexDirection: { xs: 'column', sm: 'row' },
            flexFlow: { xs: 'wrap', sm: 'unset' },
            width: { xs: '85%' },
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {children}
        </Stack>
      </Stack>

      <Box className={styles['svg-overlay-bubble1']}>
        <img src={bubbles1} alt="Bubble 1" />
      </Box>
      <Box className={styles['svg-overlay-bubble2']}>
        <img src={bubbles2} alt="Bubble 2" />
      </Box>
      <Box className={styles['svg-overlay-teacher']}>
        <img src={teachersAndStudents} alt="Teachers and students smiling" />
      </Box>
      <Box className={styles['svg-overlay-bubble3']}>
        <img src={bubbles3} alt="Bubble 3" />
      </Box>
    </>
  );
}
