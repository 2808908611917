import {
  Stack,
  SvgIconOwnProps,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { COLOR_PALETTE } from 'betterlesson-library-react';

export type Props = {
  label: string;
  isActive: boolean;
  activeColor: string;
  Icon: OverridableComponent<SvgIconTypeMap<SvgIconOwnProps, 'svg'>> & {
    muiName: string;
  };
};

export const TEST_ID = { CONTAINER: 'tab-label' };

export default function TabLabel({
  label,
  isActive,
  Icon,
  activeColor,
}: Props): JSX.Element {
  const color = isActive ? activeColor : COLOR_PALETTE.text.secondary;

  return (
    <Stack data-testid={TEST_ID.CONTAINER} direction="row" spacing={1}>
      <Icon sx={{ fill: color }} />
      <Typography color={color} textTransform="none" fontWeight={400}>
        {label}
      </Typography>
    </Stack>
  );
}
