import { getUserServiceAxios } from '@utils/UserServiceAxiosFactory';

export type OrgInfoType = {
  orgUuid: string;
  emailDomains: string[];
  orgIdentifier: string;
  displayName?: string;
};

export const getOrgInformation = async (
  orgUuid: string
): Promise<OrgInfoType | undefined> => {
  try {
    const response = await getUserServiceAxios().get(
      `/api/v1/invites/org-info/${orgUuid}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
