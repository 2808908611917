import { LoadingPage } from 'betterlesson-library-react';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { AccessToken } from '@app-types/Token';

// TODO: Implement intelligent redirect based on product role
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getURIFromRoles = (productRoles: Record<string, string[]>): string => {
  return 'users';
};

export default function Redirect(): JSX.Element {
  const { user, isAuthenticated, isLoading } = useAuth();

  const getRedirectURI = (): string => {
    if (!user?.access_token) {
      return 'users';
    }

    const { product_roles } = jwtDecode<AccessToken>(user.access_token);
    return getURIFromRoles(product_roles);
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const redirectURI = getRedirectURI();
      window.location.assign(redirectURI);
    }
  }, []);

  return <LoadingPage data-testid="redirect-loading-page" />;
}
