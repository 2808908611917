import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'betterlesson-library-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { getOrgInformation } from '@apis/deprecated/org-info';
import { APIError } from '@utils/BLApiError';
import LoginView from './components/LoginView/LoginView';

export type LoginType = {
  email: string;
  password: string;
  username: string;
};

type Props = {
  onAuthenticate: (data: LoginType) => Promise<void>;
};

export default function Login({ onAuthenticate }: Props): JSX.Element {
  const [searchParams] = useSearchParams();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const orgUuid = searchParams.get('orgUuid');
  const [emailDomains, setEmailDomains] = useState<string[]>([]);
  const [selectedEmailDomain, setSelectedEmailDomain] = useState<string>();

  const requireUsername = () => yup.string().required('Username Required!');
  const requireEmail = () =>
    yup.string().required('Email Required!').email('Email Invalid!');
  const requirePassword = () => yup.string().required('Password Required!');

  const validationSchema = yup.object().shape({
    username: emailDomains.length ? requireUsername() : yup.string(),
    email: !emailDomains.length ? requireEmail() : yup.string(),
    password: requirePassword(),
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<LoginType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: LoginType) => {
    if (orgUuid && emailDomains.length)
      setValue('email', `${data.username}${selectedEmailDomain}`);

    try {
      setIsLoggingIn(true);
      await onAuthenticate(watch());
    } catch (e) {
      console.error(e);
      const getToast = (e: unknown): string => {
        if (e instanceof APIError) {
          switch (e.apiErrorCode) {
            case 'SSOFailureSetPasswordForLabBackfillUser':
              return 'Hi we haven’t seen you in awhile, so please check your email for a link to reset your password.';
            case 'SSOFailureNoPassword':
              return 'Hi there! Please check your email for a link to set your password.';
            default:
              return 'Email and Password Combination not correct.';
          }
        }
        return 'Login Failed.';
      };
      const message = getToast(e);
      toast.error(message, {
        className: 'toast-notify-error',
      });
      if (orgUuid && emailDomains.length) setValue('username', data.username);
    }
    setIsLoggingIn(false);
  };

  const handleGoogleClick = () => {
    // TODO add Google IDP logic
  };

  const handleCleverClick = () => {
    const url = new URL(
      `${process.env.REACT_APP_USER_SERVICE_URL}/api/v1/integrations/clever/login`
    );
    url.searchParams.set('sessionId', searchParams.get('sessionId') || '');
    window.location.assign(url.toString());
  };

  const handleClasslinkClick = () => {
    // TODO add Classlink IDP logic
  };

  const handleUpdateEmailDomain = (domain: string) =>
    setSelectedEmailDomain(domain);

  const init = async () => {
    if (!orgUuid) return;
    const org = await getOrgInformation(orgUuid);
    if (!org) return;
    setEmailDomains(org.emailDomains);
    setSelectedEmailDomain(org.emailDomains[0]);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <LoginView
      isLoggingIn={isLoggingIn}
      emailRegister={register('email')}
      emailError={errors.email?.message}
      usernameRegister={register('username')}
      usernameError={errors.username?.message}
      emailDomains={emailDomains}
      selectedEmailDomain={selectedEmailDomain}
      passwordRegister={register('password')}
      passwordError={errors.password?.message}
      onSubmit={handleSubmit(onSubmit)}
      onGoogleClick={handleGoogleClick}
      onCleverClick={handleCleverClick}
      onClasslinkClick={handleClasslinkClick}
      handleUpdateEmailDomain={handleUpdateEmailDomain}
    />
  );
}
