import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { COLOR, toast } from 'betterlesson-library-react';
import { useAtom } from 'jotai';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import * as yup from 'yup';
import { updateBasicUserProfile } from '@apis/users';
import { BasicProfileType } from '@app-types/User';
import { basicProfileAtom } from '@atoms/profile';
import ProfileActionBar from '@components/ProfileActionBar/ProfileActionBar';
import BasicProfileInput from './components/BasicProfileInput/BasicProfileInput';
import EditUserIcon from './components/EditUserIcon/EditUserIcon';
import TimezoneAutocomplete from './components/TimezoneAutocomplete/TimezoneAutocomplete';

const basicProfileSchema = yup.object().shape({
  givenName: yup.string().required('Cannot be empty!'),
  familyName: yup.string().required('Cannot be empty!'),
  zoneinfo: yup.string().required('Cannot be empty!'),
  zoomSchedulerUrl: yup.string().url(),
});

export default function BasicProfile(): JSX.Element {
  const { user } = useAuth();
  const userSub = user?.profile.sub ?? '';
  const userEmail = user?.profile.email ?? '';
  const [basicProfile, setBasicProfile] = useAtom(basicProfileAtom);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<BasicProfileType>({
    resolver: yupResolver(basicProfileSchema),
    defaultValues: basicProfile,
  });

  const handleTimezoneChange = (timezone: string) => {
    setValue('zoneinfo', timezone, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<BasicProfileType> = async (
    updatedBasicProfile
  ) => {
    const response = await updateBasicUserProfile(userSub, updatedBasicProfile);

    if (!response) {
      return toast.error('Update to basic profile failed.', {
        className: 'toast-notify-error',
      });
    }

    toast.success('Basic profile has been updated', {
      className: 'toast-notify-success',
    });

    reset({
      familyName: response.familyName,
      givenName: response.givenName,
      zoneinfo: response.zoneinfo,
    });

    setBasicProfile(updatedBasicProfile);
  };

  return (
    <Stack py={2.5} spacing={2}>
      <EditUserIcon
        givenName={getValues('givenName')}
        familyName={getValues('familyName')}
      />

      <Stack>
        <Tooltip
          arrow
          title="Need to fix this? Use the contact support button below."
        >
          <Box>
            <Stack spacing={0.5}>
              <Typography variant="subtitle1" component="div">
                Email
              </Typography>

              <TextField disabled value={userEmail} />
            </Stack>
          </Box>
        </Tooltip>

        <Link
          data-testid="basic-profile-password-reset-link"
          target="_blank"
          href="/forgot-password"
          variant="subtitle1"
          fontSize="14px"
          color={COLOR.blue[700]}
          sx={{ textDecoration: 'none' }}
        >
          Password Reset
        </Link>
      </Stack>

      <BasicProfileInput
        control={control}
        name="givenName"
        label="First Name"
        error={errors.givenName?.message}
      />

      <BasicProfileInput
        control={control}
        name="familyName"
        label="Last Name"
        error={errors.familyName?.message}
      />

      <TimezoneAutocomplete
        control={control}
        error={errors.zoneinfo?.message}
        onTimezoneChange={handleTimezoneChange}
      />

      <BasicProfileInput
        control={control}
        name="zoomSchedulerUrl"
        label="Zoom Scheduler Link"
        error={errors.zoomSchedulerUrl?.message}
      />

      <ProfileActionBar
        onSubmitClick={handleSubmit(onSubmit)}
        isSubmitDisabled={!isDirty}
      />
    </Stack>
  );
}
